import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  MDBTable, 
  MDBTableHead, 
  MDBTableBody, 
  MDBBtn, 
  MDBModal, 
  MDBModalDialog, 
  MDBModalContent, 
  MDBModalHeader, 
  MDBModalTitle, 
  MDBModalBody, 
  MDBModalFooter, 
  MDBCard, 
  MDBCardBody, 
  MDBIcon, 
  MDBCardTitle,
  MDBInput,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink
} from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from "react-i18next";
import useDebounce from '../../../utils/debounce';

const client = generateClient();
const ITEMS_PER_PAGE = 50;

export default function AdminAllJobListings() {
  const { t, i18n } = useTranslation();
  const [jobsList, setJobsList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    fetchJobs();
  }, [currentPage]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearch();
    } else {
      fetchJobs();
    }
  }, [debouncedSearchTerm]);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      // First search for companies matching the search term
      const companiesResponse = await client.graphql({
        query: /* GraphQL */ `
          query SearchCompanies(
            $filter: SearchableCompanyFilterInput
            $limit: Int
          ) {
            searchCompanies(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
                name
              }
            }
          }
        `,
        variables: {
          filter: {
            name: {
              matchPhrasePrefix: debouncedSearchTerm
            }
          },
          limit: 100
        }
      });

      const matchingCompanyIds = companiesResponse.data.searchCompanies.items.map(company => company.id);

      // Search for job listings
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchCMSJobListings(
            $filter: SearchableCMSJobListingFilterInput
            $limit: Int
            $from: Int
          ) {
            searchCMSJobListings(
              filter: $filter
              limit: $limit
              from: $from
            ) {
              items {
                id
                name {
                  language
                  text
                }
                status
                company {
                  id
                  name
                  image {
                    main {
                      fileName
                      alt
                    }
                  }
                }
                employee {
                  id
                  image {
                    fileName
                    alt
                  }
                  user {
                    firstName
                    lastName
                  }
                }
                createdAt
                updatedAt
              }
              total
            }
          }
        `,
        variables: {
          filter: {
            or: [
              ...(matchingCompanyIds.length > 0 ? [{
                companyJobsId: {
                  eq: matchingCompanyIds[0]
                }
              }] : [])
            ]
          },
          limit: ITEMS_PER_PAGE,
          from: (currentPage - 1) * ITEMS_PER_PAGE
        }
      });

      setJobsList(response.data.searchCMSJobListings.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      setTotalJobs(response.data.searchCMSJobListings.total);
    } catch (error) {
      console.error('Error searching jobs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchCMSJobListings(
            $limit: Int
            $from: Int
          ) {
            searchCMSJobListings(
              limit: $limit
              from: $from
            ) {
              items {
                id
                name {
                  language
                  text
                }
                status
                approved
                company {
                  id
                  name
                  image {
                    main {
                      fileName
                      alt
                    }
                  }
                }
                employee {
                  id
                  image {
                    fileName
                    alt
                  }
                  user {
                    firstName
                    lastName
                  }
                }
                createdAt
                updatedAt
              }
              total
            }
          }
        `,
        variables: {
          limit: ITEMS_PER_PAGE,
          from: (currentPage - 1) * ITEMS_PER_PAGE
        }
      });

      setJobsList(response.data.searchCMSJobListings.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      setTotalJobs(response.data.searchCMSJobListings.total);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteJob = async () => {
    try {
      await client.graphql({
        query: /* GraphQL */ `
          mutation DeleteCMSJobListing($input: DeleteCMSJobListingInput!) {
            deleteCMSJobListing(input: $input) {
              id
            }
          }
        `,
        variables: { input: { id: selectedJob } }
      });
      setDeleteModal(false);
      setJobsList(jobsList.filter(job => job.id !== selectedJob));
    } catch (error) {
      console.error('Error deleting job listing:', error);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const totalPages = Math.ceil(totalJobs / ITEMS_PER_PAGE);

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <MDBCardTitle>{t("Job Listings")}</MDBCardTitle>
            <MDBInput
              type='text'
              label={t('Search by job title or company name')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              contrast
              style={{ maxWidth: '300px' }}
            />
          </div>

          {isLoading ? (
            <div className="text-center">
              <MDBIcon fas icon="spinner" spin /> {t("Loading")}...
            </div>
          ) : (
            <MDBTable>
              <MDBTableHead>
                <tr>
                  <th>{t("Date")}</th>
                  <th>{t("Company")}</th>
                  <th>{t("Job Title")}</th>
                  <th>{t("Posted By")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {jobsList.map(job => (
                  <tr key={job.id}>
                    <td>
                      {new Date(job.createdAt).toLocaleDateString('de-DE', { 
                        day: '2-digit', 
                        month: '2-digit', 
                        year: '2-digit' 
                      })}
                      {new Date(job.createdAt).toLocaleDateString() !== 
                       new Date(job.updatedAt).toLocaleDateString() && (
                        <>
                          <br />
                          <span className="text-success" style={{fontSize: "smaller"}}>
                            {new Date(job.updatedAt).toLocaleDateString('de-DE', { 
                              day: '2-digit', 
                              month: '2-digit', 
                              year: '2-digit' 
                            })}
                          </span>
                        </>
                      )}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        {job.company?.image?.main?.fileName ? (
                          <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${job.company.image.main.fileName}`}
                            className="rounded-circle border"
                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                            alt={job.company.name}
                          />
                        ) : (
                          <MDBIcon 
                            className="rounded-circle border"
                            style={{ 
                              height: "50px", 
                              width: "50px", 
                              textAlign: "center", 
                              paddingTop: "12px", 
                              fontSize: "20px" 
                            }}
                            fas 
                            icon="building" 
                          />
                        )}
                        <div className='ms-3'>
                          <p className='fw-bold mb-1'>{job.company?.name}</p>
                        </div>
                      </div>
                    </td>
                    <td>{job.name?.find(n => n.language === i18n.language)?.text}</td>
                    <td>
                      {job.employee && (
                        job.employee?.image?.fileName ? (
                          <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${job.employee.image.fileName}`}
                            className="rounded-circle border"
                            style={{ height: "50px", width: "50px", objectFit: "cover" }}
                            alt={`${job.employee.user.firstName} ${job.employee.user.lastName}`}
                          />
                        ) : (
                          <MDBIcon
                            className="rounded-circle border"
                            style={{
                              height: "50px",
                              width: "50px",
                              textAlign: "center",
                              paddingTop: "12px",
                              fontSize: "20px"
                            }}
                            fas
                            icon="user-alt"
                          />
                        )
                      )}
                    </td>
                    <td>
                      {job.approved ? (
                        <MDBIcon fas icon="check" className="text-success" />
                      ) : (
                        <MDBIcon fas icon="clock" className="text-warning" />
                      )}
                      {' '}
                      {job.status === "created" ? null : t(job.status)}
                    </td>
                    <td>
                      <Link to={`/admin/company-job/${job.id}`}>
                        <MDBBtn className='mx-2 my-0 py-0' color='link'>
                          {t("Edit")}
                        </MDBBtn>
                      </Link>
                      <MDBBtn
                        className='mx-2 my-0 py-0'
                        color='link'
                        style={{color: "red"}}
                        onClick={() => {
                          setSelectedJob(job.id);
                          toggleDeleteModal();
                        }}
                      >
                        {t("Delete")}
                      </MDBBtn>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          )}

          {totalPages > 1 && (
            <MDBPagination className="mb-0 justify-content-center">
              <MDBPaginationItem disabled={currentPage === 1}>
                <MDBPaginationLink 
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  tabIndex={-1}
                >
                  {t("Previous")}
                </MDBPaginationLink>
              </MDBPaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <MDBPaginationItem key={index + 1} active={currentPage === index + 1}>
                  <MDBPaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}

              <MDBPaginationItem disabled={currentPage === totalPages}>
                <MDBPaginationLink 
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                >
                  {t("Next")}
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          )}
        </MDBCardBody>
      </MDBCard>

      <MDBModal open={deleteModal} onClose={toggleDeleteModal} staticBackdrop>
        <MDBModalDialog centered size="sm">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("Delete Job Listing")}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleDeleteModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {t("Are you sure you want to delete this job listing?")}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleDeleteModal}>
                {t("Cancel")}
              </MDBBtn>
              <MDBBtn color='danger' onClick={deleteJob}>
                {t("Delete")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}