import React, { useEffect } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBIcon,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBCheckbox,
  MDBBtn,
  MDBInputGroup,
  MDBAlert,
  MDBValidationItem,
  MDBTextArea,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBAccordion,
  MDBAccordionItem,
  MDBInput,
  MDBFile,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBChip,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import UploadImage from "./UploadImage";
import contents from "./describeLectureContents.json";
import SelectTopics from "../../../../../topics/SelectTopics";
import { generateClient } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';
const client = generateClient();

export default function DescribeLecture({ handleChange, lecture, handleNextClick, activeStep, companyId, setLectureData, event }) {
  const { t, i18n } = useTranslation();
  //IMAGE INPUT HANDLING
  //--> Hier muss noch das Hochgeladene Bild gespeichert und angezeigt werden
  const [isImageConditionChecked, setIsImageConditionChecked] = useState(false);

  const handleImageConditionChange = () => {
    setIsImageConditionChecked(!isImageConditionChecked);
  };

  //TITLE INPUT HANDLING
  const [titleInputDE, setTitleInputDE] = useState(null);
  const [titleInputEN, setTitleInputEN] = useState(null);

  const handleTitleInputChange = (language, event) => {
    if (language === "DE") {
      setTitleInputDE(event.target.value);
    } else if (language === "EN") {
      setTitleInputEN(event.target.value);
    }
  };

  //DESCRIPTION INPUT HANDLING
  const [descriptionInputDE, setDescriptionInputDE] = useState(null);
  const [descriptionInputEN, setDescriptionInputEN] = useState(null);

  const handleDescriptionInputChange = (language, event) => {
    if (language === "DE") {
      setDescriptionInputDE(event.target.value);
    } else if (language === "EN") {
      setDescriptionInputEN(event.target.value);
    }
  };

  //SUBJECTS INPUT HANDLING
  const subjectItemsCount = 5;
  const [subjectsDE, setSubjectsDE] = useState([]);
  const [subjectsEN, setSubjectsEN] = useState([]);

  const [subjectInputDE, setSubjectInputDE] = useState([]);
  const [subjectInputEN, setSubjectInputEN] = useState([]);

  const handleSubjectInputChange = (language, event) => {
    if (language === "DE") {
      setSubjectInputDE(event.target.value);
    } else if (language === "EN") {
      setSubjectInputEN(event.target.value);
    }
  };

  const handleSubjectSubmit = (event) => {
    event.preventDefault();

    if (subjectInputDE.trim() !== "" && subjectInputEN.trim() !== "") {
      handleChange({
        target: {
          value: {
            id: uuidv4(),
            name: [
              {
                text: subjectInputDE,
                language: "de",
              },
              {
                text: subjectInputEN,
                language: "en",
              }
            ]
          },
          attributes: {
            datapath: {
              value: lecture?.subjects?.length ? "subjects[" + lecture.subjects.length + "]" : "subjects[0]"
            }
          },
        },
      });
      setSubjectInputDE("");
      setSubjectInputEN("");
  };
}

  const deleteSubject = (subjectId) => {
    handleChange({
      target: {
        value: null,
        attributes: {
          datapath: {
            value: "subjects[" + lecture?.subjects?.findIndex((subject) => subject.id === subjectId) + "]"
          }
        },
      },
    });
  };

  const changeSubjectOrder = (subjectId, direction) => {
    const subjectIndex = lecture?.subjects?.findIndex((subject) => subject.id === subjectId);
    if (direction === "up") {
      const currentSubject = lecture.subjects[subjectIndex];
      const previousSubject = lecture.subjects[subjectIndex - 1];
      handleChange({
        target: {
          value: previousSubject,
          attributes: {
            datapath: {
              value: "subjects[" + subjectIndex + "]"
            }
          },
        },
      });
      handleChange({
        target: {
          value: currentSubject,
          attributes: {
            datapath: {
              value: "subjects[" + (subjectIndex - 1) + "]"
            }
          },
        },
      });
    } else if (direction === "down") {
      const currentSubject = lecture.subjects[subjectIndex];
      const nextSubject = lecture.subjects[subjectIndex + 1];
      handleChange({
        target: {
          value: nextSubject,
          attributes: {
            datapath: {
              value: "subjects[" + subjectIndex + "]"
            }
          },
        },
      });
      handleChange({
        target: {
          value: currentSubject,
          attributes: {
            datapath: {
              value: "subjects[" + (subjectIndex + 1) + "]"
            }
          },
        },
      });
    }
  };


 

  //SCRIPT INPUT HANDLING
  //--> Hier murr noch das hochgeladene Skript gespeichert und angezeigt werden
  const [isScriptConditionChecked, setIsScriptConditionChecked] =
    useState(false);

  const handleScriptConditionChange = () => {
    setIsScriptConditionChecked(!isScriptConditionChecked);
  };

  //CHECK INPUT
  const [alertDanger, setAlertDanger] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // setErrorMessage and return boolean which sais if error is thrown or not
  const getErrorMessage = () => {
    // --> if (imageInput is Empty) {errorMessage = "Bitte laden Sie ein Titel Bild hoch"}
    if (!titleInputDE || !titleInputEN) {
      setErrorMessage("Bitte fügen Sie in beiden Sprachen einen Titel hinzu");
      return true;
    }else if (
      lecture?.subjects.length === 0
    ) {
      setErrorMessage(
        "Bitte fügen Sie in beiden Sprachen mindestens ein Thema hinzu"
      );
      return true;
    }
    // --> else if (scriptInput is Empty) {setErrorMessage("Bitte laden Sie ein Script hoch") return true;}
    else if (!isImageConditionChecked || !isScriptConditionChecked) {
      setErrorMessage("Bitte Bestätigen Sie alle Bedingungen");
      return true;
    }
    return false;
  };

  const handleContinue = () => {
    if (getErrorMessage()) {
      setAlertDanger(true);
      //setComplete(false);
    } else {
      //setComplete(true);
      //Hier auf letzte Seite verlinken
    }
  };

  const [examplesCollapse, setExamplesCollapse] = useState({
    title: {
      de: false,
      en: false,
    },
    description: {
      de: false,
      en: false,
    },
  });

  // Topics
  // Select Topics 
  const [topics, setTopics] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState(lecture.topics || []);


  const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);

  const handleSetSelectedTopics = (topics) => {
    setSelectedTopics(topics);
    setLectureData(oldLecture => ({...oldLecture, topics}));
  }


  // Topics data

  const getTopics = async () => {
      try {
        const topicGroupsWithTopics = await client.graphql({
          query: /* GraphQL */ `
          query ListTopicGroups(
            $filter: ModelTopicGroupFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                color
                name {
                  language
                  text
                }
                topics {
                  items {
                    id
                    name {
                      language
                      text
                    }
                    topicGroupTopicsId
                  }
                }
              }
              nextToken
              __typename
            }
          }
        `
          });
          setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
          const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
          setTopics(topics);

      } catch (error) {
          console.log("error on fetching topics", error);
      }
  }

  useEffect(() => {
      if (topics.length === 0) {
          getTopics();
      }
  }, [topics]);

  const Topics = ({topics, topicGroups, selectedTopics}) => {
    console.log("topics",topics);
    console.log("topicGroups",topicGroups);
    console.log("selectedTopics",selectedTopics);
    // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
    if(topics?.length === 0 || topicGroups?.length === 0 || selectedTopics?.length === 0) {
        return null;
    }
    let sortedTopics = [];
    selectedTopics.forEach((selectedTopic) => {
        const topicData = topics.find((topic) => topic.id === selectedTopic);
        const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
        const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
        if (topicGroupIndex === -1) {
            sortedTopics.push({...topicGroup, topics: [topicData]});
        } else {
            sortedTopics[topicGroupIndex].topics.push(topicData);
        }
    });
    console.log("sortedTopics",sortedTopics);
    return (
        <MDBRow className="my-3 my-lg-4">
            {sortedTopics.map((topicGroup) => {
                return (
                    <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
                        <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                        <MDBRow>
                            {topicGroup.topics.map((topic) => {
                                return (
                                    <MDBCol size="12" key={topic.id}>
                                        <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                    </MDBCol>
                                )
                            })}
                        </MDBRow>
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}

const setImageFileName = (fileName) => {
  // handleChange({
  //   target: {
  //     value: {
  //       fileName: fileName,
  //       alt: "Lecture Image",
  //     },
  //     attributes: {
  //       datapath: {
  //         value: "image"
  //       }
  //     },
  //   },
  // });
}

const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState({
  De: false,
  En: false,
});

const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState({
  De: null,
  En: null,
});

const handleSetFile = (file, language) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
  try {
    setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: true});
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onerror = error => {
    console.log('Error: ', error);
    setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: error});
  }
  reader.onload = async () => {
   try {
    const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
    const response = await client.graphql({
      query: /* GraphQL */ `
        mutation UploadLectureFile($base64FileBuffer: String!, $companyId: String!) {
          uploadLectureFile(base64FileBuffer: $base64FileBuffer, companyId: $companyId) {
            message
            fileName
          }
        }
      `,
      variables: {
        base64FileBuffer,
        companyId,
      },
    });
    console.log("response", response);
    handleChange({
      target: {
        value: {
          fileName: response.data.uploadLectureFile.fileName,
          alt: "Lecture Image",
        },
        attributes: {
          datapath: {
            value: "pdf" + language
          }
        },
      },
    });
   } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: false});
    setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: error.errors[0].message});
   }
  };
  
  setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: false});
  setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: null});
  } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: false});
    setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: error.errors[0].message});
  }
  
}
  
}

const [isUploadingLectureImage, setIsUploadingLectureImage] = useState(false);
const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);

const handleSetImage = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
  if(file) {
    try {
      setIsUploadingLectureImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error reader: ', error);
      setUploadingLectureImageError(error);
    }
    reader.onload = async () => {
      try {
        console.log(reader.result)
        const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
        const response = await client.graphql({
          query: /* GraphQL */ `
          mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
            uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
          }
        `,
          variables: {
            imageBuffer: base64FileBuffer,
            imageType: "lectureImage",
          },
        });
        console.log("response", response);
        const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
        handleChange({
          target: {
            value: {
              fileName,
              alt: "Lecture Image",
            },
            attributes: {
              datapath: {
                value: "image"
              }
            },
          },
        });
        } catch (error) {
          console.log("error", error);
          setIsUploadingLectureImage(false);
          setUploadingLectureImageError(error.errors[0].message);
        }
    }
    setIsUploadingLectureImage(false);
    setUploadingLectureImageError(null);
    } catch (error) {
      console.log("error", error);
      setIsUploadingLectureImage(false);
      setUploadingLectureImageError(error.errors[0].message);
    }
  }
}


  return (
    <>
      <MDBContainer>

  {/* LECTURE Topics */}
  <MDBRow className="d-flex justify-content-evenly">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
     
      <div className="mb-2 mb-lg-4">
        <MDBTypography tag="h5">
          {t("Select topics")}
        </MDBTypography>
        <span className="text-muted">
        {t("Select up tp 3 topics that best describe your promotion.")}
        </span>
      </div>
      <div className="w-100 text-center">
      <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
                    {t("Select Topics")}
                </MDBBtn>
                <MDBRow className="w-100">
                    <MDBCol>
                       <Topics
                        topics={topics}
                        topicGroups={topicGroups}
                        selectedTopics={lecture.topics || []}
                        setSelectedTopics={handleSetSelectedTopics}
                        />
                    </MDBCol>
                </MDBRow>
      </div>
    </MDBCol>
    
        </MDBRow>
        {/* LECTURE Topics END */}
  

        <hr className="my-4" />

        {/* LECTURE TITEL */}
        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12" lg="10" xl="8" xxl="7">
          <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
              {t("Enter the title of the promotion (max. 60 characters)")}
            </MDBTypography>
            <span className="text-muted">
            {t("The title of the promotion should clearly convey what it is about and at the same time justify why the topic is interesting and relevant.")}
            </span>
          </div>
           
            <MDBInputGroup className="mb-1" textAfter={<span style={lecture.title?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((lecture?.title?.length > 0? lecture.title.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="de"
                  svg
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  title={i18n.language}
                /> 
              </div>
              <MDBInput
                label={t("Title German")}
                type="text"
                maxLength="60"
                value={
                  lecture?.title?.length > 0
                    ? lecture.title.find((t) => t.language === "de")?.text
                    : ""
                }
                datapath={
                  "title[" +
                  (lecture?.title?.length > 0
                    ? lecture.title.findIndex(
                        (t) => t.language === "de"
                      ) === -1
                      ? lecture.title.length
                      : lecture.title.findIndex((t) => t.language === "de")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "de"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
            </MDBInputGroup>
            <MDBInputGroup className="mb-1" textAfter={<span style={lecture.title?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((lecture?.title?.length > 0? lecture.title.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="gb"
                  svg
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  title={i18n.language}
                />
              </div>
              <input
                className="form-control"
                placeholder={t("Title English")}
                type="text"
                maxLength="60"
                value={
                  lecture?.title?.length > 0
                    ? lecture.title.find((t) => t.language === "en")?.text
                    : ""
                }
                datapath={
                  "title[" +
                  (lecture?.title?.length > 0
                    ? lecture.title.findIndex(
                        (t) => t.language === "en"
                      ) === -1
                      ? lecture.title.length
                      : lecture.title.findIndex((t) => t.language === "en")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "en"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
            </MDBInputGroup>
           
            <div className="mt-3 mb-1">

                

            </div>

          
          </MDBCol>
        </MDBRow>
        {/* LECTURE TITEL END */}

        



        <hr className="my-4" />

        

         {/* LECTURE SUBJECTS */}
         <MDBRow className="d-flex justify-content-evenly" style={{fontSize: "0.9rem"}}>
          <MDBCol size="12">
            <div className="mb-5">
              <MDBTypography tag="h5">
                {t("Name 3 to 5 sub-points/key points for the presentation (max. 60 characters)")}
              </MDBTypography>
              {/* <span className="text-muted">
              {t("Please enter the topics of your lecture. You can add up to 5 topics.")}
              </span> */}
            </div>
            {[...Array(subjectItemsCount)].map((item, index) => 
              <MDBRow className="w-100">
                <MDBCol size="12" lg="6" className="px-1">
                  <MDBInputGroup className="mb-1 mb-md-3" textAfter={<span style={lecture?.tempSubjects?.[index]?.de?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{(lecture?.tempSubjects?.[index]?.de?.length || "0") + "/60"}</span>}>
                    <div className="input-group-text">
                      <ReactCountryFlag
                        countryCode="de"
                        svg
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                    <MDBInput
                      type="text"

                      maxLength="60"
                      label={t("Sub-Point") + " " + (index + 1) + " " +  t("Geman")}
                      value={lecture?.tempSubjects?.[index]?.de}
                      datapath={"tempSubjects[" + index + "].de"}
                      onChange={handleChange}
                    />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol size="12" lg="6" className="px-1">
                  <MDBInputGroup className="mb-3" textAfter={<span style={lecture?.tempSubjects?.[index]?.en?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{(lecture?.tempSubjects?.[index]?.en?.length || "0") + "/60"}</span>}>
                    <div className="input-group-text">
                      <ReactCountryFlag
                        countryCode="gb"
                        svg
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                    <MDBInput
                      type="text"
                      maxLength="60"
                      label={t("Sub-Point") + " " + (index + 1) + " " +  t("English")}
                      value={lecture?.tempSubjects?.[index]?.en}
                      datapath={"tempSubjects[" + index + "].en"}
                      onChange={handleChange}
                    />
                  </MDBInputGroup>
                </MDBCol>
              </MDBRow>
            )
            }
              {/* <div className="w-100 text-center">
                <MDBBtn outline type="submit" onClick={handleSubjectSubmit} disabled={lecture?.subjects?.length > 4}>
                  Thema Hinzufügen
                </MDBBtn>
                <span className={ + lecture?.subjects?.length > 4 ? "fw-bold align-items-center text-danger" : "fw-bold align-items-center"}> {lecture?.subjects?.length || 0}/5</span>
              </div> */}

            {/* <MDBListGroup
              style={{ minWidthL: "22rem" }}
              className="shadow-3 mt-3"
            >
              {lecture?.subjects?.length > 0 ? (
                lecture.subjects.map((subject, index) => {
                  return (
                    <MDBListGroupItem
                      key={index}
                      noBorders
                      className="d-flex justify-content-between align-items-center mt-0 ps-3"
                    >
                     <MDBRow className="w-100">
                        <MDBCol size="12" lg="6">
                          <div className="d-flex justify-content-start gap-4 align-items-center">
                            <ReactCountryFlag
                              countryCode="de"
                              svg
                              style={{
                                width: "17px",  
                                height: "17px",
                              }}
                              title={i18n.language}
                            />
                            {subject.name.find((name) => name.language === "de")?.text || subject.name[0]?.text}
                          </div>
                        </MDBCol>
                        <MDBCol size="12" lg="6">
                          <div className="d-flex justify-content-start gap-4 align-items-center">
                            <ReactCountryFlag
                              countryCode="gb"
                              svg
                              style={{
                                width: "17px",
                                height: "17px",
                              }}
                              title={i18n.language}
                            />
                            {subject.name.find((name) => name.language === "en")?.text || subject.name[0]?.text}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBBtn className="px-1" color="tertiary" onClick={() => changeSubjectOrder(subject.id, "up")}>
                        <MDBIcon fas icon="arrow-up" size="1x" />
                      </MDBBtn>
                      <MDBBtn className="px-1" color="tertiary" onClick={() => changeSubjectOrder(subject.id, "down")}>
                        <MDBIcon fas icon="arrow-down" size="1x" />
                      </MDBBtn>
                      <MDBBtn
                       className="px-1"
                        color="tertiary"
                        style={{ color: "#dc3545" }}
                        onClick={() => deleteSubject(subject.id)}
                      >
                        <MDBIcon fas icon="trash-alt" size="1x" />
                      </MDBBtn>
                    </MDBListGroupItem>
                  );
                })
              ) : (
                <MDBListGroupItem className="d-flex justify-content-center align-items-center">
                  <span className="text-muted">
                    {t("No subjects added yet")}
                  </span>
                </MDBListGroupItem>
              )
              }
            </MDBListGroup> */}
            
          </MDBCol>
            
        </MDBRow>
        {/* LECTURE SUBJECTS END */}

        <hr className="my-4" />
{/* LECTURE DESCRIPTION */}
<MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12" lg="10" xl="8" xxl="7">
          <div className="mb-5">
              <MDBTypography tag="h5">
                {t("Describe the content of the promotion. (Min. 500 characters)")}
              </MDBTypography>
              <span className="text-muted">
              {t("The purpose of the description is to give visitors an insight into the content of the promotion and to arouse their curiosity.")}
              </span>
            </div>
          

            <MDBInputGroup className="mb-3" textAfter={<span style={(lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "de")?.text: "")?.length < 500 ? {color: "red", fontWeight: "bold"} : {color: "green"}}>{((lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "de")?.text: "")?.length || "0") + "/500"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="de"
                  svg
                  style={{ width: "20px", height: "20px" }}
                  title={i18n.language}
                />
              </div>
              <MDBTextArea
                label={t("Promotion description German")}
                id='descriptionde'
                rows={4}
                cols={60}
                value={
                  lecture?.content?.length > 0
                    ? lecture.content.find((t) => t.language === "de")?.text
                    : ""
                }
                datapath={
                  "content[" +
                  (lecture?.content?.length > 0
                    ? lecture.content.findIndex(
                        (t) => t.language === "de"
                      ) === -1
                      ? lecture.content.length
                      : lecture.content.findIndex((t) => t.language === "de")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "de"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
              
            </MDBInputGroup>

            <MDBInputGroup className="mb-3" textAfter={<span style={(lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "en")?.text: "")?.length < 500 ? {color: "red", fontWeight: "bold"} : {color: "green"}}>{((lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "en")?.text: "")?.length || "0") + "/500"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="gb"
                  svg
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  title={i18n.language}
                />
              </div>
              <MDBTextArea
                label={t("Promotion description English")}
                id='descriptionde'
                cols={60}
                rows={4}
                value={
                  lecture?.content?.length > 0
                    ? lecture.content.find((t) => t.language === "en")?.text
                    : ""
                }
                datapath={
                  "content[" +
                  (lecture?.content?.length > 0
                    ? lecture.content.findIndex(
                        (t) => t.language === "en"
                      ) === -1
                      ? lecture.content.length
                      : lecture.content.findIndex((t) => t.language === "en")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "en"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
            </MDBInputGroup>
            
           
          </MDBCol>
         
        </MDBRow>
        {/* LECTURE DESCRIPTION END */}
        <hr className="my-4" />

{/* LECTURE IMAGE */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
    <div className="mb-5">
      <MDBTypography tag="h5">
      {t("Upload an image for your promotion")}
      </MDBTypography>
      <span className="text-muted">
      {t("Choose a topic-related image for the promotion without writing so that it is suitable for both German and English presentation.(It is not permitted to select a photo of the speaker or just the company logo)")}
      </span>
      <br />
      <span className="text-muted" style={{fontSize: "smaller"}}>{t("Recommended format: 16:9")}</span>
    </div>
    <div className="w-100 text-center mb-2 mb-lg-4">
    <img
      src={lecture?.image?.fileName ? 
      "https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/" + lecture.image.fileName: "https://via.placeholder.com/600x400"}
      className="img-thumbnail"
      style={{ height: "300px", maxWidth: "100%", width: "auto", aspectRatio: "16 / 9" , objectFit: "cover" }}
      alt='...' />
    </div>
    <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
    {
      isUploadingLectureImage.De && <span><MDBSpinner role="status" /></span>
    }
    {
      uploadingLectureImageError && <span className="mt-3 text-danger fw-bold">
      {uploadingLectureImageError}
    </span>
    }
   
    <div className="mt-3">
      <MDBCheckbox
        name="image-condition"
        id="image-condition"
        label={contents.lectureImage.condition}
        checked={lecture?.conditions?.findIndex(c => c.name === "image") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "image")].accepted : false}
        onChange={e => handleChange({target: {
            value: {
              name: "image",
              accepted: e.target.checked,
            },
            attributes: {
              datapath: {
                value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "image") > -1 ? lecture?.conditions?.findIndex(c => c.name === "image") : lecture?.conditions?.length) + "]" : "conditions[0]"
              }}
          }})}
      />
    </div>
  </MDBCol>
 
</MDBRow>
{/* LECTURE IMAGE END */}

        <hr className="my-4" />

        {/* LECTURE SCRIPT */}
        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12" lg="10" xl="8" xxl="7">
            <div className="mb-5">
              <MDBTypography tag="h5">
              {t("Optional: Upload your promotion script (PDF)")}
              </MDBTypography>
              <span className="text-muted">
              {t("The script will be made available to the participants of the event.")}
              </span>
            </div>
            <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag
                    countryCode="de"
                    svg
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
                <MDBFile id='fileDE' onChange={e => handleSetFile(e.target.files[0], "De")} />
                {
                  isUploadingLectureFiles.De && <span><MDBSpinner role="status" /></span>
                }
                {
                  uploadingLecturesErrors.De && <span className="mt-3 text-danger fw-bold">
                  {uploadingLecturesErrors.De}
                </span>
                }
              </MDBInputGroup>
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag
                    countryCode="gb"
                    svg
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
                <MDBFile id='fileEn' onChange={e => handleSetFile(e.target.files[0], "En")} />
                {
                  isUploadingLectureFiles.En && <span><MDBSpinner role="status" /></span>
                }
                {
                  uploadingLecturesErrors.En && <span className="mt-3 text-danger fw-bold">
                  {uploadingLecturesErrors.En}
                </span>
                }
              </MDBInputGroup>
             
            <div className="mt-3">
              <MDBCheckbox
                name="script-condition"
                id="script-condition"
                label={t("I agree that the promotion documents will be made available to the participants of the event. The presentation documents must be submitted by: ") + new Date(lecture.event?.lectureDocumentsEndDate).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                checked={lecture?.conditions?.findIndex(c => c.name === "script") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "script")].accepted : false}
                onChange={e => handleChange({target: {
                    value: {
                      name: "script",
                      accepted: e.target.checked,
                    },
                    attributes: {
                      datapath: {
                        value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "script") > -1 ? lecture?.conditions?.findIndex(c => c.name === "script") : lecture?.conditions?.length) + "]" : "conditions[0]"
                      }}
                  }})}
              />
            </div>
            
          </MDBCol>
          
        </MDBRow>
        {/* LECTURE SCRIPT END */}

        <hr className="my-4" />
        <MDBRow>
          <MDBCol size="12" className="d-flex justify-content-start mt-3">
            <MDBBtn onClick={handleNextClick} className="rounded-4 mb-2 mb-lg-4" style={{bottom: "0"}}>
              {t("Continue")}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBAlert
        color="danger"
        autohide
        position="bottom-right"
        delay={2000}
        appendToBody
        open={alertDanger}
        onClose={() => setAlertDanger(false)}
      >
        {errorMessage}
      </MDBAlert>
<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics}
                topicGroups={topicGroups}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={lecture.topics || []}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics}
                parentTopicGroups={topicGroups}
                maxSelect={3}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
    </>
  );
}
