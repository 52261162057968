import { useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import shortUUID from 'short-uuid';
import { useTranslation } from 'react-i18next';

export const useTicket = (eventId) => {
  const { t } = useTranslation();
  const client = generateClient();
  const [isLoading, setIsLoading] = useState(false);
  const [ticketIdError, setTicketIdError] = useState('');
  const [searchedTickets, setSearchedTickets] = useState([]);

  /**
   * Get ticket data by ticket ID
   * @param {string} ticketId - The ticket ID (UUID or short UUID)
   * @returns {Promise<Object>} - The ticket data
   */
  const getTicketById = async (ticketId) => {
    console.log('getTicketById', ticketId);
    setIsLoading(true);
    setTicketIdError('');

    let ticketIdUUIDType = null;
    try {
      // Test if ticketId is uuid or short uuid
      try {
        try {
          if (shortUUID().fromUUID(ticketId)) {
            ticketIdUUIDType = "uuid";
          } 
        } catch (error) {}
        try {
          if (shortUUID().fromUUID(shortUUID().toUUID(ticketId))) {
            ticketIdUUIDType = "shortuuid";
          }
        } catch (error) {}
      } catch (error) {
        console.error(error);
        setTicketIdError(t("Invalid Ticket ID"));
        setIsLoading(false);
        return null;
      }

      const data = await client.graphql({
        query: /* GraphQL */ `
          query GetTicket($id: ID!) {
            getTicket(id: $id) {
              owner
              id
              event {
                id
              }
              createdByUserId
              userTicketsId
              eventDays
              status
              paymentStatus
              type
              visitorType
              addons
              notes
              user {
                email
                contact {
                  phone
                }
              }
              firstName
              lastName
              companyName
              personalAddress {
                firstName
                lastName
                title
                salutation
                street
                street2
                streetNumber
                company
                contactPerson
                city
                zip
                countryCode
                __typename
              }
              personalContact {
                phone
                email
              }
              billingAddress {
                firstName
                lastName
                title
                salutation
                street
                street2
                streetNumber
                company
                contactPerson
                city
                zip
                countryCode
                __typename
              }
              billingEmail
              invitationCode
              orderNumber
              createdAt
              updatedAt
              ticketEventId
              ticketCompanyId
              ticketPaymentId
              scans {
                items {
                  scannedAt
                }
              }
              __typename
            }
          }
        `,
        variables: { id: ticketIdUUIDType === "uuid" ? ticketId : shortUUID().toUUID(ticketId) },
      });

      const ticket = data.data.getTicket;
      
      // Format ticket data for usage in the form
      const formattedTicket = {
        id: ticket.id,
        firstName: ticket.personalAddress.firstName,
        lastName: ticket.personalAddress.lastName,
        title: ticket.personalAddress.title || "",
        company: ticket.personalAddress.companyName || ticket.personalAddress.company || ticket.billingAddress?.company || "",
        eventDays: ticket.eventDays,
        email: ticket.personalContact?.email || ticket.user?.email || "",
        phone: ticket.personalContact?.phone || ticket.user?.contact?.phone || "",
        visitorType: ticket.visitorType,
      };
      
      return formattedTicket;
    } catch (error) {
      console.error('Error fetching ticket:', error);
      setTicketIdError(t("Error fetching ticket with id:") + " " + ticketId);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Create a ticket scan record
   * @param {string} ticketId - The ticket ID
   * @param {string} deviceName - The scanning device name
   * @returns {Promise<Object>} - The created scan record
   */
  const createTicketScan = async (ticketId, deviceName) => {
    try {
      const data = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateTicketScan(
            $input: CreateTicketScanInput!
            $condition: ModelTicketScanConditionInput
          ) {
            createTicketScan(input: $input, condition: $condition) {
              id
              scannedAt
              scannedLocation
              scannedDevice
              createdAt
              updatedAt
              ticketScansId
            }
          }
        `,
        variables: {
          input: {
            ticketScansId: ticketId,
            scannedAt: new Date().toISOString(),
            scannedLocation: "Lounges 2024 Karlsruhe", // This should probably be dynamic
            scannedDevice: deviceName,
          },
        },
      });
      
      return data.data.createTicketScan;
    } catch (error) {
      console.error('Error creating ticket scan:', error);
      return null;
    }
  };

  /**
   * Search for tickets
   * @param {string} searchString - The search string
   * @returns {Promise<Array>} - The search results
   */
  const searchTickets = async (searchString) => {
    if (!eventId) {
      console.error('No event ID provided for ticket search');
      return [];
    }

    setIsLoading(true);
    try {
      const data = await client.graphql({
        query: /* GraphQL */ `
          query SearchTickets(
            $filter: SearchableTicketFilterInput
            $sort: [SearchableTicketSortInput]
            $limit: Int
            $nextToken: String
            $from: Int
            $aggregates: [SearchableTicketAggregationInput]
          ) {
            searchTickets(
              filter: $filter
              sort: $sort
              limit: $limit
              nextToken: $nextToken
              from: $from
              aggregates: $aggregates
            ) {
              items {
                id
                createdByUserId
                userTicketsId
                ticketEventId
                eventDays
                status
                type
                visitorType
                notes
                personalAddress {
                  firstName
                  lastName
                  title
                  company
                }
                personalContact {
                  phone
                  email
                }
                user {
                  email
                  contact {
                    phone
                  }
                }
                firstName
                lastName
                companyName
                invitationCode
                createdAt
                updatedAt
                __typename
              }
            }
          }
        `,
        variables: {
          filter: {
            or: [
              { firstName: { matchPhrasePrefix: searchString } },
              { lastName: { matchPhrasePrefix: searchString } },
              { companyName: { matchPhrasePrefix: searchString } }
            ],
            ticketEventId: { eq: eventId }
          }
        },
      });
      
      const results = data.data.searchTickets.items;
      setSearchedTickets(results);
      return results;
    } catch (error) {
      console.error('Error searching tickets:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Create a new ticket
   * @param {Object} ticketData - The ticket data
   * @returns {Promise<Object>} - The created ticket
   */
  const createTicket = async (ticketData) => {
    if (!eventId) {
      console.error('No event ID provided for ticket creation');
      return null;
    }

    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateTicket(
            $input: CreateTicketInput!
            $condition: ModelTicketConditionInput
          ) {
            createTicket(input: $input, condition: $condition) {
              owner
              id
              event {
                id
              }
              createdByUserId
              userTicketsId
              eventDays
              status
              paymentStatus
              type
              visitorType
              addons
              notes
              personalAddress {
                firstName
                lastName
                title
                salutation
                street
                street2
                streetNumber
                company
                contactPerson
                city
                zip
                countryCode
                __typename
              }
              billingAddress {
                firstName
                lastName
                title
                salutation
                street
                street2
                streetNumber
                company
                contactPerson
                city
                zip
                countryCode
                __typename
              }
              billingEmail
              invitationCode
              orderNumber
              createdAt
              updatedAt
              ticketEventId
              ticketCompanyId
              ticketPaymentId
              __typename
            }
          }
        `,
        variables: {
          input: {
            type: ticketData.type || "visitor",
            industryId: ticketData.industryId,
            eventDays: ticketData.eventDays,
            ticketEventId: eventId,
            invitationCode: ticketData.invitationCode,
            personalAddress: {
              firstName: ticketData.firstName,
              lastName: ticketData.lastName,
              street: ticketData.street,
              streetNumber: ticketData.streetNumber,
              company: ticketData.company,
              zip: ticketData.zip,
              city: ticketData.city,
              countryCode: ticketData.country,
            },
            firstName: ticketData.firstName,
            lastName: ticketData.lastName,
            companyName: ticketData.company
          }
        }
      });
      
      return response.data.createTicket;
    } catch (error) {
      console.error('Error creating ticket:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Get the next document ID for receipts
   * @returns {Promise<string>} - The next document ID
   */
  const getNextDocumentId = async () => {
    try {
      const data = await client.graphql({
        query: /* GraphQL */ `
          mutation GetNextSystemNumber($documentType: String) {
            getNextSystemNumber(documentType: $documentType) {
              nextDocumentId
              __typename
            }
          }
        `,
        variables: {
          documentType: "receipt"
        }
      });
      
      return data.data.getNextSystemNumber.nextDocumentId;
    } catch (error) {
      console.error('Error getting next document ID:', error);
      return null;
    }
  };

  return {
    isLoading,
    ticketIdError,
    searchedTickets,
    getTicketById,
    createTicketScan,
    searchTickets,
    createTicket,
    getNextDocumentId,
  };
};