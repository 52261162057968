import React, { useState, useEffect, useCallback } from "react";
import EmployeeSelector from "../../components-user/news/EmployeeSelector";
import { useTranslation } from "react-i18next";
import { diff } from "deep-object-diff";
import { generateClient } from 'aws-amplify/api';
import { getLecture } from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML} from 'draft-js';
import {convertHTMlToRaw} from "../../utils/HTMLDraft";
import deepUpdateObject from "../../utils/deepUpdateObject";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCheckbox, MDBChip, MDBCol, MDBFile, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBSpinner, MDBTypography } from "mdb-react-ui-kit";
import ReactCountryFlag from "react-country-flag";
import SelectTopics from "../../components-user/topics/SelectTopics";
const client = generateClient();
export default function RegisterRecruitingLecture() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];
  const lectureid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[4]
    : window.location.pathname.split("/")[5];

  const [isEdit, setIsEdit] = useState(!!lectureid);  
  // Loading
  const [loading, setLoading] = useState(false);

  // Lecture data
  //const [initialLectureData, setInitialLectureData] = useState({});
  const [lecture, setLectureData] = useState({});
  const [lectureDataChanges, setLectureDataChanges] = useState({});

  const getLectureData = async () => {
    try {
      const lecture = await client.graphql({
        variables: { id: lectureid },
        query: /* GraphQL */ `
        query GetLecture($id: ID!) {
          getLecture(id: $id) {
            id
            type
            startTime
            endTime
            event {
              id
              description
              status
              date
              x4Id
              
              
            }
            company {
              id
              name
              industry
              status
              website
              admins
              x4Id
              x4AdminId
              companyAdminId
              
            }
            speakers {
              items {
                employeeId
              }
            }
            session
            mainSpeaker {
              id
              role
              permissions {
                resource
                actions
              }
              status
              x4Id
              WPJetEngineId
              permissions {
                resource
                actions
              }
              companyEmployeesId
              userEmployeeProfilesId
              
            }
            room
            title {
              language
              text
              
            }
            subjects {
              id
              name {
                language
                text
              }
              
            }
            topics {
              items {
                topicId
              }
            }
            content {
              language
              text
              
            }
            conditions {
              name
              accepted
              
            }
            presentationLanguage
            image {
              fileName
              alt
              x4UUID
              
            }
            pdfDe {
              url
              name
              s3Path
              fileName
            }
            pdfEn {
              url
              name
              s3Path
              fileName
            }
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            
          }
        }
      `,
      });
      console.log(lecture);
     
      return {
        ...lecture.data.getLecture,
        speakers: lecture.data.getLecture.speakers?.items?.map(speaker => speaker.employeeId) || [],
        topics: lecture.data.getLecture.topics?.items?.map(topic => topic.topicId) || [],
        tempSubjects: lecture.data.getLecture.subjects?.map(subject => ({de: subject.name.find(name => name.language === "de").text, en: subject.name.find(name => name.language === "en").text})) || [],
      };
    } catch (error) {
      console.warn("Error getting lecture data", error);
    }
  };

  const intialLoadLecture = async () => {
    console.log("loading lecture");
    setLoading(true);
    const dbLecture = await getLectureData();
    //setInitialLectureData(dbLecture);
    setLectureData(dbLecture);
    // Update editor state
    if(dbLecture.content) {
      const deContent = dbLecture.content.find(c => c.language === "de")?.text;
      const enContent = dbLecture.content.find(c => c.language === "en")?.text;
      setEditorState({
        de: deContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(deContent)
                        )
                      ) : EditorState.createEmpty(),
        en: enContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(enContent)
                        )
                      ) : EditorState.createEmpty(),
      });
    }
    setLoading(false);
  };

  // Employees
  const [employees, setEmployees] = useState([]);
  // Get employees with company id
  const getEmployees = async () => {
    try {
        console.log("company", companyid)
        const employees = await client.graphql({
            variables: {
              filter: { companyEmployeesId: { eq: companyid } },
              limit: 5000
             },
           
            query: /* GraphQL */ `
            query ListEmployees(
              $filter: ModelEmployeeFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    nextToken
                    items {
                        id
                        user {
                                lastName
                                middleName
                                firstName
                                title
                                image {
                                    alt
                                    fileName
                                }
                        }
                        department {
                            items {
                                department {
                                    name {
                                        language
                                        text
                                    }
                                }
                            }
                        }
                        image {
                            alt
                            fileName
                          }
                      }
                }
            }
            `
        });
        //console.log("employees", employees)
        let employeesArray = employees.data.listEmployees.items;
        setEmployees(employeesArray);
        console.log("employees", employees.data.listEmployees.items)
    } catch (err) {
        console.log("error fetching employees", err);
    }
};

  useEffect(() => {
    if (!companyid) {
      console.warn("No company id provided");
      return;
    } else if (!lectureid) {
      console.log("No lecture id provided");
      getEmployees();
      return;
    } else {
      intialLoadLecture();
      getEmployees();
    }
  }, []);

  const handleChange = (e, additionalInstructions) => {
    console.log("change", e.target.attributes.datapath.value, e.target.checked, e.target.value);
    console.log("additionalInstructions", additionalInstructions);
    console.log(e)
    
    // Get values from input
    const datapath = e.target.attributes.datapath.value;
    let value;

    // Check if the input is a checkbox
    if (e.target.type === 'checkbox') {
        value = e.target.checked; // Use 'checked' for checkboxes
    } else {
        value = e.target.value; // Use 'value' for other input types
    }

    // Update current company object
    const newLecture = deepUpdateObject(lecture, datapath, value, additionalInstructions);
    console.log("new lecture", JSON.stringify(newLecture));
    setLectureData(newLecture);
    
    //setLectureDataChanges(diff(initialLectureData, newLecture));
};

const [isSaving, setIsSaving] = useState(false);

 const createLectureHandler = async () => {
    try {
      const lectureData = lecture;
      setIsSaving(true);
      let lectureDataForLecture = {
        conditions: lectureData.conditions,
        content: lectureData.content,
        subjects: lectureData.tempSubjects?.map(subjectTexts => ({
          id: uuidv4(),
          name: [
            {
              language: "de",
              text: subjectTexts.de
            },
            {
              language: "en",
              text: subjectTexts.en
            },
          ]
        })).filter(Boolean) || lectureData.subjects || null,
        title: lectureData.title,
        type: "recruiting",
        companyLecturesId: companyid,
        ...(lectureid ? {} : {status: "created"})
      };
      if(lectureData.lectureMainSpeakerId) lectureDataForLecture.lectureMainSpeakerId = lectureData.lectureMainSpeakerId;
      if(lectureData.image?.fileName) lectureDataForLecture.image = lectureData.image;
      if(lectureData.pdfDe?.fileName) lectureDataForLecture.pdfDe = lectureData.pdfDe;
      if(lectureData.pdfEn?.fileName) lectureDataForLecture.pdfEn = lectureData.pdfEn;
      console.log("lectureDataForLecture", lectureDataForLecture)
      const updateLectureRes = await client.graphql({
        variables: { input: lectureid ? {...lectureDataForLecture, id: lectureid} :lectureDataForLecture },
        query: lectureid ?
        /* GraphQL */ `
        mutation UpdateLecture(
          $input: UpdateLectureInput!
          $condition: ModelLectureConditionInput
        ) {
            updateLecture(input: $input, condition: $condition) {
              id
            }
          }
        `
        :
        /* GraphQL */ `
        mutation CreateLecture(
          $input: CreateLectureInput!
          $condition: ModelLectureConditionInput
        ) {
          createLecture(input: $input, condition: $condition) {
            id
            type
            event {
              id

            }
            company {
              id

            }
            speakers {
              nextToken
              
            }
            session
            mainSpeaker {
              id
            }
            room
            title {
              language
              text
              
            }
            subjects {
              id
              
            }
            topics {
              nextToken
              
            }
            content {
              language
              text
              
            }
            conditions {
              name
              accepted
              
            }
            presentationLanguage
            image {
              fileName
              alt
              x4UUID
              
            }
            pdfDe {
              url
              name
              
            }
            pdfEn {
              url
              name
              
            }
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            
          }
        }
      `,
      });
      //console.log(lecture);

      // Speakers

      let dataForSpeaker = [];
        if(lectureData.speakers?.length > 0  && !lectureid) {
          lectureData.speakers.forEach(speaker => {
            dataForSpeaker.push({
              id: speaker,
              speakerLectureId: updateLectureRes.data.createLecture.id
            })
          })
          console.log("dataForSpeaker", dataForSpeaker)
          await Promise.all(dataForSpeaker.map(async speaker => {
            console.log("speaker", speaker)
            const speakerData = await client.graphql({
              variables: { input: {
                lectureId: speaker.speakerLectureId,
                employeeId: speaker.id
              } },
              query: /* GraphQL */ `
              mutation CreateLecturesSpeakers(
                $input: CreateLecturesSpeakersInput!
                $condition: ModelLecturesSpeakersConditionInput
              ) {
                createLecturesSpeakers(input: $input, condition: $condition) {
                  id
                  lectureId
                  employeeId
                }
              }
            `,
            });
            console.log("speakerData", speakerData);
          }
        ))} else if(lectureid) {
          console.log("updating speakers")
          async function updateLectureSpeakers(lectureData, lectureid, client) {
            const existingSpeakers = await fetchExistingSpeakers(lectureid, client);
            const newSpeakerIds = lectureData.speakers || [];
            const existingSpeakerIds = existingSpeakers.map(s => s.employeeId);
        
            const speakersToAdd = newSpeakerIds.filter(id => !existingSpeakerIds.includes(id));
            const speakersToRemove = existingSpeakers.filter(s => !newSpeakerIds.includes(s.employeeId));
        
            // Füge neue Speaker hinzu
            await Promise.all(speakersToAdd.map(async id => {
              await addSpeaker({ id, speakerLectureId: lectureid }, client);
            }));
        
            // Entferne überflüssige Speaker
            await Promise.all(speakersToRemove.map(async speaker => {
              await removeSpeaker(speaker.id, client);
            }));

          }
          
          async function fetchExistingSpeakers(lectureId, client) {
            const query = /* GraphQL */ `
            query ListLecturesSpeakers(
              $filter: ModelLecturesSpeakersFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listLecturesSpeakers(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                    id
                    employeeId
                  }
                }
              }
            `;
          
            const response = await client.graphql({
              query,
              variables: { filter: { lectureId: { eq: lectureId } }, limit: 10000 }
            });
          
            return response.data.listLecturesSpeakers.items || [];
          }
          
          
          
          function compareSpeakers(newSpeakers, existingSpeakers) {
            const speakersToAdd = newSpeakers.filter(speaker => !existingSpeakers.includes(speaker));
            const speakersToRemove = existingSpeakers.filter(speaker => !newSpeakers.includes(speaker));
            return { speakersToAdd, speakersToRemove };
          }
          
          async function addSpeaker(speaker, client) {
            // GraphQL-Mutation, um einen Speaker hinzuzufügen
            const mutation = /* GraphQL */ `
            mutation CreateLecturesSpeakers(
              $input: CreateLecturesSpeakersInput!
              $condition: ModelLecturesSpeakersConditionInput
            ) {
              createLecturesSpeakers(input: $input, condition: $condition) {
                  id
                }
              }
            `;
            await client.graphql({
              query: mutation,
              variables: { input: { lectureId: speaker.speakerLectureId, employeeId: speaker.id } }
            });
          }
          
          async function removeSpeaker(lectureSpeakerId, client) {
            const mutation = /* GraphQL */ `
            mutation DeleteLecturesSpeakers(
              $input: DeleteLecturesSpeakersInput!
              $condition: ModelLecturesSpeakersConditionInput
            ) {
              deleteLecturesSpeakers(input: $input, condition: $condition) {
                  id
                }
              }
            `;
          
            await client.graphql({
              query: mutation,
              variables: { input: { id: lectureSpeakerId } }
            });
          }
          

          await updateLectureSpeakers(lectureData, lectureid, client);
        }

     
          if(lectureData.topics?.length > 0  && !lectureid) {
        console.log("creating topics", lectureData.topics)
        let dataForTopics = [];
        lectureData.topics.forEach(topicId => {
          dataForTopics.push({
            topicId: topicId,
            lectureId: updateLectureRes.data.createLecture.id
          })
        })
        console.log("dataForTopics", dataForTopics)
        await Promise.all(dataForTopics.map(async topic => {
          console.log("topic", topic)
          const topicData = await client.graphql({
            variables: { input: {
              lectureId: topic.lectureId,
              topicId: topic.topicId
            } },
            query: /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              createLecturesTopics(input: $input, condition: $condition) {
                id
                lectureId
                topicId
              }
            }
          `,
          });
          console.log("topicData", topicData);
        }))
      } else if(lectureid) {
        async function updateLectureTopics(lectureData, lectureid, client) {

            // Aktualisieren von Topics für eine bestehende Lecture
            console.log("updating topics");
            const existingTopics = await fetchExistingTopics(lectureid, client);
            const newTopicIds = lectureData.topics || [];
            console.log("newTopicIds", newTopicIds)
            const existingTopicIds = existingTopics.map(t => t.topicId);

            const topicsToAdd = newTopicIds.filter(id => !existingTopicIds.includes(id));
            const topicsToRemove = existingTopics.filter(t => !newTopicIds.includes(t.topicId));

            // Füge neue Topics hinzu
            await Promise.all(topicsToAdd.map(async id => {
              await addTopic({ topicId: id, lectureId: lectureid }, client);
            }));

            // Entferne überflüssige Topics
            await Promise.all(topicsToRemove.map(async topic => {
              await removeTopic(topic.id, client);
            }));
          
        }

        async function fetchExistingTopics(lectureId, client) {
          const query = /* GraphQL */ `
            query ListLecturesTopics(
              $filter: ModelLecturesTopicsFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listLecturesTopics(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                  id
                  topicId
                }
              }
            }
          `;

          const response = await client.graphql({
            query,
            variables: { filter: { lectureId: { eq: lectureId } }, limit: 10000 }
          });

          return response.data.listLecturesTopics.items || [];
        }

        async function addTopic(topic, client) {
          const mutation = /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              createLecturesTopics(input: $input, condition: $condition) {
                id
              }
            }
          `;
          await client.graphql({
            query: mutation,
            variables: { input: { lectureId: topic.lectureId, topicId: topic.topicId } }
          });
        }

        async function removeTopic(lectureTopicId, client) {
          const mutation = /* GraphQL */ `
            mutation DeleteLecturesTopics(
              $input: DeleteLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              deleteLecturesTopics(input: $input, condition: $condition) {
                id
              }
            }
          `;

          await client.graphql({
            query: mutation,
            variables: { input: { id: lectureTopicId } }
          });
        }

        // Verwenden Sie updateLectureTopics, um Topics zu verwalten
        await updateLectureTopics(lectureData, lectureid, client);

      }
       
      
      setTimeout(() => {
        navigate(`/mycompany/${companyid}/recruiting-lectures`);
        setIsSaving(false);
      }, 2000);
      
      return updateLectureRes.data.createLecture;
    } catch (error) {
      setIsSaving(false);
      console.warn("Error creating lecture", error);
    }
  };

  // Topics 
  const [topics, setTopics] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState(lecture.topics || []);
   // Topics data

   const getTopics = async () => {
    try {
      const topicGroupsWithTopics = await client.graphql({
        query: /* GraphQL */ `
        query ListTopicGroups(
          $filter: ModelTopicGroupFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              color
              name {
                language
                text
              }
              topics {
                items {
                  id
                  name {
                    language
                    text
                  }
                  topicGroupTopicsId
                }
              }
            }
            nextToken
            __typename
          }
        }
      `
        });
        setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
        const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
        setTopics(topics);

    } catch (error) {
        console.log("error on fetching topics", error);
    }
}

const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);

  const handleSetSelectedTopics = (topics) => {
    setSelectedTopics(topics);
    setLectureData(oldlecture => ({...oldlecture, topics}));
    // setLectureData(oldLecture => ({...oldLecture, topics}));
  }

useEffect(() => {
    if (topics.length === 0) {
        getTopics();
    }
}, [topics]);

const Topics = ({topics, topicGroups, selectedTopics}) => {

  // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
  if(topics?.length === 0 || topicGroups?.length === 0 || selectedTopics?.length === 0) {
      return null;
  }
  let sortedTopics = [];
  selectedTopics.forEach((selectedTopic) => {
      const topicData = topics.find((topic) => topic.id === selectedTopic);
      const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
      const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
      if (topicGroupIndex === -1) {
          sortedTopics.push({...topicGroup, topics: [topicData]});
      } else {
          sortedTopics[topicGroupIndex].topics.push(topicData);
      }
  });
  return (
      <MDBRow className="my-2">
          {sortedTopics.map((topicGroup) => {
              return (
                  <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
                      <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                      <MDBRow>
                          {topicGroup.topics.map((topic) => {
                              return (
                                  <MDBCol size="12" key={topic.id}>
                                      <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                  </MDBCol>
                              )
                          })}
                      </MDBRow>
                  </MDBCol>
              )
          })}
      </MDBRow>
  )
}

// Editor
const [editorState, setEditorState] = useState({
  de: EditorState.createEmpty(),
  en: EditorState.createEmpty(),
})
const onEditorStateChange = (editorState, language ) => {
setEditorState(oldState => ({...oldState, [language]: editorState}));
setLectureData(oldNewsData => ({...oldNewsData, content: [...oldNewsData.content?.filter(c => c.language !== language) ?? [], {language, text: convertHTMlToRaw(editorState)}] }));

} 

// Image and Script

const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState(false);
const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState(null);

const handleSetFile = (file) => {
// upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
try {
  setIsUploadingLectureFiles(true);
const reader = new FileReader();
reader.readAsDataURL(file);
reader.onerror = error => {
  console.log('Error: ', error);
  setUploadingLecturesErrors(error);
}
reader.onload = async () => {
 try {
  const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
  const response = await client.graphql({
    query: /* GraphQL */ `
      mutation UploadLectureFile($base64FileBuffer: String!, $companyId: String!) {
        uploadLectureFile(base64FileBuffer: $base64FileBuffer, companyId: $companyId) {
          message
          fileName
        }
      }
    `,
    variables: {
      base64FileBuffer,
      companyid,
    },
  });
  console.log("response upload file", response);
  setLectureData(oldNewsData => ({...oldNewsData, file: {fileName: response.data.uploadLectureFile.fileName, type: "newsFile"}}));
  
 } catch (error) {
  console.log("error", error);
  setIsUploadingLectureFiles(false);
  setUploadingLecturesErrors(error.errors[0].message);
 }
};

setIsUploadingLectureFiles(false);
setUploadingLecturesErrors(null);
} catch (error) {
  console.log("error", error);
  setIsUploadingLectureFiles(false);
  setUploadingLecturesErrors(error.errors[0].message);
}

}

}

const [isUploadingLectureImage, setIsUploadingLectureImage] = useState(false);
const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);

const handleSetImage = (file) => {
// upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
  try {
    setIsUploadingLectureImage(true);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onerror = error => {
    console.log('Error reader: ', error);
    setUploadingLectureImageError(error);
  }
  reader.onload = async () => {
    try {
      console.log(reader.result)
      const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
      const response = await client.graphql({
        query: /* GraphQL */ `
        mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
          uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
        }
      `,
        variables: {
          imageBuffer: base64FileBuffer,
          imageType: "newsImage",
        },
      });
      console.log("response", response);
      const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
      setLectureData(oldNewsData => ({...oldNewsData, image: {fileName, alt: "Lecture Image"}}));
      
      } catch (error) {
        console.log("error", error);
        setIsUploadingLectureImage(false);
        setUploadingLectureImageError(error.errors[0].message);
      }
  }
  setIsUploadingLectureImage(false);
  setUploadingLectureImageError(null);
  } catch (error) {
    console.log("error", error);
    setIsUploadingLectureImage(false);
    setUploadingLectureImageError(error.errors[0].message);
  }
}
}

// Select Employee

const [selectEmployeeModal, setSelectEmployeeModal] = useState(false);
const toggleSelectEmployeeModal = () => setSelectEmployeeModal(!selectEmployeeModal);


  return (<>
 <MDBRow className="h-auto d-flex justify-content-center">
      <MDBCol size="12" xl="12" xxl="11">
        <MDBCard>
          <MDBCardBody className="px-1 px-md-5">
          <MDBCardTitle>{isEdit ? t("Edit Recruiting Lecture") + ": " + (lecture.title?.find(t => t.language === i18n.language)?.text || lecture?.title?.[0]?.text) : t("Recruiting Lecture Registration")}</MDBCardTitle>
{/* Employee */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
   
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
            {t("Select speakers")}
            </MDBTypography>
        </div>
        <div className="w-100 text-center">
        <MDBBtn onClick={() => toggleSelectEmployeeModal()} color="primary" outline>
            {t("Select speakers")}
        </MDBBtn>
        {lecture.speakers?.length > 0 && lecture.speakers.map((speaker, index) => {
          const employee = employees.find(employee => employee.id === speaker);
          if(!employee) return null;
          return (
              <div className="d-flex gap-4 align-items-center mb-3">
                  {employee.image?.fileName ? (
                    <img
                      src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                      className="rounded-circle border"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                      }}
                      alt={employee.image.alt}
                    />
                  ) : (
                    <MDBIcon
                      className="rounded-circle border"
                      style={{
                        height: "50px",
                        width: "50px",
                        textAlign: "center",
                        paddingTop: "12px",
                        fontSize: "20px",
                      }}
                      fas
                      icon="user-alt"
                    />
                  )}
                  <div>
                    <h6>{`${employee.user?.title ? employee.user?.title + " ": ""}${employee.user?.firstName} ${employee.user?.middleName || ""} ${employee.user?.lastName}`}</h6>
                    <span>{employee.department?.items?.[0]?.department.name[0].text}</span>
                  </div>
                </div>
        
          )
        }
        )}
      </div>
    </MDBCol>
</MDBRow>
{/* Employee END */}

{/* Topics */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="my-4" />
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
            {t("Select topic")}
            </MDBTypography>
            <span className="text-muted">
            {t("Select the topic that best describe your recruting lecture.")}
            </span>
        </div>
        <div className="w-100 text-center">
        <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
            {t("Select Topic")}
        </MDBBtn>
        <MDBRow className="w-100">
            <MDBCol>
                <Topics
                topics={topics}
                topicGroups={topicGroups}
                selectedTopics={lecture.topics || []}
                setSelectedTopics={handleSetSelectedTopics}
                />
            </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
</MDBRow>
{/* Topics END */}

{/* TITEL */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="mb-1 mb-xl-2" />
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
                {t("Title (max. 60 characters)")}
            </MDBTypography>
           
        </div>
        <MDBInputGroup className="mb-1" textAfter={<span style={lecture.title?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((lecture?.title?.length > 0? lecture.title.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
            <div className="input-group-text">
            <ReactCountryFlag
                countryCode="de"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            /> 
            </div>
            <MDBInput
            label={t("Title German")}
            type="text"
            maxLength="60"
            value={lecture?.title?.find((t) => t.language === "de")?.text || ""}
            onChange={(e) => setLectureData({...lecture, title: [{language: "de", text: e.target.value}, ...lecture.title?.filter((t) => t.language !== "de") ?? []]})}
            />
        </MDBInputGroup>
        <MDBInputGroup className="mb-1" textAfter={<span style={lecture.title?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((lecture?.title?.length > 0? lecture.title.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
            <div className="input-group-text">
            <ReactCountryFlag
                countryCode="gb"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            />
            </div>
            <input
            className="form-control"
            placeholder={t("Title English")}
            type="text"
            maxLength="60"
            value={lecture?.title?.find((t) => t.language === "en")?.text || ""}
            onChange={(e) => setLectureData({...lecture, title: [{language: "en", text: e.target.value}, ...lecture.title?.filter((t) => t.language !== "en") ?? []]})}
            
            />
        </MDBInputGroup>
    </MDBCol>
</MDBRow>
{/* TITEL END */}

{/* LECTURE DESCRIPTION */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
        <MDBTypography tag="h5">
        {t("Content (Min. 1000 characters)")}
        </MDBTypography>
    </div>
    <ReactCountryFlag
        countryCode="de"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    /> 
    <Editor
        editorState={editorState["de"]}
        editorClassName="md-textarea form-control"
        onEditorStateChange={e => onEditorStateChange(e, "de")}
      />
      <br></br>
      <ReactCountryFlag
        countryCode="gb"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    />
    <Editor
        editorState={editorState["en"]}
        editorClassName="md-textarea form-control"
        onEditorStateChange={e => onEditorStateChange(e, "en")}
      />
    </MDBCol>
</MDBRow>
{/* LECTURE DESCRIPTION END */}

{/* LECTURE IMAGE */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("Image")}
      </MDBTypography>
      <span className="text-muted" style={{fontSize: "smaller"}}>{t("Recommended format: 16:9")}</span>
    </div>
    <div className="w-100 text-center mb-2 mb-lg-4">
    <img
      src={lecture?.image?.fileName ? 
      "https://iec-cp-public.s3.eu-central-1.amazonaws.com/newsImages/" + lecture?.image.fileName: "https://via.placeholder.com/600x400"}
      className="img-thumbnail"
      style={{ height: "300px", maxWidth: "100%", width: "auto", aspectRatio: "16 / 9" , objectFit: "cover" }}
      alt='...' />
    </div>
    <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
    
    
   
    <div className="mt-3">
      <MDBCheckbox
        name="image-condition"
        id="image-condition"
        label={t("I agree that the image will be made public and we have the rights to use the image.")}
        checked={lecture?.conditions?.findIndex(c => c.name === "image") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "image")].accepted : false}
        onChange={(e) => {setLectureData(oldlecture => ({...oldlecture, conditions: [{name: "image", accepted: e.target.checked}, ...oldlecture.conditions?.filter(c => c.name !== "image") || []]}))}}
      />
    </div>
  </MDBCol>
 
</MDBRow>
{/* LECTURE IMAGE END */}

{/* LECTURE SCRIPT */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("Optional: PDF File")}
      </MDBTypography>
    </div>
    {lecture?.file?.fileName && (
      <p className="text-muted">{t("File uploaded successfully")}</p>
    )
    }
    <MDBFile id='fileDE' onChange={e => handleSetFile(e.target.files[0])} />
      
    <div className="mt-3">
      <MDBCheckbox
        name="script-condition"
        id="script-condition"
        label={t("I confirm that I have the rights to use the file and that it can be made public.")}
        checked={lecture?.conditions?.findIndex(c => c.name === "file") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "file")].accepted : false}
        onChange={(e) => {setLectureData(oldlecture => ({...oldlecture, conditions: [{name: "file", accepted: e.target.checked}, ...oldlecture.conditions?.filter(c => c.name !== "file") || []]}))}}
      />
    </div>
    
  </MDBCol>
  
</MDBRow>
{/* LECTURE SCRIPT END */}

<MDBRow className="mt-2 mt-lg-5">
  <MDBCol className="text-center mx-auto col-lg-6 col-xl-4">
  {
    isSaving ? (
    <div className="text-center">
      <MDBSpinner grow color="primary" />
    </div>
  ) : (
    <MDBBtn color="primary" className="w-100" onClick={() => createLectureHandler()}>{lectureid ? t("Update recruiting lecture") : t("Create recruiting lecture")}</MDBBtn>
  )
  }
  </MDBCol>
</MDBRow>

          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>

<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent>
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics}
                topicGroups={topicGroups}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={lecture.topics || []}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics}
                parentTopicGroups={topicGroups}
                maxSelect={1}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
<MDBModal open={selectEmployeeModal} onClose={() => setSelectEmployeeModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>{t("Select employee")}</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectEmployeeModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <EmployeeSelector
              companyId={companyid}
              selectedEmployees={lecture.speakers || []}
              setSelectedEmployees={(employees) => setLectureData({...lecture, speakers: employees.filter(Boolean)})}
              maxSelect={5}
              />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectEmployeeModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

  </>)
}
