import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generateClient } from "aws-amplify/api"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText, MDBCardImage,
    MDBCheckbox, MDBCollapse,
    MDBInput,
    MDBSelect,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBDatepicker,
    MDBTextArea,
    MDBListGroup,
    MDBListGroupItem,
    MDBModalFooter,
    MDBPagination,
    MDBPaginationItem,
    MDBPaginationLink,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
     } from "mdb-react-ui-kit"

import { transformMoney } from "../../utils/money"
import countryList from 'react-select-country-list'
import { Link } from "react-router-dom"
import { parse } from "uuid"
import {orderStates, orderPaymentStates} from "../../utils/states"
import { getLocalizedText } from "../../utils/localizedText"
import {isoToGermanDate} from "../../utils/dateTools"

function convertDate(dateStr) {
    // Datum im Format "DD.MM.YYYY" aufteilen
    const [day, month, year] = dateStr.split('.');

    // Datum im Format "YYYY-MM-DD" zusammenfügen
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

const client = generateClient()

export default function AdminAllOrders() {
    const { t, i18n } = useTranslation()

    const [industries, setIndustries] = useState([]);
    const [isLoadingIndustries, setIsLoadingIndustries] = useState(true);
    const[orders, setOrders] = useState([])
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const[loading, setLoading] = useState(true)

    const [openOrders, setOpenOrders] = useState([])

    const getEventBooth = async (eventBoothId) => {
        try {
            const bookedBooth = await client.graphql({
                query: /* GraphQL */ `
                query GetBookedBooth($id: ID!) {
                    getBookedBooth(id: $id) {
                        orderLineItem {
                            product {
                              name {
                                language
                                text
                              }
                            }
                          }
                          event {
                            id
                            name {
                              language
                              text
                            }
                          }
                    }
                    }
                `,
                variables: {
                    id: eventBoothId
                }
            })
            return ({
                name: getLocalizedText(bookedBooth.data.getBookedBooth?.orderLineItem.product.name) + " - " + getLocalizedText(bookedBooth.data.getBookedBooth?.event.name),
                event: bookedBooth.data.getBookedBooth?.event
            })

        } catch (error) {
            console.warn("error fetching event booth", error)
        }
    }

    const getDiscountCode = async (discountCodeId) => {
        try {
            const discountCode = await client.graphql({
                query: /* GraphQL */ `
                query GetDiscountCode($id: ID!) {
                    getDiscountCode(id: $id) {
                        id
                        name {
                            language
                            text
                        }
                        discountAmount
                        discountType
                        discountPercentage
                    }
                }
                `,
                variables: {
                    id: discountCodeId
                }
            })
            return discountCode.data.getDiscountCode
        } catch (error) {
            console.warn("error fetching discount code", error)
        }
    }

    const getOrders = async (filter) => {
        try {
            const variables = { 
                limit: rowsPerPage,
                nextToken: null,
                from: (page - 1) * rowsPerPage, 
                filter: {
                    ...filter,
                },
                sort
            };
            const orders = await client.graphql({
                query: /* GraphQL */ `
                query SearchOrders(
                  $filter: SearchableOrderFilterInput
                  $sort: [SearchableOrderSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableOrderAggregationInput]
                ) {
                  searchOrders(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      id
                      industryOrdersId
                      orderNumber
                      buyerOrderNumber
                      user {
                        owner
                        id
                        email
                        contact {
                            phone
                        }
                        firstName
                        middleName
                        lastName
                        salutation
                        title
                        birthday
                        personalIndustry
                        personalDepartment
                        x4Id
                        pw_hash
                        hasCompletedOnboarding
                        status
                        createdAt
                        updatedAt
                        __typename
                      }
                      company {
                        id
                        name
                        image {
                            main {
                                fileName
                            }
                        }
                        industry
                        status
                        website
                        admins
                        x4Id
                        x4AdminId
                        createdAt
                        updatedAt
                        companyAdminId
                        __typename
                      }
                      status
                      paymentStatus
                      deliveryStatus
                      products {
                        nextToken
                        __typename
                      }
                      lineItems {
                        items {
                            id
                            qty
                            taxRate
                            price
                            downPaymentPrice
                            product {
                                id
                                sku
                                name {
                                    language
                                    text
                                }
                                price
                                downPaymentPrice
                                includes {
                                    language
                                    text
                                }
                            }
                            isCanceled
                            cancelationDate
                            cancelationDocumentNumber
                            stockReturned
                            boothEquipmentRemoved
                            bookedBooth {
                                event {
                                    id
                                }
                            }
                        }
                        nextToken
                        __typename
                      }
                      notes
                      deliveryAddress {
                        firstName
                        lastName
                        title
                        salutation
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                      billingAddress {
                        firstName
                        lastName
                        title
                        salutation
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                      billingEmail
                      financial {
                        id
                        isBusiness
                        isInterEUDelivery
                        currency
                        invoiceAmount
                        invoiceAmountNet
                        invoiceNumber
                        invoiceFile {
                            s3Path
                            fileName
                        }
                        invoiceLastSent
                        invoiceReceivers
                        taxRate
                        shippingCost
                        shippingCostNet
                        paymentCost
                        discountCode
                        discountAmount
                        discountType
                        paymentProvider
                        totalAmount
                        createdAt
                        orderFinancialOrderId
                        payments {
                            items {
                                id
                                amount
                                currency
                                paymentProvider
                                transactionId
                                orderFinancialPaymentsId
                                createdAt
                            }
                        }
                        vatInfo {
                            vatNumber
                            country
                        }
                        servicePeriodStart
                        servicePeriodEnd
                        documents {
                            documentType
                            s3Path
                            documentNumber
                            referingDocumentNumber
                            documentName
                            status
                            date
                            amount
                            amountNet
                            sentToCustomerTime
                            sentToCustomerAddresses
                        }
                      }
                      event {
                        id
                        name {
                            language
                            text
                        }
                        startDate
                        endDate
                      }
                      data
                      totalAmount
                      createdAt
                      companyOrdersId
                      userOrdersId
                      orderFinancialId
                      owner
                      __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `,
              variables
            })
            console.log("orders", orders)
            const ordersWithEventBoothAndDiscounts = await Promise.all(
                orders.data.searchOrders.items.map(async o => (
                    {...o,
                        eventBooth: o.data.includes("eventBoothId") ? await getEventBooth((JSON.parse(o.data)).eventBoothId) : null,
                        discounts: o.data.includes("discounts") ? await Promise.all((JSON.parse(o.data)).discounts.map(async d => await getDiscountCode(d.id))) : []
                    })))
            setOrders(ordersWithEventBoothAndDiscounts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
            // setOrders(orders.data.searchOrders.items.map(o => ({
            //     ...o,
            //     lineItems: {items: o.lineItems.items.sort((a,b) => b.price - a.price)},
            //     eventBooth: o.data.includes("eventBoothId") ? await getEventBooth((JSON.parse(o.data)).eventBoothId) : null
            // })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
            setTotalPages(Math.ceil(orders.data.searchOrders.total / rowsPerPage))
        } catch (error) {
            console.warn("error fetching orders", error)
        }
    }

    




    const getIndustries = async () => {
        setIsLoadingIndustries(true);
        try {
            const industriesData = await client.graphql({
                query: /* GraphQL */ `
                    query ListIndustries {
                        listIndustries {
                            items {
                                id
                                name {
                                    language
                                    text
                                }
                                logo {
                                    fileName
                                }
                            }
                        }
                    }
                `
            });
            const fetchedIndustries = industriesData.data.listIndustries.items;
            setIndustries(fetchedIndustries);
            
            // Setze die erste Industry als default im Filter
            if (fetchedIndustries.length > 0) {
                setFilter(oldFilter => ({
                    ...oldFilter,
                    industryOrdersId: { eq: fetchedIndustries[0].id }
                }));
            }
        } catch (error) {
            console.warn("Error fetching industries", error);
        } finally {
            setIsLoadingIndustries(false);
        }
    };

    useEffect(() => {
        getIndustries();
    }, []);

    useEffect(() => {
        if (!isLoadingIndustries && filter.industryOrdersId) {
            getOrders(filter);
        }
    }, [rowsPerPage, filter, page, sort, isLoadingIndustries]);

    useEffect(() => {
        if(search && search.length > 0) {
            setFilter({ ...filter, 
            or: [
                // {"billingAddress.company": { regexp: `.*${search}.*` }},
                {billingEmail: { regexp: `.*${search.toLowerCase()}.*` }},
                {orderNumber: { match: parseInt(search) || 0 }},
            ]
            });
            
        } else {
            setFilter(oldFilter => {
                delete oldFilter.or;
                getOrders(oldFilter);
                return oldFilter;
            });
            
        }

    }, [search])


    const [showEditAddressModal, setShowEditAddressModal] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)

    const [newAddresses, setNewAddresses] = useState({
        billingAddress: {},
        deliveryAddress: {}
    })

    const openEditAddressModal = (orderId) => {
        setSelectedOrder(orders.find(o => o.id === orderId))
        setNewAddresses({
            billingAddress: {...orders.find(o => o.id === orderId).billingAddress},
            deliveryAddress: {...orders.find(o => o.id === orderId).deliveryAddress}
        })
        setShowEditAddressModal(true)
    }

    

    const handleSaveAddress = async () => {
        try {
            const res = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrder(
                  $input: UpdateOrderInput!
                  $condition: ModelOrderConditionInput
                ) {
                  updateOrder(input: $input, condition: $condition) {
                    id
                  }

                }
                `,
                variables: {
                    input: {
                        id: selectedOrder.id,
                        billingAddress: newAddresses.billingAddress,
                        deliveryAddress: newAddresses.deliveryAddress
                    }
                }
            })
            console.log("address updated", res)
            // Update selected order with new addresses
            const updatedOrder = {
                ...selectedOrder,
                billingAddress: newAddresses.billingAddress,
                deliveryAddress: newAddresses.deliveryAddress
            }
            setOrders(orders.map(o => o.id === selectedOrder.id ? updatedOrder : o))
            setShowEditAddressModal(false)
        }
        catch (error) {
            console.warn("error updating address", error)
        }
    }

    // Buyer Order Number
    const [showEditBuyerOrderNumberOrderIds, setShowEditBuyerOrderNumberOrderIds] = useState([])

    const handleUpdateBuyerOrderNumber = async (orderId, newOrderNumber) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrder(
                  $input: UpdateOrderInput!
                ) {
                  updateOrder(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        id: orderId,
                        buyerOrderNumber: newOrderNumber
                    }
                }
            })
        }
        catch (error) {
            console.warn("error updating order number", error)
        }
    }
    // Billing Email
    const [showEditBillingEmailModalOrderIds, setShowEditBillingEmailModalOrderIds] = useState([])

    const handleUpdateBillingEmail = async (orderId, newEmail) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrder(
                  $input: UpdateOrderInput!
                ) {
                  updateOrder(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        id: orderId,
                        billingEmail: newEmail
                    }
                }
            })
        }
        catch (error) {
            console.warn("error updating billing email", error)
        }
    }
    // Vat Number and Country
    const [showEditVatInfoModalOrderIds, setShowEditVatInfoModalOrderIds] = useState([])
    const handleUpdateVatInfo = async (orderId, newVatInfo) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                    ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: orders.find(o => o.id === orderId).orderFinancialId,
                        vatInfo: newVatInfo
                    }
                }
            })
        }
        catch (error) {
            console.warn("error updating vat info", error)
        }
    }

    const [showAddProductModal, setShowAddProductModal] = useState(false)
    const [selectedOrderForAddProduct, setSelectedOrderForAddProduct] = useState(null)

    const openAddProductModal = (orderId) => {
        setSelectedOrderForAddProduct(orders.find(o => o.id === orderId))
        getProducts()
        setShowAddProductModal(true)
    }

    const handleAddProductToOrder = async (orderId, product) => {
        const order = orders.find(o => o.id === orderId)
        try {
            // Create Order Line Item
            const createLineItemRes = await client.graphql({
                query: /* GraphQL */ `
                mutation CreateOrderLineItem(
                  $input: CreateOrderLineItemInput!
                  $condition: ModelOrderLineItemConditionInput
                ) {
                  createOrderLineItem(input: $input, condition: $condition) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        qty: 1,
                        price: product.price,
                        productOrderLineItemsId: product.id,
                        orderLineItemProductId: product.id,
                        productID: product.id,
                        orderLineItemsId: orderId,
                        taxRate: order.financial.taxRate
                    }
                }
            })
            // Update OrderFinancial with new total amount (invoiceAmountNet) (invoiceAmount,totalAmount calulated with order tax rate)
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                    ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: order.orderFinancialId,
                        invoiceAmountNet: order.financial.invoiceAmountNet + product.price,
                        invoiceAmount: order.financial.invoiceAmount+ (product.price + product.price * (order.financial.taxRate) / 100),
                        totalAmount: order.financial.totalAmount + (product.price + product.price * (order.financial.taxRate) / 100)
                    }
                }
            })
            // Update Order with new total amount and line item
            const updatedOrder = {
                ...order,
                lineItems: {
                    items: [...order.lineItems.items, {
                        id: createLineItemRes.data.createOrderLineItem.id,
                        qty: 1,
                        price: product.price,
                        product,
                        taxRate: order.financial.taxRate
                    }]
                },
                financial: {
                    ...order.financial,
                    invoiceAmountNet: order.financial.invoiceAmountNet + product.price,
                    invoiceAmount: order.financial.invoiceAmount+ (product.price + product.price * (order.financial.taxRate) / 100),
                    totalAmount: order.financial.totalAmount + (product.price + product.price * (order.financial.taxRate) / 100),
                }
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
            setShowAddProductModal(false)
        }
        catch (error) {
            console.warn("error adding product to order", error)
        }
    }

    

    const [products, setProducts] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(true)

    const getProducts = async () => {
        try {
            const products = await client.graphql({
                query: /* GraphQL */ `
                query ListProducts(
                  $filter: ModelProductFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      sku
                      name {
                        language
                        text
                      }
                      price
                      includes {
                        language
                        text
                      }
                    }
                    nextToken
                  }
                }
              `,
                variables: {limit: 1000}
            })
            console.log("products", products)
            setProducts(products.data.listProducts.items)
        } catch (error) {
            console.warn("error fetching products", error)
        }
    }


    // Payments
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [selectedOrderForPayment, setSelectedOrderForPayment] = useState(null)

    // Input Fields
    const [paymentAmount, setPaymentAmount] = useState("")
    const [paymentCurrency, setPaymentCurrency] = useState("EUR")
    const [paymentProvider, setPaymentProvider] = useState("")
    const [transactionId, setTransactionId] = useState("")


    const openPaymentModal = (orderId) => {
        setSelectedOrderForPayment(orders.find(o => o.id === orderId))
        setShowPaymentModal(true)
    }

    const handleAddPayment = async (orderId, orderFinancialId, amount, currency, paymentProvider, transactionId) => {
        const order = orders.find(o => o.id === orderId)
        try {
            // Create Payment
            const res = await client.graphql({
                query: /* GraphQL */ `
                mutation CreatePayment(
                  $input: CreatePaymentInput!
                ) {
                  createPayment(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        amount,
                        currency: currency || "EUR",
                        paymentProvider,
                        orderFinancialPaymentsId: orderFinancialId,
                        transactionId: transactionId || ""
                    }
                }
            })
            // Update local order with new payment
            const updatedOrder = {
                ...order,
                financial: {
                    ...order.financial,
                    payments: {
                        items: [...order.financial.payments.items, {
                            id: res.data.createPayment.id,
                            amount,
                            currency: currency || "EUR",
                            paymentProvider,
                            transactionId
                        }]
                    }
                }
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
            setShowPaymentModal(false)
        }
        catch (error) {
            console.warn("error adding payment", error)
        }
    }

    const handleRemovePayment = async (orderId, paymentId) => {
        try {
            // Delete Payment
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeletePayment(
                  $input: DeletePaymentInput!
                ) {
                  deletePayment(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        id: paymentId
                    }
                }
            })
            // Update local order with new payment
            const updatedOrder = {
                ...selectedOrderForPayment,
                financial: {
                    ...selectedOrderForPayment.financial,
                    payments: {
                        items: selectedOrderForPayment.financial.payments.items.filter(p => p.id !== paymentId)
                    }
                }
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
        }
        catch (error) {
            console.warn("error removing payment", error)
        }
    }

    const [createInvoiceLoadingOrders, setCreateInvoiceLoadingOrders] = useState([])
    const createInvoice = async (orderId) => {
        setCreateInvoiceLoadingOrders([...createInvoiceLoadingOrders, orderId])
        try {
           const documentRes = await client.graphql({
                query: /* GraphQL */ `
                mutation CreateDocument(
                  $documentType: String
                  $orderIds: String
                  $split: Boolean
                  $returnBase64: Boolean
                ) {
                  createDocument(
                    documentType: $documentType
                    orderIds: $orderIds
                    split: $split
                    returnBase64: $returnBase64
                  ) {
                    succeded
                    url
                    s3Path
                    documentNumber
                    documentName
                    status
                    date
                    
                  }
                }
              `,
                variables: {
                    documentType: "invoice",
                    orderIds: JSON.stringify([orderId]),
                    split: false,
                    returnBase64: false
                }
            })

            // Update Order with Invoice Document
            const order = orders.find(o => o.id === orderId)
            const updatedOrder = {
                ...order,
                financial: {
                    ...(order.financial || {}),
                    documents: [
                        ...(order.financial?.documents?.filter(doc => doc.documentNumber !== documentRes.data.createDocument.documentNumber) || []),
                        {
                            documentType: "invoice",
                            s3Path: documentRes.data.createDocument.s3Path,
                            documentNumber: documentRes.data.createDocument.documentNumber,
                            documentName: documentRes.data.createDocument.documentName,
                            status: "active",
                            date: documentRes.data.createDocument.date,
                        }
                    ]
                }
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))



        } catch (error) {
            console.warn("error creating invoice", error)
        }
        setCreateInvoiceLoadingOrders(createInvoiceLoadingOrders.filter(id => id !== orderId))
    }

    const [createRemainingInvoiceModalOpen, setCreateRemainingInvoiceModalOpen] = useState(false)
    const [selectedOrderForRemainingInvoice, setSelectedOrderForRemainingInvoice] = useState(null)
    const [remainingInvoiceDueDate, setRemainingInvoiceDueDate] = useState(null)
    const [remainingInvoiceReferingDocumentNumber, setRemainingInvoiceReferingDocumentNumber] = useState(null)
    const [isLoadingCreateRemainingInvoice, setIsLoadingCreateRemainingInvoice] = useState(false)
    const createRemainingInvoice = async () => {
        setIsLoadingCreateRemainingInvoice(true)
        try {
            const documentRes = await client.graphql({
                query: /* GraphQL */ `
                mutation CreateDocument(
                    $documentType: String
                    $orderIds: String
                    $split: Boolean
                    $returnBase64: Boolean
                    $dueDate: AWSDate
                    $referingDocumentNumber: String
                    ) {
                    createDocument(
                        documentType: $documentType
                        orderIds: $orderIds
                        split: $split
                        returnBase64: $returnBase64
                        dueDate: $dueDate
                        referingDocumentNumber: $referingDocumentNumber
                    ) {
                        succeded
                        url
                        s3Path
                        documentNumber
                        documentName
                        status
                        date
                    }
                }
                `,
                variables: {
                    documentType: "remaining-invoice",
                    orderIds: JSON.stringify([selectedOrderForRemainingInvoice.id]),
                    split: false,
                    returnBase64: false,
                    dueDate: remainingInvoiceDueDate,
                    referingDocumentNumber: remainingInvoiceReferingDocumentNumber
                }
            })

            // Update Order with Invoice Document
            const order = orders.find(o => o.id === selectedOrderForRemainingInvoice.id)
            const updatedOrder = {
                ...order,
                financial: {
                    ...(order.financial || {}),
                    documents: [
                        ...(order.financial?.documents?.filter(doc => doc.documentNumber !== documentRes.data.createDocument.documentNumber) || []),
                        {
                            documentType: "remaining-invoice",
                            s3Path: documentRes.data.createDocument.s3Path,
                            documentNumber: documentRes.data.createDocument.documentNumber,
                            referingDocumentNumber: remainingInvoiceReferingDocumentNumber,
                            documentName: documentRes.data.createDocument.documentName,
                            status: "active",
                            date: documentRes.data.createDocument.date,
                        }
                    ]
                }
            }
            setOrders(orders.map(o => o.id === selectedOrderForRemainingInvoice.id ? updatedOrder : o))
            setCreateRemainingInvoiceModalOpen(false)
        } catch (error) {
            console.warn("error creating remaining invoice", error)
        }
        setIsLoadingCreateRemainingInvoice(false)
    }




    const [sendDocumentEmailLoadingOrders, setSendDocumentEmailLoadingOrders] = useState([])
    const sendDocumentEmail = async (orderId, orderFinancialId, documentNumber) => {
        setSendDocumentEmailLoadingOrders([...sendDocumentEmailLoadingOrders, orderId])
        try {
            const res = await client.graphql({
                query: /* GraphQL */ `
                mutation SendDocumentEmail($orderId: String, $orderFinancialId: String, $documentNumber: String) {
                    sendDocumentEmail(orderId: $orderId, orderFinancialId: $orderFinancialId, documentNumber: $documentNumber) {
                        sentToEmails
                    }
                }
            `,
                variables: {
                    orderId,
                    orderFinancialId,
                    documentNumber
                }
            })
            console.log("email sent", res)

            // Update Order with last sent
            const order = orders.find(o => o.id === orderId)
            const updatedOrder = {
                ...order,
                financial: {
                    ...(order.financial || {}),
                    documents: order.financial.documents.map(doc => doc.documentNumber === documentNumber ? {
                        ...doc,
                        sentToCustomerTime: new Date().toISOString(),
                        sentToCustomerAddresses: res.data.sendDocumentEmail.sentToEmails
                    } : doc)
                }
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))

        } catch (error) {
            console.warn("error sending email", error)
        }
        setSendDocumentEmailLoadingOrders(sendDocumentEmailLoadingOrders.filter(id => id !== orderId))
    }
   

            
    // Edit Line Item
    const [lineItemsOpenEdits, setLineItemsOpenEdits] = useState({})

    const handleEditLineItem = async (orderId, id, field, value) => {
        try {
            // Update Line Item
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrderLineItem(
                  $input: UpdateOrderLineItemInput!
                ) {
                  updateOrderLineItem(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        id,
                        [field]: value
                    }
                }
            })
            // Update local order with new line item
            const order = orders.find(o => o.id === orderId)
            let updatedOrder = {
                ...order,
                lineItems: {
                    items: order.lineItems.items.map(item => item.id === id ? {...item, [field]: value} : item)
                }
            }
            

            // Recalculate total amount net, tax and total amount and update order financial
            const totalAmount = calculateOrderTotalAmount(updatedOrder)
            await client.graphql ({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                    ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: order.orderFinancialId,
                        invoiceAmountNet: totalAmount.net,
                        totalAmount: totalAmount.total,
                        invoiceAmount: totalAmount.total
                    }
                }
            })
            updatedOrder = {
                ...updatedOrder,
                financial: {
                    ...updatedOrder.financial,
                    invoiceAmountNet: totalAmount.net,
                    totalAmount: totalAmount.total,
                    invoiceAmount: totalAmount.total
                }
            } 
            
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
        }
        catch (error) {
            console.warn("error updating line item", error)
        }
    }

    const handleDeleteLineItem = async (orderId, lineItemId) => {
        const order = orders.find(o => o.id === orderId)
        const lineItem = order.lineItems.items.find(i => i.id === lineItemId)
        try {
            // Delete Line Item
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteOrderLineItem(
                    $input: DeleteOrderLineItemInput!
                    ) {
                    deleteOrderLineItem(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: lineItemId
                    }
                }
            })
            // Update local order with new line item
            const updatedOrder = {
                ...order,
                lineItems: {
                    items: order.lineItems.items.filter(i => i.id !== lineItemId)
                }
            }
            // Recalculate total amount net, tax and total amount and update order financial
            const totalAmount = calculateOrderTotalAmount(updatedOrder)
            await client.graphql ({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                    ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: order.orderFinancialId,
                        invoiceAmountNet: totalAmount.net,
                        totalAmount: totalAmount.total,
                        invoiceAmount: totalAmount.total
                    }
                }
            })
            updatedOrder.financial = {
                ...updatedOrder.financial,
                invoiceAmountNet: totalAmount.net,
                totalAmount: totalAmount.total,
                invoiceAmount: totalAmount.total
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
            

        }
        catch (error) {
            console.warn("error deleting line item", error)
        }
    }
    // Edit Order Financial
    const [orderFinancialOpenEdits, setOrderFinancialOpenEdits] = useState({})
    const [orderFinancialOpenEditsValues, setOrderFinancialOpenEditsValues] = useState({})

    const calculateDiscountAmount = (discount, financial, lineItems) => {
        let discountAmount = 0;
        if(discount.discountType === "percentage") {
            discountAmount = (discount.discountPercentage / 100) * financial.invoiceAmountNet;
        } else if(discount.discountType === "fixedAmount") {
            discountAmount = discount.discountAmount;
        }
        return -discountAmount;
    }

    const calculateOrderTotalAmount = (order) => {
        const netAmountItems = order.lineItems.items.filter(i => !i.isCanceled).reduce((acc, item) => acc + item.price * item.qty, 0);
        const discountAmount = order.discounts?.reduce((acc, discount) => acc + calculateDiscountAmount(discount, order.financial, order.lineItems.items), 0) || 0;
        return {
            net:   netAmountItems + discountAmount,
            tax: ((netAmountItems + discountAmount) * order.financial.taxRate / 100).toFixed(2),
            total: ((netAmountItems + discountAmount) + (netAmountItems + discountAmount) * order.financial.taxRate / 100).toFixed(2)
        }
    }

    const handleEditOrderFinancial = async (orderId, field, value) => {

        try {
            // Recalculate total amount
            const order = orders.find(o => o.id === orderId)
            let totalAmount = order.financial.invoiceAmountNet
            if (field === "taxRate") {
                totalAmount = parseFloat((order.financial.invoiceAmountNet * (1 + value / 100)).toFixed(2))
            }

            // Update Order Financial
            await client.graphql
            ({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                    ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: orders.find(o => o.id === orderId).orderFinancialId,
                        [field]: value,
                        ...(field === "taxRate" && {invoiceAmount: totalAmount})
                    }
                }
            })
            // Update local order with new financial
            const updatedOrder = {
                ...order,
                financial: {
                    ...order.financial,
                    [field]: value,
                    ...(field === "taxRate" && {invoiceAmount: totalAmount})
                }
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
        }
        catch (error) {
            console.warn("error updating order financial", error)
        }
    }

    // Edit Notes
    const [showEditNotesOrderIds, setShowEditNotesOrderIds] = useState([])
    const [newNotes, setNewNotes] = useState("")

    const handleStartEditNotes = (orderId) => {
        setShowEditNotesOrderIds([...showEditNotesOrderIds, orderId])
        setNewNotes(orders.find(o => o.id === orderId).notes)
    }

    const handleUpdateNotes = async (orderId, newNotes) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrder(
                  $input: UpdateOrderInput!
                ) {
                  updateOrder(input: $input) {
                    id
                  }
                }
              `,
                variables: {
                    input: {
                        id: orderId,
                        notes: newNotes
                    }
                }
            })
            // Update local order with new notes
            const updatedOrder = {
                ...orders.find(o => o.id === orderId),
                notes: newNotes
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
            setShowEditNotesOrderIds(showEditNotesOrderIds.filter(id => id !== orderId))
        }
        catch (error) {
            console.warn("error updating notes", error)
        }
    }

    const [showCancelDocumentModal, setShowCancelDocumentModal] = useState(false)
    const [selectedOrderAndDocumentForCancel, setSelectedOrderAndDocumentForCancel] = useState(null)

    const handleCancelDocument = async (orderId, orderFinancialId, documentType, documentNumber, createCredit) => {
        try {
            if(documentType === "invoice") {
               // Remove Document
                await client.graphql({
                    query: /* GraphQL */ `
                    mutation UpdateOrderFinancial(
                        $input: UpdateOrderFinancialInput!
                        ) {
                        updateOrderFinancial(input: $input) {
                            id
                        }
                        }
                    `,
                    variables: {
                        input: {
                            id: orderFinancialId,
                            documents: [
                                ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber !== documentNumber),
                                {
                                    ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber === documentNumber)[0],
                                    status: "cancelled"
                                }
                            ]
                        }
                    }
                })
            }
                // Create Credit Note
                const res = await client.graphql({
                    query: /* GraphQL */ `
                    mutation CreateDocument(
                        $documentType: String
                        $orderIds: String
                        $split: Boolean
                        $returnBase64: Boolean
                        $referingDocumentNumber: String
                        ) {
                        createDocument(
                            documentType: $documentType
                            orderIds: $orderIds
                            split: $split
                            returnBase64: $returnBase64
                            referingDocumentNumber: $referingDocumentNumber
                        ) {
                            succeded
                            url
                            base64
                            invoiceNumber
                            documentType
                            s3Path
                            documentNumber
                            documentName
                            status
                            date
                        }
                    }
                    `,
                    variables: {
                        documentType: "credit",
                        orderIds: JSON.stringify([orderId]),
                        split: false,
                        returnBase64: false,
                        referingDocumentNumber: documentNumber
                    }
                })

                // Update Order new document and credit note
                const updatedOrder = {
                    ...orders.find(o => o.id === orderId),
                    financial: {
                        ...orders.find(o => o.id === orderId).financial,
                        documents: [
                            ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber !== documentNumber),
                            {
                                ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber === documentNumber)[0],
                                status: "cancelled"
                            },
                            {
                                documentType: "credit",
                                s3Path: res.data.createDocument.url.replace("https://iec-cp-public.s3.eu-central-1.amazonaws.com/", ""),
                                documentNumber: res.data.createDocument.documentNumber,
                                documentName: res.data.createDocument.documentName,
                                status: res.data.createDocument.status,
                                date: res.data.createDocument.date
                            }
                        ]
                    }
                }
                setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
                setShowCancelDocumentModal(false)

        }
        catch (error) {
            console.warn("error canceling document", error)
        }
    }

    const handleDeleteDocument = async (orderId, orderFinancialId, documentType, documentNumber, createCredit) => {
        try {
            if(documentType === "invoice" || documentType === "remaining-invoice") {
               // Remove Document
                await client.graphql({
                    query: /* GraphQL */ `
                    mutation UpdateOrderFinancial(
                        $input: UpdateOrderFinancialInput!
                        ) {
                        updateOrderFinancial(input: $input) {
                            id
                        }
                        }
                    `,
                    variables: {
                        input: {
                            id: orderFinancialId,
                            documents: [
                                ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber !== documentNumber),
                                {
                                    ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber === documentNumber && doc.status !== "deleted")[0],
                                    status: "deleted"
                                },
                            ]
                        }
                    }
                })
            }
              

                // Update Order new document and credit note
                const updatedOrder = {
                    ...orders.find(o => o.id === orderId),
                    financial: {
                        ...orders.find(o => o.id === orderId).financial,
                        documents: [
                            ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber !== documentNumber),
                            {
                                ...orders.find(o => o.id === orderId).financial.documents.filter(doc => doc.documentNumber === documentNumber && doc.status !== "deleted")[0],
                                status: "cancelled"
                            }
                        ]
                    }
                }
                setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
                setShowCancelDocumentModal(false)

        }
        catch (error) {
            console.warn("error canceling document", error)
        }
    }

    const handleEditOrderStatus = async (type, orderId, status) => {
        console.log("update order status", type, orderId, status)
        try {
            // Update Order Status
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrder(
                    $input: UpdateOrderInput!
                    ) {
                    updateOrder(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: orderId,
                        ...(type === "payment" && {paymentStatus: status}),
                        ...(type === "status" && {status: status})
                    }
                }
            })
            // Update local order with new status
            const updatedOrder = {
                ...orders.find(o => o.id === orderId),
                ...(type === "payment" && {paymentStatus: status}),
                ...(type === "status" && {status})
            }
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o))
        }
        catch (error) {
            console.warn("error updating order status", error)
        }
    }

    // Cancel order Line Items

    const [selectedOrderLineItems, setSelectedOrderLineItems] = useState([])
    const [selectedOrderForCancelLineItems, setSelectedOrderForCancelLineItems] = useState([])
    const [showCancelLineItemsModal, setShowCancelLineItemsModal] = useState(false)
    const [cancelLineItemsOptions, setCancelLineItemsOptions] = useState({
        removeFromBooth: false,
        createCancelationDocument: false,
        returnStock: true
    })
    const [isLoadingCancelLineItems, setIsLoadingCancelLineItems] = useState(false)

    
    const handleCancelLineItems = async () => {
        setIsLoadingCancelLineItems(true)
        try {

            const order = orders.find(o => o.id === selectedOrderForCancelLineItems);

            let newFinancialDocument = null;
            // CreateCancelationDocument
            if(cancelLineItemsOptions.createCancelationDocument) {
                const documentRes = await client.graphql({
                    query: /* GraphQL */ `
                    mutation CreateDocument(
                        $documentType: String
                        $orderIds: String
                        $split: Boolean
                        $returnBase64: Boolean
                        $lineItemIds: String
                        ) {
                        createDocument(
                            documentType: $documentType
                            orderIds: $orderIds
                            split: $split
                            returnBase64: $returnBase64
                            lineItemIds: $lineItemIds
                        ) {
                            succeded
                            url
                            s3Path
                            documentNumber
                            documentName
                            status
                            date
                        }
                    }
                    `,
                    variables: {
                        documentType: "credit",
                        orderIds: JSON.stringify([selectedOrderForCancelLineItems]),
                        lineItemIds: JSON.stringify(selectedOrderLineItems),
                        split: false,
                        returnBase64: false
                    }
                })
                newFinancialDocument = {
                        documentType: "credit",
                        s3Path: documentRes.data.createDocument.url.replace("https://iec-cp-public.s3.eu-central-1.amazonaws.com/", ""),
                        documentNumber: documentRes.data.createDocument.documentNumber,
                        documentName: documentRes.data.createDocument.documentName,
                        status: documentRes.data.createDocument.status,
                        date: documentRes.data.createDocument.date
                    }
            }

            // Remove Line Items from Booth
            if(cancelLineItemsOptions.removeFromBooth) {
                // First get all the BothEquimentIds by using this query: listBoothEquipments(filter: {boothEquipmentOrderLineItemId: {eq: "orderLineItemsId"}}) {items {id}}
                try {
                    const boothEquipmentIds = await Promise.all(selectedOrderLineItems.map(async lineItemId => {
                        const orderLineItem = orders.find(o => o.id === selectedOrderForCancelLineItems).lineItems.items.find(i => i.id === lineItemId);
                        if (orderLineItem.boothEquipmentRemoved) {
                            return;
                        }

                        let boothEquipmentIds = [];
                        let nextToken = null;
                        do {
                            const res = await client.graphql({
                                query: /* GraphQL */ `
                                query ListBoothEquipments(
                                    $filter: ModelBoothEquipmentFilterInput
                                    $limit: Int
                                    $nextToken: String
                                ) {
                                    listBoothEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                        items {
                                            id
                                        }
                                        nextToken
                                    }
                                }
                                `,
                                variables: {
                                    filter: {
                                        boothEquipmentOrderLineItemId: { eq: lineItemId }
                                    },
                                    limit: 1000,
                                    nextToken: nextToken
                                }
                            });
                            boothEquipmentIds = boothEquipmentIds.concat(res.data.listBoothEquipments.items.map(item => item.id));
                            nextToken = res.data.listBoothEquipments.nextToken;
                        } while (nextToken);
                        return boothEquipmentIds.filter(Boolean)[0];
                    }
                    ))
                    // Delete BoothEquipments
                    await Promise.all(boothEquipmentIds.map(async boothEquipmentId => {
                        await client.graphql({
                            query: /* GraphQL */ `
                            mutation DeleteBoothEquipment(
                                $input: DeleteBoothEquipmentInput!
                            ) {
                                deleteBoothEquipment(input: $input) {
                                    id
                                }
                            }
                            `,
                            variables: {
                                input: {
                                    id: boothEquipmentId
                                }
                            }
                        })
                    }))
                }
                catch (error) {
                    console.warn("error removing booth equipment", error)
                }
            }

            if(cancelLineItemsOptions.returnStock) {
                // If order is for event, return stock to eventProduct, otherwise to the product
                const ordersEventId = orders.find(o => o.id === selectedOrderForCancelLineItems).eventBooth?.event?.id

                await Promise.all(selectedOrderLineItems.map(async lineItemId => {

                    const orderLineItem = orders.find(o => o.id === selectedOrderForCancelLineItems).lineItems.items.find(i => i.id === lineItemId)

                    if (orderLineItem.stockReturned) {
                        return;
                    }

                    let eventProductId = null;
                    let eventProductCurrentStock = null;

                    const eventId = orderLineItem.bookedBooth?.event?.id || ordersEventId
                    if(eventId) {
                        let allEventProducts = [];
                        let nextToken = null;
                        do {
                            const response = await client.graphql({
                                query: /* GraphQL */ `
                                    query ListEventProducts(
                                        $filter: ModelEventProductFilterInput
                                        $limit: Int
                                        $nextToken: String
                                    ) {
                                        listEventProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                            items {
                                                id
                                                stock
                                            }
                                            nextToken
                                        }
                                    }
                                `,
                                variables: {
                                    filter: {
                                        and: {
                                            productEventProductsId: { eq: orderLineItem.product.id },
                                            eventEventProductsId: { eq: eventId }
                                        }
                                    },
                                    limit: 1000,
                                    nextToken: nextToken
                                }
                            });
                            const result = response.data.listEventProducts;
                            allEventProducts = allEventProducts.concat(result.items);
                            nextToken = result.nextToken;
                        } while (nextToken);
                            
                        // Now, allEventProducts contains all items from paginated results
                        if (allEventProducts.length > 1) {
                            throw new Error("more than one event product found");
                        } else if (allEventProducts.length === 1) {
                            eventProductId = allEventProducts[0].id;
                            eventProductCurrentStock = allEventProducts[0].stock;
                        }
                    }

                    if(eventProductId) {
                        await client.graphql({
                            query: /* GraphQL */ `
                            mutation UpdateEventProduct(
                                $input: UpdateEventProductInput!
                            ) {
                                updateEventProduct(input: $input) {
                                    id
                                }
                            }
                            `,
                            variables: {
                                input: {
                                    id: eventProductId,
                                    stock: eventProductCurrentStock + orderLineItem.qty
                                }
                            }
                        })
                    } else {
                        const productRes = await client.graphql({
                            query: /* GraphQL */ `
                            query GetProduct (
                                $id: ID!
                            ) {
                                getProduct(id: $id) {
                                    id
                                    stock
                                }
                            }
                            `,
                            variables: {
                                id: orderLineItem.product.id
                            }
                        });

                        await client.graphql({
                            query: /* GraphQL */ `
                            mutation UpdateProduct(
                                $input: UpdateProductInput!
                            ) {
                                updateProduct(input: $input) {
                                    id
                                }
                            }
                            `,
                            variables: {
                                input: {
                                    id: orderLineItem.product.id,
                                    stock: productRes.data.getProduct.stock + orderLineItem.qty
                                }
                            }
                        })
                    }
                }))
            }

            // Update Order Line Items
            
            const newOrderLineItems = await Promise.all(selectedOrderLineItems.map(async lineItemId => {
                await client.graphql({
                    query: /* GraphQL */ `
                    mutation UpdateOrderLineItem(
                        $input: UpdateOrderLineItemInput!
                    ) {
                        updateOrderLineItem(input: $input) {
                            id
                        }
                    }
                    `,
                    variables: {
                        input: {
                            id: lineItemId,
                            isCanceled: true,
                            cancelationDate: new Date().toISOString(),
                            stockReturned: cancelLineItemsOptions.returnStock,
                            boothEquipmentRemoved: cancelLineItemsOptions.removeFromBooth
                        }
                    }
                })
                return (order.lineItems.items.map(item => item.id === lineItemId ? {
                        ...item,
                        isCanceled: true,
                        cancelationDate: new Date().toISOString(),
                        boothEquipmentRemoved: cancelLineItemsOptions.removeFromBooth,
                        stockReturned: cancelLineItemsOptions.returnStock
                    } : item)
                )
            }))

            const updatedOrder = {
                ...order,
                lineItems: {
                    items: newOrderLineItems.flat()
                }
            }

            // Recalculate total amount net, tax and total amount and update order financial
            console.log("updatedOrder", updatedOrder)
            const totalAmount = calculateOrderTotalAmount(updatedOrder)
            console.log("totalAmount", totalAmount)
            await client.graphql ({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                    ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                    }
                `,
                variables: {
                    input: {
                        id: order.orderFinancialId,
                        invoiceAmountNet: totalAmount.net,
                        totalAmount: totalAmount.total,
                        invoiceAmount: totalAmount.total
                    }
                }
            })
            updatedOrder.financial = {
                ...updatedOrder.financial,
                ...(newFinancialDocument ? {documents: [
                    ...updatedOrder.financial.documents,
                    newFinancialDocument
                ]} : {}),
                invoiceAmountNet: totalAmount.net,
                totalAmount: totalAmount.total,
                invoiceAmount: totalAmount.total
            }
            setOrders(orders.map(o => o.id === selectedOrderForCancelLineItems ? updatedOrder : o))


        }

        catch (error) {
            console.warn("error canceling line items", error)
        }
        setShowCancelLineItemsModal(false)
        setCancelLineItemsOptions({
            removeFromBooth: false,
            createCancelationDocument: false,
            returnStock: true
        })
        setSelectedOrderLineItems([])
        setIsLoadingCancelLineItems(false)
    }

    const handleRemoveDiscount = async (orderId, discountId) => {
        console.log("remove discount", orderId, discountId)
        const order = orders.find(o => o.id === orderId);
        try {
            // Parse order data to access discounts
            const orderData = JSON.parse(order.data);
            
            // Filter out the discount to remove
            const updatedDiscounts = orderData.discounts.filter(d => d.id !== discountId);
            
            // Update the order data with new discounts
            const updatedOrderData = {
                ...orderData,
                discounts: updatedDiscounts
            };
            
            // Update order in backend
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrder(
                    $input: UpdateOrderInput!
                ) {
                    updateOrder(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id: orderId,
                        data: JSON.stringify(updatedOrderData)
                    }
                }
            });
            
            // Create updated order object
            const updatedOrder = {
                ...order,
                data: JSON.stringify(updatedOrderData),
                discounts: order.discounts.filter(discount => discount.id !== discountId)
            };
            
            // Recalculate total amount
            const totalAmount = calculateOrderTotalAmount(updatedOrder);
            
            // Update financial information
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateOrderFinancial(
                    $input: UpdateOrderFinancialInput!
                ) {
                    updateOrderFinancial(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id: order.orderFinancialId,
                        invoiceAmountNet: totalAmount.net,
                        totalAmount: totalAmount.total,
                        invoiceAmount: totalAmount.total
                    }
                }
            });
            
            // Update order with new financial data
            updatedOrder.financial = {
                ...updatedOrder.financial,
                invoiceAmountNet: totalAmount.net,
                totalAmount: totalAmount.total,
                invoiceAmount: totalAmount.total
            };
            
            // Update orders state
            setOrders(orders.map(o => o.id === orderId ? updatedOrder : o));
        } catch (error) {
            console.warn("error removing discount", error);
        }
    };

  return (
<>
<MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("All Orders")}<Link to={`/admin/order/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create order")}</MDBBtn></Link></MDBCardTitle>
                <MDBRow className="mb-3">
                    <MDBCol lg="4" xl="3">
                        <MDBSelect
                            search
                            label={t("Select Industry")}
                            data={industries.map(industry => ({
                                text: getLocalizedText(industry.name),
                                icon: industry.logo ? `https://iec-cp-public.s3.eu-central-1.amazonaws.com/industryLogos/${industry.logo.fileName}`
                                : "https://mdbootstrap.com/img/Photos/Avatars/avatar-1.jpg"
                                ,
                                value: industry.id
                            }))}
                            value={filter.industryOrdersId?.eq}
                            onChange={(value) => {
                                setPage(1); // Reset page when industry changes
                                setFilter(oldFilter => ({
                                    ...oldFilter,
                                    industryOrdersId: { eq: value.value }
                                }));
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBInput type="text" onChange={e => setSearch(e.target.value)} label={t("Search")} />
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            <th><MDBCheckbox /></th>
                            <th width="150">{t("Order Number")}</th>
                            <th>{t("Order Time")}</th>
                            <th>{t("Customer")}</th>
                            <th>{t("Products")}</th>
                            <th>{t("Invoice amount")}</th>
                            <th>{t("Status")}</th>
                            
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {orders.map(order => {
                            const isSameAddress = JSON.stringify(order.deliveryAddress) === JSON.stringify(order.billingAddress)
                            return (
                            [
                                <tr key={order.id}
                                onClick={() => 
                                    setOpenOrders(openOrders.includes(order.id) ? openOrders.filter(id => id !== order.id) : [...openOrders, order.id])
                                }
                                className={order.status === "canceled" && "text-danger"}
                                >
                                <td><MDBCheckbox /></td>
                                <td>
                                    <span>{order.orderNumber}<br />
                                    <span className="grey-text">{order.buyerOrderNumber}</span></span>
                                    {order.industryOrdersId && 
                                        <img 
                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/industryLogos/${industries.find(i => i.id === order.industryOrdersId)?.logo?.fileName}`}
                                            style={{ height: '20px', display: 'block', marginTop: '5px' }}
                                            alt={getLocalizedText(industries.find(i => i.id === order.industryOrdersId)?.name)}
                                        />
                                    }
                                </td>
                                <td>
                                    {new Date(order.createdAt).toLocaleDateString("de-DE", {dateStyle: "medium"})} {new Date(order.createdAt).toLocaleTimeString("de-DE", {timeStyle: "short"})}
                                </td>
                                <td>
                                    <span>{order.billingAddress?.firstName} {order.billingAddress?.lastName} {order.billingAddress?.company} {order.billingAddress?.zip} {order.billingAddress?.countryCode}</span>
                                </td>
                                <td>
                                {order.lineItems.items.map(item => (
                                    <span key={item.id}>{item.product.sku}<br /></span>
                                ))}
                                </td>
                                <td>
                                    <span>{transformMoney(order.financial.invoiceAmountNet)}</span>
                                    <br />
                                    <span className="text-muted">{transformMoney(order.financial.invoiceAmount)}</span>
                                </td>
                                <td>
                                    <table>
                                        <tr>
                                            <td className="p-1 border-0"><MDBIcon fas icon="file-invoice" /></td>
                                            <td className="p-1 border-0">
                                                <span>
                                                    {order.financial.documents?.filter(doc => doc.documentType === "invoice" && doc.status === "active")?.sort((a, b) => new Date(b.date) - new Date(a.date))?.[0]?.documentNumber}
                                                </span>
                                                </td>
                                        </tr>
                                        <tr>
                                            <td className="p-1 border-0"><MDBIcon fas icon="dollar-sign" /></td>
                                            <td className="p-1 border-0">
                                                { order.financial?.payments?.items.length > 0 && transformMoney(order.financial.payments.items.reduce((acc, payment) => acc + payment.amount, 0)) }{
                                                order.financial.payments.items.reduce((acc, payment) => acc + payment.amount, 0) === order.financial.invoiceAmount ?
                                                <MDBIcon icon="check" className="text-success" />
                                                :
                                                <MDBIcon icon="times" className="text-danger" />

                                            }</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>,
                            <tr key={order.id + "details"} 
                                style={{display: openOrders.includes(order.id) ? "" : "none"}}
                            >
                                <td colSpan="7">
                                <MDBCollapse open={openOrders.includes(order.id)}>
                                    <MDBRow>
                                        <MDBCol size="4">
                                            <MDBCard style={{height: "100%"}}>
                                                <MDBCardBody>
                                                    <MDBCardText><span className="fw-bold">{t("Billing & Shipping Address")}</span>
                                                    <MDBIcon icon="edit" className="float-end" onClick={() => openEditAddressModal(order.id)} />
                                                    </MDBCardText>
                                                    <MDBRow>
                                                        <MDBCol md={isSameAddress ? "12" : "6"}>
                                                        {isSameAddress ? "" : <span className="fw-bold dark-grey-text">{t("Billing")}<br></br></span>}
                                                        
                                                        {order.billingAddress &&
                                                            <span>
                                                                {order.billingAddress.firstName && <span>{order.billingAddress.firstName} {order.billingAddress.lastName}<br></br></span>}
                                                                {order.billingAddress.company && <>{order.billingAddress.company}<br></br></> }
                                                                {order.billingAddress.street} {order.billingAddress.streetNumber}<br></br>
                                                                {order.billingAddress.street2 && <>{order.billingAddress.street2}<br></br></> }
                                                                {order.billingAddress.street3 && <>{order.billingAddress.street3}<br></br></> }
                                                                {order.billingAddress.zip} {order.billingAddress.city}<br></br>
                                                                {order.billingAddress.region && <>{order.billingAddress.region}<br></br></> }
                                                                {order.billingAddress.countryCode && t(countryList().getLabel(order.billingAddress.countryCode))}
                                                            </span>
                                                        }
                                                        </MDBCol>
                                                        {(!isSameAddress || !order.billingAddress) && order.deliveryAddress &&
                                                            <MDBCol md={isSameAddress ? "12" : "6"}>
                                                            {isSameAddress ? "" : <span className="fw-bold dark-grey-text">{t("Shipping")}<br></br></span>}
                                                            <span>
                                                                {order.deliveryAddress.firstName && <span>{order.deliveryAddress.firstName} {order.deliveryAddress.lastName}<br></br></span>}
                                                                {order.deliveryAddress.company && <>{order.deliveryAddress.company}<br></br></> }
                                                                {order.deliveryAddress.street} {order.deliveryAddress.streetNumber}<br></br>
                                                                {order.deliveryAddress.street2 && <>{order.deliveryAddress.street2}<br></br></> }
                                                                {order.deliveryAddress.street3 && <>{order.deliveryAddress.street3}<br></br></> }
                                                                {order.deliveryAddress.zip} {order.deliveryAddress.city}<br></br>
                                                                {order.deliveryAddress.region && <>{order.deliveryAddress.region}<br></br></> }
                                                                {order.deliveryAddress.countryCode && t(countryList().getLabel(order.deliveryAddress.countryCode))}
                                                            </span>
                                                            </MDBCol>
                                                        }
                                                    </MDBRow>
                                                    <span className="text-muted mt-auto" style={{fontSize: "8px"}}>{order.id}</span>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                        <MDBCol size="4">
                                            <MDBCard className="h-100">
                                                <MDBCardBody>
                                                    <MDBCardText><span className="fw-bold">{t("Customer")}</span></MDBCardText>
                                                        <div className="text-left">
                                                            <table className="w-100">
                                                            {order.company &&
                                                                    <tr>
                                                                    <td className="p-1 border-0">
                                                                        <img src={"https://iec-cp-public.s3.amazonaws.com/companyImages/" + order.company.image?.main?.fileName} alt={order.company.name} className="img-fluid" style={{maxHeight: "20px"}} />
                                                                    </td>
                                                                    <td className="p-1 border-0">
                                                                        <Link to={"/admin/company/" + order.company.id} className="text-body">{order.company.name}</Link>
                                                                        </td>
                                                                </tr>}
                                                                <tr>
                                                                    <td className="p-1 border-0"><MDBIcon icon="user" /></td>
                                                                    <td className="p-1 border-0">
                                                                        <Link to={"/admin/user/" + order.user?.id} className="text-body">{order.user?.salutation} {order.user?.firstName} {order.user?.lastName} | {order.user?.email}</Link>
                                                                        </td>
                                                                </tr>
                                                               
                                                                <tr>
                                                                    <td className="p-1 border-0"><MDBIcon icon="envelope" /></td>
                                                                    <td className="p-1 border-0">
                                                                        {
                                                                            showEditBillingEmailModalOrderIds.includes(order.id) ?
                                                                            <>
                                                                            <MDBInput size="sm" value={order.billingEmail} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, billingEmail: e.target.value} : o))} />
                                                                            <MDBBtn size="sm" color="primary" onClick={() => {handleUpdateBillingEmail(order.id, order.billingEmail); setShowEditBillingEmailModalOrderIds(showEditBillingEmailModalOrderIds.filter(id => id !== order.id))}}>{t("Save")}</MDBBtn>
                                                                            <MDBIcon icon="times" className="float-end" onClick={() => setShowEditBillingEmailModalOrderIds(showEditBillingEmailModalOrderIds.filter(id => id !== order.id))} />
                                                                            </>
                                                                            :
                                                                            order.billingEmail
                                                                        }
                                                                    {!showEditBillingEmailModalOrderIds.includes(order.id) && <MDBIcon className="float-end" icon="edit" onClick={() => setShowEditBillingEmailModalOrderIds([...showEditBillingEmailModalOrderIds, order.id])} />}
                                                                    </td>
                                                                </tr>
                                                                {order.user?.contact?.phone &&
                                                                    <tr>
                                                                    <td className="p-1 border-0"><MDBIcon icon="phone" /></td>
                                                                    <td className="p-1 border-0">{order.user?.contact.phone}</td>
                                                                </tr>}
                                                                <tr>
                                                                    <td className="p-1 border-0"><MDBIcon fas icon="file-invoice" /></td>
                                                                    <td className="p-1 border-0">
                                                                        {
                                                                            showEditBuyerOrderNumberOrderIds.includes(order.id) ?
                                                                            <>
                                                                            <MDBInput size="sm" value={order.buyerOrderNumber} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, buyerOrderNumber: e.target.value} : o))} />
                                                                            <MDBBtn size="sm" color="primary" onClick={() => {handleUpdateBuyerOrderNumber(order.id, order.buyerOrderNumber); setShowEditBuyerOrderNumberOrderIds(showEditBuyerOrderNumberOrderIds.filter(id => id !== order.id))}}>{t("Save")}</MDBBtn>
                                                                            <MDBIcon icon="times" className="float-end" onClick={() => setShowEditBuyerOrderNumberOrderIds(showEditBuyerOrderNumberOrderIds.filter(id => id !== order.id))} />
                                                                            </>
                                                                            :
                                                                            order.buyerOrderNumber
                                                                        }
                                                                    {!showEditBuyerOrderNumberOrderIds.includes(order.id) && <MDBIcon className="float-end" icon="edit" onClick={() => setShowEditBuyerOrderNumberOrderIds([...showEditBuyerOrderNumberOrderIds, order.id])} />}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-1 border-0"><MDBIcon icon="hand-holding-usd" /></td>
                                                                    <td className="p-1 border-0">
                                                                        {
                                                                            showEditVatInfoModalOrderIds.includes(order.id) ?
                                                                            <>
                                                                            <MDBInput size="sm" value={order.financial.vatInfo?.vatNumber} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, financial: {...o.financial, vatInfo: {...o.financial.vatInfo, vatNumber: e.target.value}}} : o))} />
                                                                            {/* <MDBSelect
                                                                            search
                                                                            data={[
                                                                                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                                                                                ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                                                                                const order = ['DE', 'AT', 'CH'];
                                                                                    const indexA = order.indexOf(a.value);
                                                                                const indexB = order.indexOf(b.value);
                                                                                if (indexA > -1 && indexB > -1) {
                                                                                    return indexA - indexB;
                                                                                } else if (indexA > -1) {
                                                                                    return -1;
                                                                                } else if (indexB > -1) {
                                                                                    return 1;
                                                                                }
                                                                                return a.value.localeCompare(b.value);
                                                                                }) || [{}] || [{}]
                                                                            ]}
                                                                            value={order.financial.vatInfo?.country}
                                                                            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")}
                                                                            onChange={value => setOrders(orders.map(o => o.id === order.id ? {...o, financial: {...o.financial, vatInfo: {...o.financial.vatInfo, country: value}}} : o))}
                                                                            /> */}
                                                                          
                                                                            <MDBBtn size="sm" color="primary" onClick={() => {handleUpdateVatInfo(order.id, order.financial.vatInfo); setShowEditVatInfoModalOrderIds(showEditVatInfoModalOrderIds.filter(id => id !== order.id))}}>{t("Save")}</MDBBtn>
                                                                            <MDBIcon icon="times" className="float-end" onClick={() => setShowEditVatInfoModalOrderIds(showEditVatInfoModalOrderIds.filter(id => id !== order.id))} />
                                                                            </>
                                                                            :
                                                                            order.financial.vatInfo?.vatNumber
                                                                        }
                                                                    {!showEditVatInfoModalOrderIds.includes(order.id) && <MDBIcon className="float-end" icon="edit" onClick={() => setShowEditVatInfoModalOrderIds([...showEditVatInfoModalOrderIds, order.id])} />}
                                                                    </td>
                                                                    
                                                                    
                                                                </tr>
                                                                {/* Order financial isBusiness and isInterEUDelivery with checkbox that updates order financial */}
                                                                <tr>
                                                                    <td className="p-1 border-0"><MDBIcon icon="building" /></td>
                                                                    <td className="p-1 border-0">
                                                                        <MDBCheckbox label={t("Business")} checked={order.financial.isBusiness} onChange={() => handleEditOrderFinancial(order.id, "isBusiness", !order.financial.isBusiness)} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-1 border-0"><MDBIcon icon="truck" /></td>
                                                                    <td className="p-1 border-0">
                                                                        <MDBCheckbox label={t("Inter EU Delivery")} checked={order.financial.isInterEUDelivery} onChange={() => handleEditOrderFinancial(order.id, "isInterEUDelivery", !order.financial.isInterEUDelivery)} />
                                                                    </td>
                                                                </tr>
                                                                
                                                            </table>
                                                            
                                                        </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                        <MDBCol size="4" className="h-100">
                                            <MDBCard className="h-100">
                                                <MDBCardBody>
                                                <MDBCardText><span className="fw-bold">{t("Status")}</span></MDBCardText>
                                                <MDBRow className="mb-2">
                                                    <MDBCol>
                                                        <MDBSelect size="sm" data={orderStates.map(state => ({text: t(state.label), value: state.value}))} value={order.status} onChange={value => value && value.value !== order.status && handleEditOrderStatus("status", order.id, value.value)} />
                                                    </MDBCol>
                                                    <MDBCol>
                                                        <MDBSelect size="sm" data={orderPaymentStates.map(state => ({text: t(state.label), value: state.value}))} value={order.paymentStatus} onChange={value => value && value.value !== order.paymentStatus && handleEditOrderStatus("payment", order.id, value.value)} />
                                                    </MDBCol>
                                                </MDBRow>
                                               

                                                <MDBCardText><span className="fw-bold">{t("Documents")}</span></MDBCardText>
                                                <MDBListGroup>
                                                {order.financial.documents?.sort((a, b) => new Date(b.date) - new Date(a.date)).map(doc => (
                                                    <MDBListGroupItem key={doc.documentNumber} className={(doc.status === "cancelled" || doc.status === "deleted") && "border-danger"}>
                                                    <span className="cursor-pointer" onClick={ () => window.open("https://iec-cp-public.s3.amazonaws.com/" + doc.s3Path, "_blank")}>
                                                        <span className={"fw-bold" + ((doc.status === "cancelled" || doc.status === "deleted") ? " text-decoration-line-through" : "")}>
                                                            {t(doc.documentType)}</span> - <span className={(doc.status === "cancelled" || doc.status === "deleted") ? "text-decoration-line-through" : ""}>{doc.documentType === "remaining-invoice" ? doc.referingDocumentNumber + "-R" :doc.documentNumber}</span> <span style={{fontSize: "10px"}} className={(doc.status === "cancelled" || doc.status === "deleted") ? "text-decoration-line-through" : ""}>{new Date(doc.date).toLocaleDateString("de-DE", {dateStyle: "short"})}</span> 
                                                            {/* Create remaining-invoice */}
                                                            
                                                    </span>
                                                    {doc.documentType === "invoice" && doc.status === "active" &&
                                                    <MDBBtn size="sm" color="link" className="text-body" onClick={() => {
                                                        setSelectedOrderForRemainingInvoice(order)
                                                        setRemainingInvoiceReferingDocumentNumber(doc.documentNumber)
                                                        setCreateRemainingInvoiceModalOpen(true)
                                                    } }>
                                                        <MDBIcon fas icon="plus" /> {t("Remaining Invoice")}
                                                    </MDBBtn>
                                                    }
                                                    {
                                                        doc.sentToCustomerTime ?
                                                        
                                                        <MDBPopover size='sm' color='none' btnClassName="float-end mt-1 ms-3 cursor-pointer text-success border-0 popover-clean" btnChildren={<MDBIcon icon='envelope' />} placement='bottom'>
                                                        <MDBPopoverHeader>{t("Document already sent.")}</MDBPopoverHeader>
                                                        <MDBPopoverBody>
                                                            <p>{doc.sentToCustomerAddresses?.map(address => <><span>{address}</span><br/></>)}</p>
                                                            <MDBBtn size="sm" color="primary" outline onClick={() => sendDocumentEmail(order.id, doc.documentType)}>{t("Resend")}</MDBBtn>
                                                            </MDBPopoverBody>
                                                        </MDBPopover>
                                                        :
                                                        sendDocumentEmailLoadingOrders.includes(order.id) ?
                                                        <div className="spinner-border spinner-border-sm float-end" role="status"> <span className="visually-hidden">Loading...</span> </div>
                                                        :
                                                        <MDBIcon icon='envelope' className={'float-end mt-1 ms-3 cursor-pointer' + (doc.sentToCustomerTime ? " text-success" : "")} onClick={() => sendDocumentEmail(order.id, order.financial.id, doc.documentNumber)} />
                                                    }
                                                    
                                                    {
                                                        (doc.documentType === "invoice" || doc.documentType === "remaining-invoice") && doc.status === "active" &&
                                                        <MDBIcon icon='times' className='float-end mt-1 cursor-pointer' onClick={() => {
                                                            setSelectedOrderAndDocumentForCancel({orderId: order.id, orderFinancialId: order.financial.id, documentType: doc.documentType, documentNumber: doc.documentNumber, sentToCustomerTime: doc.sentToCustomerTime})
                                                            setShowCancelDocumentModal(true)
                                                        
                                                        }} />
                                                    }
                                                    
                                                    </MDBListGroupItem>
                                                ))}

                                                </MDBListGroup>
                                                {order.financial.documents?.find(doc => doc.documentType === "invoice" && doc.status === "active") ?
                                                <></>
                                                :
                                                <MDBBtn className="mt-2" size="sm" outline color="primary" onClick={() => createInvoice(order.id)}>
                                                    {createInvoiceLoadingOrders.includes(order.id) ? 
                                                    <div className="spinner-border spinner-border-sm" role="status"> <span className="visually-hidden">Loading...</span> </div>
                                                     : t("Create Invoice")}</MDBBtn>
                                                }
                                                
                                             


                                                    {/* <MDBCardText><span className="fw-bold">{t("Invoice")}</span></MDBCardText>
                                                    {order.financial.invoiceFile && order.financial.invoiceFile.s3Path ?
                                                    <MDBCardText>
                                                        <span>{t("Invoice Number")}: {order.financial.invoiceNumber}</span>
                                                        <MDBIcon icon="times" className="float-end" onClick={() => {if (window.confirm(t("Are you sure you want to delete this invoice?"))) {
                                                            handleDeleteInvoice(order.id)
                                                        }}} />
                                                    </MDBCardText>
                                                    :
                                                    <MDBCardText>
                                                        {order.financial.invoiceNumber && <span>{t("Invoice Number")}: {order.financial.invoiceNumber}</span>}
                                                        <span>{t("No Invoice")}</span>
                                                    </MDBCardText>
                                                    }
                                                    
                                                    {order.financial.invoiceLastSent &&
                                                    <MDBCardText>
                                                        <span>{t("Last Sent")}: </span>
                                                        <span>{new Date(order.financial.invoiceLastSent).toLocaleDateString("de-DE", {dateStyle: "medium"})} {new Date(order.financial.invoiceLastSent).toLocaleTimeString("de-DE", {timeStyle: "short"})}</span>
                                                        <br></br>
                                                        <span>{order.financial.invoiceReceivers?.join(", ")}</span>
                                                    </MDBCardText>
                                                    }
                                                   
                                                    <MDBBtnGroup>
                                                    {order.financial.invoiceFile && order.financial.invoiceFile.s3Path ?
                                                        <>
                                                            <MDBBtn size="sm" outline color="success" href={"https://iec-cp-public.s3.amazonaws.com/" + order.financial.invoiceFile.s3Path} target="_blank">{t("Download Invoice")}</MDBBtn>
                                                            <MDBBtn size="sm" outline color="primary" onClick={() => sendDocumentEmail(order.id, "invoice")}>{t("Send Invoice")}</MDBBtn>
                                                        </>
                                                        
                                                        :
                                                        createInvoiceLoadingOrders.includes(order.id) ?
                                                        <MDBBtn size="sm" outline color="warning" disabled>{t("Creating Invoice")}...</MDBBtn>
                                                        :
                                                        <MDBBtn size="sm" outline color="primary" onClick={() => createInvoice(order.id)}>{t("Create Invoice")}</MDBBtn>
                                                    }
                                                    
                                                    </MDBBtnGroup> */}
                                                    
                                                    
                                                    <MDBCardText className="mt-2"><span className="fw-bold">{t("Payments")}</span></MDBCardText>
                                                    <MDBBtn size="sm" outline color="primary" onClick={() => openPaymentModal(order.id)}>{t("Add Payment")}</MDBBtn>
                                                    <MDBCardText>
                                                        {order.financial.payments.items.map(payment => (
                                                            <span key={payment.id}>{transformMoney(payment.amount)} {payment.currency} {payment.paymentProvider} {payment.transactionId}
                                                            <MDBIcon icon="times" className="float-end" onClick={() => handleRemovePayment(order.id, payment.id)} />
                                                            <br></br>
                                                            </span>
                                                        ))}
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="mt-3">
                                        <MDBCol>
                                           <MDBCard>
                                                  <MDBCardBody>
                                                    <MDBCardTitle>
                                                        {t("Positions")}
                                                        <MDBIcon icon="plus" className="float-end" onClick={() => openAddProductModal(order.id)} />
                                                    </MDBCardTitle>
                                                    {order.event?.name && <span>{t("Event")}: {getLocalizedText(order.event.name)}: {order.event.startDate && isoToGermanDate(order.event.startDate)} - {order.event.endDate && isoToGermanDate(order.event.endDate)}</span>}
                                                    {order.eventBooth?.name || ""}
                                                    <MDBTable>
                                                         <MDBTableHead>
                                                              <tr>
                                                                <th width="50"></th>
                                                                <th width="150">{t("SKU")}</th>
                                                                <th>{t("Name")}</th>
                                                                <th>{t("Quantity")}</th>
                                                                <th>{t("Tax rate")}</th>
                                                                <th>{t("Price")}</th>
                                                                
                                                              </tr>
                                                         </MDBTableHead>
                                                         <MDBTableBody>
                                                              {order.lineItems.items.map(item => (
                                                                <>
                                                                 <tr key={item.id} className={item.isCanceled && "text-decoration-line-through text-danger"}>
                                                                    <td>
                                                                        <MDBCheckbox 
                                                                        checked={selectedOrderLineItems.includes(item.id)}
                                                                        onChange={() => setSelectedOrderLineItems(selectedOrderLineItems.includes(item.id) ? selectedOrderLineItems.filter(id => id !== item.id) : [...selectedOrderLineItems, item.id])}
                                                                        />
                                                                    </td>
                                                                     <td>
                                                                        {item.product.sku}
                                                                        {/* <MDBIcon icon="times" className="float-end" onClick={() => {if (window.confirm(t("Are you sure you want to delete this item?"))) {
                                                                            handleDeleteLineItem(order.id, item.id)
                                                                        }}} /> */}
                                                                        </td>
                                                                     <td>
                                                                        {item.product.name.find(n => n.language === i18n.language)?.text || item.product.name?.[0]?.text}
                                                                       
                                                                        <br />
                                                                        <span className="text-muted mt-auto" style={{fontSize: "8px"}}>{item.id}</span>
                                                                        {item.product.includes && item.product.includes.length > 0 && 
                                                                        <>
                                                                        <br></br>
                                                                        <MDBPopover color='tertiary' rippleColor='light' btnChildren={t("Includes")} placement='bottom'>
                                                                        <MDBPopoverHeader>{t("Includes")}</MDBPopoverHeader>
                                                                        <MDBPopoverBody style={{minWidth: "500px", backgroundColor: "white"}}>
                                                                        {item.product.includes.map(inc => (
                                                                        <tr key={inc.id} style={{borderBottom: "#e0e0e0"}}>
                                                                            <td style={{borderBottom: "white"}}></td>
                                                                            <td colSpan="4" style={{borderBottom: "white", padding: "0 0 0 1.4rem"}}><ul><li>{inc.text}</li></ul></td>
                                                                        
                                                                        </tr>))}
                                                                
                                                                        </MDBPopoverBody>
                                                                        </MDBPopover>
                                                                        
                                                                        </>
                                                                        
                                                                        }

                                                                        </td>
                                                                    <td>{item.qty}</td>
                                                                    <td>
                                                                        
                                                                        {lineItemsOpenEdits[order.id.toString() + item.id.toString()]?.includes("taxRate") ?
                                                                            <MDBRow>
                                                                                <MDBCol size="8" className="px-0" style={{width: "60px"}}>
                                                                                <MDBInput size="sm" value={item.taxRate} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, lineItems: {...o.lineItems, items: o.lineItems.items.map(i => i.id === item.id ? {...i, taxRate: e.target.value} : i)}} : o))}>
                                                                                <i className="fas fa-percent trailing"></i>
                                                                                </MDBInput>
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="check" className="float-end mt-1 text-success cursor-pointer" onClick={() => {
                                                                                    const itemId = order.id.toString() + item.id.toString()
                                                                                    setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: (old[itemId] || []).filter(e => e !== "taxRate")}))
                                                                                    handleEditLineItem(order.id, item.id, "taxRate", item.taxRate)
                                                                                }} />
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="times" className="float-end mt-1 text-danger cursor-pointer" onClick={() => {
                                                                                    const itemId = order.id.toString() + item.id.toString()
                                                                                    setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: (old[itemId] || []).filter(e => e !== "taxRate")}))
                                                                                }} />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                            :
                                                                            <>
                                                                            {item.taxRate}%
                                                                            <MDBIcon icon="edit" className="float-end" onClick={() => {
                                                                                const itemId = order.id.toString() + item.id.toString()
                                                                                setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: [...(old[itemId] || []), "taxRate"]}))
                                                                            }} />
                                                                            </>
                                                                        }
                                                                        
                                                                        </td>
                                                                     <td>
                                                                        {lineItemsOpenEdits[order.id.toString() + item.id.toString()]?.includes("price") ?
                                                                            <MDBRow>
                                                                                <MDBCol size="8" className="px-0" style={{width: "100px"}}>
                                                                                <MDBInput size="sm" value={item.price} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, lineItems: {...o.lineItems, items: o.lineItems.items.map(i => i.id === item.id ? {...i, price: e.target.value} : i)}} : o))}>
                                                                                <i className="fas fa-euro-sign trailing"></i>
                                                                                </MDBInput>
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="check" className="float-end mt-1 text-success cursor-pointer" onClick={() => {
                                                                                    const itemId = order.id.toString() + item.id.toString()
                                                                                    setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: (old[itemId] || []).filter(e => e !== "price")}))
                                                                                    handleEditLineItem(order.id, item.id, "price", item.price)
                                                                                }} />
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="times" className="float-end mt-1 text-danger cursor-pointer" onClick={() => {
                                                                                    const itemId = order.id.toString() + item.id.toString()
                                                                                    setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: (old[itemId] || []).filter(e => e !== "price")}))
                                                                                }} />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                            :
                                                                            <>
                                                                            {transformMoney(item.price)}
                                                                            <MDBIcon icon="edit" className="float-end" onClick={() => {
                                                                                const itemId = order.id.toString() + item.id.toString()
                                                                                setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: [...(old[itemId] || []), "price"]}))
                                                                            }} />
                                                                            </>
                                                                        }
                                                                        <br></br>
                                                                        <span style={{fontSize: "12px"}}>{t("Downpayment")}</span>
                                                                        <br></br>
                                                                        {lineItemsOpenEdits[order.id.toString() + item.id.toString()]?.includes("downPaymentPrice") ?
                                                                            <MDBRow>
                                                                                <MDBCol size="8" className="px-0" style={{width: "100px"}}>
                                                                                <MDBInput size="sm" value={item.downPaymentPrice  || 0} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, lineItems: {...o.lineItems, items: o.lineItems.items.map(i => i.id === item.id ? {...i, downPaymentPrice: e.target.value} : i)}} : o))}>
                                                                                <i className="fas fa-euro-sign trailing"></i>
                                                                                </MDBInput>
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="check" className="float-end mt-1 text-success cursor-pointer" onClick={() => {
                                                                                    const itemId = order.id.toString() + item.id.toString()
                                                                                    setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: (old[itemId] || []).filter(e => e !== "downPaymentPrice")}))
                                                                                    handleEditLineItem(order.id, item.id, "downPaymentPrice", item.downPaymentPrice)
                                                                                }} />
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="times" className="float-end mt-1 text-danger cursor-pointer" onClick={() => {
                                                                                    const itemId = order.id.toString() + item.id.toString()
                                                                                    setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: (old[itemId] || []).filter(e => e !== "downPaymentPrice")}))
                                                                                }} />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                            :
                                                                            <span style={{fontSize: "12px"}}>
                                                                            {transformMoney(item.downPaymentPrice ||  0)}
                                                                            <MDBIcon icon="edit" className="float-end" onClick={() => {
                                                                                const itemId = order.id.toString() + item.id.toString()
                                                                                setLineItemsOpenEdits(old => ({...lineItemsOpenEdits, [itemId]: [...(old[itemId] || []), "downPaymentPrice"]}))
                                                                            }} />
                                                                            </span>
                                                                        }
                                                                     </td>
                                                                     
                                                                </tr>
                                                                </>
                                                               
                                                              ))}
                                                         </MDBTableBody>
                                                    </MDBTable>
                                                    <div>
                                                        <MDBBtn size="sm" color='tertiary' rippleColor='light' className="text-danger" onClick={() => {
                                                            setShowCancelLineItemsModal(true);
                                                            setSelectedOrderForCancelLineItems(order.id)
                                                        }}>{t("stornieren")}</MDBBtn>
                                                    </div>
                                                    {order.discounts?.length > 0 &&
                                                    <>
                                                    <MDBCardText><span className="fw-bold">{t("Discounts")}</span></MDBCardText>
                                                    <MDBTable>
                                                            <MDBTableHead>
                                                                <tr>
                                                                    <th>{t("Name")}</th>
                                                                    <th>{t("Amount")}</th>
                                                                    <th>{t("Actions")}</th>
                                                                </tr>
                                                            </MDBTableHead>
                                                            <MDBTableBody>
                                                                {order.discounts.map(discount => (
                                                                    <tr key={discount.id}>
                                                                        <td>{getLocalizedText(discount.name)}</td>
                                                                        <td>{transformMoney(
                                                                            discount.discountType === "percentage" ?
                                                                            order.lineItems.items.reduce((acc, item) => acc + item.price * item.qty, 0) * discount.discountPercentage / 100
                                                                            :
                                                                            discount.discountAmount
                                                                        )}</td>
                                                                        <td>
                                                                            <MDBIcon 
                                                                                icon="times" 
                                                                                className="cursor-pointer text-danger" 
                                                                                onClick={() => {
                                                                                    if (window.confirm(t("Are you sure you want to remove this discount?"))) {
                                                                                        handleRemoveDiscount(order.id, discount.id);
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </MDBTableBody>
                                                    </MDBTable>
                                                    </>
                                                    }
                                                  </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                       
                                    </MDBRow>
                                    <MDBRow className="mt-3">
                                        <MDBCol lg="6">
                                           <MDBCard>
                                                  <MDBCardBody>
                                                    <MDBCardTitle>
                                                        {t("Total")}
                                                    </MDBCardTitle>
                                                    <MDBTable>   
                                                            <MDBTableBody>
                                                                <tr>
                                                                    <td>{t("Net")}</td>
                                                                    <td>{transformMoney(order.financial.invoiceAmountNet)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("Tax rate")}</td>
                                                                    <td>
                                                                        {orderFinancialOpenEdits[order.id.toString()]?.includes("taxRate") ?
                                                                            <MDBRow>
                                                                                <MDBCol size="8" className="px-0" style={{width: "60px"}}>
                                                                                <MDBInput size="sm" value={order.financial.taxRate} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, financial: {...o.financial, taxRate: e.target.value}} : o))}>
                                                                                <i className="fas fa-percent trailing"></i>
                                                                                </MDBInput>
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="check" className="float-end mt-1 text-success cursor-pointer" onClick={() => {
                                                                                    setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "taxRate")}))
                                                                                    handleEditOrderFinancial(order.id, "taxRate", order.financial.taxRate)
                                                                                }} />
                                                                                </MDBCol>
                                                                                <MDBCol size="2" className="px-0">
                                                                                <MDBIcon icon="times" className="float-end mt-1 text-danger cursor-pointer" onClick={() => {
                                                                                    setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "taxRate")}))
                                                                                }} />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                            :
                                                                            <>
                                                                            {order.financial.taxRate}%
                                                                            <MDBIcon icon="edit" className="float-end" onClick={() => {
                                                                                setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: [...(old[order.id.toString()] || []), "taxRate"]}))
                                                                            }} />
                                                                            </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("Tax")}</td>
                                                                    <td>{transformMoney(order.financial.invoiceAmount - order.financial.invoiceAmountNet)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{t("Total")}</td>
                                                                    <td>{transformMoney(order.financial.invoiceAmount)}</td>
                                                                </tr>

                                                               
                                                            </MDBTableBody>
                                                    </MDBTable>
                                                    


                                                  </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                       <MDBCol lg="6">
                                             <MDBCard>
                                                <MDBCardBody>   
                                                    <MDBCardTitle>
                                                        {t("Service Period")}
                                                    </MDBCardTitle>
                                                    <MDBTable style={{maxWidth: "500px"}}>
                                                        <MDBTableBody>
                                                            <tr>
                                                                <td>{t("Start")}</td>
                                                                <td>
                                                                {orderFinancialOpenEdits[order.id.toString()]?.includes("servicePeriodStart") ?
                                                                    <MDBRow>
                                                                        <MDBCol size="8" className="px-0" style={{width: "100px"}}>
                                                                            <MDBDatepicker
                                                                            size="sm" 
                                                                            value={order.financial.servicePeriodStart ? new Date(order.financial.servicePeriodStart).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ""}
                                                                            onChange={e => setOrderFinancialOpenEditsValues(old => ({...orderFinancialOpenEditsValues, [order.id.toString()]: {...orderFinancialOpenEditsValues[order.id.toString()], servicePeriodStart: e}}))}
                                                                            format="dd.mm.yyyy"
                                                                            />
                                                                        {/* <MDBInput size="sm" value={order.financial.servicePeriodStart} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, financial: {...o.financial, servicePeriodStart: e.target.value}} : o))} type="date" /> */}
                                                                        </MDBCol>
                                                                        <MDBCol size="2" className="px-0">
                                                                        <MDBIcon icon="check" className="float-end mt-1 text-success cursor-pointer" onClick={() => {
                                                                            
                                                                            // Check if date can be parsed and then update
                                                                            if (!orderFinancialOpenEditsValues[order.id.toString()].servicePeriodStart)  {
                                                                                handleEditOrderFinancial(order.id, "servicePeriodStart", null)
                                                                                setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "servicePeriodStart")}))

                                                                            } else if(Date.parse(convertDate(orderFinancialOpenEditsValues[order.id.toString()].servicePeriodStart))) {
                                                                                console.log("Date to save", convertDate(orderFinancialOpenEditsValues[order.id.toString()].servicePeriodStart))
                                                                                handleEditOrderFinancial(order.id, "servicePeriodStart", convertDate(orderFinancialOpenEditsValues[order.id.toString()].servicePeriodStart))
                                                                                setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "servicePeriodStart")}))
                                                                            } else {
                                                                                alert("Please enter a valid date.")
                                                                            }
                                                                        }} />
                                                                        </MDBCol>
                                                                        <MDBCol size="2" className="px-0">
                                                                        <MDBIcon icon="times" className="float-end mt-1 text-danger cursor-pointer" onClick={() => {
                                                                            setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "servicePeriodStart")}))
                                                                        }
                                                                        } />
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    :
                                                                    <>
                                                                    {order.financial.servicePeriodStart ? new Date(order.financial.servicePeriodStart).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ""}
                                                                    <MDBIcon icon="edit" className="float-end" onClick={() => {
                                                                        setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: [...(old[order.id.toString()] || []), "servicePeriodStart"]}))
                                                                    }
                                                                    } />
                                                                    </>
                                                                }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{t("End")}</td>
                                                                <td>
                                                                {orderFinancialOpenEdits[order.id.toString()]?.includes("servicePeriodEnd") ?
                                                                    <MDBRow>
                                                                        <MDBCol size="8" className="px-0" style={{width: "100px"}}>
                                                                            <MDBDatepicker
                                                                            size="sm"
                                                                            value={order.financial.servicePeriodEnd ? new Date(order.financial.servicePeriodEnd).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ""}
                                                                            onChange={e => setOrderFinancialOpenEditsValues(old => ({...orderFinancialOpenEditsValues, [order.id.toString()]: {...orderFinancialOpenEditsValues[order.id.toString()], servicePeriodEnd: e}}))}
                                                                            format="dd.mm.yyyy"
                                                                            />
                                                                        {/* <MDBInput size="sm" value={order.financial.servicePeriodEnd} onChange={e => setOrders(orders.map(o => o.id === order.id ? {...o, financial: {...o.financial, servicePeriodEnd: e.target.value}} : o))} type="date" /> */}
                                                                        </MDBCol>
                                                                        <MDBCol size="2" className="px-0">
                                                                        <MDBIcon icon="check" className="float-end mt-1 text-success cursor-pointer" onClick={() => {

                                                                            // Check if date can be parsed and then update
                                                                            if (!orderFinancialOpenEditsValues[order.id.toString()].servicePeriodEnd)  {
                                                                                handleEditOrderFinancial(order.id, "servicePeriodEnd", null)
                                                                                setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "servicePeriodEnd")}))

                                                                            } else if(Date.parse(convertDate(orderFinancialOpenEditsValues[order.id.toString()].servicePeriodEnd))) {
                                                                                handleEditOrderFinancial(order.id, "servicePeriodEnd", convertDate(orderFinancialOpenEditsValues[order.id.toString()].servicePeriodEnd))
                                                                                setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "servicePeriodEnd")}))
                                                                            } else {
                                                                                alert("Please enter a valid date.")
                                                                            }
                                                                        }
                                                                        } />
                                                                        </MDBCol>
                                                                        <MDBCol size="2" className="px-0">
                                                                        <MDBIcon icon="times" className="float-end mt-1 text-danger cursor-pointer" onClick={() => {
                                                                            setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: (old[order.id.toString()] || []).filter(e => e !== "servicePeriodEnd")}))
                                                                        }
                                                                        } />
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    :
                                                                    <>
                                                                    {order.financial.servicePeriodEnd ? new Date(order.financial.servicePeriodEnd).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ""}
                                                                    <MDBIcon icon="edit" className="float-end" onClick={() => {
                                                                        setOrderFinancialOpenEdits(old => ({...orderFinancialOpenEdits, [order.id.toString()]: [...(old[order.id.toString()] || []), "servicePeriodEnd"]}))
                                                                    }
                                                                    } />
                                                                    </>
                                                                }
                                                                </td>
                                                            </tr>
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                    <MDBCardTitle>
                                                        {t("Notes")}
                                                    </MDBCardTitle>
                                                    {showEditNotesOrderIds.includes(order.id) ?
                                                        <>
                                                        <MDBTextArea rows="4" value={newNotes} onChange={e => setNewNotes(e.target.value)} />
                                                        <MDBBtn size="sm" color="primary" onClick={() => {handleUpdateNotes(order.id, newNotes); setShowEditNotesOrderIds(showEditNotesOrderIds.filter(id => id !== order.id))}}>{t("Save")}</MDBBtn>
                                                        <MDBIcon icon="times" className="float-end" onClick={() => setShowEditNotesOrderIds(showEditNotesOrderIds.filter(id => id !== order.id))} />
                                                        </>
                                                        :
                                                        order.notes
                                                    }
                                                    {!showEditNotesOrderIds.includes(order.id) && <MDBIcon className="float-end" icon="edit" onClick={() => {
                                                        setNewNotes(order.notes)
                                                        setShowEditNotesOrderIds([...showEditNotesOrderIds, order.id])
                                                    }} />}

                                                </MDBCardBody>
                                            </MDBCard>
                                       </MDBCol>
                                    </MDBRow>
                                    </MDBCollapse>      
                                </td>
                                
                            </tr>
                            
                            ])})}
                    </MDBTableBody>
                </MDBTable>
                {orders.length > 0 && 
                <>
                <nav aria-label='Page navigation' className="d-flex">
                        <MDBPagination className='mx-auto' style={{width: "fit-content"}}>
                            <MDBPaginationItem>
                            {page !== 1 && <MDBPaginationLink  className="text-dark" onClick={() => setPage(page - 1)}>{t("Previous")}</MDBPaginationLink>}
                            </MDBPaginationItem>{
                                totalPages > 0 && Array.from(Array(totalPages).keys()).slice(0,5).map((pageIndex) => {
                                    return (
                                        <MDBPaginationItem key={pageIndex} active={pageIndex + 1 === page}>
                                            <MDBPaginationLink href='#' className="text-dark" onClick={() => setPage(pageIndex+1)}>{pageIndex + 1}</MDBPaginationLink>
                                        </MDBPaginationItem>
                                    )
                                })
                            }
                            {
                                totalPages > 5 &&
                                <MDBDropdown dropup>
                                    <MDBDropdownToggle caret color="white" className="text-dark">
                                        {t("More")}
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu basic>
                                        {
                                            Array.from(Array(totalPages).keys()).slice(5).map((pageIndex) => {
                                                return (
                                                    <MDBDropdownItem link key={pageIndex} active={pageIndex + 1 === page} className="text-dark"  onClick={() => setPage(pageIndex+1)}>
                                                        {pageIndex + 1}
                                                    </MDBDropdownItem>
                                                )
                                            })
                                        }
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            }



                            <MDBPaginationItem>
                                {page !== totalPages && <MDBPaginationLink className="text-dark" onClick={() => setPage(page + 1)}>{t("Next")}</MDBPaginationLink>}
                            </MDBPaginationItem>
                        </MDBPagination>
                        <MDBDropdown className="float-end">
                        <MDBDropdownToggle caret color="white" className="text-dark">
                            {rowsPerPage}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu basic>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(10)} className="text-dark">
                                10
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(20)} className="text-dark">
                                20
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(50)} className="text-dark">
                                50
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(100)} className="text-dark">
                                100
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(200)} className="text-dark">
                                200
                            </MDBDropdownItem>
                            <MDBDropdownItem link onClick={() => setRowsPerPage(500)} className="text-dark">
                                500
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                    </nav>
                    </>
                }
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
<MDBModal open={showEditAddressModal} backdrop setOpen={setShowEditAddressModal}>
    <MDBModalDialog>
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Edit Address")}</MDBModalTitle>
                <MDBBtn color="secondary" onClick={() => setShowEditAddressModal(false)}><MDBIcon icon="times" /></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{t("Billing Address")}</MDBCardTitle>
                        <MDBRow>
                            <MDBCol size="6">
                                <MDBInput size="sm" label={t("First Name")} value={newAddresses.billingAddress?.firstName} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, firstName: e.target.value}})} />
                            </MDBCol>
                            <MDBCol size="6">
                                <MDBInput size="sm" label={t("Last Name")} value={newAddresses.billingAddress?.lastName} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, lastName: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                                <MDBInput size="sm" label={t("Company")} value={newAddresses.billingAddress?.company} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, company: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol size="8">
                                <MDBInput size="sm" label={t("Street")} value={newAddresses.billingAddress?.street} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, street: e.target.value}})} />
                            </MDBCol>
                            <MDBCol size="4">
                                <MDBInput size="sm" label={t("Number")} value={newAddresses.billingAddress?.streetNumber} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, streetNumber: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                                <MDBInput size="sm" label={t("Street 2")} value={newAddresses.billingAddress?.street2} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, street2: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                                <MDBInput size="sm" label={t("Zip")} value={newAddresses.billingAddress?.zip} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, zip: e.target.value}})} />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput size="sm" label={t("City")} value={newAddresses.billingAddress?.city} onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, city: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                            <MDBSelect
                            search
                            data={[
                                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                                ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                                const order = ['DE', 'AT', 'CH'];
                                    const indexA = order.indexOf(a.value);
                                const indexB = order.indexOf(b.value);
                                if (indexA > -1 && indexB > -1) {
                                    return indexA - indexB;
                                } else if (indexA > -1) {
                                    return -1;
                                } else if (indexB > -1) {
                                    return 1;
                                }
                                return a.value.localeCompare(b.value);
                                }) || [{}] || [{}]
                            ]}
                            value={newAddresses.billingAddress?.countryCode|| ""}
                            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")}
                            onChange={e => setNewAddresses({...newAddresses, billingAddress: {...newAddresses.billingAddress, countryCode: e?.value}})} />
                                
                            </MDBCol>
                        </MDBRow>
                        </MDBCardBody>
                </MDBCard>
                <MDBCard className="mt-3">
                    <MDBCardBody>
                        <MDBCardTitle>{t("Delivery Address")}</MDBCardTitle>
                        <MDBRow>
                            <MDBCol size="6">
                                <MDBInput size="sm" label={t("First Name")} value={newAddresses.deliveryAddress?.firstName} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, firstName: e.target.value}})} />
                            </MDBCol>
                            <MDBCol size="6">
                                <MDBInput size="sm" label={t("Last Name")} value={newAddresses.deliveryAddress?.lastName} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, lastName: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                                <MDBInput size="sm" label={t("Company")} value={newAddresses.deliveryAddress?.company} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, company: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol size="8">
                                <MDBInput size="sm" label={t("Street")} value={newAddresses.deliveryAddress?.street} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, street: e.target.value}})} />
                            </MDBCol>
                            <MDBCol size="4">
                                <MDBInput size="sm" label={t("Number")} value={newAddresses.deliveryAddress?.streetNumber} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, streetNumber: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                                <MDBInput size="sm" label={t("Street 2")} value={newAddresses.deliveryAddress?.street2} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, street2: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                                <MDBInput size="sm" label={t("Zip")} value={newAddresses.deliveryAddress?.zip} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, zip: e.target.value}})} />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput size="sm" label={t("City")} value={newAddresses.deliveryAddress?.city} onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, city: e.target.value}})} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-2">
                            <MDBCol>
                            <MDBSelect
                            search
                            data={[
                                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                                ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                                const order = ['DE', 'AT', 'CH'];
                                    const indexA = order.indexOf(a.value);
                                const indexB = order.indexOf(b.value);
                                if (indexA > -1 && indexB > -1) {
                                    return indexA - indexB;
                                } else if (indexA > -1) {
                                    return -1;
                                } else if (indexB > -1) {
                                    return 1;
                                }
                                return a.value.localeCompare(b.value);
                                }) || [{}] || [{}]
                            ]}
                            value={newAddresses.deliveryAddress?.countryCode|| ""}
                            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")}
                            onChange={e => setNewAddresses({...newAddresses, deliveryAddress: {...newAddresses.deliveryAddress, countryCode: e?.value}})} />
                               
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>

                <MDBBtn color="primary" onClick={handleSaveAddress}>{t("Save")}</MDBBtn>


            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={showAddProductModal} backdrop setOpen={setShowAddProductModal}>
    <MDBModalDialog size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Add Product")}</MDBModalTitle>
                <MDBBtn color="secondary" onClick={() => setShowAddProductModal(false)}><MDBIcon icon="times" /></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{t("Products")}</MDBCardTitle>
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th>{t("SKU")}</th>
                                    <th>{t("Name")}</th>
                                    <th>{t("Price")}</th>
                                    <th>{t("Add")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {products.map(product => (
                                    <tr key={product.id}>
                                        <td>{product.sku}</td>
                                        <td>{product.name.find(n => n.language === i18n.language)?.text || product.name?.[0]?.text}</td>
                                        <td>{product.price}€</td>
                                        <td><MDBBtn color="primary" onClick={() => handleAddProductToOrder(selectedOrderForAddProduct.id, product)}>{t("Add")}</MDBBtn></td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={showPaymentModal} backdrop setOpen={setShowPaymentModal}>
    <MDBModalDialog>
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Add Payment")}</MDBModalTitle>
                <MDBBtn color="secondary" onClick={() => setShowPaymentModal(false)}><MDBIcon icon="times" /></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>{t("Add Payment")}</MDBCardTitle>
                        <MDBInput type="number" step="0.01" label={t("Amount")} value={paymentAmount} onChange={e => setPaymentAmount(e.target.value)} />
                        <MDBSelect
                        className="mt-3"
                        search
                        label={t("Currency")}
                        value={paymentCurrency} 
                        data={["EUR", "USD", "GBP", "CHF", "SEK", "NOK", "DKK", "PLN", "CZK", "HUF", "HRK", "RUB", "TRY", "AED", "SAR", "QAR", "KWD", "BHD", "OMR", "JOD", "ILS", "EGP", "ZAR", "AUD", "NZD", "CAD", "SGD", "MYR", "IDR", "PHP", "THB", "VND", "CNY", "HKD", "TWD", "KRW", "JPY"].map(c => ({text: c, value: c}))}
                        onChange={value => setPaymentCurrency(value.value)} />
                        <MDBSelect
                        className="mt-3"
                        label={t("Provider")}
                        value={paymentProvider}
                        data={[
                            {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                            {text: t("Bank Transfer"), value: "bank_transfer" },
                            {text: t("Credit Card"), value: "credit_card" },
                            {text: t("PayPal"), value: "paypal" },
                            {text: t("Stripe"), value: "stripe" }
                        ]}
                        onChange={e => setPaymentProvider(e.value)} />
                       
                        
                        <MDBInput className="mt-3" label={t("Transaction ID")} value={transactionId} onChange={e => setTransactionId(e.target.value)} />



                        <MDBBtn className="mt-4" color="primary" onClick={() => handleAddPayment(selectedOrderForPayment.id, selectedOrderForPayment.orderFinancialId, paymentAmount, paymentCurrency, paymentProvider, transactionId)}>{t("Save")}</MDBBtn>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={showCancelDocumentModal} backdrop setOpen={setShowCancelDocumentModal}>
    <MDBModalDialog>
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Cancel Document")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowCancelDocumentModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <MDBCardTitle className="mb-2">{t("Do you really want to cancel this document?")}</MDBCardTitle>
            <p>{selectedOrderAndDocumentForCancel?.documentNumber}</p>
            {selectedOrderAndDocumentForCancel?.documentType === "invoice" && <p>{t("This will also create a credit document.")}</p>}
            {/* {!selectedOrderAndDocumentForCancel?.sentToCustomerTime &&
            <>
            <span>
                {t("The document has not yet been sent to the customer and can be deleted:")}
            </span>
            <MDBBtn color="danger" size="sm" outline onClick={() => handleDeleteDocument(selectedOrderAndDocumentForCancel?.orderId, selectedOrderAndDocumentForCancel?.orderFinancialId, selectedOrderAndDocumentForCancel?.documentType, selectedOrderAndDocumentForCancel?.documentNumber)}>{t("Delete Document")}</MDBBtn>
            </>
             } */}
             <MDBBtn color="danger" size="sm" outline onClick={() => handleDeleteDocument(selectedOrderAndDocumentForCancel?.orderId, selectedOrderAndDocumentForCancel?.orderFinancialId, selectedOrderAndDocumentForCancel?.documentType, selectedOrderAndDocumentForCancel?.documentNumber)}>{t("Delete Document")}</MDBBtn>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => setShowCancelDocumentModal(false)}>{t("Close")}</MDBBtn>
                <MDBBtn color="primary" onClick={() => handleCancelDocument(selectedOrderAndDocumentForCancel?.orderId, selectedOrderAndDocumentForCancel?.orderFinancialId, selectedOrderAndDocumentForCancel?.documentType, selectedOrderAndDocumentForCancel?.documentNumber)}>{t("Cancel Document")}</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={createRemainingInvoiceModalOpen} backdrop setOpen={setCreateRemainingInvoiceModalOpen}>
    <MDBModalDialog>
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Create Remaining Invoice")}</MDBModalTitle>
                <MDBBtn color="secondary" onClick={() => setCreateRemainingInvoiceModalOpen(false)}><MDBIcon icon="times" /></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <span>
                    {t("Create a remaining invoice for document: ")}
                </span>
                <span className="text-bold">{remainingInvoiceReferingDocumentNumber}</span>

                {/* Select Due Date and create document */}
                <MDBInput
                type="date"
                label={t('Due date')}
                value={remainingInvoiceDueDate}
                onChange={e => setRemainingInvoiceDueDate(e.target.value)}
                />


            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={() => createRemainingInvoice()}>
                    {
                        isLoadingCreateRemainingInvoice ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div> : t("Create")
                    }
                    </MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>

</MDBModal>
<MDBModal open={showCancelLineItemsModal} backdrop onClose={() => setShowCancelLineItemsModal(false)}>
    <MDBModalDialog size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Cancel Line Items")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowCancelLineItemsModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
           <MDBTable>
                <MDBTableHead>
                     <tr>
                        <th width="150">{t("SKU")}</th>
                        <th>{t("Name")}</th>
                        <th>{t("Quantity")}</th>
                     </tr>
                </MDBTableHead>
                <MDBTableBody>
                     {orders.find(o => o.id === selectedOrderForCancelLineItems)?.lineItems?.items.filter(i => selectedOrderLineItems.includes(i.id)).map(item => (
                          <tr key={item.id}>
                            <td>{item.product.sku}</td>
                            <td>{getLocalizedText(item.product.name)}</td>
                            <td>{item.qty}</td>
                          </tr>
                     ))}
                </MDBTableBody>
           </MDBTable>
            </MDBModalBody>
            <MDBModalFooter>
                {isLoadingCancelLineItems ? <div className="spinner-border spinner-border-sm" role="status"> <span className="visually-hidden">Loading...</span> </div> :
                <>
                <MDBCheckbox
                label={t("Return stock")}
                checked={cancelLineItemsOptions.returnStock}
                onChange={e => setCancelLineItemsOptions({...cancelLineItemsOptions, returnStock: !cancelLineItemsOptions.returnStock})}
                />
                {selectedOrderForCancelLineItems && orders.find(o => o.id === selectedOrderForCancelLineItems)?.eventBooth &&
                <MDBCheckbox
                label={t("Remove booth equipment")}
                checked={cancelLineItemsOptions.removeFromBooth}
                onChange={e => setCancelLineItemsOptions({...cancelLineItemsOptions, removeFromBooth: !cancelLineItemsOptions.removeFromBooth})}
                />
                }
                <MDBCheckbox
                label={t("Create cancelation document")}
                checked={cancelLineItemsOptions.createCancelationDocument}
                onChange={e => setCancelLineItemsOptions({...cancelLineItemsOptions, createCancelationDocument: !cancelLineItemsOptions.createCancelationDocument})}
                />
                <MDBBtn color="danger" onClick={() => handleCancelLineItems()}>{t("Cancel items")}</MDBBtn>
                </>
                }
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
