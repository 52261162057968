import React, { useState, useEffect } from 'react';
import { 
  MDBRow, 
  MDBCol, 
  MDBInput, 
  MDBInputGroup,
  MDBSpinner,
  MDBSelect,
  MDBBtn,
  MDBTypography,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBSwitch,
  MDBTable,
  MDBTableBody,
  MDBAlert
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { generateClient } from 'aws-amplify/api';
import useDebounce from '../../utils/debounce';

// Deine Hook/Service, der die Lecture-Logik abwickelt
import { useLecture } from './useLecture';

// GraphQL-Client erzeugen
const client = generateClient();

// Beispiel-Funktion zum Laden aller Employees einer Firma
const getEmployees = async (companyId) => {
  try {
    const employees = await client.graphql({
      variables: {
        filter: { companyEmployeesId: { eq: companyId } },
        limit: 5000,
      },
      query: /* GraphQL */ `
        query ListEmployees(
          $filter: ModelEmployeeFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
            nextToken
            items {
              id
              user {
                id
                firstName
                middleName
                lastName
                title
                email
                image {
                  alt
                  fileName
                }
              }
              department {
                items {
                  department {
                    name {
                      language
                      text
                    }
                  }
                }
              }
              image {
                alt
                fileName
              }
            }
          }
        }
      `,
    });
    return employees.data.listEmployees.items || [];
  } catch (err) {
    console.log("error fetching employees", err);
    return [];
  }
};

const LectureForm = ({ 
  lectureId = null, 
  eventId = null, 
  onSave, 
  onCancel 
}) => {
  const { t } = useTranslation();
  const {
    lecture,
    setLecture,
    company,
    searchCompanies,
    isLoading,
    saveLecture
  } = useLecture(lectureId, eventId);

  // Company-Suche
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  // Speaker States
  // Hier speichern wir die ausgewählten Speaker, inkl. Info, ob Employee oder User
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [mainSpeaker, setMainSpeaker] = useState(null); // { id, type: 'employee' | 'user' }

  // Wenn eine Firma ausgewählt wurde, speichern wir deren Employees
  const [employees, setEmployees] = useState([]);

  // States für die Suche nach Speakern
  const [speakerSearchQuery, setSpeakerSearchQuery] = useState('');
  const [showSpeakerResults, setShowSpeakerResults] = useState(false);
  const [speakerSearchResults, setSpeakerSearchResults] = useState([]);
  const [isSearchingSpeakers, setIsSearchingSpeakers] = useState(false);

  // Debounced-Suche
  const debouncedCompanySearch = useDebounce(searchQuery, 300);
  const debouncedSpeakerSearch = useDebounce(speakerSearchQuery, 300);

  // ---------------------------------------------
  // 1) Firmen-Suche
  // ---------------------------------------------
  useEffect(() => {
    const performCompanySearch = async () => {
      if (debouncedCompanySearch.length >= 2) {
        setIsSearching(true);
        try {
          const results = await searchCompanies(debouncedCompanySearch);
          setSearchResults(results);
          setShowResults(true);
        } catch (error) {
          console.error('Error searching companies:', error);
        } finally {
          setIsSearching(false);
        }
      } else {
        setSearchResults([]);
        setShowResults(false);
      }
    };

    performCompanySearch();
  }, [debouncedCompanySearch, searchCompanies]);

  // ---------------------------------------------
  // 2) Suche nach Users (NUR wenn KEINE Firma ausgewählt ist)
  // ---------------------------------------------
  const searchUsers = async (query) => {
    try {
      const searchParams = {
        filter: {
          or: [
            { firstName: { regexp: `.*${query.toLowerCase()}.*` } },
            { lastName: { regexp: `.*${query.toLowerCase()}.*` } },
            { email: { regexp: `.*${query}.*` } },
          ],
        },
        limit: 10,
      };

      const response = await client.graphql({
        query: `
          query SearchUsers($filter: SearchableUserFilterInput, $limit: Int) {
            searchUsers(filter: $filter, limit: $limit) {
              items {
                id
                firstName
                lastName
                email
                image {
                  fileName
                }
                employeeProfiles {
                  items {
                    company {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        variables: searchParams,
      });
      return response.data.searchUsers.items;
    } catch (error) {
      console.error('Error searching users:', error);
      return [];
    }
  };

  // ---------------------------------------------
  // 3) Employees laden, wenn eine Firma ausgewählt wurde
  // ---------------------------------------------
  useEffect(() => {
    const fetchEmployees = async () => {
      if (selectedCompany?.id) {
        const emps = await getEmployees(selectedCompany.id);
        setEmployees(emps);
      } else {
        setEmployees([]);
      }
    };
    fetchEmployees();

    // Speaker zurücksetzen, wenn eine neue Firma gewählt wird
    setSelectedSpeakers([]);
    setMainSpeaker(null);

    setLecture(prev => ({
      ...prev,
      speakers: [],
      speakerUsers: [],
      lectureMainSpeakerId: null,
      lectureMainSpeakerUserId: null,
    }));
  }, [selectedCompany, setLecture]);

  // ---------------------------------------------
  // 4) Speaker suchen
  //    - Bei Firma: lokal in employees filtern
  //    - Ohne Firma: searchUsers()
  // ---------------------------------------------
  useEffect(() => {
    const searchSpeakers = async () => {
      if (debouncedSpeakerSearch.length < 2) {
        setShowSpeakerResults(false);
        setSpeakerSearchResults([]);
        return;
      }

      setIsSearchingSpeakers(true);
      try {
        if (selectedCompany) {
          // Lokal filtern
          const query = debouncedSpeakerSearch.toLowerCase();
          const filtered = employees.filter(emp => {
            const fullName = (
              (emp.user?.firstName?.toLowerCase() || '') + ' ' + (emp.user?.lastName?.toLowerCase() || '')
            );
            const email = emp.user?.email?.toLowerCase() || '';
            return fullName.includes(query) || email.includes(query);
          });
          setSpeakerSearchResults(filtered);
        } else {
          // Nutzer global suchen
          const results = await searchUsers(debouncedSpeakerSearch);
          setSpeakerSearchResults(results);
        }
        setShowSpeakerResults(true);
      } catch (error) {
        console.error('Error searching speakers:', error);
      } finally {
        setIsSearchingSpeakers(false);
      }
    };

    searchSpeakers();
  }, [debouncedSpeakerSearch, selectedCompany, employees]);

  // ---------------------------------------------
  // 5) Auswahl einer Firma
  // ---------------------------------------------
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    setSearchQuery(company.name);
    setShowResults(false);
    setLecture(prev => ({
      ...prev,
      companyLecturesId: company.id,
    }));
  };

  // ---------------------------------------------
  // 6) Speaker hinzufügen
  //    - Unterscheidung: Employee vs. User
  // ---------------------------------------------
  const handleSelectSpeaker = (speaker) => {
    const isEmployee = !!selectedCompany;

    // Normalisierte Form des ausgewählten Speakers
    const newSpeaker = isEmployee
      ? {
          id: speaker.id,  // Employee ID
          firstName: speaker.user?.firstName,
          lastName: speaker.user?.lastName,
          email: speaker.user?.email,
          image: speaker.user?.image || speaker.image,
          type: 'employee',
        }
      : {
          id: speaker.id,  // User ID
          firstName: speaker.firstName,
          lastName: speaker.lastName,
          email: speaker.email,
          image: speaker.image,
          type: 'user',
        };

    // Schon vorhanden?
    const alreadySelected = selectedSpeakers.find(
      (s) => s.id === newSpeaker.id && s.type === newSpeaker.type
    );
    if (!alreadySelected) {
      const updatedSpeakers = [...selectedSpeakers, newSpeaker];
      setSelectedSpeakers(updatedSpeakers);

      if (!mainSpeaker) {
        setMainSpeaker({ id: newSpeaker.id, type: newSpeaker.type });
      }
    }

    // Suche zurücksetzen
    setSpeakerSearchQuery('');
    setShowSpeakerResults(false);
  };

  // ---------------------------------------------
  // 7) Speaker entfernen
  // ---------------------------------------------
  const handleRemoveSpeaker = (speakerId, speakerType) => {
    const updatedSpeakers = selectedSpeakers.filter(
      s => !(s.id === speakerId && s.type === speakerType)
    );
    setSelectedSpeakers(updatedSpeakers);

    // War das der Haupt-Speaker?
    if (mainSpeaker?.id === speakerId && mainSpeaker?.type === speakerType) {
      if (updatedSpeakers.length > 0) {
        setMainSpeaker({
          id: updatedSpeakers[0].id,
          type: updatedSpeakers[0].type
        });
      } else {
        setMainSpeaker(null);
      }
    }
  };

  // ---------------------------------------------
  // 8) Haupt-Speaker setzen
  // ---------------------------------------------
  const handleToggleMainSpeaker = (speakerId, speakerType) => {
    setMainSpeaker({ id: speakerId, type: speakerType });
  };

  // ---------------------------------------------
  // 9) "Speakers" aktualisieren (z.B. per Button)
  // ---------------------------------------------
  const refreshSpeakers = async () => {
    if (speakerSearchQuery.length >= 2) {
      setIsSearchingSpeakers(true);
      try {
        if (selectedCompany) {
          const query = speakerSearchQuery.toLowerCase();
          const filtered = employees.filter(emp => {
            const fullName = (
              (emp.user?.firstName?.toLowerCase() || '') + ' ' + (emp.user?.lastName?.toLowerCase() || '')
            );
            const email = emp.user?.email?.toLowerCase() || '';
            return fullName.includes(query) || email.includes(query);
          });
          setSpeakerSearchResults(filtered);
        } else {
          const results = await searchUsers(speakerSearchQuery);
          setSpeakerSearchResults(results);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsSearchingSpeakers(false);
      }
    }
  };

  // ---------------------------------------------
  // 10) Lecture speichern
  // ---------------------------------------------
  const handleSubmit = async () => {
    try {
      // Speaker IDs sammeln
      const employeeIds = selectedSpeakers
        .filter(sp => sp.type === 'employee')
        .map(sp => sp.id);

      const userIds = selectedSpeakers
        .filter(sp => sp.type === 'user')
        .map(sp => sp.id);

      // Main Speaker richtig zuordnen
      let mainSpeakerEmployeeId = null;
      let mainSpeakerUserId = null;
      if (mainSpeaker?.type === 'employee') {
        mainSpeakerEmployeeId = mainSpeaker.id;
      } else if (mainSpeaker?.type === 'user') {
        mainSpeakerUserId = mainSpeaker.id;
      }

      // Lecture-Payload erstellen
      const lecturePayload = {
        ...lecture,
        speakers: employeeIds,                  // wenn employees
        speakerUsers: userIds,                  // wenn user
        lectureMainSpeakerId: mainSpeakerEmployeeId,
        lectureMainSpeakerUserId: mainSpeakerUserId,
        companyId: selectedCompany?.id || company?.id || null,
      };

      // Speichern
      const savedLecture = await saveLecture(lecturePayload);
      if (onSave) {
        onSave({
          ...lecturePayload,
          ...savedLecture,
          company: selectedCompany || company || null,
          speakers: selectedSpeakers,
        });
      }
    } catch (error) {
      console.error("Error saving lecture:", error);
    }
  };

  // ---------------------------------------------
  // RENDER
  // ---------------------------------------------
  return (
    <form onSubmit={e => e.preventDefault()}>
      <MDBRow>
        {/* ----------------------------------------------------------------- */}
        {/* Company Search (nur wenn lecture NICHT "organizatorical" und neu) */}
        {/* ----------------------------------------------------------------- */}
        {!lectureId && lecture?.type !== "organizatorical" && (
          <MDBCol size="12" className="mb-4">
            <div className="position-relative">
              <MDBInputGroup>
                <MDBInput
                  label={t("Search Company")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setShowResults(true)}
                />
                {isSearching && (
                  <div className="input-group-text">
                    <MDBSpinner size="sm" />
                  </div>
                )}
              </MDBInputGroup>
              {showResults && searchResults.length > 0 && (
                <MDBListGroup className="w-100 shadow-5">
                  {searchResults.map((companyItem) => (
                    <MDBListGroupItem 
                      key={companyItem.id}
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => handleCompanySelect(companyItem)}
                      style={{ cursor: 'pointer' }}
                    >
                      {companyItem.image?.main?.fileName ? (
                        <img 
                          src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${companyItem.image.main.fileName}`}
                          alt={companyItem.name}
                          className="me-3"
                          style={{ width: '30px', height: '30px', objectFit: 'contain' }}
                        />
                      ) : (
                        <MDBIcon fas icon="building" className="me-3" />
                      )}
                      <span>{companyItem.name}</span>
                    </MDBListGroupItem>
                  ))}
                </MDBListGroup>
              )}
            </div>
          </MDBCol>
        )}

        {/* --------------------------------------------- */}
        {/* Typ-Auswahl */}
        {/* --------------------------------------------- */}
        <MDBCol size="12" md="6" className="mb-4">
          <MDBSelect
            label={t("Type")}
            data={[
              { text: t("Select"), value: null },
              { text: t("Marketing"), value: "marketing" },
              { text: t("Technical"), value: "technical" },
              { text: t("Educational"), value: "educational" },
              { text: t("Recruiting"), value: "recruiting" },
              { text: t("Promotion Marketing"), value: "promotion_marketing" },
              { text: t("Promotion Technical"), value: "promotion_technical" },
              { text: t("Organizatorical"), value: "organizatorical" },
            ]}
            value={lecture?.type || ''}
            onChange={e => setLecture(prev => ({
              ...prev,
              type: e.value
            }))}
          />
        </MDBCol>

        {/* --------------------------------------------- */}
        {/* Länge-Auswahl */}
        {/* --------------------------------------------- */}
        <MDBCol size="12" md="6" className="mb-4">
          <MDBSelect
            label={t("Length")}
            data={[
              { text: `30 ${t("Minutes")}`, value: 1800 },
              { text: `45 ${t("Minutes")}`, value: 2700 },
              { text: `60 ${t("Minutes")}`, value: 3600 },
            ]}
            value={lecture?.lengthInSeconds || 1800}
            onChange={e => setLecture(prev => ({
              ...prev,
              lengthInSeconds: parseInt(e?.value)
            }))}
          />
        </MDBCol>

        {/* --------------------------------------------- */}
        {/* Titel (DE/EN) */}
        {/* --------------------------------------------- */}
        <MDBCol size="12" className="mb-4">
          <MDBTypography tag="h6">{t("Title")}</MDBTypography>
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg />
            </div>
            <MDBInput
              label={t("German Title")}
              value={lecture?.title?.find(t => t.language === "de")?.text || ""}
              onChange={e => {
                const newTitle = [...(lecture?.title || [])];
                const index = newTitle.findIndex(t => t.language === "de");
                if (index !== -1) {
                  newTitle[index] = { language: "de", text: e.target.value };
                } else {
                  newTitle.push({ language: "de", text: e.target.value });
                }
                setLecture(prev => ({ ...prev, title: newTitle }));
              }}
            />
          </MDBInputGroup>

          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg />
            </div>
            <MDBInput
              label={t("English Title")}
              value={lecture?.title?.find(t => t.language === "en")?.text || ""}
              onChange={e => {
                const newTitle = [...(lecture?.title || [])];
                const index = newTitle.findIndex(t => t.language === "en");
                if (index !== -1) {
                  newTitle[index] = { language: "en", text: e.target.value };
                } else {
                  newTitle.push({ language: "en", text: e.target.value });
                }
                setLecture(prev => ({ ...prev, title: newTitle }));
              }}
            />
          </MDBInputGroup>
        </MDBCol>

        {/* --------------------------------------------- */}
        {/* Speaker Section */}
        {/* --------------------------------------------- */}
        <MDBCol size="12" className="mb-4">
          <MDBCard>
            <MDBCardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <MDBTypography tag="h5">
                  {t("Speakers")} (
                  {selectedCompany 
                    ? t("Employees") 
                    : t("Users")
                  })
                </MDBTypography>
                <div>
                  <MDBBtn 
                    color="link" 
                    className="p-1 me-2"
                    onClick={refreshSpeakers}
                  >
                    <MDBIcon fas icon="sync" />
                  </MDBBtn>
                  <MDBBtn 
                    color="link" 
                    className="p-1"
                    onClick={() => window.open('/admin/create-user', '_blank')}
                  >
                    <MDBIcon fas icon="plus" /> {t("Add New User")}
                  </MDBBtn>
                </div>
              </div>

              <MDBInputGroup className="mb-3">
                <MDBInput
                  label={t("Search Speakers")}
                  value={speakerSearchQuery}
                  onChange={(e) => setSpeakerSearchQuery(e.target.value)}
                  onFocus={() => setShowSpeakerResults(true)}
                />
                {isSearchingSpeakers && (
                  <div className="input-group-text">
                    <MDBSpinner size="sm" />
                  </div>
                )}
              </MDBInputGroup>

              {/* Vorschläge (Speaker-Suchergebnisse) */}
              {showSpeakerResults && speakerSearchResults.length > 0 && (
                <MDBListGroup className="mb-3">
                  {speakerSearchResults.map((speaker) => {
                    // Darstellung von Name/E-Mail/Avatar je nach Employee vs. User
                    const displayFirstName = selectedCompany 
                      ? speaker.user?.firstName 
                      : speaker.firstName;
                    const displayLastName  = selectedCompany 
                      ? speaker.user?.lastName 
                      : speaker.lastName;
                    const displayEmail     = selectedCompany 
                      ? speaker.user?.email 
                      : speaker.email;
                    const displayImage     = selectedCompany
                      ? (speaker.user?.image?.fileName || speaker.image?.fileName)
                      : speaker.image?.fileName;

                    return (
                      <MDBListGroupItem
                        key={speaker.id}
                        className="d-flex align-items-center justify-content-between cursor-pointer"
                        onClick={() => handleSelectSpeaker(speaker)}
                      >
                        <div className="d-flex align-items-center">
                          {displayImage ? (
                            <img 
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${displayImage}`}
                              alt=""
                              className="rounded-circle me-3"
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                            />
                          ) : (
                            <MDBIcon 
                              fas 
                              icon="user-circle" 
                              size="2x" 
                              className="me-3"
                            />
                          )}
                          <div>
                            <div>
                              {displayFirstName} {displayLastName}
                            </div>
                            <small className="text-muted">
                              {displayEmail}
                            </small>
                          </div>
                        </div>
                        <MDBBtn 
                          color="primary" 
                          size="sm"
                          className="ms-2"
                        >
                          {t("Select")}
                        </MDBBtn>
                      </MDBListGroupItem>
                    );
                  })}
                </MDBListGroup>
              )}

              {/* Ausgewählte Speaker anzeigen */}
              {selectedSpeakers.length > 0 && (
                <MDBTable>
                  <MDBTableBody>
                    {selectedSpeakers.map((speaker) => (
                      <tr key={`${speaker.id}-${speaker.type}`}>
                        <td style={{ width: '50px' }}>
                          {speaker.image?.fileName ? (
                            <img 
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.image.fileName}`}
                              alt=""
                              className="rounded-circle"
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                            />
                          ) : (
                            <MDBIcon 
                              fas 
                              icon="user-circle" 
                              size="2x"
                            />
                          )}
                        </td>
                        <td>
                          <div>{speaker.firstName} {speaker.lastName}</div>
                          <small className="text-muted">{speaker.email}</small>
                        </td>
                        <td>
                          <MDBSwitch
                            label={t("Main speaker")}
                            checked={
                              mainSpeaker?.id === speaker.id &&
                              mainSpeaker?.type === speaker.type
                            }
                            onChange={() => handleToggleMainSpeaker(speaker.id, speaker.type)}
                          />
                        </td>
                        <td style={{ width: '50px' }}>
                          <MDBBtn 
                            color="link" 
                            className="text-danger p-1"
                            onClick={() => handleRemoveSpeaker(speaker.id, speaker.type)}
                          >
                            <MDBIcon fas icon="times" />
                          </MDBBtn>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        {/* --------------------------------------------- */}
        {/* Action Buttons */}
        {/* --------------------------------------------- */}
        <MDBCol size="12">
          <div className="d-flex justify-content-end gap-2 mt-4">
            <MDBBtn 
              color="secondary" 
              onClick={onCancel}
            >
              {t("Cancel")}
            </MDBBtn>
            <MDBBtn
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <MDBSpinner size="sm" className="me-2" />
                  {t("Saving...")}
                </>
              ) : (
                t("Save")
              )}
            </MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>

      {/* --------------------------------------------- */}
      {/* Alerts und Warnings (optional) */}
      {/* --------------------------------------------- */}
      {!selectedCompany && !lectureId && lecture?.type !== "organizatorical" && (
        <MDBAlert color="warning" className="mt-3">
          {t("Please select a company first")}
        </MDBAlert>
      )}
      
      {selectedSpeakers.length === 0 && (
        <MDBAlert color="warning" className="mt-3">
          {t("Please select at least one speaker")}
        </MDBAlert>
      )}
    </form>
  );
};

export default LectureForm;
