import React, { useState, useEffect, useContext, useRef } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { 
  MDBBtn, 
  MDBCard, 
  MDBCardBody, 
  MDBCardTitle, 
  MDBCardText, 
  MDBCardImage,
  MDBCol, 
  MDBInput, 
  MDBRow, 
  MDBCheckbox,
  MDBSpinner,
  MDBTypography,
  MDBStepper,
  MDBStepperStep,
  MDBIcon,
  MDBBtnGroup,
  MDBRadio,
  MDBAlert,
  MDBToast
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';

import { useTicket } from '../hooks/useTicket';
import { usePrintTicket } from '../hooks/usePrintTicket';
import getEventDays from '../../../../utils/getEventDays';
import { isoToGermanDate } from '../../../../utils/dateTools';
import { getLocalizedText } from '../../../../utils/localizedText';
import PersonalInformation from '../../../../pages-user/event-management/tickets/book-ticket-components/PersonalInformation';
import TicketPDF from '../components/TicketPDF';
import { useIndustry } from "../../../../utils/industryContextHook";
import shortUUID from "short-uuid";

const BookTicket = ({ selectedEvent, isKioskMode }) => {
  const ticketPriceForEveryDay = true;

  const { t, i18n } = useTranslation();
  const { currentIndustry } = useIndustry();
  

  const client = generateClient();
  const navigate = useNavigate();
  
  // Ticket booking data
  const [bookTicketData, setBookTicketData] = useState({
    ticketType: 'default',
    ticketPrice: 'normal',
    invitationCode: '',
    eventDays: [],
    firstName: '',
    lastName: '',
    title: '',
    company: '',
    email: '',
    phone: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    country: 'DE',
    vatInfo: { vatNumber: '' },
    orderNumber: ''
  });

  // Personal information state
  const [personalAddress, setPersonalAddress] = useState({
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    countryCode: 'DE'
  });

  const [personalContact, setPersonalContact] = useState({
    phone: '',
    email: ''
  });

  // Billing information
  const [useProfileAddressForBilling, setUseProfileAddressForBilling] = useState(true);
  const [billingAddress, setBillingAddress] = useState({});
  const [customerType, setCustomerType] = useState('consumer');
  const [customerTypeSetByUser, setCustomerTypeSetByUser] = useState(false);
  
  // Payment calculation
  const [taxRate, setTaxRate] = useState(19);
  const [discountCode, setDiscountCode] = useState('');
  const [appliedDiscounts, setAppliedDiscounts] = useState([]);
  
  // Ticket creation status
  const [isCreating, setIsCreating] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastColor, setToastColor] = useState('success');
  
  // Validation
  const [validations, setValidations] = useState({});
  const [showValidations, setShowValidations] = useState({
    ticketType: false,
    personalAddress: false,
    personalContact: false,
    billingAddress: false
  });

  // Event and days
  const [event, setEvent] = useState(selectedEvent);
  const [eventDays, setEventDays] = useState([]);

  // Stepper navigation
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [defaultStep, setDefaultStep] = useState(1);

  // Hooks
  const { 
    createTicket,
    isLoading: isTicketLoading 
  } = useTicket(event?.id);
  
  const {
    printTicket,
    generateTicketPdf,
    printPdfFromUrl
  } = usePrintTicket();

  // Initialize with event data
  useEffect(() => {
    if (selectedEvent) {
      setEvent(selectedEvent);
      const days = getEventDays(selectedEvent);
      setEventDays(days);
      
      // If event doesn't allow selecting days, select all days
      if (selectedEvent && !selectedEvent.bookingSelectDays) {
        setBookTicketData(prev => ({ ...prev, eventDays: days }));
      }
    }
  }, [selectedEvent]);

  // Handle tax rate changes based on customer type and country
  // useEffect(() => {
  //   if ((personalAddress.company || billingAddress.company) && !customerTypeSetByUser) {
  //     setCustomerType('business');
  //   }
    
  //   if (customerType === 'business') {
  //     const country = useProfileAddressForBilling ? personalAddress.countryCode : billingAddress.countryCode;
  //     setTaxRate(country === 'DE' ? 19 : 0);
  //   } else {
  //     const country = useProfileAddressForBilling ? personalAddress.countryCode : billingAddress.countryCode;
  //     const euCountries = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
  //     setTaxRate(euCountries.includes(country) ? 19 : 0);
  //   }
  // }, [customerType, personalAddress, billingAddress, useProfileAddressForBilling, customerTypeSetByUser]);

  // Set automatic discount based on ticket price
  useEffect(() => {
    // Clear previous discounts
    setAppliedDiscounts([]);
    
    // Apply staff discount (100%)
    if (bookTicketData.ticketPrice === 'free' || bookTicketData.ticketType === 'staff') {
      setAppliedDiscounts([{
        id: "7e2461cb-2ff1-4338-9ac4-6e10a055e30c",
        code: "LOUNGES2025STAFF",
        createdAt: "2025-03-22T14:39:25.987Z",
        discountAmount: null,
        discountPercentage: 100,
        discountType: "percentage",
        endDate: "2025-03-29T13:38:00.000Z",
        maxUsage: null,
        name: [
          { language: "de", text: "Lounges2025 Standpersonal" },
          { language: "en", text: "Lounges2025 Booth personal" }
        ],
        restrictedProductIds: ["8a837bf0-1c6b-4824-8f6b-753141a73312"],
        startDate: "2025-03-22T13:38:00.000Z",
        status: "active",
        updatedAt: "2025-03-22T14:40:52.440Z",
        typename: "DiscountCode"
      }]);
    }
    // Apply reduced price discount (50%)
    else if (bookTicketData.ticketPrice === 'reduced') {
      setAppliedDiscounts([{
        id: "28980b0d-16e1-45a6-9431-26128214bf64",
        code: "LOUNGES2025REDUCED",
        createdAt: "2025-03-22T14:42:11.246Z",
        discountAmount: null,
        discountPercentage: 50,
        discountType: "percentage",
        endDate: "2025-03-29T13:41:00.000Z",
        maxUsage: null,
        name: [
          { language: "de", text: "Lounges 2025 Ermäßigtes Ticket" },
          { language: "en", text: "Lounges 2025 reduced ticket" }
        ],
        restrictedProductIds: ["8a837bf0-1c6b-4824-8f6b-753141a73312"],
        startDate: "2025-03-22T13:41:00.000Z",
        status: "active",
        updatedAt: "2025-03-22T14:42:44.973Z",
        typename: "DiscountCode"
      }]);
    }
  }, [bookTicketData.ticketPrice, bookTicketData.ticketType]);

  // Automatically set free price when staff type is selected
  useEffect(() => {
    if (bookTicketData.ticketType === 'staff') {
      setBookTicketData(prev => ({ ...prev, ticketPrice: 'free' }));
    }
  }, [bookTicketData.ticketType]);

  // Stepper navigation
  const handleClickNext = () => {
    nextRef.current.click();
  };

  const handleClickPrev = () => {
    prevRef.current.click();
  };

  // Handle day selection
  const handleDaySelection = (day, checked) => {
    setBookTicketData(prev => ({
      ...prev,
      eventDays: checked
        ? [...prev.eventDays, day]
        : prev.eventDays.filter(d => d !== day)
    }));
  };

  // Create ticket and order
  const handleCreateTicket = async () => {
    // Validate inputs before proceeding
    if (!personalAddress.firstName || !personalAddress.lastName || bookTicketData.eventDays.length === 0) {
      setErrors(['Please fill out all required fields']);
      return;
    }

    setIsCreating(true);
    setErrors([]);
    
    try {
      // Prepare ticket data
      const ticketData = {
        industryId: currentIndustry.id,
        type: bookTicketData.ticketType,
        eventDays: bookTicketData.eventDays,
        firstName: personalAddress.firstName,
        lastName: personalAddress.lastName,
        title: personalAddress.title,
        company: personalAddress.company,
        street: personalAddress.street,
        streetNumber: personalAddress.streetNumber,
        zip: personalAddress.zip,
        city: personalAddress.city,
        country: personalAddress.countryCode,
        email: personalContact.email,
        phone: personalContact.phone,
        invitationCode: bookTicketData.invitationCode || undefined
      };
      
      // Create ticket
      const createdTicket = await createTicket(ticketData);
      
      if (createdTicket) {
        setTicketId(createdTicket.id);
        
        // If ticket is not free, create an order
        if (event?.ticketProduct?.price > 0 && bookTicketData.ticketPrice !== 'free' && bookTicketData.ticketType !== 'staff') {
          await createOrder(createdTicket);
        }
        
        setToastMessage(t('Ticket created successfully!'));
        setToastColor('success');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error creating ticket:', error);
      setErrors([t('Error creating ticket. Please try again.')]);
      setToastMessage(t('Error creating ticket'));
      setToastColor('danger');
      setShowToast(true);
    } finally {
      setIsCreating(false);
    }
  };

  // Create order for paid tickets
  const createOrder = async (ticket) => {
    try {

      const daysCount = ticketPriceForEveryDay ? bookTicketData.eventDays.length : 1;

      const productsForOrder = [{
        id: event.ticketProduct.id,
        price: event.ticketProduct.price,
        ticketId: ticket.id,
        qty: daysCount,
        discount: 0,
        discountType: "",
        taxRate
      }];

      const basePrice = event.ticketProduct.price * daysCount;
      const totalAmount = bookTicketData.ticketPrice === 'normal' 
        ? basePrice 
        : bookTicketData.ticketPrice === 'reduced' 
          ? basePrice * 0.5 
          : 0;
      
      // Apply any discounts
      const discounts = appliedDiscounts.map(discount => ({
        ...discount,
        amount: discount.discountType === 'percentage' 
          ? totalAmount * (discount.discountPercentage / 100)
          : discount.discountAmount
      }));

        let data = {};
        if(discounts.length > 0) {
            data.discounts = discounts
        }

      const orderPayload = {
        type: "ticket_onSite",
        isAdminOrder: true,
        industryOrdersId: currentIndustry.id,
        buyerOrderNumber: bookTicketData.orderNumber || null,
        deliveryAddress: personalAddress,
        billingAddress: useProfileAddressForBilling ? personalAddress : billingAddress,
        billingEmail: personalContact.email,
        products: productsForOrder,
        isBusiness: customerType === "business",
        vatInfo: bookTicketData.vatInfo?.vatNumber ? bookTicketData.vatInfo : null,
        currency: "EUR",
        totalAmount: totalAmount + (totalAmount * taxRate / 100),
        invoiceAmount: totalAmount + (totalAmount * taxRate / 100),
        invoiceAmountNet: totalAmount,
        taxRate,
        notes: "Ausgewählte Tage: " + bookTicketData.eventDays.map(day => isoToGermanDate(day)).join(", "),
        ...(Object.keys(data).length > 0 && { data: JSON.stringify(data) }),
      };

      const response = await client.graphql({
        query: /* GraphQL */ `
          mutation PlaceOrder($order: String) {
            placeOrder(order: $order)
          }
        `,
        variables: {
          order: JSON.stringify(orderPayload)
        }
      });

      const orderId = response.data.placeOrder;
      
      // Fetch the order details
      const orderResponse = await client.graphql({
        query: /* GraphQL */ `
          query GetOrder($id: ID!) {
            getOrder(id: $id) {
              id
              orderNumber
              totalAmount
              status
              paymentStatus
              createdAt
            }
          }
        `,
        variables: { id: orderId }
      });
      
      setOrderData(orderResponse.data.getOrder);

      // Create invoice for the order
      if (orderId) {
        const invoiceResponse = await createInvoice(orderId, ticket);
        setInvoiceData(invoiceResponse);
      }

      return orderId;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  };

  // Create invoice for the order
  const createInvoice = async (orderId, ticket) => {
    setIsGeneratingInvoice(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateDocument(
            $documentType: String
            $orderIds: String
            $split: Boolean
            $returnBase64: Boolean
            $qrData: String
            $hideServicePeriod: Boolean
            $hidePaymentTerms: Boolean
          ) {
            createDocument(
              documentType: $documentType
              orderIds: $orderIds
              split: $split
              returnBase64: $returnBase64
              qrData: $qrData
              hideServicePeriod: $hideServicePeriod
              hidePaymentTerms: $hidePaymentTerms
            ) {
              succeded
              url
              s3Path
              documentNumber
              documentName
              status
              date
              amount
              amountNet
            }
          }
        `,
        variables: {
          documentType: "invoice",
          orderIds: JSON.stringify([orderId]),
          split: false,
          returnBase64: false,
          qrData: shortUUID().fromUUID(ticket.id),
          hideServicePeriod: true,
          hidePaymentTerms: true
        }
      });

      setToastMessage(t('Invoice created successfully!'));
      setToastColor('success');
      setShowToast(true);
      
      return response.data.createDocument;
    } catch (error) {
      console.error('Error creating invoice:', error);
      setToastMessage(t('Error creating invoice'));
      setToastColor('danger');
      setShowToast(true);
      return null;
    } finally {
      setIsGeneratingInvoice(false);
    }
  };

  // State for tracking ticket, order, and invoice information
  const [ticketData, setTicketData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [isFetchingTicket, setIsFetchingTicket] = useState(false);
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
  
  // Fetch ticket details after creation
  useEffect(() => {
    const fetchTicketDetails = async () => {
      if (!ticketId) return;
      
      setIsFetchingTicket(true);
      try {
        const response = await client.graphql({
          query: /* GraphQL */ `
            query GetTicket($id: ID!) {
              getTicket(id: $id) {
                id
                type
                eventDays
                createdAt
                ticketFile {
                  s3Path
                }
                personalAddress {
                  title
                  firstName
                  lastName
                  company
                }
                personalContact {
                  phone
                  email
                }
                event {
                  id
                  name {
                    language
                    text
                  }
                  type
                  startDate
                  endDate
                  image {
                    fileName
                  }
                }
              }
            }
          `,
          variables: { id: ticketId }
        });
  
        setTicketData(response.data.getTicket);
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      } finally {
        setIsFetchingTicket(false);
      }
    };
  
    fetchTicketDetails();
  }, [ticketId]);



  

  const [isLoadingTicketPDFS3path, setIsLoadingTicketPDFS3path] = useState(false);
  const [ticketPDFS3path, setTicketPDFS3path] = useState(null);

   const fetchTicket = async () => {
      try {
        const ticketPDFData = await client.graphql({
          query: /* GraphQL */ `
            query GetTicket($id: ID!) {
              getTicket(id: $id) {
                id
                ticketFile {
                  s3Path
                }
              }
            }
          `,
          variables: { id: ticketId }
        });
  
        const fetchedTicket = ticketPDFData.data.getTicket;
        if(fetchedTicket.ticketFile?.s3Path) {
          setTicketPDFS3path(fetchedTicket.ticketFile.s3Path); 
          return fetchedTicket.ticketFile.s3Path
        } else {
        return null;
        }
      } catch (error) {
        console.error('Error fetching ticket:', error);
        return null;
      }
    };
  
    // Effect to handle ticket monitoring
    useEffect(() => {
      if (!ticketId || ticketPDFS3path) return;
      setIsLoadingTicketPDFS3path(true);
      const checkTicketStatus = async () => {

        const ticketPDFFile = await fetchTicket();
        console.log("ticketPDFFile", ticketPDFFile);
        if (ticketPDFFile) {
          setIsLoadingTicketPDFS3path(false);
        } else {
          // If ticket file is not yet available, check again after 5 seconds
          setTimeout(checkTicketStatus, 3000);
        }
      };
      if(!ticketPDFS3path) {
      checkTicketStatus();
      }
    }, [ticketId]);

    const iframeRef = useRef(null);

    const handlePrintInvoice = () => {
      if (invoiceData) {
        printPdfFromUrl(`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${invoiceData.s3Path}`);
      }
    }
  
    const handlePrintTicket = () => {
      if (ticketPDFS3path) {
        //printPdfFromUrl(`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${ticketPDFS3path}`);
        //window.open(`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${ticketPDFS3path}`, '_blank');
        iframeRef.current.contentWindow.print();
      }
    }

      // Reset booking form
  const resetBooking = () => {
    setTicketId(null);
    setTicketData(null);
    setOrderData(null);
    setInvoiceData(null);
    setBookTicketData({
      ticketType: 'default',
      ticketPrice: 'normal',
      invitationCode: '',
      eventDays: [],
      firstName: '',
      lastName: '',
      title: '',
      company: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      zip: '',
      city: '',
      country: 'DE',
      vatInfo: { vatNumber: '' },
      orderNumber: ''
    });
    setPersonalAddress({
      salutation: '',
      title: '',
      firstName: '',
      lastName: '',
      company: '',
      street: '',
      streetNumber: '',
      zip: '',
      city: '',
      countryCode: 'DE'
    });
    setPersonalContact({
      phone: '',
      email: ''
    });
    setBillingAddress({});
    setCustomerType('consumer');
    setCustomerTypeSetByUser(false);
    setValidations({});
    setShowValidations({
      ticketType: false,
      personalAddress: false,
      personalContact: false,
      billingAddress: false
    });
    setTaxRate(19);
    setDiscountCode('');
    setAppliedDiscounts([]);
    setErrors([]);
    setIsLoadingTicketPDFS3path(false);
    setTicketPDFS3path(null);
    setDefaultStep(1);
  };

  // Display success page if ticket is created
  if (ticketId) {
    return (
      <>
        <MDBTypography tag="h1" className="text-center text-success mb-4">
          <MDBIcon fas icon="check-circle" className="me-2" />
          {t("Booking Successful!")}
        </MDBTypography>

        <MDBCard className="mx-auto" style={{ maxWidth: '800px' }}>
          <MDBCardBody>
            {(isFetchingTicket) ? (
              <div className="text-center py-4">
                <MDBSpinner className="mb-3" />
                <p>{isFetchingTicket ? t("Loading ticket information...") : t("Generating invoice...")}</p>
              </div>
            ) : (
              <>
                <MDBCardTitle>{t("Your Ticket Details")}</MDBCardTitle>
                
                <div className="mb-4">
                  <h5 className="text-primary">{t("Event")}</h5>
                  <p>{ticketData?.event?.name?.find(n => n.language === i18n.language)?.text || ticketData?.event?.name?.[0]?.text}</p>
                  
                  <h5 className="text-primary mt-3">{t("Selected Days")}</h5>
                  <p>{ticketData?.eventDays?.map(day => isoToGermanDate(day)).join(", ")}</p>

                  <h5 className="text-primary mt-3">{t("Attendee")}</h5>
                  <p>{ticketData?.personalAddress?.title} {ticketData?.personalAddress?.firstName} {ticketData?.personalAddress?.lastName}</p>
                  {ticketData?.personalAddress?.company && <p>{ticketData.personalAddress.company}</p>}
                  
                  <h5 className="text-primary mt-3">{t("Booking Date")}</h5>
                  <p>{isoToGermanDate(ticketData?.createdAt)}</p>
                </div>

                <div className="mb-4">
                  <h5 className="text-primary">{t("Ticket & Invoice")}</h5>
                  <MDBBtnGroup shadow='0' className="w-100">
                    {/* {event?.ticketProduct?.price > 0 && bookTicketData.ticketPrice !== 'free' && bookTicketData.ticketType !== 'staff' && 
                  (!isGeneratingInvoice && invoiceData ? (
                      <MDBBtn 
                      color="outline-primary"
                      onClick={handlePrintInvoice}
                    >
                      <MDBIcon fas size='2x' icon="file-invoice" />
                      <br />
                      {t("Download Invoice")}
                    </MDBBtn>
                      )
                    : (
                      <MDBBtn 
                        color="outline-primary"
                      >
                      <MDBSpinner size='sm' />
                        <br />
                        {t("Generating Invoice")}
                      </MDBBtn>
                    )
                   )}
                    {!isLoadingTicketPDFS3path ? (
                     <MDBBtn 
                     color="outline-primary"
                     onClick={handlePrintTicket}
                    >
                        <MDBIcon fas size='2x' icon="file-pdf" />
                        <br />
                        {t("Print Ticket")}
                      </MDBBtn>
                    )
                    : (
                      <MDBBtn
                        color="outline-primary"
                      >
                        <MDBSpinner size='sm' />
                        <br />
                        {t("Generating Ticket")}
                      </MDBBtn>
                    )} */}
                    
                    {/* Print button */}
                    <MDBBtn 
                      color="outline-primary"
                      onClick={() => printTicket({
                        ...bookTicketData,
                        ...personalAddress,
                        ...personalContact,
                        personalAddress,
                        personalContact,
                      }, TicketPDF)}
                    >
                      <MDBIcon fas size='2x' icon="id-card-alt" />
                      <br />
                      {t("Print Badge")}
                    </MDBBtn>
                  </MDBBtnGroup>
                </div>
                
               
              </>
            )}
            <MDBRow>
              {isGeneratingInvoice && (
                <MDBCol size="12" md="6" className="mx-auto text-center">
                  <h4 className="text-center mt-4">{t("Invoice")}</h4>
                  <MDBSpinner />
                </MDBCol>
              )}
              {!isGeneratingInvoice && invoiceData && (
              <MDBCol size="12" md="6" className="mx-auto">
                <h4 className="text-center mt-4">{t("Invoice")}</h4>
                <iframe ref={iframeRef} title="PrintInvoice" src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${invoiceData.s3Path}`} width="100%" height="300px"></iframe>
              </MDBCol>
              )}
              {isLoadingTicketPDFS3path && (
                <MDBCol size="12" md="6" className="mx-auto text-center">
                  <h4 className="text-center mt-4">{t("Ticket")}</h4>
                  <MDBSpinner />
                </MDBCol>
              )}
              {!isLoadingTicketPDFS3path &&
              <MDBCol size="12" md="6" className="mx-auto">
                <h4 className="text-center mt-4">{t("Ticket")}</h4>
               <iframe ref={iframeRef} title="PrintTicket" src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${ticketPDFS3path}`} width="100%" height="300px"></iframe>
              </MDBCol>
              }
            </MDBRow>
            <div className="text-center mt-4">
              <MDBBtn color="primary" onClick={resetBooking}>
                {t("New Booking")}
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </>
    );
  }
  
  return (
    <>
      <MDBTypography tag='h3' className="text-center">{t("Book ticket")}</MDBTypography>
      <div className="d-none">
        <MDBBtn ref={prevRef}>{t("Previous")}</MDBBtn>
        <MDBBtn ref={nextRef}>{t("Next")}</MDBBtn>
      </div>
      
      <MDBStepper defaultStep={defaultStep} externalNext={nextRef} externalPrev={prevRef} type="vertical" className="text-center">
        {/* Step 1: Select ticket type */}
        <MDBStepperStep itemId={1} headText={t("Select ticket type")} headIcon={<MDBIcon fas icon="ticket-alt" />}>
          <MDBCard className="mx-auto" style={{ maxWidth: '800px' }}>
            <MDBCardBody className="text-center">
              <h4>{t("Select ticket type")}</h4>
              
              <div className="my-4">
                <MDBTypography tag="h5">{t("Ticket Type")}</MDBTypography>
                <MDBBtnGroup className="mb-4">
                  <MDBBtn
                    color={bookTicketData.ticketType === 'default' ? 'primary' : 'light'}
                    onClick={() => setBookTicketData(prev => ({ ...prev, ticketType: 'default' }))}
                  >
                    {t("Visitor")}
                  </MDBBtn>
                  {!isKioskMode && (
                  <MDBBtn
                    color={bookTicketData.ticketType === 'staff' ? 'primary' : 'light'}
                    onClick={() => setBookTicketData(prev => ({ ...prev, ticketType: 'staff' }))}
                  >
                    {t("Booth Staff")}
                  </MDBBtn>
                  )}
                </MDBBtnGroup>
                
                <MDBTypography tag="h5">{t("Ticket Price")}</MDBTypography>
                <MDBBtnGroup className="mb-4">
                  <MDBBtn
                    color={bookTicketData.ticketPrice === 'normal' ? 'primary' : 'light'}
                    onClick={() => setBookTicketData(prev => ({ ...prev, ticketPrice: 'normal' }))}
                    disabled={bookTicketData.ticketType === 'staff'}
                  >
                    {t("Normal")}
                    {event?.ticketProduct?.price > 0 && (
                      <div className="small mt-1">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(event.ticketProduct.price * (1 + taxRate / 100))} / {t("Day")}
                      </div>
                    )}
                  </MDBBtn>
                  <MDBBtn
                    color={bookTicketData.ticketPrice === 'reduced' ? 'primary' : 'light'}
                    onClick={() => setBookTicketData(prev => ({ ...prev, ticketPrice: 'reduced' }))}
                    disabled={bookTicketData.ticketType === 'staff'}
                  >
                    {t("Reduced")}
                    {event?.ticketProduct?.price > 0 && (
                      <div className="small mt-1">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(event.ticketProduct.price * 0.5 * (1 + taxRate / 100))} / {t("Day")}
                      </div>
                    )}
                  </MDBBtn>
                  {!isKioskMode && (
                  <MDBBtn
                    color={bookTicketData.ticketPrice === 'free' ? 'primary' : 'light'}
                    onClick={() => setBookTicketData(prev => ({ ...prev, ticketPrice: 'free' }))}
                    disabled={bookTicketData.ticketType === 'staff'}
                  >
                    {t("Free")}
                  </MDBBtn>
                  )}
                </MDBBtnGroup>
                <br />
                <span>* {t("Reduced tickets only with a valid invitation code or for government officials and public servants or students")}</span>
                
                {event?.hasInvitationCodes && (
                  <div className="my-4 w-50 mx-auto">
                    <MDBTypography tag="h5">{t("Invitation Code")}</MDBTypography>
                    <MDBInput
                      label={t("Enter invitation code")}
                      value={bookTicketData.invitationCode}
                      onChange={(e) => setBookTicketData(prev => ({ ...prev, invitationCode: e.target.value }))}
                      className="w-75 mx-auto"
                    />
                  </div>
                )}
              </div>
              
              {event?.bookingSelectDays && (
                <div className="my-4">
                  <MDBTypography tag="h5">{t("Select days to attend")}</MDBTypography>
                  <div className="d-flex flex-wrap justify-content-center">
                    {eventDays.map((day, index) => (
                      <div key={day} className="mx-3 my-2">
                        <MDBCheckbox
                          label={isoToGermanDate(day)}
                          checked={bookTicketData.eventDays.includes(day)}
                          onChange={(e) => handleDaySelection(day, e.target.checked)}
                        />
                      </div>
                    ))}
                    {/* Select all */}
                    <div className="mx-3 my-2">
                      <MDBCheckbox
                        label={t("Select all")}
                        checked={bookTicketData.eventDays.length === eventDays.length}
                        onChange={(e) => setBookTicketData(prev => ({
                          ...prev,
                          eventDays: e.target.checked ? eventDays : []
                        }))}
                      />
                    </div>
                  </div>
                  
                  {showValidations.ticketType && bookTicketData.eventDays.length === 0 && (
                    <div className="text-danger mt-2">
                      {t("Please select at least one day")}
                    </div>
                  )}
                </div>
              )}
              
              {bookTicketData.ticketType === 'staff' && (
                <MDBAlert color="info" className="mt-4">
                  <MDBIcon fas icon="info-circle" className="me-2" />
                  {t("Booth staff tickets are free of charge and allow access to the exhibition area during setup, event days, and teardown.")}
                </MDBAlert>
              )}
              
              {bookTicketData.ticketPrice === 'reduced' && (
                <MDBAlert color="info" className="mt-4">
                  <MDBIcon fas icon="info-circle" className="me-2" />
                  {t("Reduced tickets are available for students, trainees, retirees, and employees of public authorities. You may be asked to show proof of eligibility at the event.")}
                </MDBAlert>
              )}
              
              <div className="mt-4">
                <MDBBtn
                  color="primary"
                  onClick={() => {
                    if (bookTicketData.eventDays.length === 0 && event?.bookingSelectDays) {
                      setShowValidations(prev => ({ ...prev, ticketType: true }));
                    } else {
                      handleClickNext();
                    }
                  }}
                >
                  {t("Next")}
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBStepperStep>
        
        {/* Step 2: Personal Information */}
        <MDBStepperStep itemId={2} headText={t("Personal information")} headIcon={<MDBIcon fas icon="user" />}>
          <MDBCard className="mx-auto" style={{ maxWidth: '1200px' }}>
            <MDBCardBody>
              <PersonalInformation
                event={event}
                params={bookTicketData}
                setParams={setBookTicketData}
                useProfileAddressForBilling={useProfileAddressForBilling}
                setUseProfileAddressForBilling={setUseProfileAddressForBilling}
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                personalAddress={personalAddress}
                setPersonalAddress={setPersonalAddress}
                personalContact={personalContact}
                setPersonalContact={setPersonalContact}
                customerType={customerType}
                setCustomerType={setCustomerType}
                setCustomerTypeSetByUser={setCustomerTypeSetByUser}
                handleClickPrev={handleClickPrev}
                handleClickNext={handleClickNext}
                validations={validations}
                showValidations={showValidations}
                setShowValidations={setShowValidations}
                hidePaymentFields={bookTicketData.ticketPrice === 'free' || bookTicketData.ticketType === 'staff'}
                skipValidation={true}
                hideBadgePreview={true}
                hideSelectCustomerType={true}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBStepperStep>
        
        {/* Step 3: Confirmation */}
        <MDBStepperStep itemId={3} headText={t("Confirmation")} headIcon={<MDBIcon fas icon="check" />}>
          <MDBCard className="mx-auto" style={{ maxWidth: '1000px' }}>
            <MDBCardBody>
              <h4 className="text-center mb-4">{t("Confirm your booking")}</h4>
              
              <MDBRow>
                <MDBCol md="6">
                  <MDBCard>
                    <MDBCardBody className="text-start">
                      <MDBCardImage
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/${selectedEvent?.image?.fileName}`}
                        alt="Event Image"
                        fluid
                        style={{ maxHeight: "250px", objectFit: "cover" }}
                      />
                      <MDBCardText className="mt-3">
                      <p><strong>{t("Ticket Type")}:</strong> {t(bookTicketData.ticketType === 'staff' ? 'Booth Staff' : 'Visitor')}</p>
                      <p><strong>{t("Ticket Price")}:</strong> {t(bookTicketData.ticketPrice === 'normal' ? 'Normal' : bookTicketData.ticketPrice === 'reduced' ? 'Reduced' : 'Free')}</p>
                      
                      <h6>{t("Selected Days")}</h6>
                      <ul>
                        {bookTicketData.eventDays.map(day => (
                          <li key={day}>{isoToGermanDate(day)}</li>
                        ))}
                      </ul>
                      
                      {bookTicketData.invitationCode && (
                        <p><strong>{t("Invitation Code")}:</strong> {bookTicketData.invitationCode}</p>
                      )}
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                  
                </MDBCol>
                
                <MDBCol md="6">
                  <MDBCard>
                    <MDBCardBody className="text-start">
                    <h5>{t("Personal Information")}</h5>
                    <p>
                      {personalAddress.title && <span>{personalAddress.title} </span>}
                      {personalAddress.firstName} {personalAddress.lastName}
                    </p>
                    {personalAddress.company && <p>{personalAddress.company}</p>}
                    <p>{personalAddress.street} {personalAddress.streetNumber}</p>
                    <p>{personalAddress.zip} {personalAddress.city}</p>
                    <p>{personalAddress.countryCode}</p>
                    
                    <p><strong>{t("Email")}:</strong> {personalContact.email}</p>
                    {personalContact.phone && <p><strong>{t("Phone")}:</strong> {personalContact.phone}</p>}
                    
                    {/* Show billing information only for paid tickets */}
                    {bookTicketData.ticketPrice !== 'free' && bookTicketData.ticketType !== 'staff' && (
                      <div className="mt-4">
                        <h5>{t("Billing Information")}</h5>
                        {!useProfileAddressForBilling ? (
                          <>
                            <p>{billingAddress.company}</p>
                            <p>{billingAddress.firstName} {billingAddress.lastName}</p>
                            <p>{billingAddress.street} {billingAddress.streetNumber}</p>
                            <p>{billingAddress.zip} {billingAddress.city}</p>
                            <p>{billingAddress.countryCode}</p>
                          </>
                        ) : (
                          <p>{t("Same as personal information")}</p>
                        )}
                        
                        {bookTicketData.orderNumber && (
                          <p><strong>{t("Order Number")}:</strong> {bookTicketData.orderNumber}</p>
                        )}
                      </div>
                    )}
                    </MDBCardBody>
                  </MDBCard>
                  
                </MDBCol>
              </MDBRow>
              
             {/* Price breakdown */}
            {event?.ticketProduct?.price > 0 && (
              <MDBCard className="mt-4">
                <MDBCardBody className="text-start">
                  <h5>{t("Price Breakdown")}</h5>
                  
                  {ticketPriceForEveryDay && (
                    <MDBRow>
                      <MDBCol md="8">
                        <p>{t("Number of Days")}</p>
                      </MDBCol>
                      <MDBCol md="4" className="text-end">
                        {bookTicketData.eventDays.length}
                      </MDBCol>
                    </MDBRow>
                  )}
                  
                  <MDBRow>
                    <MDBCol md="8">
                      <p>
                        {ticketPriceForEveryDay 
                          ? t("Ticket per day") 
                          : t("Ticket")}: {getLocalizedText(event.name)}
                      </p>
                    </MDBCol>
                    <MDBCol md="4" className="text-end">
                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(event.ticketProduct.price)}
                      {ticketPriceForEveryDay && ` × ${bookTicketData.eventDays.length}`}
                    </MDBCol>
                  </MDBRow>
                  
                  {ticketPriceForEveryDay && (
                    <MDBRow>
                      <MDBCol md="8">
                        <p>{t("Subtotal")}</p>
                      </MDBCol>
                      <MDBCol md="4" className="text-end">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(event.ticketProduct.price * bookTicketData.eventDays.length)}
                      </MDBCol>
                    </MDBRow>
                  )}
                  
                  {bookTicketData.ticketPrice === 'reduced' && (
                    <MDBRow>
                      <MDBCol md="8">
                        <p>{t("Reduced Price Discount (50%)")}</p>
                      </MDBCol>
                      <MDBCol md="4" className="text-end text-danger">
                        -{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          event.ticketProduct.price * (ticketPriceForEveryDay ? bookTicketData.eventDays.length : 1) * 0.5
                        )}
                      </MDBCol>
                    </MDBRow>
                  )}
                  
                  {(bookTicketData.ticketPrice === 'free' || bookTicketData.ticketType === 'staff') && (
                    <MDBRow>
                      <MDBCol md="8">
                        <p>{t("Staff Discount (100%)")}</p>
                      </MDBCol>
                      <MDBCol md="4" className="text-end text-danger">
                        -{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          event.ticketProduct.price * (ticketPriceForEveryDay ? bookTicketData.eventDays.length : 1)
                        )}
                      </MDBCol>
                    </MDBRow>
                  )}
                  
                  {taxRate > 0 && bookTicketData.ticketPrice === 'normal' && (
                    <MDBRow>
                      <MDBCol md="8">
                        <p>{t("VAT")} ({taxRate}%)</p>
                      </MDBCol>
                      <MDBCol md="4" className="text-end">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          event.ticketProduct.price * (ticketPriceForEveryDay ? bookTicketData.eventDays.length : 1) * (taxRate / 100)
                        )}
                      </MDBCol>
                    </MDBRow>
                  )}
                  
                  {taxRate > 0 && bookTicketData.ticketPrice === 'reduced' && (
                    <MDBRow>
                      <MDBCol md="8">
                        <p>{t("VAT")} ({taxRate}%)</p>
                      </MDBCol>
                      <MDBCol md="4" className="text-end">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                          event.ticketProduct.price * (ticketPriceForEveryDay ? bookTicketData.eventDays.length : 1) * 0.5 * (taxRate / 100)
                        )}
                      </MDBCol>
                    </MDBRow>
                  )}
                  
                  <hr />
                  
                  <MDBRow className="fw-bold">
                    <MDBCol md="8">
                      <p>{t("Total")}</p>
                    </MDBCol>
                    <MDBCol md="4" className="text-end">
                      {(() => {
                        const daysCount = ticketPriceForEveryDay ? bookTicketData.eventDays.length : 1;
                        const basePrice = event.ticketProduct.price * daysCount;
                        
                        if (bookTicketData.ticketPrice === 'normal') {
                          return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            basePrice * (1 + taxRate / 100)
                          );
                        } else if (bookTicketData.ticketPrice === 'reduced') {
                          return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                            basePrice * 0.5 * (1 + taxRate / 100)
                          );
                        } else {
                          return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0);
                        }
                      })()}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            )}
              
             
              {/* Error display */}
              {errors.length > 0 && (
                <div className="alert alert-danger mt-3">
                  {errors.map((error, index) => (
                    <p key={index} className="mb-0">{error}</p>
                  ))}
                </div>
              )}
              
              {/* Navigation and submit buttons */}
              <div className="d-flex justify-content-between mt-4">
                <MDBBtn color="light" onClick={handleClickPrev}>
                  {t("Back")}
                </MDBBtn>
                
                <MDBBtn 
                  color="success" 
                  onClick={handleCreateTicket}
                  disabled={isCreating}
                >
                  {isCreating ? (
                    <>
                      <MDBSpinner size='sm' className='me-2' />
                      {t("Creating ticket...")}
                    </>
                  ) : (
                    t("Confirm booking")
                  )}
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBStepperStep>
      </MDBStepper>
      
      {/* Toast notification */}
      <MDBToast
        color={toastColor}
        open={showToast}
        autohide
        delay={5000}
        onClose={() => setShowToast(false)}
        position='top-right'
        appendToBody
      >
        {toastMessage}
      </MDBToast>
    </>
  );
};

export default BookTicket;