import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { ticketStyles } from '../utils/pdfStyles';

/**
 * Ticket PDF component
 * @param {Object} props - Component props
 * @param {Object} props.ticketData - The ticket data
 * @param {string} props.qrCode - The QR code data URL
 * @returns {JSX.Element} - PDF Document component
 */
const TicketPDF = ({ ticketData, qrCode }) => {
  // Set dynamic company text size based on length
  const companyTextStyle = {
    ...ticketStyles.companyText,
    fontSize: ticketData.company && ticketData.company.length < 30 ? "18mm" : "14mm",
  };

  // Sort days chronologically
  const sortedDays = ticketData.eventDays ? 
    [...ticketData.eventDays].sort((a, b) => new Date(a) - new Date(b)) : 
    [];
    
  return (
    <Document>
      <Page style={ticketStyles.page} size={{ width: 800, height: 2000 }}>
        <View style={ticketStyles.section}>
                <Text style={{...ticketStyles.nameText, marginTop: (!(ticketData.phone || ticketData.email) && sortedDays.length < 1) ? "70mm" : "0mm"}}>
                {ticketData.title && ticketData.title.length < 10 ? ticketData.title + " " : ""}
                {ticketData.firstName} {ticketData.lastName}
                </Text>
                
                <Text style={companyTextStyle}>
                {ticketData.company}
                </Text>
                
                <View style={ticketStyles.row}>
                  <View style={[ticketStyles.cell]}>
                    {qrCode && (ticketData.phone || ticketData.email) && (
                    <Image 
                      style={{ width: "120mm", marginLeft: "60px" }} 
                      src={qrCode} 
                    />
                    )}
                  </View>
                  
                  <View style={[ticketStyles.cell, { display: "flex", justifyContent: "center" }]}>
                    {sortedDays.map((day, index) => (
                    <Text key={index} style={ticketStyles.daysText}>
                      {new Date(day).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit" })}
                    </Text>
                    ))}
                  </View>
              </View>

                {/* Bottom section - upside down */}
          <View style={[ticketStyles.section2, ticketStyles.flipText]}>
          <Text style={{...ticketStyles.nameText, marginTop: (!(ticketData.phone || ticketData.email) && sortedDays.length < 1) ? "70mm" : "0mm"}}>
              {ticketData.title && ticketData.title.length < 10 ? ticketData.title + " " : ""}
              {ticketData.firstName} {ticketData.lastName}
            </Text>
            
            <Text style={companyTextStyle}>
              {ticketData.company}
            </Text>
            
            <View style={ticketStyles.row}>
              <View style={[ticketStyles.cell]}>
                {qrCode && (ticketData.phone || ticketData.email) && (
                  <Image 
                    style={{ width: "120mm", marginLeft: "60px" }} 
                    src={qrCode} 
                  />
                )}
              </View>
              
              <View style={[ticketStyles.cell, { display: "flex", justifyContent: "center" }]}>
                {sortedDays.map((day, index) => (
                  <Text key={index} style={ticketStyles.daysText}>
                    {new Date(day).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit" })}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TicketPDF;