import { useState, useEffect, useRef } from 'react';
import { pdf } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import VCard from 'vcard-creator';
import shortUUID from 'short-uuid';

/**
 * Hook for handling ticket printing functionality
 * @returns {Object} - Functions and state for ticket printing
 */
export const usePrintTicket = () => {
  const [pdfTicketContent, setPdfTicketContent] = useState(null);
  const [pdfReceiptContent, setPdfReceiptContent] = useState(null);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  
  // Referenz auf das wiederverwendbare iframe
  const printIframeRef = useRef(null);
  
  // iframe erstellen oder wiederverwenden
  const getOrCreatePrintIframe = () => {
    if (!printIframeRef.current) {
      // Erstelle ein neues iframe, wenn noch keines existiert
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.name = 'print-iframe'; // Name für die Identifizierung
      document.body.appendChild(iframe);
      printIframeRef.current = iframe;
    }
    return printIframeRef.current;
  };

  // iframe bereinigen (z.B. beim Unmount der Komponente)
  const cleanupPrintIframe = () => {
    if (printIframeRef.current && document.body.contains(printIframeRef.current)) {
      document.body.removeChild(printIframeRef.current);
      printIframeRef.current = null;
    }
  };

  // Beim Unmount der Komponente das iframe entfernen
  useEffect(() => {
    return () => {
      cleanupPrintIframe();
    };
  }, []);

  /**
   * Generate vCard for ticket contact information
   * @param {Object} ticketData - The ticket data containing contact information
   * @returns {string} - vCard data as string
   */
  const generateVCard = (ticketData) => {
    const vCard = new VCard();
    vCard.addName(ticketData.lastName, ticketData.firstName);
    vCard.addCompany(ticketData.company);
    
    if (ticketData.email) {
      vCard.addEmail(ticketData.email);
    }
    
    if (ticketData.phone) {
      vCard.addPhoneNumber(ticketData.phone);
    }
    
    return vCard.toString();
  };

  /**
   * Generate QR code for ticket
   * @param {Object} ticketData - The ticket data
   * @returns {Promise<string>} - QR code data URL
   */
  const generateQRCode = async (ticketData) => {
    try {
      // For tickets with contact information, generate a vCard QR
      if (ticketData.email || ticketData.phone) {
        return await QRCode.toDataURL(generateVCard(ticketData), { scale: 1 });
      }
      // For receipt, generate an ID QR
      else if (ticketData.id) {
        return await QRCode.toDataURL(shortUUID().fromUUID(ticketData.id), { scale: 1 });
      }
      
      return null;
    } catch (error) {
      console.error('Error generating QR code:', error);
      return null;
    }
  };

  /**
   * Print a PDF document
   * @param {Object} PDFDocument - PDF document to print
   * @returns {Promise<void>}
   */
  const printPdf = async (PDFDocument) => {
    if (!PDFDocument) return;
    
    try {
      // PDF zu Blob konvertieren
      const blob = await pdf(PDFDocument).toBlob();
      const url = URL.createObjectURL(blob);
      
      // Wiederverwendbares iframe holen
      const iframe = getOrCreatePrintIframe();
      
      // URL in iframe laden
      iframe.src = url;
      
      // Warten, bis das iframe geladen ist, dann drucken
      iframe.onload = () => {
        try {
          iframe.contentWindow.print();
          
          // URL freigeben, wenn das iframe geladen wurde
          // Das iframe selbst wird nicht entfernt, sondern wiederverwendet
          setTimeout(() => {
            URL.revokeObjectURL(url);
          }, 1000);
        } catch (err) {
          console.error('Error during print:', err);
        }
      };
    } catch (error) {
      console.error('Error preparing PDF for print:', error);
    }
  };

  // print a pdf from an url
  const printPdfFromUrl = async (url) => {
    try {
      // Wiederverwendbares iframe holen
      const iframe = getOrCreatePrintIframe();
      
      // URL in iframe laden
      iframe.src = url;

      // Warten, bis das iframe geladen ist, dann drucken
      iframe.onload = () => {
        try {
          iframe.contentWindow.print();
        } catch (err) {
          console.error('Error during print:', err);
        }
      };
      
    } catch (error) {
      console.error('Error printing PDF from URL:', error);
    }
  };

  /**
   * Generate PDF content for a ticket
   * @param {Object} ticketData - The ticket data
   * @param {Object} TicketPDFComponent - React component for rendering ticket PDF
   * @returns {Promise<Object>} - PDF document
   */
  const generateTicketPdf = async (ticketData, TicketPDFComponent) => {
    if (!ticketData || !TicketPDFComponent) return null;
    console.log("ticketData", ticketData);
    setIsGeneratingPdf(true);
    try {
      const qrCode = await generateQRCode(ticketData);
      const pdfContent = <TicketPDFComponent ticketData={ticketData} qrCode={qrCode} />;
      setPdfTicketContent(pdfContent);
      return pdfContent;
    } catch (error) {
      console.error('Error generating ticket PDF:', error);
      return null;
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  /**
   * Generate PDF content for a receipt
   * @param {Object} ticketData - The ticket data
   * @param {Object} ReceiptPDFComponent - React component for rendering receipt PDF
   * @returns {Promise<Object>} - PDF document
   */
  const generateReceiptPdf = async (ticketData, ReceiptPDFComponent) => {
    if (!ticketData || !ReceiptPDFComponent) return null;
    
    setIsGeneratingPdf(true);
    try {
      const qrCode = await generateQRCode(ticketData);
      const pdfContent = <ReceiptPDFComponent ticketData={ticketData} qrCode={qrCode} />;
      setPdfReceiptContent(pdfContent);
      return pdfContent;
    } catch (error) {
      console.error('Error generating receipt PDF:', error);
      return null;
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  /**
   * Print a ticket
   * @param {Object} ticketData - The ticket data
   * @param {Object} TicketPDFComponent - React component for rendering ticket PDF
   * @returns {Promise<void>}
   */
  const printTicket = async (ticketData, TicketPDFComponent) => {
    const pdfContent = await generateTicketPdf(ticketData, TicketPDFComponent);
    if (pdfContent) {
      await printPdf(pdfContent);
    }
  };

  /**
   * Print a receipt
   * @param {Object} ticketData - The ticket data
   * @param {Object} ReceiptPDFComponent - React component for rendering receipt PDF
   * @returns {Promise<void>}
   */
  const printReceipt = async (ticketData, ReceiptPDFComponent) => {
    const pdfContent = await generateReceiptPdf(ticketData, ReceiptPDFComponent);
    if (pdfContent) {
      await printPdf(pdfContent);
    }
  };

  return {
    pdfTicketContent,
    pdfReceiptContent,
    isGeneratingPdf,
    generateTicketPdf,
    generateReceiptPdf,
    printTicket,
    printReceipt,
    generateQRCode,
    cleanupPrintIframe,
    printPdfFromUrl
  };
};