import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBSpinner,
  MDBInput,
  MDBInputGroup,
  MDBRow,
  MDBCol,
  MDBSwitch,
  MDBToast,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBIcon,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink
} from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { getLocalizedText } from '../../utils/localizedText';
import { transformMoney } from '../../utils/money';

const client = generateClient();
const ITEMS_PER_PAGE = 10;

export default function EditDiscount() {
  const { discountId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  
  // Existing state
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [discount, setDiscount] = useState({
    code: '',
    name: [],
    discountType: 'fixedAmount',
    discountAmount: '',
    discountPercentage: '',
    maxUsage: '',
    startDate: '',
    endDate: '',
    status: 'active',
    canBeCombined: false,
    restrictedProductIds: []
  });

  // New state for product selection
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [productSearch, setProductSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productPage, setProductPage] = useState(1);
  const [productTotalPages, setProductTotalPages] = useState(0);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  useEffect(() => {
    fetchDiscount();
  }, [discountId]);

  // Load selected products when discount is fetched
  useEffect(() => {
    if (discount.restrictedProductIds?.length > 0) {
      fetchSelectedProducts();
    }
  }, [discount.restrictedProductIds]);

  const fetchSelectedProducts = async () => {
    try {
      const promises = discount.restrictedProductIds.map(productId =>
        client.graphql({
          query: /* GraphQL */ `
            query GetProduct($id: ID!) {
              getProduct(id: $id) {
                id
                sku
                name {
                  language
                  text
                }
                price
              }
            }
          `,
          variables: { id: productId }
        })
      );

      const responses = await Promise.all(promises);
      const products = responses.map(response => response.data.getProduct);
      setSelectedProducts(products);
    } catch (error) {
      console.error('Error fetching selected products:', error);
    }
  };

  const fetchDiscount = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query GetDiscountCode($id: ID!) {
            getDiscountCode(id: $id) {
              id
              code
              name {
                language
                text
              }
              description {
                language
                text
              }
              discountType
              discountAmount
              discountPercentage
              maxUsage
              usageCount
              startDate
              endDate
              status
              canBeCombined
              restrictedProductIds
              createdAt
              updatedAt
            }
          }
        `,
        variables: { id: discountId }
      });

      const discountData = response.data.getDiscountCode;
      
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().slice(0, 16);
      };

      setDiscount({
        ...discountData,
        startDate: formatDate(discountData.startDate),
        endDate: formatDate(discountData.endDate)
      });
    } catch (error) {
      console.error('Error fetching discount:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadProducts = async (page = 1) => {
    setIsLoadingProducts(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchProducts(
            $filter: SearchableProductFilterInput
            $sort: [SearchableProductSortInput]
            $limit: Int
            $nextToken: String
            $from: Int
          ) {
            searchProducts(
              filter: $filter
              sort: $sort
              limit: $limit
              nextToken: $nextToken
              from: $from
            ) {
              items {
                id
                sku
                name {
                  language
                  text
                }
                price
                type
              }
              total
            }
          }
        `,
        variables: {
          limit: ITEMS_PER_PAGE,
          from: (page - 1) * ITEMS_PER_PAGE,
          filter: productSearch ? {
            or: [
              { sku: { match: `${productSearch}*` } },
              { name_text: { wildcard: `${productSearch}*` } }
            ]
          } : null
        }
      });
      
      setProducts(response.data.searchProducts.items);
      setProductTotalPages(Math.ceil(response.data.searchProducts.total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  useEffect(() => {
    if (showAddProductModal) {
      loadProducts(productPage);
    }
  }, [productPage, productSearch, showAddProductModal]);

  const addProduct = (product) => {
    if (!selectedProducts.find(p => p.id === product.id)) {
      setSelectedProducts([...selectedProducts, product]);
      setDiscount(prev => ({
        ...prev,
        restrictedProductIds: [...prev.restrictedProductIds || [], product.id]
      }));
    }
  };

  const removeProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
    setDiscount(prev => ({
      ...prev,
      restrictedProductIds: prev.restrictedProductIds.filter(id => id !== productId)
    }));
  };

  const removeNullValues = (obj) => Object.fromEntries( Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined).map(([key, value]) => [key, typeof value === 'object' && !Array.isArray(value) ? removeNullValues(value) : value] ) );

  const handleSave = async () => {
    try {
      setIsSaving(true);
      let newDiscount = {
        ...removeNullValues(discount),
        startDate: new Date(discount.startDate).toISOString(),
        endDate: new Date(discount.endDate).toISOString()
        };

        delete newDiscount.createdAt; delete newDiscount.updatedAt;

        if (discount.discountType === 'fixedAmount') {
            newDiscount.discountAmount = parseFloat(discount.discountAmount);
        }
        if (discount.discountType === 'percentage') {
            newDiscount.discountPercentage = parseFloat(discount.discountPercentage);
        }
        if (discount.maxUsage) {
            newDiscount.maxUsage = parseInt(discount.maxUsage);
        }


      await client.graphql({
        query: /* GraphQL */ `
          mutation UpdateDiscountCode($input: UpdateDiscountCodeInput!) {
            updateDiscountCode(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: newDiscount
        }
      });

      setShowToast(true);
      setTimeout(() => {
        navigate('/admin/discounts');
      }, 2000);
    } catch (error) {
      console.error('Error updating discount:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <MDBPagination className='mb-0 justify-content-center'>
        <MDBPaginationItem disabled={currentPage === 1}>
          <MDBPaginationLink onClick={() => onPageChange(currentPage - 1)}>
            {t('Previous')}
          </MDBPaginationLink>
        </MDBPaginationItem>

        {[...Array(totalPages)].map((_, index) => (
          <MDBPaginationItem key={index + 1} active={currentPage === index + 1}>
            <MDBPaginationLink onClick={() => onPageChange(index + 1)}>
              {index + 1}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}

        <MDBPaginationItem disabled={currentPage === totalPages}>
          <MDBPaginationLink onClick={() => onPageChange(currentPage + 1)}>
            {t('Next')}
          </MDBPaginationLink>
        </MDBPaginationItem>
      </MDBPagination>
    );
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <MDBSpinner />
      </div>
    );
  }

  return (
    <>
      <MDBCard>
        <MDBCardBody>
        <MDBRow>
        <MDBCol size="12" md="6" className="mb-3">
          <MDBInput
            label={t("Code")}
            value={discount.code}
            onChange={(e) => setDiscount({ ...discount, code: e.target.value })}
          />
        </MDBCol>
        <MDBCol size="12" md="6" className="mb-3">
          <select
            className="form-select"
            value={discount.status}
            onChange={(e) => setDiscount({ ...discount, status: e.target.value })}
          >
            <option value="active">{t("Active")}</option>
            <option value="inactive">{t("Inactive")}</option>
          </select>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" className="mb-4">
          <h5>{t("Name")}</h5>
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg />
            </div>
            <MDBInput
              label={t("Name (German)")}
              value={discount.name.find(n => n.language === 'de')?.text}
              onChange={(e) => {
                const updatedName = discount.name.map(n =>
                  n.language === 'de' ? { ...n, text: e.target.value } : n
                );
                setDiscount({ ...discount, name: updatedName });
              }}
            />
          </MDBInputGroup>
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg />
            </div>
            <MDBInput
              label={t("Name (English)")}
              value={discount.name.find(n => n.language === 'en')?.text}
              onChange={(e) => {
                const updatedName = discount.name.map(n =>
                  n.language === 'en' ? { ...n, text: e.target.value } : n
                );
                setDiscount({ ...discount, name: updatedName });
              }}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" md="6" className="mb-3">
          <select
            className="form-select"
            value={discount.discountType}
            onChange={(e) => setDiscount({ ...discount, discountType: e.target.value })}
          >
            <option value="fixedAmount">{t("Fixed Amount")}</option>
            <option value="percentage">{t("Percentage")}</option>
          </select>
        </MDBCol>
        <MDBCol size="12" md="6" className="mb-3">
          {discount.discountType === 'fixedAmount' ? (
            <MDBInput
              type="number"
              label={t("Discount Amount (€)")}
              value={discount.discountAmount}
              onChange={(e) => setDiscount({ ...discount, discountAmount: e.target.value })}
            />
          ) : (
            <MDBInput
              type="number"
              label={t("Discount Percentage (%)")}
              value={discount.discountPercentage}
              onChange={(e) => setDiscount({ ...discount, discountPercentage: e.target.value })}
            />
          )}
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" md="4" className="mb-3">
          <MDBInput
            type="number"
            label={t("Max Usage")}
            value={discount.maxUsage}
            onChange={(e) => setDiscount({ ...discount, maxUsage: e.target.value })}
          />
          {discount.usageCount > 0 && (
            <small className="text-muted">
              {t("Current usage")}: {discount.usageCount}
            </small>
          )}
        </MDBCol>
        <MDBCol size="12" md="4" className="mb-3">
          <MDBInput
            type="datetime-local"
            label={t("Start Date")}
            value={discount.startDate}
            onChange={(e) => setDiscount({ ...discount, startDate: e.target.value })}
          />
        </MDBCol>
        <MDBCol size="12" md="4" className="mb-3">
          <MDBInput
            type="datetime-local"
            label={t("End Date")}
            value={discount.endDate}
            onChange={(e) => setDiscount({ ...discount, endDate: e.target.value })}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size="12" className="mb-3">
          <MDBSwitch
            checked={discount.canBeCombined}
            onChange={(e) => setDiscount({ ...discount, canBeCombined: e.target.checked })}
            label={t("Can be combined with other discounts")}
          />
        </MDBCol>
      </MDBRow>
          
          {/* Add new section for restricted products */}
          <h5 className="mb-3 mt-4">{t('Restricted Products')}</h5>
          <MDBTable>
            <MDBTableHead>
              <tr>
                <th>{t('SKU')}</th>
                <th>{t('Name')}</th>
                <th>{t('Price')}</th>
                <th></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {selectedProducts.map(product => (
                <tr key={product.id}>
                  <td>{product.sku}</td>
                  <td>{getLocalizedText(product.name)}</td>
                  <td>{transformMoney(product.price)}</td>
                  <td>
                    <MDBBtn 
                      color="danger" 
                      size="sm" 
                      onClick={() => removeProduct(product.id)}
                    >
                      <MDBIcon fas icon="trash" />
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>

          <div className="text-center mb-4">
            <MDBBtn 
              color="primary" 
              onClick={() => setShowAddProductModal(true)}
            >
              <MDBIcon fas icon="plus" className="me-2" />
              {t('Add Product')}
            </MDBBtn>
          </div>

          {/* Existing action buttons */}
          <div className="text-end">
            <MDBBtn 
              color="secondary" 
              className="me-2" 
              onClick={() => navigate('/admin/discounts')}
            >
              {t('Cancel')}
            </MDBBtn>
            <MDBBtn 
              color="primary" 
              onClick={handleSave} 
              disabled={isSaving}
            >
              {isSaving ? <MDBSpinner size="sm" /> : t('Save')}
            </MDBBtn>
          </div>
        </MDBCardBody>
      </MDBCard>

      {/* Product Selection Modal */}
      <MDBModal 
        open={showAddProductModal} 
        toggle={() => setShowAddProductModal(false)}
      >
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t('Add Product')}</MDBModalTitle>
              <MDBBtn 
                className="btn-close" 
                color="none" 
                onClick={() => setShowAddProductModal(false)} 
              />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="mb-4">
                <MDBCol>
                  <MDBInput
                    label={t('Search Product')}
                    value={productSearch}
                    onChange={(e) => setProductSearch(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>{t('SKU')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Price')}</th>
                    <th></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {isLoadingProducts ? (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <MDBSpinner size="sm" />
                      </td>
                    </tr>
                  ) : (
                    products.map(product => (
                      <tr key={product.id}>
                        <td>{product.sku}</td>
                        <td>{getLocalizedText(product.name)}</td>
                        <td>{transformMoney(product.price)}</td>
                        <td>
                          <MDBBtn 
                            color="primary" 
                            size="sm" 
                            onClick={() => addProduct(product)}
                            disabled={selectedProducts.some(p => p.id === product.id)}
                          >
                            {selectedProducts.some(p => p.id === product.id) 
                              ? t('Added') 
                              : t('Add')}
                          </MDBBtn>
                        </td>
                      </tr>
                    ))
                  )}
                </MDBTableBody>
              </MDBTable>
              <Pagination
                currentPage={productPage}
                totalPages={productTotalPages}
                onPageChange={setProductPage}
              />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBToast
        color='success'
        show={showToast}
        autohide
        delay={3000}
        position='top-right'
        appendToBody
      >
        {t('Discount code updated successfully')}
      </MDBToast>
    </>
  );
}