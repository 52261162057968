import React, { useState, useEffect, useContext } from 'react';
import { 
  MDBTabs, 
  MDBTabsItem, 
  MDBTabsLink, 
  MDBTabsContent, 
  MDBTabsPane,
  MDBSelect,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBSwitch
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { generateClient } from 'aws-amplify/api';
import { useSearchParams } from 'react-router-dom';

// Import tab components
import PrintTab from './tabs/Print';
import SearchTab from './tabs/Search';
import BookTicketTab from './tabs/BookTicket';

// Import utility functions
import { getLocalizedText } from '../../../utils/localizedText';
import { isoToGermanDate } from '../../../utils/dateTools';
import { DevContext } from "../../../App";

/**
 * Main PrintTicket component that manages tabs and event selection
 * @returns {JSX.Element} - Component
 */
const PrintTicket = () => {
  const { isKioskMode, setIsKioskMode } = useContext(DevContext);
  const { t } = useTranslation();
  const client = generateClient();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [activeTab, setActiveTab] = useState('print');
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(searchParams.get('eventId'));
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [ticketData, setTicketData] = useState({});

  // Fetch available events on component mount
  useEffect(() => {
    fetchEvents();
  }, []);

  // Update selected event when ID changes
  useEffect(() => {
    if (selectedEventId) {
      const event = events.find(e => e.id === selectedEventId);
      setSelectedEvent(event);
    } else {
      setSelectedEvent(null);
    }
  }, [selectedEventId, events]);

  // Fetch events from API
  const fetchEvents = async () => {
    setIsLoadingEvents(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query ListEvents {
            listEvents {
              items {
                id
                name {
                  text
                  language
                }
                startDate
                endDate
                status
                bookingSelectDays
                hasInvitationCodes
                ticketProduct {
                  id
                  price
                }
                image {
                    fileName
                  }
              }
            }
          }
        `,
      });
      
      const fetchedEvents = response.data.listEvents.items.filter(event => event.status === "active");
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoadingEvents(false);
    }
  };

  // Handle event selection
  const handleEventSelection = (eventId) => {
    setSelectedEventId(eventId);
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      if (eventId) {
        newParams.set('eventId', eventId);
      } else {
        newParams.delete('eventId');
      }
      return newParams;
    });
  };

  // Handle tab change
  const handleTabClick = (tabName) => {
    if (tabName !== activeTab) {
      setActiveTab(tabName);
    }
  };

  // Handle ticket selection from search tab
  const handleSelectTicket = (ticket) => {
    setTicketData(ticket);
  };

  if (!selectedEventId) {
    return (
      <MDBCard className="text-center">
        <MDBCardBody className="py-5">
          <MDBIcon fas icon="ticket-alt" size="3x" className="mb-3 text-primary" />
          <h4 className="mb-3">{t("Please Select an Event")}</h4>
          <p className="text-muted mb-4">{t("Select an event to manage tickets")}</p>
          <div className="w-50 mx-auto">
            <MDBSelect 
              label={t("Event")}
              onChange={event => event && handleEventSelection(event.value)}
              data={[
                { text: t("Select an event..."), value: "" },
                ...events.map(event => ({ 
                  text: `${getLocalizedText(event.name)}: ${event.startDate && isoToGermanDate(event.startDate)} - ${event.endDate && isoToGermanDate(event.endDate)}`, 
                  value: event.id 
                }))
              ]}
              value={selectedEventId || ""}
            />
          </div>
        </MDBCardBody>
      </MDBCard>
    );
  }

  return (
    <>
      <MDBCard className="mb-4">
        <MDBCardBody>
          <MDBRow>
            <MDBCol size="12" md="6" xl="3">
              <MDBSelect
                label={t("Event")}
                onChange={event => event && handleEventSelection(event.value)}
                value={selectedEventId}
                data={[
                  { text: t("Select an event..."), value: "" },
                  ...events.map(event => ({
                    text: `${getLocalizedText(event.name)}: ${event.startDate && isoToGermanDate(event.startDate)} - ${event.endDate && isoToGermanDate(event.endDate)}`,
                    value: event.id
                  }))
                ]}
              />
              
            </MDBCol>
            <MDBCol className="float-end" size="12" md="6" xl="9">
            <div className="float-end">
              <MDBSwitch
              label="Kiosk Mode"
              checked={isKioskMode}
              onChange={() => setIsKioskMode(!isKioskMode)}
              />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>

      {isLoadingEvents ? (
        <div className="text-center my-5">
          <MDBSpinner />
          <p className="mt-2">{t("Loading events...")}</p>
        </div>
      ) : (
        <>
          <MDBTabs className='mb-3'>
            <MDBTabsItem>
              <MDBTabsLink 
                onClick={() => handleTabClick('print')} 
                active={activeTab === 'print'}
              >
                {t("Print Tickets")}
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink 
                onClick={() => handleTabClick('search')} 
                active={activeTab === 'search'}
              >
                {t("Search Tickets")}
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink 
                onClick={() => handleTabClick('book')} 
                active={activeTab === 'book'}
              >
                {t("Book tickets")}
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane open={activeTab === 'print'}>
              <PrintTab 
                selectedEvent={selectedEvent} 
                initialTicketData={ticketData}
              />
            </MDBTabsPane>
            
            <MDBTabsPane open={activeTab === 'search'}>
              <SearchTab 
                selectedEvent={selectedEvent} 
                onSelectTicket={handleSelectTicket}
                onChangeTab={handleTabClick}
              />
            </MDBTabsPane>
            
            <MDBTabsPane open={activeTab === 'book'}>
              <BookTicketTab
              selectedEvent={selectedEvent}
              isKioskMode={isKioskMode}
              />
            </MDBTabsPane>
          </MDBTabsContent>
        </>
      )}
    </>
  );
};

export default PrintTicket;