import { useContext, useState, useEffect } from "react"
import { UserContext } from "../App"
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBCardText, MDBCheckbox, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBBtn, MDBModalBody, MDBModalFooter, MDBCardImage, MDBDropdownItem, MDBSpinner } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { signOut } from "aws-amplify/auth";

import EditProfile from "../components-user/EditProfile";
import EditEmployeeProfile from "../components-user/employee-profile/EditEmployeeProfile";
import EditCompanyProfile from "../components-user/manage-company/Profile";
import EditCompanyBilling from "../components-user/manage-company/Billing";
import EditCompanyEmployees from "../components-user/manage-company/Persons";
import EmploymentRequests from "./dashboard/EmploymentRequests";
import { Link } from "react-router-dom";
import { getLocalizedText } from "../utils/localizedText";
import { useIndustry } from "../utils/industryContextHook";
import ShowTicket from "../components-user/tickets/ShowTicket";

const client = generateClient();

function isoToGermanDate(isoString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Format the date as DD.MM.YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export default function Dashboard() {
    const { t, i18n } = useTranslation();
    const { user, setUserCompletedOnboarding} = useContext(UserContext);
    const { currentIndustry} = useIndustry();

    // Onboarding Modal
    const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
    const toggleOnboardingModal = () => setOpenOnboardingModal(!openOnboardingModal);
    const [savingStep, setSavingStep] = useState(false);
    const [activeOnboardingStep, setActiveOnboardingStep] = useState("personalProfile");
    const [completedOnboardingSteps, setCompletedOnboardingSteps] = useState({
        personalProfile: false,
        companyProfile: false,
        employeeProfile: false
    });
    const [toggleSaveOnboardingSteps, setToggleSaveOnboardingSteps] = useState({
        personalProfile: false,
        companyProfile: false,
        employeeProfile: false
    });

    // Ticket Modal
    const [showTicketModal, setShowTicketModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [loadingTickets, setLoadingTickets] = useState(false);
    
    const toggleTicketModal = () => setShowTicketModal(!showTicketModal);
   

    const updateUserCompletedOnboarding = async () => {
        try {
            const userRes = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateUser(
                  $input: UpdateUserInput!
                  $condition: ModelUserConditionInput
                ) {
                  updateUser(input: $input, condition: $condition) {
                    id
                    hasCompletedOnboarding
                  }
                }
              `,
                variables: { input: {
                    id: user.id,
                    hasCompletedOnboarding: true
                } }
            });
        setUserCompletedOnboarding(true);
        } catch (error) {
            console.warn("error updating user", error);
        }
    }

    useEffect(() => {
        if(completedOnboardingSteps.personalProfile
        && completedOnboardingSteps.employeeProfile
        && completedOnboardingSteps.companyProfile) {
            updateUserCompletedOnboarding()
            window.scrollTo(0, 0);
        }
    }, [completedOnboardingSteps])
    
    
    const handleChangeComplete = (section) => {
        setCompletedOnboardingSteps({
            ...completedOnboardingSteps,
            [section]: true
        });
        setToggleSaveOnboardingSteps({
            ...toggleSaveOnboardingSteps,
            [section]: false
        })
        window.scrollTo(0, 0);
        setSavingStep(false);
    }

    const handleClickNextButton = () => {
        if(completedOnboardingSteps.personalProfile === false) {
            setToggleSaveOnboardingSteps({
                ...toggleSaveOnboardingSteps,
                personalProfile: true
            });
            setSavingStep(true);

        }
        if(completedOnboardingSteps.personalProfile === true && completedOnboardingSteps.companyProfile === false) {
            setToggleSaveOnboardingSteps({
                ...toggleSaveOnboardingSteps,
                companyProfile: true
            });
            setSavingStep(true);

        }
        if(completedOnboardingSteps.personalProfile === true && completedOnboardingSteps.employeeProfile === false && completedOnboardingSteps.companyProfile === true) {
            setToggleSaveOnboardingSteps({
                ...toggleSaveOnboardingSteps,
                employeeProfile: true
            });
            setSavingStep(true);
            
        }
    }

    const handleClickPersonalProfile = () => {
        setCompletedOnboardingSteps({
            ...completedOnboardingSteps,
            personalProfile: true
        });
    }

    const [news, setNews] = useState([]);
    const [loadingNews, setLoadingNews] = useState(false);

    const fetchNews = async () => {
        setLoadingNews(true);
        try {
            const newsData = await client.graphql({
                query: /* GraphQL */ `
                query ListCMSNews(
                  $filter: ModelCMSNewsFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listCMSNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        type
                        approved
                        status
                        createdAt
                        image {
                            fileName
                            alt
                        }
                        name {
                            language
                            text
                        }
                        excerpt {
                            language
                            text
                        }
                        __typename
                  }
                }
            }
              `,
                variables: { 
                    limit: 10,
                    filter: {
                        approved: { eq: true },
                        status: { eq: "checked" }
                    }
                }
            });
            const newsList = newsData.data.listCMSNews.items
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setNews(newsList);
        } catch (error) {
            console.log('error on fetching news', error);
        } finally {
            setLoadingNews(false);
        }
    };

    const [events, setEvents] = useState([]);
    const [userTickets, setUserTickets] = useState([]);

    // Fetch user tickets
    const fetchUserTickets = async () => {
        if (!user.id) return;
        
        setLoadingTickets(true);
        try {
            const ticketsData = await client.graphql({
                query: /* GraphQL */ `
                query GetUser($id: ID!) {
                  getUser(id: $id) {
                    tickets {
                      items {
                        id
                        ticketEventId
                        status
                        paymentStatus
                        eventDays
                        event {
                          id
                          name {
                            language
                            text
                          }
                          startDate
                          endDate
                        }
                      }
                    }
                  }
                }
                `,
                variables: { id: user.id }
            });
            
            const ticketsList = ticketsData.data.getUser.tickets.items;
            console.log('User tickets', ticketsList);
            setUserTickets(ticketsList);
        } catch (error) {
            console.log('Error fetching user tickets', error);
        } finally {
            setLoadingTickets(false);
        }
    };

    const fetchEvents = async (currentIndustry) => {
        try {
            const eventData = await client.graphql({
                query: /* GraphQL */ `
                query ListIndustryEvents(
                  $filter: ModelIndustryEventsFilterInput
                  $limit: Int
                ) {
                  listIndustryEvents(filter: $filter, limit: $limit) {
                    items {
                    event {
                      id
                      name {
                        language
                        text
                        }
                      image {
                        fileName
                        alt
                      }
                      location {
                        address {
                            city
                        }
                      }
                      status
                      date
                      startDate
                      endDate
                      enableTicketBooking
                      landingPageUrl
                      x4Id
                      createdAt
                      updatedAt
                      __typename
                    }
                    __typename
                  }
                }
                }
              `,
                variables: { filter: { industryId: { eq: currentIndustry.id }} }
            });
            const eventList = eventData.data.listIndustryEvents.items.map(e => e.event)
            .filter(e => {
                if (e.status !== "active" || !e.endDate) return false;
                const endDate = new Date(e.endDate);
                endDate.setHours(23, 59, 59, 999);
                return endDate >= new Date();
            })
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            console.log('event list', eventList);
            setEvents(eventList);
        } catch (error) {
            console.log('error on fetching events', error);
        }
    }

    // Helper function to check if user has a ticket for an event
    const hasTicketForEvent = (eventId) => {
        return userTickets.some(ticket => 
            ticket.event.id === eventId && 
            ticket.status !== "cancelled" && 
            ticket.paymentStatus !== "refunded"
        );
    };

    // Handler to show ticket
    const handleShowTicket = (eventId) => {
        const ticket = userTickets.find(t => t.event.id === eventId);
        if (ticket) {
            setSelectedTicket(ticket);
            setShowTicketModal(true);
        }
    };

    // Handler to delete ticket
    const handleDeleteTicket = () => {
        // Remove the ticket from the local state
        setUserTickets(userTickets.filter(t => t.id !== selectedTicket.id));
        setShowTicketModal(false);
        // No need to reload the tickets as we've already updated the state
    };

    const [todos, setTodos] = useState([]);

    const fetchTodos = async () => {
        try {
            const todoData = await client.graphql({
                query: /* GraphQL */ `
                query ListToDos(
                  $filter: ModelToDoFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listToDos(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                            id
                            user {
                                id
                            }
                            company {
                                id
                                name
                                image {
                                    main {
                                        fileName
                                        alt
                                    }
                                }
                            }
                            title
                            description
                            status
                            dueDate
                            startDate
                            priority
                            createdBy {
                                id
                            }
                            createdAt
                            updatedAt
                            __typename
                        }
                    }
                }
                `,
                variables: { filter: { 
                    or : [
                        { userTodosId: { eq: user.id } },
                        ...user.employeeProfiles?.items?.map(e => ({ companyTodosId: { eq: e.company.id } }))
                    ]
                 } }
            });
            const todoList = todoData.data.listToDos.items.filter(t => t.company.id).sort((a, b) => a.priority - b.priority);;
            console.log('todo list', todoList);
            setTodos(todoList);
        } catch (error) {
            console.log('error on fetching todos', error);
        }
    }

    // Update Todo in backend when checked or unchecked
    const updateTodo = async (todo) => {
        try {
            const todoData = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateToDo(
                  $input: UpdateToDoInput!
                  $condition: ModelToDoConditionInput
                ) {
                  updateToDo(input: $input, condition: $condition) {
                        id
                        user {
                            id
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        title
                        description
                        status
                        dueDate
                        priority
                        createdBy {
                            id
                        }
                        createdAt
                        updatedAt
                        __typename
                    }
                }
                `,
                variables: {
                    input: {
                        id: todo.id,
                        status: todo.status
                    }
                }
            });
            const todoList = [...todos];
            const todoIndex = todoList.findIndex(t => t.id === todo.id);
            todoList[todoIndex] = todoData.data.updateToDo.sort((a, b) => a.priority - b.priority);
            setTodos(todoList);
        } catch (error) {
            console.log('error on updating todo', error);
        }
    }

    const handleCheckTodo = (i) => {
        const newTodos = [...todos];
        newTodos[i].status = newTodos[i].status === "completed" ? "open" : "completed";
        setTodos(newTodos);
        updateTodo({
            id: newTodos[i].id,
            status: newTodos[i].status
        });
    }

    useEffect(() => {
        if (user && todos.length === 0) {
            fetchTodos();
        }
    }, [user]);
    
    useEffect(() => {
        if (user && user.id) {
            fetchUserTickets();
        }
    }, [user]);
            
    useEffect(() => {
        if(currentIndustry) {
            fetchEvents(currentIndustry);
            fetchNews();
        }
    }, [currentIndustry]);

  return <>
  <span data-hj-allow className="float-end text-muted" style={{marginTop: "-50px"}}>{user.username}</span>
    <MDBRow>
        <MDBCol size="12" md="12">
            <h4>Willkommen bei {getLocalizedText(currentIndustry?.name)}!</h4>
        </MDBCol>
        <EmploymentRequests />
        <MDBCol size="12" md="12">
            <MDBRow>
            {
                todos && todos.length > 0 &&
                <MDBCol size="12" className="mt-2 mt-md-0 px-0">
                <h5>{t("To do's")}</h5>
                    <MDBCard>
                        <MDBCardBody>
                            
                            <MDBRow>
                                {todos && todos.length > 0 && todos.map((todo, i) => (
                                    <MDBCol size="12" key={i}>
                                        <MDBCard className="mb-2 bg-opacity-10" background={todo.status === "completed" ? "success" : todo.startDate ? "grey" : "danger"}>
                                            <MDBCardBody className="p-2">
                                            <MDBRow>
                                            <MDBCol size="10">
                                                <MDBCardText>{todo.startDate ? "Ab " +  isoToGermanDate(todo.startDate) + " - " : ""}{todo.title}</MDBCardText>
                                                </MDBCol>
                                                <MDBCol size="2">
                                                {!todo.startDate && <MDBCheckbox checked={todo.status === "completed"} onChange={() => handleCheckTodo(i)} />}
                                                </MDBCol>
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            }
                
                <MDBCol size="12" className="mt-2 mt-md-3">
                <h5>{t("Upcoming Events")}</h5>
                    {loadingTickets ? (
                        <div className="text-center my-3">
                            <MDBSpinner role="status">
                                <span className="visually-hidden">{t("Loading")}</span>
                            </MDBSpinner>
                        </div>
                    ) : (
                        <MDBRow>
                            {events && events.length > 0 && events.map((event, i) => (
                                <MDBCol size="12" md="8" xl="3" key={i}>
                                    <MDBCard className="mb-2">
                                        <a href={event.landingPageUrl} target="_blank" rel="noreferrer">
                                            {event.image?.fileName && <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.image.fileName} position='top' alt='...' />}
                                        </a>
                                        <MDBCardBody>
                                            <MDBCardTitle>{getLocalizedText(event.name)}</MDBCardTitle>
                                            <MDBCardText>
                                                {isoToGermanDate(event.startDate)} - {isoToGermanDate(event.endDate)}<br></br>
                                                {event.location?.address?.city}
                                            </MDBCardText>
                                            {event.enableTicketBooking && (
                                                hasTicketForEvent(event.id) ? (
                                                    <MDBBtn color="success" onClick={() => handleShowTicket(event.id)}>
                                                        {t("Show ticket")}
                                                    </MDBBtn>
                                                ) : (
                                                    <Link to={"/book-ticket?eventId=" + event.id}>
                                                        <MDBBtn color="primary">{t("Book ticket")}</MDBBtn>
                                                    </Link>
                                                )
                                            )}
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    )}
                </MDBCol>
                
                {/* News Section */}
                {/* <MDBCol size="12" className="mt-4">
                    <h5>{t("News")}</h5>
                    {loadingNews ? (
                        <div className="text-center my-3">
                            <MDBSpinner role="status">
                                <span className="visually-hidden">{t("Loading")}</span>
                            </MDBSpinner>
                        </div>
                    ) : (
                        <MDBRow>
                            {news && news.length > 0 ? news.map((newsItem, i) => (
                                <MDBCol size="12" md="4" key={i}>
                                    <MDBCard className="mb-2">
                                        <MDBCardBody>
                                            <Link to={`/news/${newsItem.id}`} className="text-reset">
                                            <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/newsImages/" + newsItem.image.fileName} position='top' alt={newsItem.image.alt} />
                                                <MDBCardTitle>{getLocalizedText(newsItem.name)}</MDBCardTitle>
                                                <MDBCardText>
                                                    {isoToGermanDate(newsItem.createdAt)}
                                                </MDBCardText>
                                            </Link>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            )) : (
                                <MDBCol>
                                    <p className="text-muted">{t("No news available")}</p>
                                </MDBCol>
                            )}
                        </MDBRow>
                    )}
                </MDBCol> */}
            </MDBRow>
        </MDBCol>
    </MDBRow>

{/* Ticket Modal */}
<MDBModal open={showTicketModal} onClose={toggleTicketModal}>
    <MDBModalDialog centered>
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Your Ticket")}</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={toggleTicketModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                {selectedTicket && (
                    <ShowTicket 
                        inModal={true} 
                        ticketId={selectedTicket.id} 
                        handleDeleteTicket={handleDeleteTicket} 
                    />
                )}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

{/* Onboarding Modal */}
<MDBModal staticBackdrop open={openOnboardingModal} setOpen={setOpenOnboardingModal}> 
  <MDBModalDialog centered size="fullscreen">
      <MDBModalContent style={{backgroundColor: "#fafafa"}}>
          <MDBModalHeader>

              <MDBModalTitle>Willkommen bei Cleanroom Processes!</MDBModalTitle>
                <MDBBtn onClick={() => signOut()}>Logout</MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            {
                completedOnboardingSteps.personalProfile === false &&
                <>
                <p>Wir freuen uns, dich auf unserer Plattform zu begrüßen.</p>
                <p className="fw-bold">
                Bitte vervollständige dein Profil.
                </p>
                <div className="mx-xxl-5">
                    <EditProfile presetUserId={user.id} handleChangeComplete={handleChangeComplete} toggleSaveChanges={toggleSaveOnboardingSteps.personalProfile} />
                </div>
                </>
            }
            {
                completedOnboardingSteps.personalProfile === true
                && completedOnboardingSteps.employeeProfile === false
                && completedOnboardingSteps.companyProfile === false
                && user.employeeProfiles?.items?.length > 0 && user.employeeProfiles?.items?.[0].role === "admin"
                
                &&
                <>
                <p className="fw-bold">
                Du wurdest als Administrator eines Unternehmens angegeben. Bitte überprüfe die Angaben deines Unternehmens:
                </p>
                {/* <p>Du bist nicht der Administrator dieses Unternehmens? Klicke hier.</p> */}
                <div className="mx-xxl-5">

                        <EditCompanyProfile handleChangeComplete={handleChangeComplete} toggleSaveChanges={toggleSaveOnboardingSteps.companyProfile} isActive={true} presetCompanyId={user.employeeProfiles.items[0].company?.id} />

                    <div className="mt-3">
                    <h5>Rechnungsstellung</h5>
                    <EditCompanyBilling toggleSaveChanges={toggleSaveOnboardingSteps.companyProfile} isActive={true} presetCompanyId={user.employeeProfiles.items[0].company?.id} />
                    </div>
                    
                    <EditCompanyEmployees isOnboarding={true} toggleSaveChanges={toggleSaveOnboardingSteps.companyProfile} isActive={true} presetCompanyId={user.employeeProfiles.items[0].company?.id} />

                </div>
                </>
            }
            {
                completedOnboardingSteps.personalProfile === true 
                && completedOnboardingSteps.companyProfile === true 
                && completedOnboardingSteps.employeeProfile === false
                && user.employeeProfiles?.items?.length > 0
                &&
                <>
                <p className="fw-bold">
                Wir haben zu deinem Account ein Mitarbeiterprofil gefunden. Mit deinem Mitarbeiterprofil stellst du dich im Gegensatz zu deinem persönlichen Profil als Mitarbeiter des Unternehmens vor. Du kannst mehrere Mitarbeiterprofile besitzten, solltest du mehrere Firmen vertreten.
                Bitte überprüfe deine Angaben:
                </p>
                <div className="mx-xxl-5">
                <MDBCard className="mx-xxl-5">
                    <MDBCardBody className="px-3 px-lg-5 mx-xxl-5">
                        <EditEmployeeProfile isOnboarding={true} handleChangeComplete={handleChangeComplete} toggleSaveChanges={toggleSaveOnboardingSteps.employeeProfile} employeeId={user.employeeProfiles?.items?.[0].id} isActive={true} />
                    </MDBCardBody>
                </MDBCard>
                    
                </div>
                </>
            }
          </MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            {
                savingStep ? 
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                :
                <MDBBtn color="success" className="mb-3" onClick={() => handleClickNextButton()}>{t("Save")}</MDBBtn>
            }
           
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
</>  
}