import { useState, useEffect, useContext, useRef, useLayoutEffect } from "react"
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBRow, MDBStepper, MDBStepperStep, MDBTypography, MDBValidationItem, MDBAlert } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { UserContext } from "../../../App";
import { isoToGermanDate } from "../../../utils/dateTools";
import getEventDays from "../../../utils/getEventDays";
import { getEvent as getEventQuery, listTickets } from "../../../graphql/queries";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../../utils/debounce";
import EventAndTicket from "./book-ticket-components/EventAndTicket";
import Visit from "./book-ticket-components/Visit";
import PersonalInformation from "./book-ticket-components/PersonalInformation";
import Confirmation from "./book-ticket-components/Confirmation";
import Success from "./book-ticket-components/Success";
import { useIndustry } from "../../../utils/industryContextHook";
import { logError } from "../../../utils/logging";

import { createTicket as createTicketMutation } from "../../../graphql/mutations";
import { updateUser as updateUserMutation } from "../../../graphql/mutations";

import { fullAddressSchema, visitSchema } from "../../../validation/schemas/generic";
import { hotjar } from "react-hotjar";
import { fetchAuthSession } from "aws-amplify/auth";

async function getIdToken() {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  console.log("idToken", idToken)
  return idToken;
}

export default function BookTicket() {
  const { currentIndustry } = useIndustry();
  const { user } = useContext(UserContext);
  const client = generateClient();
  const { t, i18n } = useTranslation()
  const [hasExistingTicket, setHasExistingTicket] = useState(false);
  const [isCheckingTicket, setIsCheckingTicket] = useState(true);
  const [isCreatingTicket, setIsCreatingTicket] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [event, setEvent] = useState(null);
  const [eventDays, setEventDays] = useState([]);
  const [params, setParams] = useState({
    ticketType: "default",
    selectedDays: [],
    vatInfo: {
      vatNumber: ""
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const checkExistingTicket = async () => {
    if (!user?.id || !params.eventId) return;
    
    try {
      const ticketsResponse = await client.graphql({
        query: /* GraphQL */ `
        query GetUser($id: ID!, $ticketEventId: ID!) {
          getUser(id: $id) {
                tickets(filter: {ticketEventId: {eq: $ticketEventId}}) {
                  items {
                    id
                  }
                }
              }
          }
        `,
        variables: {
          id: user.id,
          ticketEventId: params.eventId
        }
      });
      if(ticketsResponse.data.getUser.tickets.items.length > 0) {
        setHasExistingTicket(true);
      }
    } catch (error) {
      console.error("Error checking existing tickets:", error);
    } finally {
      setIsCheckingTicket(false);
    }
  };
 

  // User selections
  const [taxRate, setTaxRate] = useState(19);
  const [customerType, setCustomerType] = useState("consumer");
  const [customerTypeSetByUser, setCustomerTypeSetByUser] = useState(false);
  const [personalAddress, setPersonalAddress] = useState({});
  const [personalContact, setPersonalContact] = useState({
    phone: "",
    email: ""
  });
  const [useProfileAddressForBilling, setUseProfileAddressForBilling] = useState(true);
  const [billingAddress, setBillingAddress] = useState({});

  // Detect Mobile Device
  const [isMobile, setIsMobile] = useState(false);
  useLayoutEffect(() => {
    const updateSize = () => {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    // Set Tax rate, if customer is business - if country is Germany, set 19% tax rate, otherwise 0%. country is personalAddress.countryCode or billingAddress.countryCode if useProfileAddressForBilling is false
    if((personalAddress.company || billingAddress.company) && !customerTypeSetByUser) {
      console.log("customerTypeSetByUser",customerTypeSetByUser)
      setCustomerType("business");
    }
    if(customerType === "business") {
      const country = useProfileAddressForBilling ? personalAddress.countryCode : billingAddress.countryCode;
      if(country === "DE") {
        setTaxRate(19);
      } else {
        setTaxRate(0);
      }
    } else {
      // If customer is private and country is in EU, set 19% tax rate, otherwise 0%
      const country = useProfileAddressForBilling ? personalAddress.countryCode : billingAddress.countryCode;
      const euCountries = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
      if(euCountries.includes(country)) {
        setTaxRate(19);
      } else {
        setTaxRate(0);
      }
    }
  }, [customerType, personalAddress, billingAddress, useProfileAddressForBilling]);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [defaultStep, setDefaultStep] = useState(1);

  const handleClickNext = () => {
    nextRef.current.click();
  }

  const handleClickPrev = () => {
    prevRef.current.click();
  }

  useEffect(() => {
    if(user) {
      setPersonalAddress({
        salutation: user.salutation,
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        street: user.contact?.address?.street || "",
        streetNumber: user.contact?.address?.streetNumber || "",
        zip: user.contact?.address?.zip || "",
        city: user.contact?.address?.city || "",
        countryCode: user.contact?.address?.countryCode || "",
        company: user.contact?.address?.company || ""
      });
      
      // Initialize personalContact with user data if available
      setPersonalContact({
        phone: user.contact?.phone || "",
        email: user.contact?.email || user.email || ""
      });
    }
  }, [user]);

  // URL parameters
  const [paramsInitialized, setParamsInitialized] = useState(false);
  const [routerParams, setRouterParams] = useSearchParams();
  const extractExistingParams = (searchParams) => {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
      if (value === 'true') {
        acc[key] = true;
      } else if (value === 'false') {
        acc[key] = false;
      } else if (key === 'selectedDays' && value) {
        // Only process selectedDays if it has a value
        acc[key] = value.split(",").filter(day => day); // Filter to remove empty strings
      } else if (value) {
        // Only add params that have a value
        acc[key] = value;
      }
      return acc;
    }, {});
  }
  
  useEffect(() => {
    console.log("setParams", extractExistingParams(routerParams));
    if (!paramsInitialized) {
      let paramsQuery = {
        ...extractExistingParams(routerParams),
      };
      
      // Ensure selectedDays is always an array
      if (!paramsQuery.selectedDays) {
        paramsQuery.selectedDays = [];
      }
      
      setParams(old => ({...old, ...paramsQuery}));
      setParamsInitialized(true);
    }
  }, [routerParams]);
  // Update URL parameters when user selections change
  useEffect(() => {
    console.log("setRouterParams", params);
    if (paramsInitialized) {
      // Create a clean object with only the parameters we want to show in URL
      const urlParams = {};
      
      // Add ticketType
      if (params.ticketType) {
        urlParams.ticketType = params.ticketType;
      }
      
      // Only add selectedDays if there are any days selected
      if (params.selectedDays && params.selectedDays.length > 0) {
        urlParams.selectedDays = params.selectedDays.join(",");
      }
      
      // Add other necessary params but exclude complex objects like vatInfo
      if (params.eventId) urlParams.eventId = params.eventId;
      if (params.boothId) urlParams.boothId = params.boothId;
      if (params.invitationCode) urlParams.invitationCode = params.invitationCode;
      if (params.orderNumber) urlParams.orderNumber = params.orderNumber;
      if (params.companyId) urlParams.companyId = params.companyId;
      if (params.adminId) urlParams.adminId = params.adminId;
      
      // Never add complex objects like vatInfo to URL
      
      setRouterParams(urlParams);
    }
  }, [params, paramsInitialized]);

  useEffect(() => {
    if (paramsInitialized) {
      checkExistingTicket();
    }
  }, [user?.id, params.eventId, paramsInitialized]);

  // Fetch event
  const getEvent = async () => {
      if(params.eventId) {
          try {
            const eventData = await client.graphql({
              query: /* GraphQL */ `
              query GetEvent($id: ID!) {
                getEvent(id: $id) {
                  id
                  type
                  name {
                    language
                    text
                  }
                  description {
                    language
                    text
                  }
                  bookingNotice {
                    language
                    text
                  }
                  status
                  image {
                    fileName
                    alt
                  }
                  date
                  enableTicketBooking
                  hasInvitationCodes
                  suggestedInvitationCode
                  bookingSelectDays
                  startDate
                  endDate
                  startTime
                  endTime
                  location {
                    name {
                      language
                      text
                      __typename
                    }
                    address {
                      street
                      street2
                      streetNumber
                      company
                      contactPerson
                      city
                      zip
                      countryCode
                      __typename
                    }
                    coordinates {
                      lat
                      lng
                      __typename
                    }
                    __typename
                  }
                  ticketProduct {
                    id
                    name {
                      language
                      text
                    }
                    price
                    image {
                      main {
                        fileName
                      }
                    }
                  }
                  badgePreview {
                    fileName
                  }
                  bookingTicketBulletPoints {
                    text {
                      language
                      text
                    }
                  }
                  floorPlan {
                    url
                    s3Path
                    fileName
                    type
                    name
                    __typename
                  }
                  landingPageUrl
                  ticketTAC {
                    language
                    text
                  }
                  ticketPrivacyPolicy {
                    language
                    text
                  }
                }
              }
            `,
              variables: { id: params.eventId } });
              setEvent(eventData.data.getEvent);
              setEventDays(getEventDays(eventData.data.getEvent));
              if(!eventData.data.getEvent.bookingSelectDays) {
                setParams(old => ({...old, selectedDays: getEventDays(eventData.data.getEvent)}));
              }
          } catch (error) {
            console.error(error);
            
          }
      }
  }
  useEffect(() => {
      getEvent().then(() => setTimeout(() => {
        setIsLoading(false)
      }, 1000))
  }, [params.eventId]);


  const [orderDiscounts, setOrderDiscounts] = useState([]);

  const placeOrder = async (ticket) => {
    const idToken = await getIdToken();
    try {
      
        const productsForOrder = [{
            id: event.ticketProduct.id,
            ticketId: ticket.id,
            qty: 1,
            discount: 0,
            discountType: "",
            taxRate
        }];


        const totalAmount = [event?.ticketProduct].reduce((acc, product) => {
            return acc + (product.qty && product.qty > 1 ? product.price * product.qty : product.price);
        }, 0);

        let data = {};
        if(params.boothId) {
            data.eventBoothId = params.boothId;
        }
        if(params.eventId) {
            data.eventId = params.eventId;
        }
        if(orderDiscounts.length > 0) {
            data.discounts = orderDiscounts
        }

        const response = await fetch('https://y3vayhw2i5bqrm6fpwqz7fzfwe.appsync-api.eu-central-1.amazonaws.com/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': idToken
            },
            body: JSON.stringify({
                query: /* GraphQL */ `
                mutation PlaceOrder(
                  $order: String
                ) {
                  placeOrder(
                    order: $order
                  )
                }
              `,
                variables: {
                    order: JSON.stringify({
                        type: "ticket",
                        industryOrdersId: currentIndustry.id,
                        buyerOrderNumber: params.orderNumber ? (params.orderNumber === "not required" ? "" : params.orderNumber) : null,
                        notes: params.notes || null,
                        deliveryAddress: personalAddress,
                        billingAddress: useProfileAddressForBilling ? personalAddress : billingAddress,
                        billingEmail: params.billingEmail  ? (params.billingEmail === "not required" ? "" : params.billingEmail) : null,
                        products: productsForOrder,
                        isBusiness: customerType === "business",
                        vatInfo: params.vatInfo?.vatNumber ? params.vatInfo : null,
                        currency: "EUR",
                        totalAmount: totalAmount + (totalAmount * taxRate / 100),
                        invoiceAmount: totalAmount + (totalAmount * taxRate / 100),
                        invoiceAmountNet: totalAmount,
                        taxRate,
                        paymentProvider: "invoice",
                        ...(params.companyId && { companyOrdersId: params.companyId }),
                        ...(params.adminId && { adminOrdersId: params.adminId }),
                        ...(params.notes && { notes: params.notes }),
                        ...(Object.keys(data).length > 0 && { data: JSON.stringify(data) }),
                    })
                }
            })
        });
    
        const responseData = await response.json();
        hotjar.event("Order placed");
        return responseData.data.placeOrder;
        

    } catch (error) {
        console.log("error", error)
        hotjar.event("Error creating order");
    }
}
  // Create ticket booking
  const createTicket = async () => {
    if(!user.id) {
      hotjar.event("Book ticket without user data");
    }
      try {
        setIsCreatingTicket(true);
        const ticketData = await client.graphql({
          query: /* GraphQL */ `
          mutation CreateTicket(
            $input: CreateTicketInput!
            $condition: ModelTicketConditionInput
          ) {
            createTicket(input: $input, condition: $condition) {
              id
              type
              invitationCode
              orderNumber
              billingEmail
              createdAt
              updatedAt
              eventDays
              userTicketsId
              ticketEventId
              ticketCompanyId
              ticketPaymentId
              __typename
            }
          }
        `,
          variables: {
            input: {
              industryId: currentIndustry.id,
              type: params.ticketType,
              userTicketsId: user.id,
              createdByUserId: user.id,
              addons: params.createCompanyProfile ? ["company-profile"] : [], 
              eventDays: params.selectedDays, 
              ticketEventId: params.eventId,
              invitationCode: params.invitationCode,
              personalAddress: personalAddress,
              personalContact: personalContact,
              firstName: personalAddress.firstName,
              lastName: personalAddress.lastName,
              companyName: personalAddress.company,
              billingAddress: useProfileAddressForBilling ? null : billingAddress
            }
          }
        });
        console.log("Created Ticket");
        //console.log(ticketData);
        if(event?.ticketProduct?.price > 0) {
        await placeOrder(ticketData.data.createTicket);
        }
        setTicketId(ticketData.data.createTicket.id);
        setIsCreatingTicket(false);
      } catch (error) {
        console.error(error);
        logError({message: "Error creating ticket", userId: user.id, additionalData: {error}});
        setErrors(old => [...old, t("Unable to create ticket. Please contact support.")]);
        throw error;
      }
  }

  const updateUserPersonalAddress = async () => {
    try {
      const personalAddressForUser = {...personalAddress};
      delete personalAddressForUser.salutation;
      delete personalAddressForUser.title;
      delete personalAddressForUser.firstName;
      delete personalAddressForUser.lastName;

      const userData = await client.graphql({
        query: updateUserMutation,
        variables: {
          input: {
            id: user.id,
            contact: {
              address: personalAddressForUser,
              phone: personalContact.phone,
              email: personalContact.email
            }
          }
        }
      });
      console.log("Updated User Address and Contact")
      //console.log(userData);
    } catch (error) {
      console.error(error);
    }
  }

  const hasUserPersonalAddressChanged = () => {
    return user.contact?.address.street !== personalAddress.street ||
    user.contact?.address.streetNumber !== personalAddress.streetNumber ||
    user.contact?.address.zip !== personalAddress.zip ||
    user.contact?.address.city !== personalAddress.city ||
    user.contact?.address.countryCode !== personalAddress.countryCode ||
    user.contact?.address.company !== personalAddress.company ||
    user.contact?.phone !== personalContact.phone ||
    user.contact?.email !== personalContact.email
  }

  // Handle booking
  const [bookingInProgress, setBookingInProgress] = useState(false);
  const handleBookTicket = async () => {

    if(validations?.visit?.length > 0 || validations?.personalAddress?.length > 0 || validations?.personalContact?.length > 0 || (!useProfileAddressForBilling && validations?.billingAddress?.length > 0)) {
      setErrors([t("Please fill out all required fields."), ...validations?.visit, ...validations?.personalAddress, ...validations?.personalContact, ...(useProfileAddressForBilling ? [] : validations?.billingAddress)].filter(Boolean));
      return;
    }

    try {
      setBookingInProgress(true);
      if(hasUserPersonalAddressChanged()) {
        updateUserPersonalAddress();
      }
      await createTicket();
      setTimeout(() => {
        setBookingInProgress(false);
        
      }, 1000);
    } catch (error) {
      console.error(error);
      setBookingInProgress(false);
    }
  }



  // Validation
  const [validations, setValidations] = useState({});
  const [showValidations, setShowValidations] = useState({
    visit: false,
    personalAddress: false,
    personalContact: false,
    billingAddress: false
  });

  const debouncedPersonalAddress = useDebounce(personalAddress, 1000);
  const debouncedPersonalContact = useDebounce(personalContact, 1000);
  const debouncedBillingAddress = useDebounce(billingAddress, 1000);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    const personalProfile = fullAddressSchema.validate(debouncedPersonalAddress).map((validation) => validation.path);
    const personalContactErrors = [];
    // Add validation for contact data
    // Email validation is already handled by the input type="email"
    setValidations({
      personalAddress: personalProfile, 
      personalContact: personalContactErrors, 
      billingAddress: fullAddressSchema.validate(debouncedBillingAddress).map((validation) => validation.path), 
      visit: visitSchema.validate({...debouncedParams}).map((validation) => validation.path)
    });
  }, [debouncedPersonalAddress, debouncedPersonalContact, debouncedBillingAddress, debouncedParams]);


  return (
    ticketId ?
    <Success ticketId={ticketId} /> :
    hasExistingTicket ?
    (<MDBCard className="mt-4">
        <MDBCardBody>
            <MDBIcon fas icon="info-circle" className="me-2" />
            <strong>{t("You already have a ticket for this event")}</strong>
            <p className="mt-3">
              {t("Each person must book their ticket through their own account. Only registered companies can book tickets for all employees.")}
            </p> 
        </MDBCardBody>
      </MDBCard> )
      :
      event && !event?.enableTicketBooking ?
      (
        <MDBCard className="mt-4">
          <MDBCardBody>
              <MDBIcon fas icon="info-circle" className="me-2" />
              <strong>{t("Ticket booking for this event has been disabled.")}</strong>
              <p className="mt-3">
                {t("You can purchase a ticket at the event entrance.")}
              </p> 
          </MDBCardBody>
        </MDBCard>
      )
      
      :
    !isLoading &&
  <>
  <MDBTypography tag='h1' className="text-center">{t("Book ticket")}</MDBTypography>
       <div className="d-none">
       <MDBBtn ref={prevRef}>{t("Previous")}</MDBBtn>
        <MDBBtn ref={nextRef}>{t("Next")}</MDBBtn>
       </div>
<MDBCard className="mt-lg-4">
    <MDBCardBody>


    {event &&
      <MDBStepper defaultStep={defaultStep} externalNext={nextRef} externalPrev={prevRef} type="horizontal" className="text-center">
      <MDBStepperStep itemId={1} headText={!isMobile && t("Select event and ticket")} headIcon={<MDBIcon fas icon="calendar-alt" />} >
        <EventAndTicket
          event={event}
          params={params}
          setParams={setParams}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          />
      </MDBStepperStep>
      {(event.bookingSelectDays || event.hasInvitationCodes)&&
      <MDBStepperStep itemId={2} headText={!isMobile && t("Your visit")} headIcon={<MDBIcon fas icon="calendar-day" />}>
        <Visit
          params={params}
          setParams={setParams}
          eventDays={eventDays}
          hasInvitationCodes={event?.hasInvitationCodes}
          suggestedInvitationCode={event?.suggestedInvitationCode}
          bookingSelectDays={event?.bookingSelectDays}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          validations={validations}
          showValidations={showValidations}
          setShowValidations={setShowValidations}
          />
      </MDBStepperStep>
      }
      <MDBStepperStep itemId={(event.bookingSelectDays || event.hasInvitationCodes) ? 3 : 2} headText={!isMobile && t("Personal information")} headIcon={<MDBIcon fas icon="user" />}>
        <PersonalInformation
          user={user}
          event={event}
          params={params}
          setParams={setParams}
          useProfileAddressForBilling={useProfileAddressForBilling}
          setUseProfileAddressForBilling={setUseProfileAddressForBilling}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          personalAddress={personalAddress}
          setPersonalAddress={setPersonalAddress}
          personalContact={personalContact}
          setPersonalContact={setPersonalContact}
          customerType={customerType}
          setCustomerType={setCustomerType}
          setCustomerTypeSetByUser={setCustomerTypeSetByUser}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          validations={validations}
          showValidations={showValidations}
          setShowValidations={setShowValidations}
          />
      </MDBStepperStep>
      <MDBStepperStep itemId={(event.bookingSelectDays || event.hasInvitationCodes) ? 4 : 3} headText={!isMobile && t("Confirmation")} headIcon={<MDBIcon fas icon="check" />}>
      <Confirmation
          user={user}
          event={event}
          eventId={params.eventId}
          params={params}
          taxRate={taxRate}
          personalAddress={personalAddress}
          personalContact={personalContact}
          useProfileAddressForBilling={useProfileAddressForBilling}
          setUseProfileAddressForBilling={setUseProfileAddressForBilling}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          handleBookTicket={handleBookTicket}
          bookingInProgress={bookingInProgress}
          handleClickPrev={handleClickPrev}
          handleClickNext={handleClickNext}
          validations={validations}
          showValidations={showValidations}
          setShowValidations={setShowValidations}
          setOrderDiscounts={setOrderDiscounts}
          errors={errors}
          />
      </MDBStepperStep>
    </MDBStepper>
    }
    </MDBCardBody>
</MDBCard>
</>
  )
}