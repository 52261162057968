import { useEffect, useState } from 'react';
import { 
  MDBCard, 
  MDBCardBody, 
  MDBCardTitle, 
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBToast,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink
} from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";
import { fetchAuthSession } from 'aws-amplify/auth';
import { post, get } from 'aws-amplify/api';
import { Link } from "react-router-dom";

const ITEMS_PER_PAGE = 20;
const SEARCH_DELAY = 500; // Verzögerung in ms für die Suche

const USER_STATUS_OPTIONS = [
  { text: 'All', value: '' },
  { text: 'Unconfirmed', value: 'UNCONFIRMED' },
  { text: 'Confirmed', value: 'CONFIRMED' },
  { text: 'Archived', value: 'ARCHIVED' },
  { text: 'Compromised', value: 'COMPROMISED' },
  { text: 'Reset Required', value: 'RESET_REQUIRED' },
  { text: 'Force Change Password', value: 'FORCE_CHANGE_PASSWORD' }
];

async function listUsers(limit, paginationToken, statusFilter) {
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  if (statusFilter) {
    path = `/listUsersByStatus?status=${statusFilter}`;
  }
  if (paginationToken) {
    const encodedToken = encodeURIComponent(paginationToken);
    path += `${path.includes('?') ? '&' : '?'}token=${encodedToken}`;
  }
  if (limit) {
    path += `${path.includes('?') ? '&' : '?'}limit=${limit}`;
  }
  
  let options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
    }
  };

  try {
    const response = await get({ apiName, path, options }).response;
    console.log('listUsers response:', response);
    const contentType = response.headers['content-type'] || '';
    
    if (contentType.includes('application/json')) {
      return await response.body.json();
    } else {
      // Handle non-JSON responses (like HTML error pages)
      const textResponse = await response.body.text();
      const errorDetails = extractErrorDetails(textResponse, response.status);
      throw new Error(errorDetails);
    }
  } catch (error) {
    console.error(`API Error (${path}):`, error);
    throw error;
  }
}

async function searchUsers(searchTerm, limit, paginationToken) {
  let apiName = 'AdminQueries';
  let path = `/searchUsers?searchTerm=${encodeURIComponent(searchTerm)}`;
  
  if (paginationToken) {
    const encodedToken = encodeURIComponent(paginationToken);
    path += `&token=${encodedToken}`;
  }
  if (limit) {
    path += `&limit=${limit}`;
  }
  
  let options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
    }
  };

  try {
    const response = await get({ apiName, path, options }).response;
    console.log('searchUsers response:', response);
    const contentType = response.headers['content-type'] || '';
    
    if (contentType.includes('application/json')) {
      return await response.body.json();
    } else {
      // Handle non-JSON responses (like HTML error pages)
      const textResponse = await response.body.text();
      const errorDetails = extractErrorDetails(textResponse, response.status);
      throw new Error(errorDetails);
    }
  } catch (error) {
    console.error(`API Error (${path}):`, error);
    throw error;
  }
}

async function confirmUserSignUp(username) {
  let apiName = 'AdminQueries';
  let path = '/confirmUserSignUp';
  let options = {
    body: { username },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
    }
  };

  try {
    const response = await post({ apiName, path, options }).response;
    const contentType = response.headers['content-type'] || '';
    
    if (contentType.includes('application/json')) {
      return await response.body.json();
    } else {
      // Handle non-JSON responses (like HTML error pages)
      const textResponse = await response.body.text();
      const errorDetails = extractErrorDetails(textResponse, response.status);
      throw new Error(errorDetails);
    }
  } catch (error) {
    console.error(`API Error (${path}):`, error);
    throw error;
  }
}

async function deleteUser(username) {
  let apiName = 'AdminQueries';
  let path = '/deleteUser';
  let options = {
    body: { username },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
    }
  };

  try {
    const response = await post({ apiName, path, options }).response;
    const contentType = response.headers['content-type'] || '';
    
    if (contentType.includes('application/json')) {
      return await response.body.json();
    } else {
      // Handle non-JSON responses (like HTML error pages)
      const textResponse = await response.body.text();
      const errorDetails = extractErrorDetails(textResponse, response.status);
      throw new Error(errorDetails);
    }
  } catch (error) {
    console.error(`API Error (${path}):`, error);
    throw error;
  }
}

// Helper function to extract meaningful error information from HTML or other responses
function extractErrorDetails(responseText, statusCode) {
  // Check if response is HTML and try to extract the error message
  if (responseText.includes('<!DOCTYPE html>') || responseText.includes('<html')) {
    // Try to extract the error message from the HTML
    const preMatch = responseText.match(/<pre>([^<]+)<\/pre>/);
    if (preMatch && preMatch[1]) {
      return `Error ${statusCode}: ${preMatch[1].trim()}`;
    }
    // If we can't extract a specific message, return a generic one with the status
    return `Error ${statusCode}: Unexpected HTML response from server`;
  }
  
  // For non-HTML, non-JSON responses
  return `Error ${statusCode}: ${responseText.substring(0, 100)}${responseText.length > 100 ? '...' : ''}`;
}

export default function CognitoUsers() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', color: 'success' });
  const [deleteModal, setDeleteModal] = useState({ show: false, user: null });
  const [actionLoading, setActionLoading] = useState({ userId: null, action: null });
  const [statusFilter, setStatusFilter] = useState('');
  const [paginationToken, setPaginationToken] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    // Nur fetchen, wenn kein Suchbegriff eingegeben ist
    if (!searchTerm.trim()) {
      fetchUsers();
    }
  }, [statusFilter, currentPage]);

  // Effekt für die Suche mit Verzögerung
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchTerm.trim().length > 0) {
      const timeout = setTimeout(() => {
        handleSearch();
      }, SEARCH_DELAY);
      
      setSearchTimeout(timeout);
    }

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTerm]);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listUsers(
        ITEMS_PER_PAGE,
        currentPage > 1 ? paginationToken : null,
        statusFilter
      );
      setUsers(response.Users || []);
      setPaginationToken(response.NextToken);
      setHasMore(!!response.NextToken);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message || t('Error fetching users'));
      showToast(error.message || t('Error fetching users'), 'danger');
      setUsers([]);
      setHasMore(false);
    }
    setLoading(false);
  };

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      // Wenn Suchbegriff leer ist, zurück zur normalen Benutzerliste
      fetchUsers();
      return;
    }

    setIsSearching(true);
    setLoading(true);
    setError(null);
    
    try {
      const response = await searchUsers(
        searchTerm,
        ITEMS_PER_PAGE,
        null // Keine Pagination für die erste Suche
      );
      setUsers(response.Users || []);
      setPaginationToken(response.NextToken);
      setHasMore(!!response.NextToken);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error searching users:', error);
      setError(error.message || t('Error searching users'));
      showToast(error.message || t('Error searching users'), 'danger');
      setUsers([]);
      setHasMore(false);
    }
    
    setLoading(false);
    setIsSearching(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchReset = () => {
    setSearchTerm('');
    fetchUsers();
  };

  const showToast = (message, color = 'success') => {
    setToast({ show: true, message, color });
    setTimeout(() => setToast({ show: false, message: '', color: 'success' }), 3000);
  };

  async function updateAttribute(username, attribute, value, verified) {
    let apiName = 'AdminQueries';
    let path = '/updateUserAttribute';
    let options = {
        body: { username, attribute, value, verified },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

  const handleConfirmUser = async (username) => {
    setActionLoading({ userId: username, action: 'confirm' });
    try {
      await confirmUserSignUp(username);
      await updateAttribute(username, 'email_verified', 'true');
      
      // Aktualisiere den Benutzer in der Liste
      if (searchTerm.trim()) {
        await handleSearch();
      } else {
        await fetchUsers();
      }
      
      showToast(t('User confirmed successfully'));
    } catch (error) {
      console.error('Error confirming user:', error);
      showToast(error.message || t('Error confirming user'), 'danger');
    }
    setActionLoading({ userId: null, action: null });
  };

  const handleDeleteUser = async () => {
    const username = deleteModal.user?.Username;
    setActionLoading({ userId: username, action: 'delete' });
    try {
      await deleteUser(username);
      
      // Nach dem Löschen aktualisieren
      if (searchTerm.trim()) {
        await handleSearch();
      } else {
        await fetchUsers();
      }
      
      showToast(t('User deleted successfully'));
      setDeleteModal({ show: false, user: null });
    } catch (error) {
      console.error('Error deleting user:', error);
      showToast(error.message || t('Error deleting user'), 'danger');
    }
    setActionLoading({ userId: null, action: null });
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.value);
    setCurrentPage(1);
    setPaginationToken(null);
    // Suchbegriff zurücksetzen, wenn Status geändert wird
    setSearchTerm('');
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRetry = () => {
    if (searchTerm.trim()) {
      handleSearch();
    } else {
      fetchUsers();
    }
  };

  // Filtern ist nicht mehr nötig, da wir jetzt direkt auf dem Server suchen
  // Aber wir behalten diese Funktion für zusätzliche client-seitige Filterung bei
  const filteredUsers = users;

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>{t('Cognito Users')}</MDBCardTitle>
          
          <MDBRow className="mb-4">
            <MDBCol size="12" md="6">
              <div className="d-flex">
                <MDBInput 
                  type="text" 
                  label={t('Search by email or name')} 
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  className="flex-grow-1"
                />
                {searchTerm && (
                  <MDBBtn 
                    color="light" 
                    className="ms-2" 
                    onClick={handleSearchReset}
                  >
                    <MDBIcon fas icon="times" />
                  </MDBBtn>
                )}
              </div>
              <small className="text-muted">
                {searchTerm ? t('Searching from Cognito...') : t('Enter email or name to search')}
              </small>
            </MDBCol>
            <MDBCol size="12" md="6">
              <MDBSelect
                label={t('Filter by Status')}
                data={USER_STATUS_OPTIONS}
                value={statusFilter}
                onChange={handleStatusChange}
                disabled={isSearching || !!searchTerm.trim()}
              />
              {searchTerm && (
                <small className="text-muted">
                  {t('Status filter is disabled during search')}
                </small>
              )}
            </MDBCol>
          </MDBRow>

          {error && (
            <div className="alert alert-danger mb-4">
              <strong>{t('Error')}:</strong> {error}
              <button 
                className="btn btn-sm btn-outline-danger ms-3" 
                onClick={handleRetry}
              >
                {t('Retry')}
              </button>
            </div>
          )}

          <MDBTable>
            <MDBTableHead>
              <tr>
                <th>{t('Email')}</th>
                <th>{t('Name')}</th>
                <th>{t('Created At')}</th>
                <th>{t('Status')}</th>
                <th>{t('Actions')}</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <MDBSpinner />
                  </td>
                </tr>
              ) : filteredUsers.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    {error ? t('Failed to load users') : 
                     searchTerm ? t('No users found matching your search') : t('No users found')}
                  </td>
                </tr>
              ) : (
                filteredUsers.map((user) => {
                  const attributes = user.Attributes.reduce((acc, attr) => {
                    acc[attr.Name] = attr.Value;
                    return acc;
                  }, {});
                  const isLoading = actionLoading.userId === user.Username;

                  return (
                    <tr key={user.Username}>
                      <td>{attributes.email || user.Username}
                    {attributes["custom:userId"] && <>
                      <Link to={`/admin/user/${attributes["custom:userId"]}`}>
                      <MDBIcon fas icon="user-alt" />
                      </Link>
                    </>
                    }

                      </td>
                      <td>
                        {attributes['given_name'] || ''} {attributes['family_name'] || ''}
                      </td>
                      <td>
                        {new Date(user.UserCreateDate).toLocaleDateString('de-DE', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                        })}
                      </td>
                      <td>{user.UserStatus}</td>
                      <td>
                        {user.UserStatus === 'UNCONFIRMED' && (
                          <MDBBtn
                            color="success"
                            size="sm"
                            className="me-2"
                            onClick={() => handleConfirmUser(user.Username)}
                            disabled={isLoading}
                          >
                            {isLoading && actionLoading.action === 'confirm' ? (
                              <MDBSpinner size="sm" />
                            ) : (
                              <MDBIcon fas icon="check" />
                            )}
                          </MDBBtn>
                        )}
                        <MDBBtn
                          color="danger"
                          size="sm"
                          onClick={() => setDeleteModal({ show: true, user })}
                          disabled={isLoading}
                        >
                          {isLoading && actionLoading.action === 'delete' ? (
                            <MDBSpinner size="sm" />
                          ) : (
                            <MDBIcon fas icon="trash" />
                          )}
                        </MDBBtn>
                      </td>
                    </tr>
                  );
                })
              )}
            </MDBTableBody>
          </MDBTable>

          {/* Pagination nur anzeigen, wenn nicht gesucht wird */}
          {!loading && !error && users.length > 0 && !searchTerm && (
            <div className="d-flex justify-content-center mt-4">
              <MDBPagination>
                <MDBPaginationItem disabled={currentPage === 1}>
                  <MDBPaginationLink onClick={() => handlePageChange(currentPage - 1)}>
                    {t('Previous')}
                  </MDBPaginationLink>
                </MDBPaginationItem>
                
                <MDBPaginationItem active>
                  <MDBPaginationLink>
                    {currentPage}
                  </MDBPaginationLink>
                </MDBPaginationItem>

                <MDBPaginationItem disabled={!hasMore}>
                  <MDBPaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                    {t('Next')}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              </MDBPagination>
            </div>
          )}
        </MDBCardBody>
      </MDBCard>

      {/* Delete Confirmation Modal */}
      <MDBModal
        open={deleteModal.show}
        onClose={() => setDeleteModal({ show: false, user: null })}
        staticBackdrop
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t('Confirm Deletion')}</MDBModalTitle>
              <MDBBtn 
                className='btn-close' 
                color='none' 
                onClick={() => setDeleteModal({ show: false, user: null })}
              />
            </MDBModalHeader>
            <MDBModalBody>
              {t('Are you sure you want to delete the user')} {deleteModal.user?.Username}?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn 
                color='secondary' 
                onClick={() => setDeleteModal({ show: false, user: null })}
              >
                {t('Cancel')}
              </MDBBtn>
              <MDBBtn 
                color='danger' 
                onClick={handleDeleteUser}
                disabled={actionLoading.userId === deleteModal.user?.Username}
              >
                {actionLoading.userId === deleteModal.user?.Username ? (
                  <MDBSpinner size="sm" />
                ) : (
                  t('Delete')
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Toast for feedback */}
      <MDBToast
        color={toast.color}
        autohide
        position="top-right"
        delay={3000}
        open={toast.show}
        onClose={() => setToast({ ...toast, show: false })}
      >
        {toast.message}
      </MDBToast>
    </>
  );
}