import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBSelect, MDBCol } from "mdb-react-ui-kit";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";
import { Link, useSearchParams } from "react-router-dom";
import { isoToGermanDate } from "../utils/dateTools";
import { UserContext } from "../App";

const client = generateClient();

export default function AllEventLectures() {
    const { t, i18n } = useTranslation();
    const { user } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState('');
    const [events, setEvents] = useState([]);
    const [isLoadingEvents, setIsLoadingEvents] = useState(true);
    const [lecturesByEvent, setLecturesByEvent] = useState({});
    const [isLoadingLectures, setIsLoadingLectures] = useState({});
    const [shownLectures, setShownLectures] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const [filters, setFilters] = useState({
        type: null
    });

    // Fetch active events
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const fromDate = new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10);
                const toDate = new Date(Date.now() + 21 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10);
                console.log(fromDate, toDate);
                
                // Zuerst die Tickets des Users abrufen
                const ticketsResponse = await client.graphql({
                    query: /* GraphQL */ `
                        query GetUser($id: ID!) {
                            getUser(id: $id) {
                                tickets {
                                    items {
                                        ticketEventId
                                        event {
                                            id
                                            name {
                                                language
                                                text
                                            }
                                            status
                                            startDate
                                            endDate
                                            publicVideos
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        id: user.id
                    }
                });

                // Extrahiere die Events aus den Tickets
                const userTickets = ticketsResponse.data.getUser.tickets.items;
                const userEvents = userTickets
                    .filter(ticket => ticket.event) // Filtere Tickets ohne Event aus
                    .map(ticket => ticket.event)
                    .filter(event => 
                        event.status === "active" && 
                        event.startDate <= toDate &&
                        event.startDate >= fromDate
                    );

                // Setze die gefilterten Events
                setEvents(userEvents);
                
                if (userEvents.length > 0) {
                    setActiveTab(userEvents[0].id);
                    fetchLecturesForEvent(userEvents[0].id);
                }
                
                setIsLoadingEvents(false);
            } catch (error) {
                console.error("Error fetching events:", error);
                setIsLoadingEvents(false);
            }
        };

        if (user?.id) {
            fetchEvents();
        }
    }, [user]);

    // Fetch lectures for an event
    const fetchLecturesForEvent = async (eventId) => {
        setIsLoadingLectures(prev => ({ ...prev, [eventId]: true }));
        
        try {
            const searchLectureFilter = {
                eventLecturesId: { eq: eventId },
                approved: { eq: true }
                
            };

            if (filters.type) {
                searchLectureFilter.type = { eq: filters.type };
            }

            const response = await client.graphql({
                variables: {
                    filter: searchLectureFilter
                   
                },
                query: /* GraphQL */ `
                    query SearchLectures($filter: SearchableLectureFilterInput, $limit: Int) {
                        searchLectures(filter: $filter, limit: $limit) {
                            items {
                                id
                                approved
                                title {
                                    language
                                    text
                                }
                                speakers {
                                    items {
                                    id
                                        employee {
                                            image {
                                                fileName
                                                alt
                                            }
                                        }
                                    }
                                }
                                company {
                                    id
                                    name
                                    image {
                                        main {
                                            fileName
                                            alt
                                        }
                                    }
                                }
                                type
                                companyBooth
                                room
                                startTime
                                endTime
                                pdfDe {
                                    fileName
                                    s3Path
                                }
                                pdfEn {
                                    fileName
                                    s3Path
                                }
                                videoS3Key {
                                    s3Path
                                }
                            }
                        }
                    }
                `
            });

            const lectures = response.data.searchLectures.items;
            setLecturesByEvent(prev => ({ ...prev, [eventId]: lectures }));
            setShownLectures(prev => ({ ...prev, [eventId]: lectures }));
            setIsLoadingLectures(prev => ({ ...prev, [eventId]: false }));
        } catch (error) {
            console.error("Error fetching lectures:", error);
            setIsLoadingLectures(prev => ({ ...prev, [eventId]: false }));
        }
    };

    const handleTabClick = (eventId) => {
        setActiveTab(eventId);
        if (!lecturesByEvent[eventId]) {
            fetchLecturesForEvent(eventId);
        }
    };

    const searchLectures = (searchTerm, eventId) => {
        if (!lecturesByEvent[eventId]) return;

        if (searchTerm.length > 0) {
            const filtered = lecturesByEvent[eventId].filter(lecture => {
                const companyName = lecture.company?.name?.toLowerCase();
                const lectureTitle = lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase();
                const searchTermLower = searchTerm.toLowerCase();
                
                return companyName?.includes(searchTermLower) || lectureTitle?.includes(searchTermLower);
            });
            setShownLectures(prev => ({ ...prev, [eventId]: filtered }));
        } else {
            setShownLectures(prev => ({ ...prev, [eventId]: lecturesByEvent[eventId] }));
        }
    };

    const handleFilterChange = (type) => {
        setFilters({ ...filters, type: type.value });
        events.forEach(event => {
            fetchLecturesForEvent(event.id);
        });
    };

    const downloadFile = async (fileName, path) => {
        const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
        const match = path.match(regex);
        const identity = match[1];
        const getUrlResult = await getUrl({
            key: fileName,
            options: {
                accessLevel: 'protected',
                targetIdentityId: identity,
            },
        });
        window.open(getUrlResult.url, '_blank');
    };

    if (isLoadingEvents) {
        return <div className="text-center"><div className="spinner-border" role="status"></div></div>;
    }

    return (
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("Event Lectures")}</MDBCardTitle>
                
                <MDBTabs className='mb-3'>
                    {events.map(event => (
                        <MDBTabsItem key={event.id}>
                            <MDBTabsLink 
                                onClick={() => handleTabClick(event.id)} 
                                active={activeTab === event.id}
                            >
                                {event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text}
                            </MDBTabsLink>
                        </MDBTabsItem>
                    ))}
                </MDBTabs>

                <MDBTabsContent>
                    {events.map(event => (
                        <MDBTabsPane key={event.id} open={activeTab === event.id}>
                            <MDBRow>
                                <MDBCol md="6">
                                    <MDBInput 
                                        type="text" 
                                        onChange={e => searchLectures(e.target.value, event.id)} 
                                        label={t("Search")} 
                                    />
                                </MDBCol>
                                <MDBCol md="3">
                                    <MDBSelect 
                                        label={t("Type")}
                                        onChange={handleFilterChange}
                                        value={filters.type}
                                        data={[
                                            { text: t("All"), value: null },
                                            { text: t("Marketing"), value: "marketing" },
                                            { text: t("Technical"), value: "technical" },
                                            { text: t("Educational"), value: "educational" },
                                            { text: t("Recruiting"), value: "recruiting" },
                                            { text: t("Promotion Marketing"), value: "promotion_marketing" },
                                            { text: t("Promotion Technical"), value: "promotion_technical" }
                                        ]}
                                    />
                                </MDBCol>
                                <MDBCol md="1">
                                    <MDBIcon 
                                        className="float-end" 
                                        fas 
                                        icon="sync-alt" 
                                        onClick={() => fetchLecturesForEvent(event.id)} 
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBTable className="mt-3">
                                <MDBTableHead>
                                    <tr>
                                        <th style={{minWidth: "125px"}}>{t("Date & Time")}</th>
                                        <th>{t("Company")}</th>
                                        <th>{t("Type")}</th>
                                        <th>{t("Title")}</th>
                                        <th>{t("Speakers")}</th>
                                        <th>{t("Documents & Media")}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {isLoadingLectures[event.id] ? (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <div className="spinner-border" role="status"/>
                                            </td>
                                        </tr>
                                    ) : shownLectures[event.id]?.length > 0 ? (
                                        shownLectures[event.id].map((lecture, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {lecture.startTime ? (
                                                        <>
                                                            {isoToGermanDate(lecture.startTime)}<br/>
                                                            {lecture.startTime.substring(11, 16)} - {lecture.endTime.substring(11, 16)}<br/>
                                                            {lecture.room ? t("Room") + ": " + lecture.room : t("not yet determined")}
                                                        </>
                                                    ) : t("not yet determined")}
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        {lecture.company?.image?.main?.fileName ? (
                                                            <img
                                                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${lecture.company.image.main.fileName}`}
                                                                className="rounded-circle border"
                                                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                                alt={lecture.company.name}
                                                            />
                                                        ) : (
                                                            <MDBIcon 
                                                                className="rounded-circle border" 
                                                                style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} 
                                                                fas 
                                                                icon="building" 
                                                            />
                                                        )}
                                                        <div className='ms-3'>
                                                            <p className='fw-bold mb-1'>{lecture.company?.name}</p>
                                                            <p className='text-muted mb-0'>{lecture.companyBooth}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{t(lecture.type)}</td>
                                                <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                                                <td>
                                                    {lecture.speakers?.items?.map((speaker, idx) => (
                                                        <div key={idx}>
                                                            {speaker?.employee?.image?.fileName ? (
                                                                <img
                                                                    src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <MDBIcon
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }}
                                                                    fas
                                                                    icon="user-alt"
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td style={{maxWidth: "200px"}}>
                                                    {lecture.pdfDe?.fileName && (
                                                        <div className="text-center">
                                                            <MDBBtn 
                                                                color="link" 
                                                                onClick={() => downloadFile(lecture.pdfDe.fileName, lecture.pdfDe.s3Path)} 
                                                                size="sm"
                                                            >
                                                                {lecture.pdfDe.fileName}
                                                            </MDBBtn>
                                                        </div>
                                                    )}
                                                    {lecture.pdfEn?.fileName && (
                                                        <div className="text-center">
                                                            <MDBBtn 
                                                                color="link" 
                                                                onClick={() => downloadFile(lecture.pdfEn.fileName, lecture.pdfEn.s3Path)} 
                                                                size="sm"
                                                            >
                                                                {lecture.pdfEn.fileName}
                                                            </MDBBtn>
                                                        </div>
                                                    )}
                                                    {lecture.videoS3Key?.s3Path && events?.find(event => event.id === activeTab)?.publicVideos && (
                                                         <Link to={`/academy/basics/lecture/${lecture.id}`} className="text-reset text-decoration-none">
                                                            <MDBBtn color="link" size="sm">{t("Watch Video")}</MDBBtn>
                                                         </Link>
                                                         )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                {t("No lectures found")}
                                            </td>
                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBTabsPane>
                    ))}
                </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>
    );
}