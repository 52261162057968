import React, { useState } from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBBtn, MDBIcon, MDBInput, MDBListGroup, MDBListGroupItem, MDBRow, MDBCol, MDBDatepicker } from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';

export default function TimeRangePriceModal({ isOpen, onClose, eventProduct, onUpdateEventProduct }) {
  const { t } = useTranslation();
  const [timeRangePrices, setTimeRangePrices] = useState(eventProduct.timeRangePrices || []);
  const [newStart, setNewStart] = useState('');
  const [newEnd, setNewEnd] = useState('');
  const [newPrice, setNewPrice] = useState('');

  const handleAddTimeRange = () => {
    if (!newStart || !newEnd || !newPrice) return;
    console.log(newStart, newEnd, newPrice); // Logs: 18.02.2025 20.02.2025 200
    
    const startDate = moment.tz(newStart, "DD.MM.YYYY", "Europe/Berlin").startOf('day');
    const endDate = moment.tz(newEnd, "DD.MM.YYYY", "Europe/Berlin").endOf('day');
    
    const newTimeRange = {
      timeRange: {
        start: startDate.toISOString(),
        end: endDate.toISOString()
      },
      price: parseFloat(newPrice)
    };
    console.log(newTimeRange); // Logs: {"timeRange": {"start": null,"end": null},"price": 1}
    setTimeRangePrices([...timeRangePrices, newTimeRange]);
    setNewStart('');
    setNewEnd('');
    setNewPrice('');
  };

  const handleRemoveTimeRange = (index) => {
    const updatedPrices = timeRangePrices.filter((_, i) => i !== index);
    setTimeRangePrices(updatedPrices);
  };

  const handleSave = () => {
    onUpdateEventProduct(eventProduct.id, eventProduct.price, eventProduct.stock, timeRangePrices);
    onClose();
  };

  return (
    <MDBModal open={isOpen} onClose={onClose}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{t("Time Range Prices")}</MDBModalTitle>
            <MDBBtn className="btn-close" color="none" onClick={onClose}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow className="mb-4">
              <MDBCol size="12" lg="3">
              <MDBDatepicker
                format="dd.mm.yyyy"
                label={t("Start Date")}
                value={newStart}
                onChange={(value) => setNewStart(value)}
                inputLabel={t("Start Date")}
                filter={(date) => {
                    // Deaktiviert das Datum, wenn es bereits in einem vorhandenen Zeitbereich liegt
                    return !timeRangePrices.some(rangePrice => {
                    const rangeStart = moment(rangePrice.timeRange.start).startOf('day');
                    const rangeEnd = moment(rangePrice.timeRange.end).endOf('day');
                    return moment(date).isBetween(rangeStart, rangeEnd, 'day', '[]');
                    });
                }}
                />
              </MDBCol>
              <MDBCol size="12" lg="3">
              <MDBDatepicker
                format="dd.mm.yyyy"
                label={t("End Date")}
                value={newEnd}
                onChange={(value) => setNewEnd(value)}
                inputLabel={t("End Date")}
                min={ newStart ? moment(newStart, "DD.MM.YYYY").toDate() : undefined }
                filter={(date) => {
                    if (!newStart) {
                    // Falls noch kein Startdatum ausgewählt wurde, deaktivieren wir nur bereits belegte Tage
                    return !timeRangePrices.some(rangePrice => {
                        const rangeStart = moment(rangePrice.timeRange.start).startOf('day');
                        const rangeEnd = moment(rangePrice.timeRange.end).endOf('day');
                        return moment(date).isBetween(rangeStart, rangeEnd, 'day', '[]');
                    });
                    }
                    const candidateStart = moment(newStart, "DD.MM.YYYY").startOf('day');
                    const candidateEnd = moment(date).endOf('day');
                    // Überprüft, ob der neue Zeitbereich [candidateStart, candidateEnd] mit einem bestehenden überlappt
                    const overlaps = timeRangePrices.some(rangePrice => {
                    const rangeStart = moment(rangePrice.timeRange.start).startOf('day');
                    const rangeEnd = moment(rangePrice.timeRange.end).endOf('day');
                    return candidateStart.isBefore(rangeEnd) && candidateEnd.isAfter(rangeStart);
                    });
                    return !overlaps;
                }}
                />
              </MDBCol>
              <MDBCol size="12" lg="3">
                <MDBInput
                  type="number"
                  label={t("Price")}
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                />
              </MDBCol>
              <MDBCol size="12" lg="3">
                <MDBBtn onClick={handleAddTimeRange} className="mb-4" disabled={!newStart || !newEnd || !newPrice}>
                    {t("Add")}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            

            <MDBListGroup>
              {timeRangePrices.map((rangePrice, index) => (
                <MDBListGroupItem key={index} className="d-flex justify-content-between align-items-center">
                  <div>
                    {moment(rangePrice.timeRange.start).format('DD.MM.YYYY')} - {' '}
                    {moment(rangePrice.timeRange.end).format('DD.MM.YYYY')}:{' '}
                    <span className="ms-2 fw-bold">{rangePrice.price}€</span>
                  </div>
                  <MDBBtn 
                    color="danger" 
                    size="sm"
                    onClick={() => handleRemoveTimeRange(index)}
                  >
                    <MDBIcon fas icon="trash" />
                  </MDBBtn>
                </MDBListGroupItem>
              ))}
            </MDBListGroup>

            <div className="d-flex justify-content-end mt-4">
              <MDBBtn color="danger" size="sm" outline onClick={onClose} className="me-2">
                {t("Cancel")}
              </MDBBtn>
              <MDBBtn onClick={handleSave}>
                {t("Save")}
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export const TimeRangePriceButton = ({ hasTimeRangePrices, onClick }) => {
  const { t } = useTranslation();
  
  return (
      <MDBIcon 
        fas 
        icon="clock" 
        onClick={onClick}
        className={"mx-2 " + (hasTimeRangePrices ? 'text-success' : 'text-muted')} 
      />
  );
};