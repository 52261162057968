import React, { useState, useContext } from 'react';
import { 
  MDBBtn, 
  MDBCard, 
  MDBCardBody, 
  MDBCol, 
  MDBInput, 
  MDBRow, 
  MDBTable
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useTicket } from '../hooks/useTicket';

/**
 * Search tab component for searching tickets
 * @param {Object} props - Component props
 * @param {Object} props.selectedEvent - The selected event object
 * @param {Function} props.onSelectTicket - Function to call when a ticket is selected
 * @param {Function} props.onChangeTab - Function to change active tab
 * @returns {JSX.Element} - Component
 */
const SearchTab = ({ selectedEvent, onSelectTicket, onChangeTab }) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState('');

  const { 
    searchTickets, 
    searchedTickets,
    isLoading
  } = useTicket(selectedEvent?.id);

  // Handle search submission
  const handleSearch = async () => {
    if (searchString.trim().length > 0) {
      await searchTickets(searchString);
    }
  };

  // Handle ticket selection and switch to Print tab
  const handleSelectTicket = (ticket) => {
    const ticketData = {
      id: ticket.id,
      title: ticket.personalAddress?.title || '',
      firstName: ticket.firstName || ticket.personalAddress?.firstName || '',
      lastName: ticket.lastName || ticket.personalAddress?.lastName || '',
      company: ticket.companyName || ticket.personalAddress?.company || '',
      eventDays: ticket.eventDays || [],
      email: ticket.personalContact?.email || ticket.user?.email || '',
      phone: ticket.personalContact?.phone || ticket.user?.contact?.phone || '',
      visitorType: ticket.visitorType || '',
    };
    
    onSelectTicket(ticketData);
    onChangeTab('print');
  };

  return (
    <>
      <MDBCard className="mb-3">
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <MDBInput 
                label={t("Search")} 
                value={searchString} 
                onChange={(e) => setSearchString(e.target.value)} 
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </MDBCol>
            <MDBCol size="auto">
              <MDBBtn 
                onClick={handleSearch}
                disabled={isLoading || !searchString.trim() || !selectedEvent}
                className="mt-1"
              >
                {t("Search")}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
  
      <MDBTable>
        <thead>
          <tr>
            <th>{t("First name")}</th>
            <th>{t("Last name")}</th>
            <th>{t("Company")}</th>
            <th>{t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {searchedTickets.map((ticket, index) => (
            <tr key={index}>
              <td>{ticket.firstName || ticket.personalAddress?.firstName}</td>
              <td>{ticket.lastName || ticket.personalAddress?.lastName}</td>
              <td>{ticket.companyName || ticket.personalAddress?.company}</td>
              <td>
                <MDBBtn 
                  onClick={() => handleSelectTicket(ticket)}
                  size="sm"
                >
                  {t("Select")}
                </MDBBtn>
              </td>
            </tr>
          ))}
          {searchedTickets.length === 0 && searchString.trim() && (
            <tr>
              <td colSpan="4" className="text-center">
                {t("No results found")}
              </td>
            </tr>
          )}
        </tbody>
      </MDBTable>
    </>
  );
};

export default SearchTab;