import { StyleSheet, Font } from '@react-pdf/renderer';

// Registriere die Fonts
import SairaFront from '../../../../scss/fonts/SairaSemiCondensed-Regular.ttf';
import SairaFontBold from '../../../../scss/fonts/SairaSemiCondensed-Bold.ttf';

Font.register({ family: 'SairaBold', src: SairaFontBold });
Font.register({ family: 'Saira', src: SairaFront });

// Ticket PDF styles
export const ticketStyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    textAlign: 'center',
    fontFamily: "Saira"
  },
  section: {
    marginTop: "105mm",
    padding: 10,
    flexGrow: 1
  },
  section2: {
    marginBottom: "85mm",
    padding: 10,
    flexGrow: 1
  },
  nameText: {
    fontSize: "22mm",
    fontFamily: "SairaBold"
  },
  companyText: {
    fontSize: "18mm", // Dynamisch in der Komponente anpassen
  },
  daysText: {
    fontSize: "16mm",
  },
  flipText: {
    transform: "rotate(180deg)",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "nowrap",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35
  },
  cell: {
    alignContent: "center",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    height: "350px",
    width: "400px",
  },
});

// Receipt PDF styles
export const receiptStyles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
    fontFamily: "Saira"
  },
  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: "#3E3E3E"
  },
  titleContainer: {
    flexDirection: 'row',
    marginTop: 24
  },
  logo: {
    width: 90
  },
  reportTitle: {
    fontSize: 16,
    textAlign: 'center'
  },
  addressTitle: {
    fontSize: 11,
    fontStyle: 'bold',
    textAlign: 'center'
  },
  invoice: {
    fontWeight: 'bold',
    fontSize: 20
  },
  invoiceNumber: {
    fontSize: 11,
    fontWeight: 'bold'
  },
  address: {
    fontWeight: 400,
    fontSize: 10
  },
  theader: {
    marginTop: 20,
    fontSize: 10,
    fontStyle: 'bold',
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    height: 20,
    backgroundColor: '#DEDEDE',
    borderColor: 'whitesmoke',
    borderRightWidth: 1,
    borderBottomWidth: 1
  },
  theader2: {
    flex: 2,
    borderRightWidth: 0,
    borderBottomWidth: 1
  },
  tbody: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    borderColor: 'whitesmoke',
    borderRightWidth: 1,
    borderBottomWidth: 1
  },
  tbody2: {
    flex: 2,
    borderRightWidth: 1
  },
  total: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: 'whitesmoke',
    borderBottomWidth: 1
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 9,
  },
  footerColumn: {
    width: '30%',
    marginRight: 10
  },
  qrCode: {
    width: "40mm",
    alignSelf: "center",
    marginTop: "20mm"
  }
});