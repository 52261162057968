import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient } from 'aws-amplify/api';
import { 
  MDBRow, 
  MDBCol, 
  MDBInput, 
  MDBBtn, 
  MDBSpinner, 
  MDBIcon,
  MDBInputGroup
} from 'mdb-react-ui-kit';
import useDebounce from '../../../utils/debounce';
import { getLocalizedText } from '../../../utils/localizedText';

const client = generateClient();

const StockReservationForm = ({ 
  isEdit = false, 
  stockReservation = null, 
  onSave, 
  onCancel,
  isSaving = false
}) => {
  const { t, i18n } = useTranslation();
  
  // Form state
  const [companySearchTerm, setCompanySearchTerm] = useState('');
  const [companySearchResults, setCompanySearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isCompanySearchLoading, setIsCompanySearchLoading] = useState(false);
  const [showCompanyResults, setShowCompanyResults] = useState(false);
  
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [productSearchResults, setProductSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductSearchLoading, setIsProductSearchLoading] = useState(false);
  const [showProductResults, setShowProductResults] = useState(false);
  
  const [eventProducts, setEventProducts] = useState([]);
  const [selectedEventProduct, setSelectedEventProduct] = useState(null);
  const [isEventProductsLoading, setIsEventProductsLoading] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('active');
  
  // Debounced search terms
  const debouncedCompanySearch = useDebounce(companySearchTerm, 500);
  const debouncedProductSearch = useDebounce(productSearchTerm, 500);
  
  // Effect for company search
  useEffect(() => {
    if (debouncedCompanySearch.length >= 2) {
      searchCompanies();
    } else {
      setCompanySearchResults([]);
    }
  }, [debouncedCompanySearch]);
  
  // Effect for product search
  useEffect(() => {
    if (debouncedProductSearch.length >= 2 && selectedCompany) {
      searchProducts();
    } else {
      setProductSearchResults([]);
    }
  }, [debouncedProductSearch]);
  
  // Initialize form if editing
  useEffect(() => {
    if (isEdit && stockReservation) {
      if (stockReservation.company) {
        setSelectedCompany(stockReservation.company);
        setCompanySearchTerm(stockReservation.company.name);
      }
      
      if (stockReservation.product) {
        setSelectedProduct(stockReservation.product);
        setProductSearchTerm(getLocalizedText(stockReservation.product.name));
      }
      
      if (stockReservation.eventProduct) {
        fetchEventProducts(stockReservation.product?.id);
        setSelectedEventProduct(stockReservation.eventProduct);
      }
      
      setQuantity(stockReservation.qty || 1);
      
      if (stockReservation.reservationDate) {
        setStartDate(new Date(stockReservation.reservationDate).toISOString().slice(0, 10));
      }
      
      if (stockReservation.reservationEndDate) {
        setEndDate(new Date(stockReservation.reservationEndDate).toISOString().slice(0, 10));
      }
      
      setStatus(stockReservation.status || 'active');
    }
  }, [isEdit, stockReservation]);
  
  // Search for companies
  const searchCompanies = async () => {
    setIsCompanySearchLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchCompanies(
            $filter: SearchableCompanyFilterInput
            $limit: Int
          ) {
            searchCompanies(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
                name
                image {
                  main {
                    fileName
                  }
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            name: {
              matchPhrasePrefix: debouncedCompanySearch
            }
          },
          limit: 10
        }
      });
      
      setCompanySearchResults(response.data.searchCompanies.items);
      setShowCompanyResults(true);
    } catch (error) {
      console.error('Error searching companies:', error);
    } finally {
      setIsCompanySearchLoading(false);
    }
  };
  
  // Search for products
  const searchProducts = async () => {
    setIsProductSearchLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchProducts(
            $filter: SearchableProductFilterInput
            $limit: Int
          ) {
            searchProducts(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
                name {
                  language
                  text
                }
                image {
                  main {
                    fileName
                  }
                }
                eventProducts {
                  items {
                    id
                    event {
                      id
                      name {
                        language
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            name_text: {
              match: debouncedProductSearch
            }
          },
          limit: 10
        }
      });
      
      setProductSearchResults(response.data.searchProducts.items);
      setShowProductResults(true);
    } catch (error) {
      console.error('Error searching products:', error);
    } finally {
      setIsProductSearchLoading(false);
    }
  };
  
  // Fetch event products for a product
  const fetchEventProducts = async (productId) => {
    if (!productId) return;
    
    setIsEventProductsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query GetProduct($id: ID!) {
            getProduct(id: $id) {
              eventProducts {
                items {
                  id
                  event {
                    id
                    name {
                      language
                      text
                    }
                  }
                  product {
                    id
                    name {
                      language
                      text
                    }
                    image {
                      main {
                        fileName
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: productId
        }
      });
      
      setEventProducts(response.data.getProduct.eventProducts.items);
    } catch (error) {
      console.error('Error fetching event products:', error);
    } finally {
      setIsEventProductsLoading(false);
    }
  };
  
  // Select a company from search results
  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
    setCompanySearchTerm(company.name);
    setShowCompanyResults(false);
  };
  
  // Select a product from search results
  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    setProductSearchTerm(getLocalizedText(product.name));
    setShowProductResults(false);
    setSelectedEventProduct(null);
    fetchEventProducts(product.id);
  };
  
  // Prepare form data and call onSave
  const handleSubmit = () => {
    const formData = {
      companyStockReservationsId: selectedCompany?.id,
      qty: parseInt(quantity),
      status,
      reservationDate: startDate ? new Date(startDate).toISOString() : new Date().toISOString()
    };
    
    if (endDate) {
      formData.reservationEndDate = new Date(endDate).toISOString();
    }
    
    if (selectedEventProduct) {
      formData.eventProductStockReservationsId = selectedEventProduct.id;
    } else if (selectedProduct) {
      formData.productStockReservationsId = selectedProduct.id;
    }
    
    if (isEdit && stockReservation?.id) {
      formData.id = stockReservation.id;
    }
    
    onSave(formData);
  };
  
  // Validate form
  const isFormValid = () => {
    return (
      selectedCompany && 
      (selectedProduct || selectedEventProduct) && 
      quantity > 0 && 
      startDate
    );
  };

  return (
    <div>
      {/* Company Selection */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <label className="form-label">{t("Company")}</label>
          <div className="position-relative">
            <MDBInputGroup>
              <MDBInput
                label={t("Search for company")}
                value={companySearchTerm}
                onChange={(e) => setCompanySearchTerm(e.target.value)}
                onClick={() => setShowCompanyResults(true)}
                disabled={isEdit} // Disable if editing
              />
              {isCompanySearchLoading && (
                <span className="input-group-text">
                  <MDBSpinner size="sm" />
                </span>
              )}
            </MDBInputGroup>
            
            {showCompanyResults && companySearchResults.length > 0 && (
              <div 
                className="position-absolute w-100 bg-white shadow-3 rounded p-3 z-index-dropdown"
                style={{ zIndex: 1000, maxHeight: '200px', overflowY: 'auto' }}
              >
                {companySearchResults.map(company => (
                  <div 
                    key={company.id} 
                    className="d-flex align-items-center p-2 rounded cursor-pointer hover-bg-light"
                    onClick={() => handleSelectCompany(company)}
                  >
                    {company.image?.main?.fileName ? (
                      <img
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${company.image.main.fileName}`}
                        className="rounded-circle border me-2"
                        style={{ height: "30px", width: "30px", objectFit: "contain" }}
                        alt={company.name}
                      />
                    ) : (
                      <MDBIcon 
                        className="rounded-circle border me-2"
                        style={{ 
                          height: "30px", 
                          width: "30px", 
                          textAlign: "center", 
                          paddingTop: "7px", 
                          fontSize: "16px" 
                        }}
                        fas 
                        icon="building" 
                      />
                    )}
                    <span>{company.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          
          {selectedCompany && (
            <div className="mt-2 d-flex align-items-center">
              <MDBIcon fas icon="check-circle" className="text-success me-2" />
              <span>{t("Selected")}: {selectedCompany.name}</span>
            </div>
          )}
        </MDBCol>
      </MDBRow>
      
      {/* Product Selection */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <label className="form-label">{t("Product")}</label>
          <div className="position-relative">
            <MDBInputGroup>
              <MDBInput
                label={t("Search for product")}
                value={productSearchTerm}
                onChange={(e) => setProductSearchTerm(e.target.value)}
                onClick={() => setShowProductResults(true)}
                disabled={!selectedCompany || isEdit} // Disable if no company selected or editing
              />
              {isProductSearchLoading && (
                <span className="input-group-text">
                  <MDBSpinner size="sm" />
                </span>
              )}
            </MDBInputGroup>
            
            {showProductResults && productSearchResults.length > 0 && (
              <div 
                className="position-absolute w-100 bg-white shadow-3 rounded p-3 z-index-dropdown"
                style={{ zIndex: 1000, maxHeight: '200px', overflowY: 'auto' }}
              >
                {productSearchResults.map(product => (
                  <div 
                    key={product.id} 
                    className="d-flex align-items-center p-2 rounded cursor-pointer hover-bg-light"
                    onClick={() => handleSelectProduct(product)}
                  >
                    {product.image?.main?.fileName ? (
                      <img
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/${product.image.main.fileName}`}
                        className="rounded border me-2"
                        style={{ height: "30px", width: "30px", objectFit: "contain" }}
                        alt={getLocalizedText(product.name)}
                      />
                    ) : (
                      <MDBIcon 
                        className="rounded border me-2"
                        style={{ 
                          height: "30px", 
                          width: "30px", 
                          textAlign: "center", 
                          paddingTop: "7px", 
                          fontSize: "16px" 
                        }}
                        fas 
                        icon="box" 
                      />
                    )}
                    <span>{getLocalizedText(product.name)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          
          {selectedProduct && (
            <div className="mt-2 d-flex align-items-center">
              <MDBIcon fas icon="check-circle" className="text-success me-2" />
              <span>{t("Selected")}: {getLocalizedText(selectedProduct.name)}</span>
            </div>
          )}
        </MDBCol>
      </MDBRow>
      
      {/* Event Product Selection (if available) */}
      {selectedProduct && eventProducts.length > 0 && (
        <MDBRow className="mb-4">
          <MDBCol size="12">
            <label className="form-label">{t("Event Product")}</label>
            {isEventProductsLoading ? (
              <div className="text-center my-2">
                <MDBSpinner size="sm" />
              </div>
            ) : (
              <select 
                className="form-select" 
                value={selectedEventProduct?.id || ''}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  if (selectedId) {
                    const eventProduct = eventProducts.find(ep => ep.id === selectedId);
                    setSelectedEventProduct(eventProduct);
                  } else {
                    setSelectedEventProduct(null);
                  }
                }}
              >
                <option value="">{t("Use regular product (no event)")}</option>
                {eventProducts.map(eventProduct => (
                  <option key={eventProduct.id} value={eventProduct.id}>
                    {getLocalizedText(eventProduct.event.name)}
                  </option>
                ))}
              </select>
            )}
            
            {selectedEventProduct && (
              <div className="mt-2 d-flex align-items-center">
                <MDBIcon fas icon="check-circle" className="text-success me-2" />
                <span>{t("Selected event")}: {getLocalizedText(selectedEventProduct.event.name)}</span>
              </div>
            )}
          </MDBCol>
        </MDBRow>
      )}
      
      {/* Quantity */}
      <MDBRow className="mb-4">
        <MDBCol size="12" md="6">
          <MDBInput
            label={t("Quantity")}
            type="number"
            min="1"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </MDBCol>
        <MDBCol size="12" md="6">
          <select 
            className="form-select" 
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active">{t("Active")}</option>
            <option value="pending">{t("Pending")}</option>
            <option value="expired">{t("Expired")}</option>
          </select>
        </MDBCol>
      </MDBRow>
      
      {/* Dates */}
      <MDBRow className="mb-4">
        <MDBCol size="12" md="6">
          <MDBInput
            label={t("Reservation Start Date")}
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </MDBCol>
        <MDBCol size="12" md="6">
          <MDBInput
            label={t("Reservation End Date (Optional)")}
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      
      {/* Actions */}
      <MDBRow className="mt-4">
        <MDBCol size="12" className="d-flex justify-content-end">
          <MDBBtn
            color="secondary"
            onClick={onCancel}
            className="me-2"
          >
            {t("Cancel")}
          </MDBBtn>
          <MDBBtn
            color="primary"
            onClick={handleSubmit}
            disabled={!isFormValid() || isSaving}
          >
            {isSaving ? (
              <>
                <MDBSpinner size="sm" className="me-2" />
                {t("Saving...")}
              </>
            ) : (
              t(isEdit ? "Save Changes" : "Create")
            )}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default StockReservationForm;