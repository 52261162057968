import React from "react";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardFooter,
  MDBCardTitle,
  MDBIcon,
  MDBCheckbox,
  MDBRadio,
} from "mdb-react-ui-kit";
import lectureData from "./lectureDummyData.json";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import lectureSchema from '../../../../../../validation/LectureValidationUser';

export default function CheckLectureInformation(props) {
  const { handleChange, lecture, employees, createLectureHandler, isSaving, topics, topicGroups, backClick } = props;
  const { t, i18n } = useTranslation();

  // const [missingInputs, setMissingInputs] = useState([]);

  // const checkAllInputs = () => {
  //   let missingInputsNew = [];
  //   if (!lecture?.speakers?.length) missingInputsNew.push("Referenten");
  //   if (!lecture?.title?.[0].text) missingInputsNew.push("Titel");
  //   if (lecture?.topics ? (lecture.topics.length < 1) : true) missingInputsNew.push("Themenbereiche");
  //   if (!lecture?.subjects?.length) missingInputsNew.push("Unterpunkte");
  //   if (!lecture?.content?.length) missingInputsNew.push("Beschreibung");
  //   if (!lecture?.image?.fileName) missingInputsNew.push("Bild");

  //   setMissingInputs(missingInputsNew);
  // }

  // useEffect(() => {
  //   checkAllInputs()
  // }, [lecture])

  const [validationErrors, setValidationErrors] = useState([]);

    const validateLectures = (lectures) => {
        let errors = lectures.map((lecture) => {

            let lectureErrors = lectureSchema.validate(lecture).map((error) => ({
                field: error.path,
                message: error.message
            })).filter(e => e.message)

            // remove conditions
            lectureErrors = lectureErrors.filter((error) => error.message !== "Bedingungen");

            // Custom validation for conditions
            if(lecture.type === "marketing") {
                // Check image and speaker conditions
                // Image
                if(lecture.image?.fileName) {
                    const imageCondition = lecture.conditions?.find(c => c.name === "image");
                    if(!imageCondition || !imageCondition.accepted) {
                        lectureErrors.push({field: "image", message: "Bildrechte"})
                    }
                }
                // Speaker
                if(lecture.speakers?.length > 0) {
                    const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                    if(!speakerCondition || !speakerCondition.accepted) {
                        lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                    }
                }
               
                } else if (lecture.type === "technical" || lecture.type === "educational") {
                    if(!lecture.conditions) {
                        console.log("no conditions", lecture.id, lecture.conditions)

                    }
                    // Check image and speaker conditions and adCondition, recordCondition, rightsCondition, deadlineCondition
                    // Image
                    if(lecture.image?.fileName) {
                        const imageCondition = lecture.conditions?.find(c => c.name === "image");
                        if(!imageCondition || !imageCondition.accepted) {
                            lectureErrors.push({field: "image", message: "Bildrechte"})
                        }
                    }
                    // Speaker
                    if(lecture.speakers?.length > 0) {
                        const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                        if(!speakerCondition || !speakerCondition.accepted) {
                            lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                        }
                    }
                    // Ad
                    const adCondition = lecture.conditions?.find(c => c.name === "adCondition");
                    if(!adCondition || !adCondition.accepted) {
                        lectureErrors.push({field: "ad", message: "Bedingungen-Werbefrei"})
                    }
                    // Record
                    const recordCondition = lecture.conditions?.find(c => c.name === "recordCondition");
                    if(!recordCondition || !recordCondition.accepted) {
                        lectureErrors.push({field: "record", message: "Bedingungen-Aufzeichnung"})
                    }
                    // Rights
                    const rightsCondition = lecture.conditions?.find(c => c.name === "rightsContition");
                    if(!rightsCondition || !rightsCondition.accepted) {
                        lectureErrors.push({field: "rights", message: "Bedingungen-Aufzeichnungsrechte"})
                    }
                    // Deadline
                    const deadlineCondition = lecture.conditions?.find(c => c.name === "deadlineContition");
                    if(!deadlineCondition || !deadlineCondition.accepted) {
                        lectureErrors.push({field: "deadline", message: "Bedingungen-Deadline"})
                    }
                    // Lecture Documents
                    const lectureDocumentsCondition = lecture.conditions?.find(c => c.name === "script");
                    if(!lectureDocumentsCondition || !lectureDocumentsCondition.accepted) {
                        lectureErrors.push({field: "script", message: "Vortragsunterlagen-Bedingungen"})
                    }
                   
                }
            


            return ({
                    id: lecture.id,
                    errors: lectureErrors
                })
            });
        
        // errors.forEach((error) => {
        //     console.log(error)
        // })

        setValidationErrors(errors);
    }

    useEffect(() => {
      validateLectures([lecture])
    }, [lecture]);

    const backToEdit = (times) => {
      for (let index = 0; index < times; index++) {
        backClick();
      }
     
    }
  

  return (

<>
<MDBRow className="d-flex justify-content-center text-center">
  <MDBCol size="10" sizeXl="8" sizeXXL="6" className="my-4">
    <MDBCard>
      <MDBCardBody>
        <MDBTypography tag="h5">
          {t("Select the language in which you would like to hold the lecture.")}
        </MDBTypography>
        <MDBRadio name='language' id='language' value='DE' label={t("German")} inline
        checked={lecture.presentationLanguage === "DE" || !lecture.presentationLanguage}
        onChange={() => handleChange({target: {
          value: "DE",
          attributes: {
            datapath: {
              value: "presentationLanguage"
            }
          }
        }})}
         />
        <MDBRadio name='language' id='language' value='EN' label={t("English")} inline
        checked={lecture.presentationLanguage === "EN"}
        onChange={() => handleChange({target: {
          value: "EN",
          attributes: {
            datapath: {
              value: "presentationLanguage"
            }
          }
        }})}
        />
      </MDBCardBody>
    </MDBCard>
  </MDBCol>
</MDBRow>
      <MDBRow>
        <MDBCol size="12" className="d-flex justify-content-center">
          <MDBTypography tag="h2">{t("Lecture Preview")}</MDBTypography>
        </MDBCol>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="10" sizeXl="8" sizeXXL="6" className="mt-4">
            <MDBCard>
              {/* 
              ------HIER MUSS DAS LECTURE BILD EINGEBUNDEN WERDEN--------------
              <MDBCardImage
                src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/${lectureData.image.fileName}"
                alt="lectureData.image.alt"
                position="top"
                style={{ maxHeight: "350px" }}
              />
              */}
              <div className="mx-auto mt-2">
                <MDBCardImage
                  src={lecture?.image?.fileName ? `https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/${lecture.image.fileName}` : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                  alt="..."
                  position="top"
                  className="img-thumbnail"
                  style={{ height: "300px", maxWidth: "100%", width: "auto", aspectRatio: "16 / 9" , objectFit: "cover" }}
                />
              </div>


              <MDBCardHeader>
                <MDBCardTitle className="mt-2">{lecture?.title?.find(t => t.language === i18n.language)?.text || lecture?.title?.[0].text}</MDBCardTitle>
              </MDBCardHeader>
              <MDBCardBody>
              

                <MDBRow>
                  <MDBCol size="12" md="6" className="mt-1 mt-xl-2">
                  <MDBTypography tag="h5">{t("Sub-points")}</MDBTypography>
                    <MDBListGroup>
                      {lecture?.tempSubjects?.map((subject, index) => (
                        <MDBListGroupItem>
                          {subject[i18n.language]}
                        </MDBListGroupItem>
                      ))}
                    </MDBListGroup>
                  </MDBCol>

                  <MDBCol size="12" md="6" className="mt-1 mt-xl-2">
                  <MDBTypography tag="h5">{t("Presention persons")}</MDBTypography>
                    <MDBListGroup>
                      
                  {lecture?.speakers?.map((id, index) => {
                    const employee = employees.find((employee) => employee.id === id);
                    if (!employee) return null;
                    return  <MDBListGroupItem key={index}>
                      <div className="d-flex gap-4 align-items-center">
                      {employee.image?.fileName ? 
                        <img
                          src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                          className="rounded-circle border"
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt={employee.image.alt}
                        />
                       : 
                        <MDBIcon
                          className="rounded-circle border"
                          style={{
                            height: "50px",
                            width: "50px",
                            textAlign: "center",
                            paddingTop: "12px",
                            fontSize: "20px",
                          }}
                          fas
                          icon="user-alt"
                        />
                      }
                      <div>
                        <h6>{`${employee.user.firstName}${employee.user.middleName ? " " + employee.user.middleName : ""} ${employee.user.lastName}`}</h6>
                        <span>{employee.department?.items?.[0]?.department?.name?.find(n => n.language === i18n.language)?.text || employee.department?.items?.[0]?.department?.name?.[0]?.text || ""}</span>
                      </div>
                      </div>
                      
                    </MDBListGroupItem>
                  }
                   
                      )}
                     

                      
                    </MDBListGroup>
                    <div className="mt-1 mt-xl-3">
                      <MDBTypography tag="h5">{t("Topics")}</MDBTypography>
                      <MDBListGroup>
                        {lecture?.topics?.map((topic, index) => (
                          <MDBListGroupItem>
                            {topics.find(t => t.id === topic)?.name?.find(n => n.language === i18n.language)?.text || topics.find(t => t.id === topic)?.name?.[0]?.text || ""}
                          </MDBListGroupItem>
                        ))}
                      </MDBListGroup>
                    </div>
                    
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol size="12" xl="9" className="mx-auto">
          <MDBTypography tag="h5">{t("Marketing")}</MDBTypography>
          <p>{t("We offer you the following options to further market your presentation topic. Not obligatory, we will contact you with further information.")}</p>
          <MDBCheckbox
            label={t("Would you like to publish the contents of the presentation in the ECV's Cleanroom Processes magazine?")}
            checked={lecture?.conditions?.findIndex(c => c.name === "ecv") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "ecv")].accepted : false}
            onChange={e => handleChange({target: {
                value: {
                  name: "ecv",
                  accepted: e.target.checked,
                },
                attributes: {
                  datapath: {
                    value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "ecv") > -1 ? lecture?.conditions?.findIndex(c => c.name === "ecv") : lecture?.conditions?.length) + "]" : "conditions[0]"
                  }}
              }})}
          />

          <MDBCheckbox
          label={t("Would you like to produce a podcast or an interview to market the lecture?")}
          checked={lecture?.conditions?.findIndex(c => c.name === "podcast") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "podcast")].accepted : false}
          onChange={e => handleChange({target: {
                value: {
                  name: "podcast",
                  accepted: e.target.checked,
                },
                attributes: {
                  datapath: {
                    value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "podcast") > -1 ? lecture?.conditions?.findIndex(c => c.name === "podcast") : lecture?.conditions?.length) + "]" : "conditions[0]"
                  }}
              }})}
          />
          </MDBCol>
        </MDBRow>
        {
  validationErrors?.[0]?.errors?.length > 0 ?

<>
        <MDBCol size="12" className="text-center">
          {/* <MDBTypography tag="h6" color="danger">{t("Your lecture is incomplete, but can be saved. It will not yet be taken into account for planning.")}</MDBTypography> */}
          <MDBTypography tag="h6" color="warning">{t("Please fill the following fields:")}</MDBTypography>
        </MDBCol>
        <MDBListGroup style={{ width: "fit-content" }} light small className="text-center mx-auto">
        {validationErrors[0]?.errors?.length > 0 && [...new Set(validationErrors[0]?.errors.map(e => e.message))].map((e) => (
          <MDBListGroupItem className="px-4">{e} <MDBIcon fas icon="edit" onClick={e.includes("Referent") ? () => backToEdit(2) : () => e.startsWith("Bedingungen") ? backToEdit(3) :backToEdit(1)} /></MDBListGroupItem>
        ))}
        
      
        </MDBListGroup>

</>
:
<>
        <MDBCol size="12" className="text-center mt-4">
          <MDBTypography tag="h6" color="success">{t("Your lecture is complete")}</MDBTypography>
        </MDBCol>
</>
}
        <MDBRow>
          <MDBCol size="12" className="d-flex justify-content-center mb-4">
          {isSaving ? <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
              </div> :
              <MDBBtn onClick={() => createLectureHandler()}>
              {t("Save Lecture")}
            </MDBBtn>
              }
            
            <br></br>
            
          </MDBCol>
        </MDBRow>
        {/* <div className="w-100 text-center">
        <p>{t("You can edit the lecture again later. Only complete lectures will be taken into account in the planning")}</p>
        </div> */}
        
      </MDBRow>
    </>
  );
}
