import { useState, useEffect, useContext } from "react";
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCheckbox, MDBChip, MDBCol, MDBCollapse, MDBIcon, MDBInput, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBSelect, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane, MDBTypography } from "mdb-react-ui-kit";

import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { post, get } from 'aws-amplify/api'
import { useTranslation } from "react-i18next";

import UserProfile from '../../components-user/EditProfile';
import EmployeeProfile from "../../components-user/employee-profile/EditEmployeeProfile";
import CreateEmployee from "../../components-dev-admin/manage-company/CreateEmployee";
import RemoveEmployee from "../../components-user/manage-company/RemoveEmployee";
import { Link } from "react-router-dom";
import { isoToGermanDate } from "../../utils/dateTools";
import ShowTicket from "../../components-user/tickets/ShowTicket";
import { deleteTicket } from "../../graphql/mutations";
import { UserContext } from "../../App";




const client = generateClient();


async function getCognitoUser(username) { 
    let apiName = 'AdminQueries';
    let path = '/getUser';
    let options = {
        queryParams: { username },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        } 
    }
    return (await (get({apiName, path, options}).response)).body.json();
}

async function getCognitoUserGroups(username) { 
    let apiName = 'AdminQueries';
    let path = '/listGroupsForUser';
    let options = {
        queryParams: { username },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        } 
    }
    return (await (get({apiName, path, options}).response)).body.json();
}

async function addCognitoUserToGroup(username, groupname) { 
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let options = {
        body: { username, groupname },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        } 
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function removeCognitoUserFromGroup(username, groupname) {
    let apiName = 'AdminQueries';
    let path = '/removeUserFromGroup';
    let options = {
        body: { username, groupname },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function disableCognitoUser(username) {
    let apiName = 'AdminQueries';
    let path = '/disableUser';
    let options = {
        body: { username },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function enableCognitoUser(username) {
    let apiName = 'AdminQueries';
    let path = '/enableUser';
    let options = {
        body: { username },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function signOutCognitoUser(username) {
    let apiName = 'AdminQueries';
    let path = '/signUserOut';
    let options = {
        body: { username },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function resendCognitoUserInvitation(username) {
    let apiName = 'AdminQueries';
    let path = '/resendInvitation';
    let options = {
        body: { username },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function setCognitoUserPassword(username, password) {
    let apiName = 'AdminQueries';
    let path = '/setUserPassword';
    let options = {
        body: { username, password },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function updateAttribute(username, attribute, value, verified) {
    let apiName = 'AdminQueries';
    let path = '/updateUserAttribute';
    let options = {
        body: { username, attribute, value, verified },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (post({apiName, path, options}).response)).body.json();
}

async function getAllCognitoGroups() {
    let apiName = 'AdminQueries';
    let path = '/listGroups';
    let options = {
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).tokens.accessToken.toString()}`
        }
    }
    return (await (get({apiName, path, options}).response)).body.json();
}

export default function Admin_User() {
    const { t, i18n } = useTranslation();
    const { user: currentUser, reloadUser: reloadCurrentUser } = useContext(UserContext);
    const userid =  window.location.pathname.split("/")[3];

    const [loading, setLoading] = useState({
        user: false,
        cognitoUser: false,
        cognitoUserGroups: false,
        resendInvitation: false,
        resetPassword: false,
        disableAccess: false,
        signOut: false,
        updateGroups: false
    });

    const [updatedStates, setUpdatedStates] = useState({});

    useEffect(() => {
        if(Object.keys(updatedStates).length > 0) {
            setTimeout(() => {
                setUpdatedStates({});
            }, 3000);
        }
    }, [updatedStates])
    

    const [user, setUser] = useState({});
    const [cognitoUser, setCognitoUser] = useState({})
    const [cognitoAttributes, setCognitoAttributes] = useState([{}])
    const [cognitoUserGroups, setCognitoUserGroups] = useState([])
    const [allCognitoGroups, setAllCognitoGroups] = useState([]);
    const [openAddGroupCollapse, setOpenAddGroupCollapse] = useState(false);

    const [cognitoCollapseOpen, setCognitoCollapseOpen] = useState(false);
    const [profileTabOpen, setProfileTabOpen] = useState("personal");

    const [addEmployeesModal, setAddEmployeesModal] = useState(false);
    const [removeEmployeeModal, setRemoveEmployeeModal] = useState(false);
    const [selectedEmployeeRemove, setSelectedEmployeeRemove] = useState({companyId: null, employee: null});


    const getUser = async () => {
        try {
            const user = await client.graphql({
                variables: { id: userid },
                query: /* GraphQL */ `
                query GetUser($id: ID!) {
                  getUser(id: $id) {
                    id
                    email
                    firstName
                    middleName
                    title
                    lastName
                    salutation
                    image {
                      fileName
                      alt
                    }
                    birthday
                    aboutMe {
                      language
                      text
                    }
                    contact {
                      phone
                      fax
                      email
                      mobile
                      address {
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                    }
                    employeeProfiles {
                        items {
                          id
                          role
                          user {
                            email
                            firstName
                            middleName
                            title
                            lastName
                            salutation
                          }
                          company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                }
                            }
                          }
                        }
                      }
                    personalIndustry
                    personalDepartment
                    hasCompletedOnboarding
                    x4Id
                    hasInsightAccess
                    tickets {
                        items {
                            id
                            createdByUserId
                            eventDays
                            status
                            paymentStatus
                            type
                            addons
                            notes
                            invitationCode
                            createdAt
                            updatedAt
                            event {
                                id
                                name {
                                language
                                text
                                }
                            }
                            personalAddress {
                                firstName
                                lastName
                            }
                            ticketPaymentId
                            ticketFile {
                                s3Path
                            }
                            __typename
                        }
                        nextToken
                        __typename
                        }
                  }
                }
              `
            });
            console.log("user",user);
            return user.data.getUser;
        } catch (error) {
            console.warn("error fetching user", error);
        }
    }

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await getUser();
                setUser(user);
                const cognitoUser = await getCognitoUser(user.email);
                setCognitoUser(cognitoUser);
                setCognitoAttributes(cognitoUser.UserAttributes);
                const cognitoUserGroups = await getCognitoUserGroups(user.email);
                setCognitoUserGroups(cognitoUserGroups.Groups);
            } catch (error) {
                console.warn("error", error);
            }
        };
        if(userid) fetchData();
    }, [userid]);

    const updateUser = async (user) => {
        try {
            const user = await getUser();
            setUser(user);
        } catch (error) {
            console.warn("error", error);
        }
    }


    const handleRemoveUserFromGroup = async (username, groupname) => {
        try {
            setLoading({ ...loading, updateGroups: true });
            await removeCognitoUserFromGroup(username, groupname);
            setCognitoUserGroups(cognitoUserGroups.filter(group => group.GroupName !== groupname));
            setLoading({ ...loading, updateGroups: false });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, updateGroups: false });
        }
    }

    const [selectedGroupToAdd, setSelectedGroupToAdd] = useState(null);

    const handleAddUserToGroup = async () => {
        try {
            setLoading({ ...loading, updateGroups: true });
            await addCognitoUserToGroup(user.email, selectedGroupToAdd);
            const cognitoUserGroups = await getCognitoUserGroups(user.email);
            setCognitoUserGroups(cognitoUserGroups.Groups);
            setLoading({ ...loading, updateGroups: false });
            setOpenAddGroupCollapse(false);
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, updateGroups: false });
        }
    }
    const startAddGroup = async () => {
        try {
            setOpenAddGroupCollapse(true);
            const groups = await getAllCognitoGroups();
            setAllCognitoGroups(groups.Groups);
        } catch (error) {
            console.warn("error", error);
        }

    }

    const handleResendInvitation = async () => {
        try {
            setLoading({ ...loading, resendInvitation: true });
            await resendCognitoUserInvitation(user.email);
            setLoading({ ...loading, resendInvitation: false });
            setUpdatedStates({ ...updatedStates, resendInvitation: "success" });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, resendInvitation: false });
            setUpdatedStates({ ...updatedStates, resendInvitation: "error" });
        }
    }

    const [newCognitoPassword, setNewCognitoPassword] = useState("");
    const handleResetPassword = async () => {
        try {
            setLoading({ ...loading, resetPassword: true });
            await setCognitoUserPassword(user.email, newCognitoPassword);
            setNewCognitoPassword("");
            setLoading({ ...loading, resetPassword: false });
            setUpdatedStates({ ...updatedStates, resetPassword: "success" });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, resetPassword: false });
            setUpdatedStates({ ...updatedStates, resetPassword: "error" });
        }
    }

    const handleUpdateAttribute = async (attribute, value) => {
        try {
            setLoading({ ...loading, updateAttribute: true });
            await updateAttribute(cognitoUser.Username, attribute, value, (attribute === "email" || attribute === "phone_number") ? true : null);
            setLoading({ ...loading, updateAttribute: false });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, updateAttribute: false });
        }
    }

    const handleTakeOwnership = async () => {
        // get the users attribute "custom:userId", get the cognito user id from the currenty logged in user. update the currently logged in user with the "custom:userId" attribute of the user. reload the auth session and update the current user by using relaodUser.
        try {
           const customUserId = user.id;//cognitoUser.UserAttributes.find(attr => attr.Name === "custom:userId").Value
           const currentUserCognitoId = currentUser.sub
           // Update currentcognito user with custom:userId
            await updateAttribute(currentUserCognitoId, "custom:userId", customUserId, false)
            await reloadCurrentUser()
        } catch (error) { 
            console.warn("error", error);
        }
    }

    const handleDisableAccess = async () => {
        try {
            setLoading({ ...loading, disableAccess: true });
            await disableCognitoUser(user.email);
            setCognitoUser({ ...cognitoUser, Enabled: false });
            setLoading({ ...loading, disableAccess: false });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, disableAccess: false });
        }
    }

    const handleEnableAccess = async () => {
        try {
            setLoading({ ...loading, disableAccess: true });
            await enableCognitoUser(user.email);
            setCognitoUser({ ...cognitoUser, Enabled: true });
            setLoading({ ...loading, disableAccess: false });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, disableAccess: false });
        }
    }

    const handleSignOut = async () => {
        try {
            setLoading({ ...loading, signOut: true });
            await signOutCognitoUser(user.email);
            setLoading({ ...loading, signOut: false });
        } catch (error) {
            console.warn("error", error);
            setLoading({ ...loading, signOut: false });
        }
    }

    const handleCreateEmployeeCallback = () => {
        updateUser();
        setAddEmployeesModal(false);
    }

    const handleRemoveEmployee = (employee) => {  
        setSelectedEmployeeRemove({companyId: employee.company.id, employee: employee});
        setRemoveEmployeeModal(true);
    }

    const handleRemoveEmployeeCallback = () => {
        updateUser();
        setRemoveEmployeeModal(false);
    }


    const [viewTicketModal, setViewTicketModal] = useState(false);
    const toggleViewTicketModal = () => setViewTicketModal(!viewTicketModal);

    const [selectedTicket, setSelectedTicket] = useState(null);
    
    const handleDeleteTicket = async (id) => {
        try {
          const data = await client.graphql({
            query: deleteTicket,
            variables: { input: { id } },
            })
            console.log(data)
            setUser({ ...user, tickets: { items: user.tickets.items.filter(ticket => ticket.id !== id) } });
        }
        catch (error) {
          console.error(error)
        }
    }

    const handleChangeInsightAccess = async (id, access) => {
        //update user on the user.hasInsightAccess field
        try {
          const data = await client.graphql({
            query: /* GraphQL */ `
            mutation UpdateUser(
              $input: UpdateUserInput!
            ) {
              updateUser(input: $input) {
                id
                hasInsightAccess
                }
                }
                `,
            variables: { input: { id, hasInsightAccess: access } },
            })
            console.log(data)
            setUser({ ...user, hasInsightAccess: access });
        }
        catch (error) {
          console.error(error)
        }
    }




  return (
<>
<MDBCard>
    <MDBCardBody>
        <MDBRow>
            <MDBCol md="4">
                <MDBTypography tag="h5" className="float-start">{t("Cognito User")}</MDBTypography>
                <p className="float-end text-muted">{cognitoUser.Username}</p>
                <br></br>
                <div className='d-flex justify-content-between mt-4'>
                <MDBTypography tag="h6">{t("Enabled")}</MDBTypography>
                <p>{cognitoUser.Enabled ? "Yes" : "No"}</p>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                <MDBTypography tag="h6">{t("Confirmation Status")}</MDBTypography>
                <p>{
                    cognitoUser.UserStatus &&
                    (cognitoUser.UserStatus === "CONFIRMED" ?
                    <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Confirmed")}</MDBChip>
                    : cognitoUser.UserStatus === "RESET_REQUIRED" ?
                    <MDBChip className="bg-warning text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Confirmed, must reset password")}</MDBChip>
                    : cognitoUser.UserStatus === "UNCONFIRMED" ?
                    <MDBChip className="bg-danger text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Not confirmed")}</MDBChip>
                    : cognitoUser.UserStatus === "FORCE_CHANGE_PASSWORD" ?
                    <MDBChip className="bg-warning text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Confirmed, use temporary password")}</MDBChip>
                    : cognitoUser.UserStatus === "EXTERNAL_PROVIDER" ?
                    <MDBChip className="bg-info text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Signed in with a third-party IdP")}</MDBChip>
                    : <MDBChip className="bg-danger text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Status isn't known")}</MDBChip>
                    )
                    }</p>
                </div>
                {!cognitoCollapseOpen && <MDBBtn color='primary' size='sm' onClick={() => setCognitoCollapseOpen(!cognitoCollapseOpen)}>{t("Show Details")}</MDBBtn>}
                <MDBCollapse open={cognitoCollapseOpen}>
                <div className='d-flex justify-content-between mt-2'>
                <MDBTypography tag="h6">{t("User Created")}</MDBTypography>
                <p>{cognitoUser.UserCreateDate}</p>
                </div>
                <div className='d-flex justify-content-between mt-2'>
                <MDBTypography tag="h6">{t("Last Modified")}</MDBTypography>
                <p>{cognitoUser.UserLastModifiedDate}</p>
                </div>
                    {cognitoAttributes.map((attribute, index) => {
                        return (
                            <div className='d-flex justify-content-between mt-2' key={index}>
                            <MDBTypography tag="h6">{t(attribute.Name)}</MDBTypography>
                            <div className='d-flex'>
                            {attribute.Name === "sub" ?
                            <p>{attribute.Value}</p>
                            :
                            attribute.Name === "email_verified" || attribute.Name === "phone_number_verified" ?
                            <div 
                                className="pointer"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    const newValue = attribute.Value === "true" ? "false" : "true";
                                    setCognitoAttributes(cognitoAttributes.map((attr, i) => {
                                        if(i === index) {
                                            return { ...attr, Value: newValue }
                                        }
                                        return attr;
                                    }));
                                    handleUpdateAttribute(attribute.Name, newValue);
                                }}
                            >
                                {attribute.Value === "true" ? 
                                    <MDBIcon fas icon="check" className="text-success" size="lg" /> : 
                                    <MDBIcon fas icon="times" className="text-danger" size="lg" />
                                }
                            </div>
                            :
                            <>
                            <MDBInput type='text' value={attribute.Value} onChange={e => {
                                    setCognitoAttributes(cognitoAttributes.map((attr, i) => {
                                        if(i === index) {
                                            return { ...attr, Value: e.target.value }
                                        }
                                        return attr;
                                    }))
                                }} />
                                <MDBBtn color='primary' size='sm' onClick={() => handleUpdateAttribute(attribute.Name, attribute.Value)}><MDBIcon fas icon="check" /></MDBBtn>
                            </>
                            }
                                
                            </div>
                            </div>
                        )
                    })}

                <MDBBtn color='primary' size='sm' onClick={() => setCognitoCollapseOpen(!cognitoCollapseOpen)}>{t("Hide Details")}</MDBBtn>
                </MDBCollapse>
                
            </MDBCol>
            <MDBCol md="4">
            <MDBTypography tag="h5">{t("Access settings")}</MDBTypography>
            {loading.resendInvitation ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> :
            cognitoUser.UserStatus === "FORCE_CHANGE_PASSWORD" &&
            <div className='d-flex justify-content-between mt-3'>
                <MDBTypography tag="h6">{t("Resend Invitation")}</MDBTypography>
                <MDBBtn color={updatedStates.resendInvitation ? (updatedStates.resendInvitation === "success" ? "success" : "danger") : "primary"} size='sm' onClick={() => handleResendInvitation()}>{t("Resend")}</MDBBtn>
            </div>
            }
            {loading.resetPassword ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> :
            <div className='d-flex justify-content-between mt-3'>
                <MDBTypography tag="h6">{t("Reset password")}</MDBTypography>
                <input type='text' value={newCognitoPassword} onChange={e => setNewCognitoPassword(e.target.value)} />
                <MDBBtn color={updatedStates.resetPassword ? (updatedStates.resetPassword === "success" ? "success" : "danger") : "primary"} size='sm' onClick={() => handleResetPassword()}>{t("Reset")}</MDBBtn>
            </div>
            }
            {loading.disableAccess 
            ? 
            <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>
            : (cognitoUser.Enabled ?
            <div className='d-flex justify-content-between mt-3'>
                <MDBTypography tag="h6">{t("Disable Access")}</MDBTypography>
                <MDBBtn color='primary' size='sm' onClick={() => handleDisableAccess()}>{t("Disable")}</MDBBtn>
            </div>
            :
            <div className='d-flex justify-content-between mt-3'>
                <MDBTypography tag="h6">{t("Enable Access")}</MDBTypography>
                <MDBBtn color='primary' size='sm' onClick={() => handleEnableAccess()}>{t("Enable")}</MDBBtn>
            </div>
            )
            }
            {loading.disableAccess ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> :
            <div className='d-flex justify-content-between mt-3'>
                <MDBTypography tag="h6">{t("Sign out")}</MDBTypography>
                <MDBBtn color='primary' size='sm' onClick={() => handleSignOut()}>{t("Sign out")}</MDBBtn>
            </div>
            }
                <div className='d-flex justify-content-between mt-3'>
                <MDBTypography tag="h6">{t("Take over access")}</MDBTypography>
                <MDBBtn color='primary' disabled={!currentUser?.sub} size='sm' onClick={() => handleTakeOwnership()}>{t("Take")}</MDBBtn>
            </div>
            <hr></hr>
            <MDBTypography tag="h5">{t("User groups")}<MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light' onClick={() => startAddGroup()}>{t("Add")}</MDBBtn></MDBTypography>
            <MDBCollapse open={openAddGroupCollapse}>
                {allCognitoGroups.length > 0 && 
                <MDBSelect   
                    label='Select group'
                    data={allCognitoGroups.map(group => { return { text: group.GroupName, value: group.GroupName }})}
                    onChange={val => { setSelectedGroupToAdd(val.value) }}
                />}
                <div className="my-1 float-end">
                    <MDBBtn color='primary' size='sm' className="me-1" onClick={() => setOpenAddGroupCollapse(!openAddGroupCollapse)}>{t("Cancel")}</MDBBtn>
                    <MDBBtn color='primary' size='sm' onClick={() => handleAddUserToGroup()}>{t("Add")}</MDBBtn>
                </div>
                
            </MDBCollapse>

            {loading.updateGroups ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> :
            <MDBListGroup>
            {cognitoUserGroups?.map(group => {
                return (
                    <MDBListGroupItem key={group.GroupName}>
                    {group.GroupName}
                    <MDBIcon icon='times' className='float-end' onClick={() => handleRemoveUserFromGroup(user.email, group.GroupName)} />
                    </MDBListGroupItem>
                )
            })}
            </MDBListGroup>
            }
            </MDBCol>
            <MDBCol md="4">
                 <MDBTypography tag="h5">{t("Onboarding Status")}</MDBTypography>
                 {user.hasCompletedOnboarding ? <p>{t("Completed")}</p> : <p>{t("Not completed")}</p>}
                <MDBTypography tag="h5">{t("Connections")}<MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light' onClick={() => setAddEmployeesModal(true)}>{t("Add")}</MDBBtn></MDBTypography>
                { user.employeeProfiles?.items?.length > 0 && user.employeeProfiles.items.map((profile, index) => {
                    return (
                        <div className='d-flex justify-content-between align-items-center mt-3'>
                        <MDBTypography tag="h6">{profile.role}</MDBTypography>
                        <Link to={"/admin/company/" + profile.company.id}>
                            <div className='d-flex align-items-center'>
                            {profile.company.image?.main?.fileName ?
                                <img
                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+profile.company.image.main.fileName}
                                    className=""
                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                    alt={profile.company.name}
                                />
                            :
                                <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                        
                            }
                            <div className='ms-3'>
                            <p className='fw-bold mb-1'>{profile.company.name}</p>
                            </div>
                            </div>
                            </Link>
                            <MDBIcon icon='times' className='float-end' onClick={() => handleRemoveEmployee(profile)} />
                        </div>
                        
                    )

                }
                )}
                
            </MDBCol>
            
        </MDBRow>
    </MDBCardBody>
</MDBCard>
<MDBCard className="mt-4">
    <MDBCardBody>
    <MDBTabs className='mb-3'>
        <MDBTabsItem>
            <MDBTabsLink onClick={() => setProfileTabOpen("personal")} active={profileTabOpen === 'personal'}>
            <MDBIcon fas icon='sliders-h' className='me-2' /> {t("Personal Profile")}
            </MDBTabsLink>
        </MDBTabsItem>
        {user.employeeProfiles?.items?.length > 0 && user.employeeProfiles.items.map((profile, index) =>  <MDBTabsItem key={index}>
            <MDBTabsLink onClick={() => setProfileTabOpen(profile.id)} active={profileTabOpen === profile.id}>
            <MDBIcon fas icon='user-tie' className='me-2' /> {profile.company.name}
            </MDBTabsLink>
        </MDBTabsItem>
        )}
        <MDBTabsItem>
            <MDBTabsLink onClick={() => setProfileTabOpen("tickets")} active={profileTabOpen === 'tickets'}>
            <MDBIcon fas icon='sliders-h' className='me-2' /> {t("Tickets")}
            </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
            <MDBTabsLink onClick={() => setProfileTabOpen("insight")} active={profileTabOpen === 'insight'}>
            <MDBIcon fas icon='video' className='me-2' /> ACADEMY
            </MDBTabsLink>
        </MDBTabsItem>
    </MDBTabs>
    <MDBTabsContent>
        <MDBTabsPane open={profileTabOpen === 'personal'}><UserProfile presetUserId={userid} adminOptions={true} /></MDBTabsPane>
        {user.employeeProfiles?.items?.length > 0 && user.employeeProfiles.items.map((profile, index) =>  <MDBTabsPane key={index} open={profileTabOpen === profile.id}><EmployeeProfile employeeId={profile.id} isActive={profileTabOpen === profile.id} /></MDBTabsPane>
        )}
        <MDBTabsPane open={profileTabOpen === 'tickets'}>
            <MDBTable>
            <MDBTableHead>
                <tr>
                <th>{t("Product")}</th>
                    <th>{t("Event")}</th>
                    <th>{t("Details")}</th>
                    <th>{t("Booking date")}</th>
                    <th>{t("Payment status")}</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {user.tickets?.items?.map((ticket, index) => (
                    <tr key={index}>
                        <td>{ticket.type === "default" ?
                        t("Visitor Ticket") : t("Premium ticket")
                        }</td>
                        <td>{ticket.event.name.find(name => name.language === i18n.language)?.text || ticket.event.name[0]?.text}</td>
                        <td>
                        {ticket.personalAddress?.firstName} {ticket.personalAddress?.lastName} <br/>
                        {ticket.eventDays.map((day, index) => (index !== 0 ? ", " : "" ) +isoToGermanDate(day))}
                        </td>
                        <td>{isoToGermanDate(ticket.createdAt)}</td>
                        <td>{ticket.paymentStatus === "paid" ? <div className="text-success"><MDBIcon icon='check' /> {t("Paid")}</div> : <div className="text-danger"><MDBIcon icon='times' /> {t("Unpaid")}</div>}</td>
                        <td>
                            <MDBBtnGroup>
                                <MDBBtn color="primary" size="sm"
                                    onClick={() => {
                                        setSelectedTicket(ticket)
                                        toggleViewTicketModal()
                                    }}
                                    >{t("View")}</MDBBtn>
{/*                             
                                    <MDBBtn color="danger" size="sm"
                                    onClick={() => {
                                        if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                                            handleDeleteTicket(ticket.id)
                                        }
                                    }}
                                    >{t("Delete")}</MDBBtn> */}

                            </MDBBtnGroup>
                        </td>
                    </tr>
                ))
                }
            </MDBTableBody>
        </MDBTable>
        </MDBTabsPane>
        <MDBTabsPane open={profileTabOpen === 'insight'}>
            <MDBTypography tag="h5">{t("Basics Access")}</MDBTypography>
            <MDBCheckbox
                label={t("Basics Access")}
                checked={user.hasInsightAccess}
                onChange={() => handleChangeInsightAccess(user.id, !user.hasInsightAccess)}
            />

            </MDBTabsPane>

    </MDBTabsContent>
    </MDBCardBody>
</MDBCard>
{/* Modals */}
{/* Create Employee */}
<MDBModal open={addEmployeesModal} onClose={() => setAddEmployeesModal(false)} staticBackdrop>
    <MDBModalDialog centered size="lg" style={{zIndex: "9"}}>
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Create employee account")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={() => setAddEmployeesModal(!addEmployeesModal)}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
            <CreateEmployee presetUserId={userid} doneCallback={() => handleCreateEmployeeCallback()} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
{/* Remove Employee */}
<MDBModal open={removeEmployeeModal} onClose={() => setRemoveEmployeeModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove employee")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={() => setRemoveEmployeeModal(!removeEmployeeModal)}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <RemoveEmployee companyId={selectedEmployeeRemove.companyId} isActive={removeEmployeeModal} toggleModal={() => handleRemoveEmployeeCallback()} employee={selectedEmployeeRemove.employee} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={viewTicketModal} onClose={() => setViewTicketModal(false)} position="top">
    <MDBModalDialog>
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Your ticket")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleViewTicketModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <ShowTicket ticketId={selectedTicket?.id} handleDeleteTicket={handleDeleteTicket} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
