import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBFile, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import lectureSchema from '../../../validation/LectureValidation';
import { isoToGermanDate } from "../../../utils/dateTools";
import { getUrl, uploadData, remove } from "aws-amplify/storage";
import { fetchAuthSession } from "aws-amplify/auth";
import ReactCountryFlag from "react-country-flag";
import ReactPlayer from 'react-player';
import { useIndustry } from "../../../utils/industryContextHook";
import { UserContext } from "../../../App";

const client = generateClient();
export default function AllLectures({adminActions}) {
    const { t, i18n } = useTranslation();
    const { currentIndustry } = useIndustry();
    const { user } = useContext(UserContext);
    
    // Check if the current user has the CompanyAdmin group
    const isCompanyAdmin = user?.["cognito:groups"]?.includes('CompanyAdmin');
    
    const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];

   
    const [lectures, setLectures] = useState([]);

    const getLectures = async () => {
        let allLectures = [];
        let nextToken = null;
        try {
            do {
                const response = await client.graphql({
                    variables: {
                        filter: {
                            companyLecturesId: {
                                eq: companyid
                            },
                            createdAt: {
                                gt: "2023-10-01T00:00:00.000Z"
                            },
                            type: {
                                regexp: "(marketing|technical|educational|marketing_wien|educational_wien|technical_wien)"
                            },
                            industryLecturesId: {
                                eq: currentIndustry?.id
                            }
                        },
                        limit: 1000,
                        nextToken: nextToken
                    },
                    query: /* GraphQL */ `
                    query SearchLectures(
                      $filter: SearchableLectureFilterInput
                      $sort: [SearchableLectureSortInput]
                      $limit: Int
                      $nextToken: String
                      $from: Int
                      $aggregates: [SearchableLectureAggregationInput]
                    ) {
                      searchLectures(
                        filter: $filter
                        sort: $sort
                        limit: $limit
                        nextToken: $nextToken
                        from: $from
                        aggregates: $aggregates
                      ) {
                        items {
                            title {
                                language
                                text
                            }
                            event {
                                id
                                startDate
                                lectureEditEndDate
                                lectureDocumentsEndDate
                                lecturePresentationEndDate
                                name {
                                    language
                                    text
                                }
                            }
                            speakers {
                                items {
                                    employee {
                                        image {
                                            fileName
                                            alt
                                        }
                                    }
                                }
                            }
                            subjects {
                                id
                                name {
                                    language
                                    text
                                }
                            } 
                            topics {
                                items {
                                  topic {
                                    id
                                    name {
                                      language
                                      text
                                    }
                                  }
                                }
                            }         
                            content {
                                language
                                text
                            }
                            conditions {
                                name
                                accepted
                            }
                            image {
                                fileName
                                alt
                            }
                            pdfDe {
                                url
                                name
                                fileName
                                s3Path  
                            }
                            pdfEn {
                                url
                                name
                                fileName
                                s3Path  
                            }
                            presentation {
                                url
                                name
                                fileName
                                s3Path  
                            }
                            videoS3Key {
                                s3Path
                            }
                            company {
                                id
                                name
                                image {
                                    main {
                                        fileName
                                        alt
                                    }
                                }
                            }
                            videoProgress {
                                items {
                                    id
                                    userId
                                    user {
                                      id
                                      title
                                      firstName
                                      lastName
                                      image {
                                        fileName
                                      }
                                      contact {
                                          address {
                                              company
                                          }
                                      }
                                    }
                                    videoId
                                    progressInSeconds
                                    totalWatchedInSeconds
                                }
                            }
                            id
                            session
                            adminComment
                            room
                            type
                            presentationLanguage
                            createdAt
                            updatedAt
                            companyLecturesId
                            eventLecturesId
                            startTime
                            endTime
                            lectureMainSpeakerId
                            __typename
                        }
                        nextToken
                        total
                        aggregateItems {
                          name
                          result {
                            ... on SearchableAggregateScalarResult {
                              value
                            }
                            ... on SearchableAggregateBucketResult {
                              buckets {
                                key
                                doc_count
                                __typename
                              }
                            }
                          }
                          __typename
                        }
                        __typename
                      }
                    }
                    `
                });
                const lecturesBatch = response.data.searchLectures.items;
                allLectures = allLectures.concat(lecturesBatch);
                nextToken = response.data.searchLectures.nextToken;
            } while (nextToken);
            console.log("lectures", allLectures);
            setLectures(
                allLectures
                    .map(lecture => ({
                        ...lecture,
                        topics: lecture.topics.items.map(topic => topic.topic)
                    }))
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            );
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items)
                setLectures(error.data.searchLectures.items);
        }
    };

    const [events, setEvents] = useState([]);
    const getCompanyAttendingEvents = async (companyId) => {
        // Get company attending events by getting listBookedBooths with filter companyBookedBoothsId
        try {
            let companyBookedBooths = []; let nextToken = null;
            do {
                const response = await client.graphql({
                    query: /* GraphQL */ `query ListBookedBooths(
                        $filter: ModelBookedBoothFilterInput
                        $limit: Int
                        $nextToken: String
                    ) {
                        listBookedBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
                            items {
                                id
                                event {
                                    id
                                }
                            }
                            nextToken
                        }
                    }`,
                    variables: {
                        filter: {
                            companyBookedBoothsId: {
                                eq: companyId
                            }
                        },
                        limit: 100, // Adjust the limit as needed
                        nextToken: nextToken
                    }
                });

                companyBookedBooths = companyBookedBooths.concat(response.data.listBookedBooths.items);
                nextToken = response.data.listBookedBooths.nextToken;
            } while (nextToken);
            return companyBookedBooths.map(item => item.event.id);
        } catch (error) {
            console.log("error on fetching company attending events", error);
        }
    }
    const getEvents = async () => {
        try {
            // Get events by getting industry events
            const companyAttendingEventIds = await getCompanyAttendingEvents(companyid);
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListIndustryEvents(
                    $filter: ModelIndustryEventsFilterInput
                    $limit: Int
                    $nextToken: String
                    ) {
                    listIndustryEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        event {
                        id
                        startDate
                        lectureRegistrationStartDate
                        lectureRegistrationEndDate
                        lectureEditEndDate
                        lecturePresentationEndDate
                        lectureDocumentsEndDate
                        status
                        name {
                            language
                            text
                        }
                        }
                    }
                    nextToken
                    }
                }
                `,
                variables: {
                    filter: {
                       and:{
                        industryId: {
                            eq: currentIndustry?.id
                        },
                        or: companyAttendingEventIds.map(id => ({
                            eventId: {
                                eq: id
                            }
                        }))
                       }
                    }
                }
            });
            // Filter events that are active and have startDate in the future
            setEvents(events.data.listIndustryEvents.items
                .filter(event => event.event.status === "active" && new Date(event.event.lectureRegistrationStartDate) <= new Date() && new Date(event.event.lectureRegistrationEndDate) >= new Date())
                .map(event => event.event));
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }


    useEffect(() => {
        getEvents();
        getLectures();
    }, [currentIndustry]);

    const [validationErrors, setValidationErrors] = useState([]);

    const validateLectures = (lectures) => {
        let errors = lectures.map((lecture) => {

            let lectureErrors = lectureSchema.validate(lecture).map((error) => ({
                field: error.path,
                message: error.message
            })).filter(e => e.message)

            // remove conditions
            lectureErrors = lectureErrors.filter((error) => error.message !== "Bedingungen");

            // Custom validation for conditions
            if(lecture.type === "marketing") {
                // Check image and speaker conditions
                // Image
                if(lecture.image?.fileName) {
                    const imageCondition = lecture.conditions?.find(c => c.name === "image");
                    if(!imageCondition || !imageCondition.accepted) {
                        lectureErrors.push({field: "image", message: "Bildrechte"})
                    }
                }
                // Speaker
                if(lecture.speakers?.items?.length > 0) {
                    const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                    if(!speakerCondition || !speakerCondition.accepted) {
                        lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                    }
                }
               
                } else if (lecture.type === "technical" || lecture.type === "educational") {
                    if(!lecture.conditions) {
                        console.log("no conditions", lecture.id, lecture.conditions)

                    }
                    // Check image and speaker conditions and adCondition, recordCondition, rightsCondition, deadlineCondition
                    // Image
                    if(lecture.image?.fileName) {
                        const imageCondition = lecture.conditions?.find(c => c.name === "image");
                        if(!imageCondition || !imageCondition.accepted) {
                            lectureErrors.push({field: "image", message: "Bildrechte"})
                        }
                    }
                    // Speaker
                    if(lecture.speakers?.items?.length > 0) {
                        const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                        if(!speakerCondition || !speakerCondition.accepted) {
                            lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                        }
                    }
                    // Ad
                    const adCondition = lecture.conditions?.find(c => c.name === "adCondition");
                    if(!adCondition || !adCondition.accepted) {
                        lectureErrors.push({field: "ad", message: "Bedingungen-Werbefrei"})
                    }
                    // Record
                    const recordCondition = lecture.conditions?.find(c => c.name === "recordCondition");
                    if(!recordCondition || !recordCondition.accepted) {
                        lectureErrors.push({field: "record", message: "Bedingungen-Aufzeichnung"})
                    }
                    // Rights
                    const rightsCondition = lecture.conditions?.find(c => c.name === "rightsContition");
                    if(!rightsCondition || !rightsCondition.accepted) {
                        lectureErrors.push({field: "rights", message: "Bedingungen-Aufzeichnungsrechte"})
                    }
                    // Deadline
                    const deadlineCondition = lecture.conditions?.find(c => c.name === "deadlineContition");
                    if(!deadlineCondition || !deadlineCondition.accepted) {
                        lectureErrors.push({field: "deadline", message: "Bedingungen-Deadline"})
                    }
                    // Script
                    const scriptCondition = lecture.conditions?.find(c => c.name === "script");
                    if(!scriptCondition || !scriptCondition.accepted) {
                        lectureErrors.push({field: "script", message: "Vortragsunterlagen-Bedingungen"})
                    }
                   
                }
            


            return ({
                    id: lecture.id,
                    errors: lectureErrors
                })
            });
        
        // errors.forEach((error) => {
        //     console.log(error)
        // })

        setValidationErrors(errors);
    }
    
    useEffect(() => {
        if(lectures.length > 0) {
            setTimeout(() => {
                validateLectures(lectures)
            }, 500);
        }
    }, [lectures]);


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }

    const canEditLecture = (lecture) => {
        // Wenn der Vortrag einem Event zugeordnet ist und das Event bereits begonnen hat oder lectureEditEndDate des Events überschritten ist
        if ((lecture.event?.startDate && new Date(lecture.event.startDate) <= new Date()) || (lecture.event?.lectureEditEndDate && new Date(lecture.event.lectureEditEndDate) < new Date())) {
            return false;
        }
        return true;
    };
    
    const canDeleteLecture = (lecture) => {
        // Wenn der Vortrag bereits eine Startzeit hat, kann er nicht gelöscht werden
        if (lecture.startTime) {
            return false;
        }
        return true;
    };


    // Video
    // Video Upload, View and Remove Modal
    const [videoModal, setVideoModal] = useState(false);
    const [selectedLectureVideo, setSelectedLectureVideo] = useState(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [lectureVideosUploadStatus, setLectureVideosUploadStatus] = useState({});
    const hanleOpenVideoModal = async (lectureId) => {
        setSelectedLectureVideo(lectureId);
        const videoKey = lectures.find(l => l.id === lectureId)?.videoS3Key?.s3Path;
        if (videoKey) {
            const videoUrl = await getVideoUrl(videoKey);
            setSelectedVideoUrl(videoUrl);
        }
        toggleVideoModal();
    }
    const toggleVideoModal = () => setVideoModal(!videoModal);

    const getVideoUrl = async (videoKey) => {
        try {
            const result = await getUrl({
                key: videoKey,
                level: 'public'
            });
            console.log("video url", result.url.href);
            return result.url.href;
        } catch (error) {
            console.log("error on getting video url", error);
        }
    }

    // Statistics Modal
    const [statisticsModal, setStatisticsModal] = useState(false);

    const handleOpenStatisticsModal = (lectureId) => {
        setSelectedLectureVideo(lectureId);
        setStatisticsModal(true);
    }
  

    // Document Upload Modal
    const [documentUploadModal, setDocumentUploadModal] = useState(false);
    const [selectedLectureForDocuments, setSelectedLectureForDocuments] = useState(null);
    const toggleDocumentUploadModal = () => setDocumentUploadModal(!documentUploadModal);
    
    const handleOpenDocumentUploadModal = (lectureId) => {
        setSelectedLectureForDocuments(lectureId);
        toggleDocumentUploadModal();
    };

    // File upload states
    const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState({
        De: false,
        En: false,
        Presentation: false
    });
    
    const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState({
        De: null,
        En: null,
        Presentation: null
    });

    const handleSetFile = async (file, fileType) => {
        if (!file || !selectedLectureForDocuments) return;
        
        try {
            // fileType can be 'De', 'En', or 'Presentation'
            setIsUploadingLectureFiles({...isUploadingLectureFiles, [fileType]: true});
            
            // Upload file to S3
            const result = await uploadData({
                key: file.name,
                data: file,
                options: {
                    accessLevel: 'protected'
                }
            }).result;
            
            console.log('Upload succeeded: ', result);
            
            // Get the file URL
            const fileURL = await getUrl({ 
                key: result.key, 
                options: { level: 'protected' } 
            });
            
            // Update the lecture with the file info
            const identityId = (await fetchAuthSession()).identityId;
            
            // Determine which field to update based on fileType
            let updateField = {};
            if (fileType === 'De' || fileType === 'En') {
                updateField[`pdf${fileType}`] = {
                    fileName: result.key,
                    name: result.key,
                    s3Path: `protected/${identityId}/${result.key}`,
                    updatedAt: new Date().toISOString()
                };
            } else if (fileType === 'Presentation') {
                updateField.presentation = {
                    fileName: result.key,
                    name: result.key,
                    s3Path: `protected/${identityId}/${result.key}`,
                    updatedAt: new Date().toISOString()
                };
            }
            
            const updatedLecture = await client.graphql({
                variables: {
                    input: {
                        id: selectedLectureForDocuments,
                        ...updateField
                    }
                },
                query: /* GraphQL */ `
                mutation UpdateLecture($input: UpdateLectureInput!) {
                    updateLecture(input: $input) {
                        id
                        pdfDe {
                            fileName
                            name
                            s3Path
                            updatedAt
                        }
                        pdfEn {
                            fileName
                            name
                            s3Path
                            updatedAt
                        }
                        presentation {
                            fileName
                            name
                            s3Path
                            updatedAt
                        }
                    }
                }
                `
            });
            
            // Update the lecture in the local state
            setLectures(lectures.map(lecture => 
                lecture.id === selectedLectureForDocuments 
                    ? {
                        ...lecture, 
                        ...(fileType === 'De' || fileType === 'En' 
                            ? { [`pdf${fileType}`]: updatedLecture.data.updateLecture[`pdf${fileType}`] }
                            : { presentation: updatedLecture.data.updateLecture.presentation })
                      } 
                    : lecture
            ));
            
            setIsUploadingLectureFiles({...isUploadingLectureFiles, [fileType]: false});
            setUploadingLecturesErrors({...uploadingLecturesErrors, [fileType]: null});
            
        } catch (error) {
            console.log("Error uploading file:", error);
            setIsUploadingLectureFiles({...isUploadingLectureFiles, [fileType]: false});
            setUploadingLecturesErrors({
                ...uploadingLecturesErrors, 
                [fileType]: error.errors?.[0].message || error.message
            });
        }
    };

    const downloadFile = async (fileName, path) => {
        try {
            const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
            const match = path.match(regex);
            const identity = match[1];
            
            const getUrlResult = await getUrl({
                key: fileName,
                options: {
                    accessLevel: 'protected',
                    targetIdentityId: identity,
                },
            });
            
            console.log('Signed URL: ', getUrlResult.url);
            window.open(getUrlResult.url, '_blank');
        } catch (error) {
            console.log("Error downloading file:", error);
        }
    };

    const removeFile = async (fileName, path, fileType, lectureId) => {
        try {
            await remove({ key: fileName, options: { accessLevel: 'protected' } });
            
            // Update field based on fileType
            let updateField = {};
            if (fileType === 'De' || fileType === 'En') {
                updateField[`pdf${fileType}`] = null;
            } else if (fileType === 'Presentation') {
                updateField.presentation = null;
            }
            
            // Update the lecture in the database
            const updatedLecture = await client.graphql({
                variables: {
                    input: {
                        id: lectureId,
                        ...updateField
                    }
                },
                query: /* GraphQL */ `
                mutation UpdateLecture($input: UpdateLectureInput!) {
                    updateLecture(input: $input) {
                        id
                        pdfDe {
                            fileName
                            name
                            s3Path
                        }
                        pdfEn {
                            fileName
                            name
                            s3Path
                        }
                        presentation {
                            fileName
                            name
                            s3Path
                        }
                    }
                }
                `
            });
            
            // Update the lecture in the local state
            setLectures(lectures.map(lecture => 
                lecture.id === lectureId 
                    ? {
                        ...lecture,
                        ...(fileType === 'De' ? { pdfDe: null } : {}),
                        ...(fileType === 'En' ? { pdfEn: null } : {}),
                        ...(fileType === 'Presentation' ? { presentation: null } : {})
                      } 
                    : lecture
            ));
            
        } catch (error) {
            console.log("Error removing file:", error);
        }
    };

    const canUploadDocuments = (lecture) => {
        // Check if user is CompanyAdmin and if lectureDocumentsEndDate exists and hasn't passed yet
        return isCompanyAdmin && 
               lecture.event?.lectureDocumentsEndDate && 
               new Date(lecture.event.lectureDocumentsEndDate) >= new Date();
    };

    const canUploadPresentation = (lecture) => {
        // Check if user is CompanyAdmin and if lecturePresentationEndDate exists and hasn't passed yet
        return isCompanyAdmin && 
               lecture.event?.lecturePresentationEndDate && 
               new Date(lecture.event.lecturePresentationEndDate).setHours(23, 59, 59, 999) >= new Date();
    };

    const [presentationUploadModal, setPresentationUploadModal] = useState(false);
    const togglePresentationUploadModal = () => setPresentationUploadModal(!presentationUploadModal);

    const handleOpenPresentationUploadModal = (lectureId) => {
        setSelectedLectureForDocuments(lectureId);
        togglePresentationUploadModal();
    };

  return (
<>

<MDBRow className="mb-3 mb-lg-5">
    {events.length > 0 && events.map((event) => (
        <MDBCol size="12" lg="5" key={event.id}>
            <MDBCard className="mx-auto text-center" style={event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate) > new Date() ? {backgroundColor: "#c0c0c0"} : {}}>
                <MDBCardBody>
                    <MDBCardTitle>
                        <h2>{event.name.find(n => n.language === i18n.language)?.text || event.name[0].text}</h2>
                    </MDBCardTitle>
                    {event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate) <= new Date() &&
                    <h5>{t("Register Lectures for ")} {event.name.find(n => n.language === i18n.language)?.text || event.name[0].text} {t("now.")}</h5>
                    }
                    {event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate) > new Date() ?
                        <p>{t("Registration will start on")} {isoToGermanDate(event.lectureRegistrationStartDate)}</p>
                        :
                        <Link to={`/mycompany/${companyid}/register-lecture?eventId=${event.id}`}>
                            <MDBBtn>{t("Register Lecture")}</MDBBtn>
                        </Link>
                    }
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    ))}
</MDBRow>

<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>
            <h2>{t("Lectures")}</h2>
        </MDBCardTitle>
        <MDBTable>
            <MDBTableHead>
                <tr>
                <th>{t("Event")}</th>
                    <th>{t("Title")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Speakers")}</th>
                    <th>{t("Date, Time & Room")}</th>
                    <th>{t("Status")}</th>
                    <th width="250">{t("Documents")}</th>
                    <th width="150">{t("Recording")}</th>
                    <th>{t("Actions")}</th>

                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {lectures.length > 0 ? 
                    lectures.map((lecture) => (
                        <tr key={lecture.id}>
                        <td>
                            {lecture.event?.name && (lecture.event?.name.find(n => n.language === i18n.language)?.text || lecture.event?.name?.[0]?.text)}
                        </td>
                            <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                            <td>{t(lecture.type)}</td>
                            <td>{lecture.speakers?.items?.map(speaker => <>
                            {speaker?.employee?.image?.fileName ?
                            <img
                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                }}
                                alt={speaker.employee?.image.alt}
                            />
                            :
                            <MDBIcon
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    textAlign: "center",
                                    paddingTop: "12px",
                                    fontSize: "20px",
                                }}
                                fas
                                icon="user-alt"
                            />
                            }
                            </>)}</td>
                            <td>{lecture.startTime ? <>
                                {isoToGermanDate(lecture.startTime)}<br></br>
                                {lecture.startTime.substring(11, 16)} - {lecture.endTime.substring(11, 16)}<br></br>
                                {lecture.room ? t("Room") + ": " + lecture.room : t("not yet determined")}
                            </> : t("not yet determined")}</td>
                            <td>{
                                validationErrors.find(error => error.id === lecture.id)?.errors.length > 0 ?
                                    ( <div><p className="text-warning">{t("Incomplete")}</p>{[...new Set(validationErrors.find(error => error.id === lecture.id).errors.map(error => error.message))].join(", ")}</div>
                                    )
                                    :
                                    <p className="text-success">{t("Complete")}</p>
                                }
                                {lecture.adminComment && lecture.adminComment.length > 2  &&
                                    <p className="text-warning">{t("Admin Comment")}: <br/>{lecture.adminComment}</p>
                                }
                            </td>
                            <td>
                                <div className="mb-2 text-center">
                                    <span>{t("Visitor Docs")}:</span>
                                    <div className="d-flex align-items-center justify-content-center">
                                        DE
                                        {lecture.pdfDe?.fileName ? 
                                            <MDBIcon fas icon="check" className="text-success me-1" title={t("German document available")} />
                                            : 
                                            <MDBIcon fas icon="times" className="text-danger me-1" title={t("No German document")} />
                                        }
                                        EN
                                        {lecture.pdfEn?.fileName ? 
                                            <MDBIcon fas icon="check" className="text-success me-1" title={t("English document available")} />
                                            : 
                                            <MDBIcon fas icon="times" className="text-danger me-1" title={t("No English document")} />
                                        }
                                        {isCompanyAdmin &&
                                            <MDBBtn 
                                                size="sm"
                                                color='tertiary'
                                                rippleColor='light'
                                                className="text-body"
                                                disabled={!canUploadDocuments(lecture)}
                                                onClick={() => handleOpenDocumentUploadModal(lecture.id)}
                                            >
                                                <MDBIcon fas icon="upload" />
                                            </MDBBtn>
                                        }
                                    </div>
                                </div>
                                <div className="text-center">
                                <span>{t("Presentation")}:</span>
                                    <div className="d-flex align-items-center justify-content-center">
                                        
                                        {lecture.presentation?.fileName ? 
                                            <MDBIcon fas icon="check" className="text-success me-1" title={t("Presentation available")} />
                                            : 
                                            <MDBIcon fas icon="times" className="text-danger me-1" title={t("No presentation")} />
                                        }
                                        {isCompanyAdmin &&
                                            <MDBBtn 
                                                size="sm"
                                                color='tertiary'
                                                rippleColor='light'
                                                className="text-body"
                                                disabled={!canUploadPresentation(lecture)}
                                                onClick={() => handleOpenPresentationUploadModal(lecture.id)}
                                            >
                                                <MDBIcon fas icon="upload" />
                                            </MDBBtn>  
                                        }
                                    </div>
                                </div>
                                {!isCompanyAdmin && <span className="text-muted">{t("Only the company admin can upload the documents.")}</span>}
                            </td>
                            <td>
                                {lecture.videoS3Key?.s3Path &&
                                    <>
                                    <MDBBtn color='link' className="px-2" onClick={() => hanleOpenVideoModal(lecture.id)}>{t("View recording")}</MDBBtn>
                                    <br/>
                                    <MDBBtn color='link' className="px-2" onClick={() => handleOpenStatisticsModal(lecture.id)}>{t("View statistics")}</MDBBtn>
                                    </>
                                }
                            </td>
                            <td>
                                <MDBBtnGroup shadow="0">
                                    
                                        {adminActions ?
                                        <Link to={`/admin/lecture/${companyid}/${lecture.id}`}>
                                            <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                                        </Link>
                                        : canEditLecture(lecture) && (
                                        <Link to={`/mycompany/${companyid}/register-lecture/${lecture.id}`}>
                                            <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                                        </Link>)
                                        }
                                        
                                        {/* Document Upload Button */}
                                        {/* {canUploadDocuments(lecture) && (
                                            <MDBBtn 
                                                color='link'
                                                className="px-2"
                                                onClick={() => handleOpenDocumentUploadModal(lecture.id)}
                                            >
                                                {t("Upload Documents")}
                                            </MDBBtn>
                                        )} */}
                                        
                                    {canDeleteLecture(lecture) && (
                                        <MDBBtn 
                                            color='link' 
                                            style={{color: "red"}} 
                                            className="px-2" 
                                            onClick={() => {
                                                setSelectedLectureRemove(lecture.id); 
                                                toggleDeleteLectureModal();
                                            }}
                                        >
                                            {t("Delete")}
                                        </MDBBtn>
                                    )}
                                    {/* {(!canEditLecture(lecture) || !canDeleteLecture(lecture)) && (
                                        <small className="text-muted">
                                            {!canEditLecture(lecture) && t("Event has started")}
                                            {!canDeleteLecture(lecture) && t("Lecture is scheduled")}
                                        </small>
                                    )} */}
                                </MDBBtnGroup>
                            </td>
                        </tr>
                    ))
                :
                    <tr>
                        <td colSpan="7">
                            {t("No lectures found")}
                        </td>
                    </tr>
                }
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove lecture")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
            <p>{t("Are you sure you want to remove this lecture?")}</p>
            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={videoModal} onClose={() => setVideoModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("View recording")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => {
                    setSelectedLectureVideo(null);
                    setSelectedVideoUrl(null);
                    toggleVideoModal();
                }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <h6>
                    {selectedLectureVideo && (lectures.find(l => l.id === selectedLectureVideo)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureVideo).title[0].text)}
                </h6>
                <MDBCardText className="text-start">
                <span className="fw-bold">Vortrags-Video zur Kontrolle bis 10. Mai 2024</span>
                <br/>
                Liebe Referenten und Admins,<br/>
                bitte schauen Sie sich das Video an. Bitte prüfen Sie die Videos gründlich, um sicherzustellen, dass sie werbefrei sind und keine inhaltlichen Fehler enthalten. Bitte notieren Sie alle Fehler, die Sie finden, und senden Sie Ihre Korrekturvorschläge per E-Mail an martin@i-ec.de bis 10. Mai 2024. 
                <br/>
                Beachten Sie bitte, dass der Veröffentlichung bereits zugestimmt haben und wir ausschließlich inhaltliche Fehler korrigieren. <br/>
                Sollte das Vortrags-Video Produkt-Werbung enthalten, nehmen wir uns die Freiheit, das Video nicht in den Streamingbereich zu übernehmen.
                </MDBCardText>
                {
                    selectedVideoUrl &&
                    <ReactPlayer
                        url={selectedVideoUrl}
                        controls
                        width="100%"
                        height="auto"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload",
                                    onContextMenu: e => e.preventDefault()
                                }
                            }
                        }}
                    />

                }
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={statisticsModal} onClose={() => setStatisticsModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("View statistics")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setStatisticsModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>{t("User")}</th>
                            <th>{t("Watched Length")}</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {lectures.find(l => l.id === selectedLectureVideo)?.videoProgress.items.map((videoProgress) => (
                            <tr key={videoProgress.id}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        {videoProgress.user?.image?.fileName ?
                                            <img
                                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+videoProgress.user.image.fileName}
                                                className="rounded-circle border"
                                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                alt={videoProgress.user.firstName + " " + videoProgress.user.lastName}
                                            />
                                        :
                                            <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user" />
                                                    
                                        }
                                        <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{(videoProgress.user?.title ? videoProgress.user.title + " " : "") + videoProgress.user?.firstName + " " + videoProgress.user?.lastName}</p>
                                            <p className='text-muted mb-0'>{videoProgress.user?.contact?.address?.company || ""}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>{videoProgress.totalWatchedInSeconds} {t("Seconds")}</td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={documentUploadModal} onClose={toggleDocumentUploadModal}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload Lecture Documents")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleDocumentUploadModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                {selectedLectureForDocuments && (
                    <>
                        <h5>
                            {lectures.find(l => l.id === selectedLectureForDocuments)?.title?.find(t => t.language === i18n.language)?.text || 
                            lectures.find(l => l.id === selectedLectureForDocuments)?.title?.[0]?.text}
                        </h5>
                        
                        <p className="text-muted mb-4">
                            {t("The documents will be made available to the participants of the event. Deadline for submission: ")}
                            {isoToGermanDate(lectures.find(l => l.id === selectedLectureForDocuments)?.event?.lectureDocumentsEndDate)}
                        </p>
                        
                        {/* German Document Upload */}
                        <div className="mb-4">
                            <h6>{t("German Document")}</h6>
                            <div className="d-flex align-items-center mb-2">
                                <div className="me-2">
                                    <ReactCountryFlag
                                        countryCode="de"
                                        svg
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                                
                                {lectures.find(l => l.id === selectedLectureForDocuments)?.pdfDe?.fileName ? (
                                    <div className="d-flex flex-column">
                                        <MDBBtn 
                                            color="link" 
                                            onClick={() => downloadFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.s3Path
                                            )} 
                                            size="sm" 
                                            className="mb-1"
                                        >
                                            {lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.fileName}
                                        </MDBBtn>
                                        
                                        <MDBBtn 
                                            color="link" 
                                            style={{color: "red"}} 
                                            size="sm" 
                                            onClick={() => removeFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.s3Path,
                                                'De',
                                                selectedLectureForDocuments
                                            )}
                                        >
                                            {t("Remove")}
                                        </MDBBtn>
                                    </div>
                                ) : (
                                    <MDBFile id='fileDocDe' onChange={e => handleSetFile(e.target.files[0], 'De')} />
                                )}
                                
                                {isUploadingLectureFiles.De && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' />}
                            </div>
                            
                            {uploadingLecturesErrors.De && (
                                <div className="text-danger small mb-2">{uploadingLecturesErrors.De}</div>
                            )}
                        </div>
                        
                        {/* English Document Upload */}
                        <div>
                            <h6>{t("English Document")}</h6>
                            <div className="d-flex align-items-center mb-2">
                                <div className="me-2">
                                    <ReactCountryFlag
                                        countryCode="gb"
                                        svg
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                                
                                {lectures.find(l => l.id === selectedLectureForDocuments)?.pdfEn?.fileName ? (
                                    <div className="d-flex flex-column">
                                        <MDBBtn 
                                            color="link" 
                                            onClick={() => downloadFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.s3Path
                                            )} 
                                            size="sm" 
                                            className="mb-1"
                                        >
                                            {lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.fileName}
                                        </MDBBtn>
                                        
                                        <MDBBtn 
                                            color="link" 
                                            style={{color: "red"}} 
                                            size="sm" 
                                            onClick={() => removeFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.s3Path,
                                                'En',
                                                selectedLectureForDocuments
                                            )}
                                        >
                                            {t("Remove")}
                                        </MDBBtn>
                                    </div>
                                ) : (
                                    <MDBFile id='fileDocEn' onChange={e => handleSetFile(e.target.files[0], 'En')} />
                                )}
                                
                                {isUploadingLectureFiles.En && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' />}
                            </div>
                            
                            {uploadingLecturesErrors.En && (
                                <div className="text-danger small mb-2">{uploadingLecturesErrors.En}</div>
                            )}
                        </div>
                    </>
                )}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={presentationUploadModal} onClose={togglePresentationUploadModal}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload Presentation")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={togglePresentationUploadModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                {selectedLectureForDocuments && (
                    <>
                        <h5>
                            {lectures.find(l => l.id === selectedLectureForDocuments)?.title?.find(t => t.language === i18n.language)?.text || 
                            lectures.find(l => l.id === selectedLectureForDocuments)?.title?.[0]?.text}
                        </h5>
                        
                        <p className="text-muted mb-4">
                            {t("Please upload your presentation for this lecture. Preferably a PowerPoint file, but other document formats are also accepted. Deadline for submission: ")}
                            {isoToGermanDate(lectures.find(l => l.id === selectedLectureForDocuments)?.event?.lecturePresentationEndDate)}
                        </p>
                        
                        <div className="mb-4">
                            <h6>{t("Presentation File")}</h6>
                            <div className="d-flex align-items-center mb-2">
                                {lectures.find(l => l.id === selectedLectureForDocuments)?.presentation?.fileName ? (
                                    <div className="d-flex flex-column">
                                        <MDBBtn 
                                            color="link" 
                                            onClick={() => downloadFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.s3Path
                                            )} 
                                            size="sm" 
                                            className="mb-1"
                                        >
                                            {lectures.find(l => l.id === selectedLectureForDocuments).presentation.fileName}
                                        </MDBBtn>
                                        
                                        <MDBBtn 
                                            color="link" 
                                            style={{color: "red"}} 
                                            size="sm" 
                                            onClick={() => removeFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.s3Path,
                                                'Presentation',
                                                selectedLectureForDocuments
                                            )}
                                        >
                                            {t("Remove")}
                                        </MDBBtn>
                                    </div>
                                ) : (
                                    <MDBFile id='filePresentation' onChange={e => handleSetFile(e.target.files[0], 'Presentation')} />
                                )}
                                
                                {isUploadingLectureFiles.Presentation && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' />}
                            </div>
                            
                            {uploadingLecturesErrors.Presentation && (
                                <div className="text-danger small mb-2">{uploadingLecturesErrors.Presentation}</div>
                            )}
                        </div>
                    </>
                )}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
