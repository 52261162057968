import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient } from 'aws-amplify/api';
import { 
  MDBTable, 
  MDBTableHead, 
  MDBTableBody, 
  MDBBtn, 
  MDBModal, 
  MDBModalDialog, 
  MDBModalContent, 
  MDBModalHeader, 
  MDBModalTitle, 
  MDBModalBody, 
  MDBModalFooter, 
  MDBCard, 
  MDBCardBody, 
  MDBIcon, 
  MDBCardTitle,
  MDBInput,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBSpinner,
  MDBToast,
  MDBSelect
} from 'mdb-react-ui-kit';
import useDebounce from '../../../utils/debounce';
import { getLocalizedText } from '../../../utils/localizedText';
import { Link } from "react-router-dom";
import StockReservationForm from './StockReservationForm';

const client = generateClient();
const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const DEFAULT_ITEMS_PER_PAGE = 25;

export default function AdminStockReservations() {
  const { t, i18n } = useTranslation();
  const [stockReservations, setStockReservations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  
  // Modals
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditQtyModal, setShowEditQtyModal] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [editQty, setEditQty] = useState(1);
  
  // Toast notifications
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastColor, setToastColor] = useState('success');
  
  // Action states
  const [isSaving, setIsSaving] = useState(false);
  
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    fetchStockReservations();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearch();
    } else {
      fetchStockReservations();
    }
  }, [debouncedSearchTerm]);

  const fetchStockReservations = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchStockReservations(
            $filter: SearchableStockReservationFilterInput
            $limit: Int
            $from: Int
          ) {
            searchStockReservations(
              filter: $filter
              limit: $limit
              from: $from
            ) {
              items {
                id
                qty
                status
                reservationDate
                reservationEndDate
                company {
                  id
                  name
                  image {
                    main {
                      fileName
                    }
                  }
                }
                product {
                  id
                  name {
                    language
                    text
                  }
                  image {
                    main {
                      fileName
                    }
                  }
                }
                eventProduct {
                  id
                  product {
                    id
                    name {
                      language
                      text
                    }
                    image {
                      main {
                        fileName
                      }
                    }
                  }
                  event {
                    id
                    name {
                      language
                      text
                    }
                  }
                }
                createdAt
                updatedAt
              }
              total
            }
          }
        `,
        variables: {
          limit: itemsPerPage,
          from: (currentPage - 1) * itemsPerPage
        }
      });

      setStockReservations(response.data.searchStockReservations.items);
      setTotalItems(response.data.searchStockReservations.total);
    } catch (error) {
      console.error('Error fetching stock reservations:', error);
      showToastNotification(t("Error loading stock reservations"), 'danger');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      // Search for companies matching the search term
      const companiesResponse = await client.graphql({
        query: /* GraphQL */ `
          query SearchCompanies(
            $filter: SearchableCompanyFilterInput
            $limit: Int
          ) {
            searchCompanies(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
              }
            }
          }
        `,
        variables: {
          filter: {
            name: {
              matchPhrasePrefix: debouncedSearchTerm
            }
          },
          limit: 100
        }
      });
      
      // Search for products matching the search term
      const productsResponse = await client.graphql({
        query: /* GraphQL */ `
          query SearchProducts(
            $filter: SearchableProductFilterInput
            $limit: Int
          ) {
            searchProducts(
              filter: $filter
              limit: $limit
            ) {
              items {
                id
                eventProducts {
                    items {
                        id
                    }
                }
                name {
                    language
                    text
                }
              }
            }
          }
        `,
        variables: {
          filter: {
                name_text: {
                  match: debouncedSearchTerm
                }
          },
          limit: 100
        }
      });

      const matchingCompanyIds = companiesResponse.data.searchCompanies.items.map(company => company.id)
    // Find all products with exact name matches
    const exactMatchProducts = productsResponse.data.searchProducts.items.filter(product => {
        // Check if any of the product's name translations matches exactly (case insensitive)
        return product.name?.some(nameObj =>  
            nameObj.text.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        );
    });

    // If we have exact matches, use only those products and their event products
    let matchingProductIds = [];
    let matchingEventProductIds = [];

    if (exactMatchProducts.length > 0) {
        matchingProductIds = exactMatchProducts.map(product => product.id);
        matchingEventProductIds = exactMatchProducts.flatMap(product =>
            product.eventProducts.items.map(eventProduct => eventProduct.id)
        );
    } else {
        // No exact match found, use all results
        matchingProductIds = productsResponse.data.searchProducts.items.map(product => product.id);
        matchingEventProductIds = productsResponse.data.searchProducts.items.flatMap(product =>
            product.eventProducts.items.map(eventProduct => eventProduct.id)
        );
    }

      
      // If no matches, show empty result
      if (matchingCompanyIds.length === 0 && matchingProductIds.length === 0 && matchingEventProductIds.length === 0) {
        setStockReservations([]);
        setTotalItems(0);
        setIsLoading(false);
        return;
      }

      // Search for stock reservations with found IDs
      const orFilters = [];
      
      if (matchingCompanyIds.length > 0) {
        matchingCompanyIds.forEach(id => {
          orFilters.push({
            companyStockReservationsId: {
              eq: id
            }
          });
        });
      }
      
      if (matchingProductIds.length > 0) {
        matchingProductIds.forEach(id => {
          orFilters.push({
            productStockReservationsId: {
              eq: id
            }
          });
        });
      }

        if (matchingEventProductIds.length > 0) {
            matchingEventProductIds.forEach(id => {
                orFilters.push({
                    eventProductStockReservationsId: {
                        eq: id
                    }
                });
            });
        }

      const response = await client.graphql({
        query: /* GraphQL */ `
          query SearchStockReservations(
            $filter: SearchableStockReservationFilterInput
            $limit: Int
            $from: Int
          ) {
            searchStockReservations(
              filter: $filter
              limit: $limit
              from: $from
            ) {
              items {
                id
                qty
                status
                reservationDate
                reservationEndDate
                company {
                  id
                  name
                  image {
                    main {
                      fileName
                    }
                  }
                }
                product {
                  id
                  name {
                    language
                    text
                  }
                  image {
                    main {
                      fileName
                    }
                  }
                }
                eventProduct {
                  id
                  product {
                    id
                    name {
                      language
                      text
                    }
                    image {
                      main {
                        fileName
                      }
                    }
                  }
                  event {
                    id
                    name {
                      language
                      text
                    }
                  }
                }
                createdAt
                updatedAt
              }
              total
            }
          }
        `,
        variables: {
          filter: {
            or: orFilters
          },
          limit: itemsPerPage,
          from: (currentPage - 1) * itemsPerPage
        }
      });

      setStockReservations(response.data.searchStockReservations.items);
      setTotalItems(response.data.searchStockReservations.total);
    } catch (error) {
      console.error('Error searching stock reservations:', error);
      showToastNotification(t("Error searching stock reservations"), 'danger');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateQuantity = async () => {
    if (!selectedReservation) return;
    
    setIsSaving(true);
    try {
      await client.graphql({
        query: /* GraphQL */ `
          mutation UpdateStockReservation($input: UpdateStockReservationInput!) {
            updateStockReservation(input: $input) {
              id
              qty
            }
          }
        `,
        variables: {
          input: {
            id: selectedReservation.id,
            qty: parseInt(editQty)
          }
        }
      });
      
      // Update local state
      setStockReservations(prevState => 
        prevState.map(res => 
          res.id === selectedReservation.id ? {...res, qty: parseInt(editQty)} : res
        )
      );
      
      showToastNotification(t("Quantity updated successfully"), 'success');
      setShowEditQtyModal(false);
    } catch (error) {
      console.error('Error updating quantity:', error);
      showToastNotification(t("Error updating quantity"), 'danger');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCreateReservation = async (formData) => {
    setIsSaving(true);
    try {
      const result = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateStockReservation($input: CreateStockReservationInput!) {
            createStockReservation(input: $input) {
              id
              qty
              status
              reservationDate
              reservationEndDate
              company {
                id
                name
                image {
                  main {
                    fileName
                  }
                }
              }
              product {
                id
                name {
                  language
                  text
                }
                image {
                  main {
                    fileName
                  }
                }
              }
              eventProduct {
                id
                product {
                  id
                  name {
                    language
                    text
                  }
                  image {
                    main {
                      fileName
                    }
                  }
                }
                event {
                  id
                  name {
                    language
                    text
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: formData
        }
      });
      
      // Add the new reservation to the list
      const newReservation = result.data.createStockReservation;
      setStockReservations(prevState => [newReservation, ...prevState]);
      setTotalItems(prevTotal => prevTotal + 1);
      
      showToastNotification(t("Stock reservation created successfully"), 'success');
      setShowCreateModal(false);
    } catch (error) {
      console.error('Error creating stock reservation:', error);
      showToastNotification(t("Error creating stock reservation"), 'danger');
    } finally {
      setIsSaving(false);
    }
  };
  
  const handleUpdateReservation = async (formData) => {
    setIsSaving(true);
    try {
      const result = await client.graphql({
        query: /* GraphQL */ `
          mutation UpdateStockReservation($input: UpdateStockReservationInput!) {
            updateStockReservation(input: $input) {
              id
              qty
              status
              reservationDate
              reservationEndDate
              company {
                id
                name
                image {
                  main {
                    fileName
                  }
                }
              }
              product {
                id
                name {
                  language
                  text
                }
                image {
                  main {
                    fileName
                  }
                }
              }
              eventProduct {
                id
                product {
                  id
                  name {
                    language
                    text
                  }
                  image {
                    main {
                      fileName
                    }
                  }
                }
                event {
                  id
                  name {
                    language
                    text
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: formData
        }
      });
      
      // Update the reservation in the list
      const updatedReservation = result.data.updateStockReservation;
      setStockReservations(prevState => 
        prevState.map(res => 
          res.id === updatedReservation.id ? updatedReservation : res
        )
      );
      
      showToastNotification(t("Stock reservation updated successfully"), 'success');
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating stock reservation:', error);
      showToastNotification(t("Error updating stock reservation"), 'danger');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteReservation = async () => {
    if (!selectedReservation) return;
    
    setIsSaving(true);
    try {
      await client.graphql({
        query: /* GraphQL */ `
          mutation DeleteStockReservation($input: DeleteStockReservationInput!) {
            deleteStockReservation(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            id: selectedReservation.id
          }
        }
      });
      
      // Update local state
      setStockReservations(prevState => 
        prevState.filter(res => res.id !== selectedReservation.id)
      );
      setTotalItems(prevTotal => prevTotal - 1);
      
      showToastNotification(t("Stock reservation deleted successfully"), 'success');
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting stock reservation:', error);
      showToastNotification(t("Error deleting stock reservation"), 'danger');
    } finally {
      setIsSaving(false);
    }
  };

  const openEditQtyModal = (reservation) => {
    setSelectedReservation(reservation);
    setEditQty(reservation.qty);
    setShowEditQtyModal(true);
  };

  const openDeleteModal = (reservation) => {
    setSelectedReservation(reservation);
    setShowDeleteModal(true);
  };

  const openEditModal = (reservation) => {
    setSelectedReservation(reservation);
    setShowEditModal(true);
  };

  const showToastNotification = (message, color = 'success') => {
    setToastMessage(message);
    setToastColor(color);
    setShowToast(true);
  };

  const getProductName = (reservation) => {
    if (reservation.product) {
      return getLocalizedText(reservation.product.name);
    } else if (reservation.eventProduct && reservation.eventProduct.product) {
      return getLocalizedText(reservation.eventProduct.product.name) + " - " + getLocalizedText(reservation.eventProduct.event.name); 
    }
    return t("Unknown Product");
  };

  const getProductImage = (reservation) => {
    if (reservation.product && reservation.product.image && reservation.product.image.main) {
      return `https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/${reservation.product.image.main.fileName}`;
    } else if (reservation.eventProduct && reservation.eventProduct.product && 
               reservation.eventProduct.product.image && reservation.eventProduct.product.image.main) {
      return `https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/${reservation.eventProduct.product.image.main.fileName}`;
    }
    return "https://via.placeholder.com/50x50";
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <MDBCardTitle>{t("Product Stock Reservations")}</MDBCardTitle>
              <MDBInput
                type='text'
                label={t('Search by product or company name')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                wrapperStyle={{ minWidth: '200px', maxWidth: '700px' }}
              />
              <MDBBtn 
                color='tertiary' 
                rippleColor='light'
                onClick={() => setShowCreateModal(true)}
              >
                {t("Create Reservation")}
              </MDBBtn>
          </div>

          {isLoading ? (
            <div className="text-center my-5">
              <MDBSpinner role="status">
                <span className="visually-hidden">{t("Loading")}</span>
              </MDBSpinner>
            </div>
          ) : (
            <MDBTable align="middle" hover responsive>
              <MDBTableHead>
                <tr>
                  <th>{t("Company")}</th>
                  <th>{t("Product")}</th>
                  <th>{t("Quantity")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Reservation Period")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {stockReservations.length > 0 ? (
                  stockReservations.map((reservation) => (
                    <tr key={reservation.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          {reservation.company?.image?.main?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/${reservation.company.image.main.fileName}`}
                              className="rounded-circle border"
                              style={{ height: "50px", width: "50px", objectFit: "contain" }}
                              alt={reservation.company.name}
                            />
                          ) : (
                            <MDBIcon 
                              className="rounded-circle border"
                              style={{ 
                                height: "50px", 
                                width: "50px", 
                                textAlign: "center", 
                                paddingTop: "12px", 
                                fontSize: "20px" 
                              }}
                              fas 
                              icon="building" 
                            />
                          )}
                          <div className='ms-3'>
                            <Link to={`/admin/company/${reservation.company?.id}`}>
                            <p className='fw-bold mb-1 text-body'>{reservation.company?.name}</p>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <img
                            src={getProductImage(reservation)}
                            className="rounded border"
                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                            alt={getProductName(reservation)}
                          />
                          <div className='ms-3'>
                            <Link to={`/admin/product/${reservation.product?.id || reservation.eventProduct?.product.id}`}>
                            <p className='fw-bold mb-1 text-body'>{getProductName(reservation)}</p>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <span className="me-2">{reservation.qty}</span>
                          <MDBBtn 
                            color='link' 
                            size='sm' 
                            className="p-0"
                            onClick={() => openEditQtyModal(reservation)}
                          >
                            <MDBIcon fas icon='edit' />
                          </MDBBtn>
                        </div>
                      </td>
                      <td>
                        <span className={`badge rounded-pill ${reservation.status === 'active' ? 'bg-success' : 'bg-warning'}`}>
                          {reservation.status}
                        </span>
                      </td>
                      <td>
                        {reservation.reservationDate && (
                          <div>
                            {new Date(reservation.reservationDate).toLocaleDateString('de-DE')}
                            {' - '}
                            {reservation.reservationEndDate 
                              ? new Date(reservation.reservationEndDate).toLocaleDateString('de-DE')
                              : t("No end date")}
                          </div>
                        )}
                      </td>
                      <td>
                        <MDBBtn 
                          color='link' 
                          size='sm'
                          className="me-2"
                          onClick={() => openEditModal(reservation)}
                        >
                          {t("Edit")}
                        </MDBBtn>
                        <MDBBtn 
                          color='link' 
                          size='sm'
                          className="text-danger"
                          onClick={() => openDeleteModal(reservation)}
                        >
                          {t("Delete")}
                        </MDBBtn>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {t("No stock reservations found")}
                    </td>
                  </tr>
                )}
              </MDBTableBody>
            </MDBTable>
          )}

          {totalItems > 0 && (
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div>
                <select 
                  className="form-select" 
                  value={itemsPerPage} 
                  onChange={handleItemsPerPageChange}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  {ITEMS_PER_PAGE_OPTIONS.map(option => (
                    <option key={option} value={option}>
                      {option} {t("per page")}
                    </option>
                  ))}
                </select>
                <span className="ms-3">
                  {t("Showing")} {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} - {Math.min(currentPage * itemsPerPage, totalItems)} {t("of")} {totalItems}
                </span>
              </div>
              
              {totalPages > 1 && (
                <MDBPagination className="mb-0">
                  <MDBPaginationItem disabled={currentPage === 1}>
                    <MDBPaginationLink 
                      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                      tabIndex={-1}
                    >
                      {t("Previous")}
                    </MDBPaginationLink>
                  </MDBPaginationItem>

                  {/* Show limited page numbers with ellipsis for large page counts */}
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, idx) => {
                    let pageNum;
                    if (totalPages <= 5) {
                      pageNum = idx + 1;
                    } else if (currentPage <= 3) {
                      pageNum = idx + 1;
                    } else if (currentPage >= totalPages - 2) {
                      pageNum = totalPages - 4 + idx;
                    } else {
                      pageNum = currentPage - 2 + idx;
                    }

                    return (
                      <MDBPaginationItem key={pageNum} active={currentPage === pageNum}>
                        <MDBPaginationLink onClick={() => setCurrentPage(pageNum)}>
                          {pageNum}
                        </MDBPaginationLink>
                      </MDBPaginationItem>
                    );
                  })}

                  <MDBPaginationItem disabled={currentPage === totalPages}>
                    <MDBPaginationLink 
                      onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    >
                      {t("Next")}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                </MDBPagination>
              )}
            </div>
          )}
        </MDBCardBody>
      </MDBCard>

      {/* Create Stock Reservation Modal (Placeholder) */}
      <MDBModal open={showCreateModal} onClose={() => setShowCreateModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="lg">
            <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Create Stock Reservation")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowCreateModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <StockReservationForm
                isEdit={false}
                onSave={handleCreateReservation}
                onCancel={() => setShowCreateModal(false)}
                isSaving={isSaving}
                />
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>

      {/* Edit Stock Reservation Modal (Placeholder) */}
      <MDBModal open={showEditModal} onClose={() => setShowEditModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="lg">
            <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Edit Stock Reservation")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowEditModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <StockReservationForm
                isEdit={true}
                stockReservation={selectedReservation}
                onSave={handleUpdateReservation}
                onCancel={() => setShowEditModal(false)}
                isSaving={isSaving}
                />
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>

      {/* Edit Quantity Modal */}
      <MDBModal open={showEditQtyModal} onClose={() => setShowEditQtyModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size='sm'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("Update Quantity")}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => setShowEditQtyModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput 
                label={t("Quantity")}
                type="number"
                min="1"
                value={editQty}
                onChange={(e) => setEditQty(e.target.value)}
              />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setShowEditQtyModal(false)}>
                {t("Cancel")}
              </MDBBtn>
              <MDBBtn 
                color='primary'
                onClick={handleUpdateQuantity}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <MDBSpinner size='sm' className='me-2' />
                    {t("Saving...")}
                  </>
                ) : (
                  t("Save")
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Delete Confirmation Modal */}
      <MDBModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size='sm'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("Delete Stock Reservation")}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => setShowDeleteModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>{t("Are you sure you want to delete this stock reservation?")}</p>
              <p className="text-danger fw-bold">{t("This action cannot be undone.")}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => setShowDeleteModal(false)}>
                {t("Cancel")}
              </MDBBtn>
              <MDBBtn 
                color='danger'
                onClick={handleDeleteReservation}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <MDBSpinner size='sm' className='me-2' />
                    {t("Deleting...")}
                  </>
                ) : (
                  t("Delete")
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Toast Notification */}
      <MDBToast
        color={toastColor}
        show={showToast}
        autohide
        headerContent={
          <>
            <strong className="me-auto">
              {toastColor === 'success' ? t("Success") : t("Error")}
            </strong>
          </>
        }
        bodyContent={toastMessage}
        appendToBody
        position='top-right'
        delay={3000}
        onClose={() => setShowToast(false)}
      />
    </>
  );
}