import { useEffect, useRef, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon, MDBTypography, MDBBtnGroup, MDBSpinner } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { isoToGermanDate } from "../../../../utils/dateTools";
import QRCode from 'qrcode.react';
import shortUUID from 'short-uuid';
// Import PDF components
import { PDFDownloadLink } from '@react-pdf/renderer';
import MobileTicketPDF from '../../../../components-user/tickets/MobileTicketPDF';
import { getLocalizedText } from "../../../../utils/localizedText";
import { getEvent } from "../../../../graphql/queries";

const client = generateClient();

export default function Success({ ticketId }) {
  const { t, i18n } = useTranslation();
  const [ticket, setTicket] = useState(null);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDownload, setShowDownload] = useState(false);
  const qrRef = useRef(null);

  // Function to fetch ticket data
  const fetchTicket = async () => {
    try {
      const ticketData = await client.graphql({
        query: /* GraphQL */ `
          query GetTicket($id: ID!) {
            getTicket(id: $id) {
              id
              type
              eventDays
              createdAt
              ticketFile {
                s3Path
              }
              personalAddress {
                title
                firstName
                lastName
                company
              }
              personalContact {
                phone
                email
              }
              event {
                id
                name {
                  language
                  text
                }
                type
                startDate
                endDate
                image {
                  fileName
                }
              }
            }
          }
        `,
        variables: { id: ticketId }
      });

      const fetchedTicket = ticketData.data.getTicket;
      setTicket(fetchedTicket);
      
      // Fetch event details
      if (fetchedTicket?.event?.id) {
        try {
          const eventData = await client.graphql({
            query: getEvent,
            variables: { id: fetchedTicket.event.id }
          });
          
          setEvent(eventData.data.getEvent);
        } catch (error) {
          console.error('Error fetching event details:', error);
        }
      }
      
      return fetchedTicket;
    } catch (error) {
      console.error('Error fetching ticket:', error);
      return null;
    }
  };

  // Effect to handle ticket monitoring
  useEffect(() => {
    if (!ticketId) return;

    const checkTicketStatus = async () => {
      const ticketData = await fetchTicket();
      
      if (ticketData?.ticketFile?.s3Path) {
        setIsLoading(false);
        
        // Check if download should be shown (after 10 seconds from creation)
        if (new Date(ticketData.createdAt).getTime() + 10000 < new Date().getTime()) {
          setShowDownload(true);
        } else {
          // If 10 seconds haven't passed yet, set a timeout to enable download
          const timeToWait = 10000 - (new Date().getTime() - new Date(ticketData.createdAt).getTime());
          setTimeout(() => setShowDownload(true), Math.max(0, timeToWait));
        }
      } else {
        // If ticket file is not yet available, check again after 5 seconds
        setTimeout(checkTicketStatus, 5000);
      }
    };

    checkTicketStatus();
  }, [ticketId]);

  // Function to download QR code
  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = `QRCode Ticket Lounges 2024 - ${ticket.personalAddress.firstName} ${ticket.personalAddress.lastName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!ticketId || !ticket) {
    return <div className="text-center mt-4">{t("No ticket information available")}</div>;
  }

  return (
    <div className="container mt-4">
      <MDBTypography tag="h1" className="text-center text-success mb-4">
        <MDBIcon fas icon="check-circle" className="me-2" />
        {t("Booking Successful!")}
      </MDBTypography>

      <MDBCard className="mx-auto" style={{ maxWidth: '800px' }}>
        <MDBCardBody>
          <MDBCardTitle>{t("Your Ticket Details")}</MDBCardTitle>
          
          <div className="mb-4">
            <h5 className="text-primary">{t("Event")}</h5>
            <p>{ticket.event.name.find(n => n.language === i18n.language)?.text || ticket.event?.name[0].text}</p>
            
            <h5 className="text-primary mt-3">{t("Selected Days")}</h5>
            <p>{ticket.eventDays.map(day => isoToGermanDate(day)).join(", ")}</p>

            <h5 className="text-primary mt-3">{t("Booking Date")}</h5>
            <p>{isoToGermanDate(ticket.createdAt)}</p>
          </div>

          <div className="mb-4">
            <h5 className="text-primary">{t("Download Ticket")}</h5>
            {isLoading ? (
              <div className="text-center py-3">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">{t("Loading")}</span>
                </div>
                <p className="mt-2">{t("Generating your ticket")}</p>
              </div>
            ) : (
              <>
                {showDownload && event && ticket?.personalAddress ? (
                  <MDBBtnGroup shadow='0' aria-label='Basic example' className="w-100">
                    {event?.image?.fileName && ticket?.id && ticket?.personalAddress?.firstName && ticket?.personalAddress?.lastName && (
                      <PDFDownloadLink
                        document={
                          <MobileTicketPDF
                            eventImageUrl={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/${event?.image?.fileName}`}
                            qrCodeValue={shortUUID().fromUUID(ticket.id)}
                            ticketOwnerName={`${ticket.personalAddress.firstName} ${ticket.personalAddress.lastName}`}
                            selectedDays={ticket.eventDays.map(isoToGermanDate)}
                            event={event}
                            ticket={ticket}
                          />
                        }
                        fileName={`Mobile_Ticket_${ticket.personalAddress.firstName}_${ticket.personalAddress.lastName}_&_${getLocalizedText(event.name)}.pdf`}
                        className="btn ripple ripple-surface ripple-surface-dark btn btn-outline-secondary"
                      >
                        {({ loading }) =>
                          loading ? (
                            <>
                              <MDBIcon fas size='2x' icon="spinner" spin />
                            </>
                          ) : (
                            <>
                              <MDBIcon fas size='2x' icon="mobile" />
                              <br />
                              Mobile Ticket
                            </>
                          )
                        }
                      </PDFDownloadLink>
                    )}

                    {ticket?.id && (
                      <a
                        className="btn ripple ripple-surface ripple-surface-dark btn btn-outline-secondary"
                        href={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/tickets/${ticket.id}.pdf`}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <MDBIcon fas size='2x' icon="file-pdf" />
                        <br />
                        A4 PDF Ticket
                      </a>
                    )}
                  </MDBBtnGroup>
                ) : (
                  <div className="w-100 text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <br />
                    <span>{t("Generating ticket")}</span>
                  </div>
                )}
              </>
            )}
          </div>


          {ticket.type !== "default" && (
            <div className="alert alert-info">
              <MDBIcon fas icon="info-circle" className="me-2" />
              {t("You will receive the invoice by email.")}
            </div>
          )}
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}