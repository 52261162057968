import { generateClient, get } from 'aws-amplify/api';
import { fetchAuthSession } from "aws-amplify/auth";
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBSelect, MDBCol, MDBCheckbox, MDBDatepicker, MDBProgress, MDBProgressBar, MDBSpinner, MDBFile } from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import lectureSchema from '../../validation/LectureValidation';
import {onCreateLecture, onUpdateLecture, onDeleteLecture} from '../../graphql/subscriptions';
import { type } from "@testing-library/user-event/dist/type";
import usePrevious from "../../utils/usePrevious";
import { getUrl, remove, uploadData } from "aws-amplify/storage";
import ReactPlayer from 'react-player'
import * as XLSX from 'xlsx';

import LectureModal from '../lectures/scheduling/LectureModal';
import LectureForm from "../lectures/LectureForm";
import { isoToGermanDate } from "../../utils/dateTools";
import ReactCountryFlag from "react-country-flag";
import { getLocalizedText } from "../../utils/localizedText";

const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [lectures, setLectures] = useState([]);
    const [shownLectures, setShownLectures] = useState([]);
    const [totalLectures, setTotalLectures] = useState(0);

    const [isLectureFormOpen, setIsLectureFormOpen] = useState(false);
    const [editingLecture, setEditingLecture] = useState(null);


    const [validationErrors, setValidationErrors] = useState([]);

    const validateLectures = (lectures) => {
        let errors = lectures.map((lecture) => {

            let lectureErrors = lectureSchema.validate(lecture).map((error) => ({
                field: error.path,
                message: error.message
            })).filter(e => e.message)

            // remove conditions
            lectureErrors = lectureErrors.filter((error) => error.message !== "Bedingungen");

            // Custom validation for conditions
            if(lecture.type === "marketing") {
                // Check image and speaker conditions
                // Image
                if(lecture.image?.fileName) {
                    const imageCondition = lecture.conditions?.find(c => c.name === "image");
                    if(!imageCondition || !imageCondition.accepted) {
                        lectureErrors.push({field: "image", message: "Bildrechte"})
                    }
                }
                // Speaker
                if(lecture.speakers?.length > 0) {
                    const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                    if(!speakerCondition || !speakerCondition.accepted) {
                        lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                    }
                }
               
                } else if (lecture.type === "technical" || lecture.type === "educational") {
                    if(!lecture.conditions) {
                        console.log("no conditions", lecture.id, lecture.conditions)

                    }
                    // Check image and speaker conditions and adCondition, recordCondition, rightsCondition, deadlineCondition
                    // Image
                    if(lecture.image?.fileName) {
                        const imageCondition = lecture.conditions?.find(c => c.name === "image");
                        if(!imageCondition || !imageCondition.accepted) {
                            lectureErrors.push({field: "image", message: "Bildrechte"})
                        }
                    }
                    // Speaker
                    if(lecture.speakers?.length > 0) {
                        const speakerCondition = lecture.conditions?.find(c => c.name === "speakers");
                        if(!speakerCondition || !speakerCondition.accepted) {
                            lectureErrors.push({field: "speaker", message: "Referenten-Bedingung"})
                        }
                    }
                    // Ad
                    const adCondition = lecture.conditions?.find(c => c.name === "adCondition");
                    if(!adCondition || !adCondition.accepted) {
                        lectureErrors.push({field: "ad", message: "Bedingungen-Werbefrei"})
                    }
                    // Record
                    const recordCondition = lecture.conditions?.find(c => c.name === "recordCondition");
                    if(!recordCondition || !recordCondition.accepted) {
                        lectureErrors.push({field: "record", message: "Bedingungen-Aufzeichnung"})
                    }
                    // Rights
                    const rightsCondition = lecture.conditions?.find(c => c.name === "rightsContition");
                    if(!rightsCondition || !rightsCondition.accepted) {
                        lectureErrors.push({field: "rights", message: "Bedingungen-Aufzeichnungsrechte"})
                    }
                    // Deadline
                    const deadlineCondition = lecture.conditions?.find(c => c.name === "deadlineContition");
                    if(!deadlineCondition || !deadlineCondition.accepted) {
                        lectureErrors.push({field: "deadline", message: "Bedingungen-Deadline"})
                    }
                   
                }
            


            return ({
                    id: lecture.id,
                    errors: lectureErrors
                })
            });
        
        // errors.forEach((error) => {
        //     console.log(error)
        // })

        setValidationErrors(errors);
    }

    useEffect(() => {
        if(lectures.length > 0) {
            setTimeout(() => {
                validateLectures(lectures)
            }, 500);
        }
    }, [lectures]);

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const extractExistingParams = (searchParams) => {
        return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            if (value === 'true') {
                acc[key] = true;
            } else if (value === 'false') {
                acc[key] = false;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
    }

    const [filters, setFilters] = useState({
        approved: null,
        createdAtStart: "2023-12-01T00:00:00.000Z",
        createdAtEnd: null,
        createdAtSort: "desc",
        status: null,
        type: null
    });
    const [lecturesLoaded, setLecturesLoaded] = useState(false);
    const getLectures = async (eventId) => {
        try {
            setIsLoading(true);
            const searchLectureFilter = {
                eventLecturesId: { eq: eventId }
            };
            const searchLectureSort = {};
            if (typeof filters.approved === "boolean") {
                searchLectureFilter.approved = { eq: filters.approved };
            }
            if (filters.createdAtStart && filters.createdAtEnd) {
                searchLectureFilter.and = [{createdAt: {gt: filters.createdAtStart}}, {createdAt: {lt: filters.createdAtEnd}}];
            } else if (filters.createdAtStart) {
                searchLectureFilter.createdAt = { gt: filters.createdAtStart };
            } else if (filters.createdAtEnd) {
                searchLectureFilter.createdAt = { lt: filters.createdAtEnd };
            }
            if (filters.createdAtSort) {
                searchLectureSort.field = "createdAt";
                searchLectureSort.direction = filters.createdAtSort;
            }
            if (filters.updatedAtSort) {
                searchLectureSort.field = "updatedAt";
                searchLectureSort.direction = filters.updatedAtSort;
            }
            if (filters.status) {
                searchLectureFilter.status = { eq: filters.status };
            }
            if (filters.type) {
                searchLectureFilter.type = { eq: filters.type };
            }

            const lectures = await client.graphql({
                variables: {
                    filter: searchLectureFilter,
                    sort: searchLectureSort,
                    limit: 1000
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                id
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                    user {
                                        title
                                        firstName
                                        lastName
                                        email
                                    }
                                }
                            }
                        }
                        speakersUsers {
                            items {
                                user {
                                    title
                                    firstName
                                    lastName
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        mainSpeakerUser {
                            image {
                              fileName
                              alt
                              }
                              title
                              firstName
                              lastName
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }        
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            fileName
                            s3Path
                            url
                            name
                            updatedAt
                        }
                        pdfEn {
                            fileName
                            s3Path
                            url
                            name
                            updatedAt
                        }
                        presentation {
                            fileName
                            s3Path
                            fileName
                            updatedAt
                        }
                        videoS3Key {
                            s3Path
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                            
                        }
                        conditions {
                            name
                            accepted
                        }
                        id
                        adminComment
                        companyBooth
                        session
                        room
                        startTime
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        approved
                        status
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            const lecturesData = lectures.data.searchLectures.items.map(lecture => ({
                ...lecture,
                topics: lecture.topics.items.map(topic => topic.topic),
                speakers: lecture.speakers.items.filter(Boolean).length > 0 ? lecture.speakers.items : lecture.speakersUsers.items.filter(Boolean).length > 0 ? lecture.speakersUsers.items.map(speakerUser => ({employee: speakerUser})) : []
            }))
            
            // .sort((a, b) => {
            //     // Behandlung von leeren oder fehlenden Werten
            //     if (!a.room && !b.room) {
            //         return 0;
            //     } else if (!a.room) {
            //         return 1; // a nach unten
            //     } else if (!b.room) {
            //         return -1; // b nach unten
            //     }
            
            //     // Zuerst nach Raum sortieren
            //     if (a.room !== b.room) {
            //         return a.room.localeCompare(b.room);
            //     } else {
            //         // Wenn die Räume gleich sind, nach Startzeit sortieren
            //         if (!a.startTime && !b.startTime) {
            //             return 0;
            //         } else if (!a.startTime) {
            //             return 1; // a nach unten
            //         } else if (!b.startTime) {
            //             return -1; // b nach unten
            //         }
            //         return new Date(a.startTime) - new Date(b.startTime);
            //     }
            // });
            setLectures(lecturesData);
            setShownLectures(lecturesData);
            setTotalLectures(lectures.data.searchLectures.total);
            setLecturesLoaded(true); 
            setIsLoading(false);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) {
                const lectureData = error.data.searchLectures.items.map(lecture => ({
                    ...lecture,
                    topics: lecture.topics.items.map(topic => topic.topic),
                    speakers: lecture.speakers.items.length > 0 ? lecture.speakers.items : lecture.speakersUsers.items.length > 0 ? lecture.speakersUsers.items.map(speakerUser => ({employee: speakerUser})) : []
                }))
                setLectures(lectureData);
                setShownLectures(lectureData);
            }
            setIsLoading(false);
        }
    };
    const [searchTerm, setSearchTerm] = useState("");

    const handleInputSearch = (e) => {
        setSearchTerm(e.target.value);
        searchLectures(e.target.value);
    }

    const searchLectures = (searchTerm) => {
        console.log("searchTerm", searchTerm);
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            if (searchTerm.length > 0) {
                newParams.set('search', searchTerm);
            } else {
                newParams.delete('search');
            }
            return newParams;
        });
        if (searchTerm.length > 0) {
            setShownLectures(lectures.filter(lecture => {
                if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                    return true;
                }
                if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                    return true;
                }
                return false;
            }));
        } else {
            setShownLectures(lectures);
        }
    }

    useEffect(() => {
        const searchTerm = searchParams.get("search");
        if (lecturesLoaded && searchTerm) {
            handleInputSearch({target: {value: searchTerm}});
        }
    }, [lecturesLoaded]);

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents(
                  $filter: ModelEventFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            name {
                                language
                                text
                            }
                            startDate
                            lectureRegistrationStartDate
                            endDate
                            status
                        }
                    }
                }
                `,
                variables: {
                    filter: {
                        // lectureRegistrationStartDate: { le: new Date().toISOString().substring(0, 10) },
                        lectureRooms: { gt: 0 },
                        startDate: { ge: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10) }
                        // status: { eq: "active" }
                    }
                }
            });
            // Sort events by start date
            const sortedEvents = events.data.listEvents.items.sort((a, b) =>  new Date(b.startDate) - new Date(a.startDate));
            setEvents(sortedEvents);
            return sortedEvents;
        } catch (error) {
            console.log("error on fetching events", error);
        }
    };


    const [filtersInitialized, setFiltersInitialized] = useState(false);

useEffect(() => {
    // Beim ersten Render die Filter aus den searchParams extrahieren und setzen
    const extractedParams = extractExistingParams(searchParams);
    setFilters(oldFilters => ({ ...extractedParams, createdAtStart: extractedParams.createdAtStart || oldFilters.createdAtStart , createdAtSort: extractedParams.createdAtSort || oldFilters.createdAtSort }));
    setFiltersInitialized(true); // Setze auf true, sobald die Initialisierung abgeschlossen ist
}, []); // Leere Abhängigkeiten, um den Hook nur einmal auszuführen

const loadEvents = async () => {
    const eventsData = await getEvents();
    setEvents(eventsData);

    const eventIdFromParams = searchParams.get("event");
    if (eventIdFromParams && eventsData.find(event => event.id === eventIdFromParams)) {
        setSelectedEvent(eventIdFromParams);
    } else if (eventsData.length > 0) {
        setSelectedEvent(eventsData[0].id); // Erstes Event auswählen, wenn keines in den Params
    }
}


useEffect(() => {
    if (filtersInitialized) {
        // Aktualisiere die searchParams und rufe getLectures auf, nachdem die Filter initialisiert wurden
        const params = Object.entries(filters).reduce((acc, [key, value]) => {
            if (value !== null) {
                acc[key] = value;
            }
            return acc;
        }, {});

        setSearchParams(params);
        if(selectedEvent) {
            getLectures(selectedEvent);
          }
    }
    if(events.length === 0) {
        loadEvents();
    }
}, [filters, filtersInitialized]); // Abhängigkeit von den Filters und filtersInitialized

useEffect(() => {
  if(selectedEvent) {
    getLectures(selectedEvent);
  }
}, [selectedEvent])

const handleEventTabClick = (eventId) => {
    setSelectedEvent(eventId);
    setSearchParams(prev => {
        const newParams = new URLSearchParams(prev);
        newParams.set('event', eventId);
        return newParams;
    });
};

    // subscribe to lecture changes


// Subscribe to update of Todo
// const updateSub = client
//   .graphql({ query: onUpdateLecture })
//   .subscribe({
//     next: ({ data }) => console.log("update", data),
//     error: (error) => console.warn(error)
//   });

const updateLectureOnSubscription = (lecture) => {
    if (!lecture) {
        return;
    }
    setLectures(currentLectures => currentLectures.map(l => l.id === lecture.id ? lecture : l));
    setShownLectures(currentShownLectures => currentShownLectures.map(l => l.id === lecture.id ? lecture : l));
};

const subscribeUpdateLecture = () => {
    console.log("subscribe update lecture")
    const subscription = client.graphql({
        query: /* GraphQL */ `
        subscription OnUpdateLecture($filter: ModelSubscriptionLectureFilterInput) {
          onUpdateLecture(filter: $filter) {
            title {
                language
                text
            }
            speakers {
                items {
                    employee {
                        image {
                        fileName
                        alt
                        }
                    }
                }
            }
            subjects {
                id
                name {
                    language
                    text
                }
            } 
            topics {
                items {
                  topic {
                    id
                    name {
                      language
                      text
                    }
                  }
                }
            }        
            content {
                language
                text
            }
            conditions {
                name
                accepted
            }
            image {
                fileName
                alt

            }
            pdfDe {
                url
                fileName
                name
                updatedAt
            }
            pdfEn {
                url
                fileName
                name
                updatedAt
            }
            presentation {
                fileName
                name
                updatedAt
            }
            company {
                id
                name
                image {
                    main {
                        fileName
                        alt
                    }
                }
            }
            conditions {
                name
                accepted
            }
            id
            adminComment
            session
            companyBooth
            room
            type
            presentationLanguage
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            approved
            status
            __typename
          }
        }
        `
    }).subscribe({
        next: ({ data }) => {
            // Update the lecture in the list of lectures, found by data.onUpdateLecture.id
            console.log("update", data);
            //getLectures();
            updateLectureOnSubscription(data.onUpdateLecture);

        },
        error: (error) => console.warn(error)
    });

    return {
        unsubscribe: () => subscription.unsubscribe()
    };
};

useEffect(() => {
    // Starten des Abonnements
    const subscription = subscribeUpdateLecture();

    // Bereinigungsfunktion
    return () => {
        // Beenden des Abonnements
        subscription.unsubscribe();
    };
}, []);


  // Stop receiving data updates from the subscription

    


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setShownLectures(shownLectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }

    


    const filterbyHasErrors = (selectedErrors) => { // selectedErrors = ["Titel", "Referenten"]
        console.log(selectedErrors);
        if (selectedErrors.length > 0) {
            setShownLectures(lectures.filter(lecture => {
                if (validationErrors.find(error => error.id === lecture.id)) {
                    return validationErrors.find(error => error.id === lecture.id).errors.some(error => selectedErrors.includes(error.message));
                }
                return false;
            }));
        } else {
            setShownLectures(lectures);
        }
       
       
    }

    const [selectedConditions, setSelectedConditions] = useState([]); // selectedConditions = ["ECV", "Podcast"
    const prevConditions = usePrevious(selectedConditions);

    const filterByConditions = async () => { // selectedConditions = ["ECV", "Podcast"]
        console.log(selectedConditions);
       
        if (selectedConditions.length > 0) {
           
            setShownLectures(shownLectures.filter(lecture => {
                if(lecture.conditions?.length > 0) {
                if (selectedConditions.includes("ECV") && selectedConditions.includes("Podcast")) {
                    return (lecture.conditions?.find(condition => condition.name === "ecv")?.accepted && lecture.conditions?.find(condition => condition.name === "podcast")?.accepted);
                } else if (selectedConditions.includes("ECV")) {
                    return lecture.conditions?.find(condition => condition.name === "ecv")?.accepted;
                } else if (selectedConditions.includes("Podcast")) {
                    return lecture.conditions?.find(condition => condition.name === "podcast")?.accepted;
                }
                return false;
            } else {
                return false;
            }
            }));
        } else {
            setShownLectures(lectures);
        }
    }

    useEffect(() => {
       // If something changed in the selectedConditions, filter the lectures
         if (prevConditions !== selectedConditions) {
        filterByConditions(selectedConditions);
        }
    }, [selectedConditions, shownLectures]);



    // Video Upload, View and Remove Modal
    const [videoModal, setVideoModal] = useState(false);
    const [selectedLectureVideo, setSelectedLectureVideo] = useState(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [lectureVideosUploadStatus, setLectureVideosUploadStatus] = useState({});
    const hanleOpenVideoModal = async (lectureId) => {
        setSelectedLectureVideo(lectureId);
        const videoKey = lectures.find(l => l.id === lectureId)?.videoS3Key?.s3Path;
        if (videoKey) {
            const videoUrl = await getVideoUrl(videoKey);
            setSelectedVideoUrl(videoUrl);
        }
        toggleVideoModal();
    }
    const toggleVideoModal = () => setVideoModal(!videoModal);

    const inputFieldRef = useRef();

    const uploadVideo = async (file, lectureId) => {
        try {
            let lastTimeStamp = Date.now(); // Initial timestamp
            let lastTransferredBytes = 0; // Initial transferred bytes

            const result = await uploadData({
              key: `lectureVideos/${lectureId}.mp4`, 
              data: file,
              options: {
                onProgress: ({ transferredBytes, totalBytes }) => {
                  if (totalBytes) {
                    const currentTime = Date.now();
                    const timeElapsed = (currentTime - lastTimeStamp) / 1000; // Convert ms to seconds
                    const bytesTransferred = transferredBytes - lastTransferredBytes;
                    const speedInMBps = (bytesTransferred / (1024 * 1024)) / timeElapsed; // Convert bytes to MB and divide by seconds
                    setLectureVideosUploadStatus(old => ({
                        ...old,
                      [lectureId]: {
                        transferredBytes,
                        totalBytes,
                        percent: Math.round((transferredBytes / totalBytes) * 100),
                        speed: speedInMBps.toFixed(2) + ' MB/s',
                      },
                    }));
                  }
                }
              }
            }).result;
            console.log('Path from Response: ', result.key);
            // Update the lecture with the video path on the field videoS3Key
            setLectures(lectures.map(l => l.id === lectureId ? {...l, videoS3Key: {s3Path: result.key}} : l));
            setShownLectures(shownLectures.map(l => l.id === lectureId ? {...l, videoS3Key: {s3Path: result.key}} : l));
            setSelectedVideoUrl(await getVideoUrl(result.key));
            await client.graphql({
                variables: {
                    input: {
                        id: lectureId,
                        videoS3Key: {
                            s3Path: result.key
                        }
                    }
                },
                query: /* GraphQL */ `
                mutation UpdateLecture(
                  $input: UpdateLectureInput!
                ) {
                  updateLecture(input: $input) {
                    id
                    videoS3Key {
                        s3Path
                    }
                  }
                }
              `
            });
          } catch (error) {
            console.log('Error : ', error);
          }
    }

    const getVideoUrl = async (videoKey) => {
        try {
            const result = await getUrl({
                key: videoKey,
                level: 'public'
            });
            console.log("video url", result.url.href);
            return result.url.href;
        } catch (error) {
            console.log("error on getting video url", error);
        }
    }

    const removeVideo = async (lectureId) => {
        try {
            const videoKey = lectures.find(l => l.id === lectureId)?.videoS3Key?.s3Path;
            if (videoKey) {
                await remove({
                    key: videoKey
                });
                setLectures(lectures.map(l => l.id === lectureId ? {...l, videoS3Key: null} : l));
                setShownLectures(shownLectures.map(l => l.id === lectureId ? {...l, videoS3Key: null} : l));
                await client.graphql({
                    variables: {
                        input: {
                            id: lectureId,
                            videoS3Key: null
                        }
                    },
                    query: /* GraphQL */ `
                    mutation UpdateLecture(
                      $input: UpdateLectureInput!
                    ) {
                      updateLecture(input: $input) {
                        id
                        videoS3Key {
                            s3Path
                        }
                      }
                    }
                  `
                });
                setSelectedVideoUrl(null);
            }
        } catch (error) {
            console.log("error on removing video", error);
        }
    }

    const handleDownloadExcel = () => {
        // Excel-Daten vorbereiten
        const excelData = [];
        
        // Header hinzufügen
        excelData.push([
            'Erstellungsdatum',
            'Vortragsart',
            'Firma',
            'Titel',
            'Titel EN',
            'Link',
            'Status',
            'Fehler',
            'Admin Comment',
            'Präsentation Tag',
            'Präsentation Uhrzeit',
            'Präsentation Raum',
            // 'Hauptreferent',
            'Referenten',
            'Sprache',
            'ECV Kommentar',
            'Podcast',
            'ECV',
        ]);
        
        // Daten für jeden Vortrag hinzufügen
        shownLectures.forEach(lecture => {
            const errors = validationErrors?.find(error => error.id === lecture.id)?.errors?.map(e => e.message)?.join(', ') || '';
            
            // Datum und Uhrzeit formatieren, falls vorhanden
            const presentationDate = lecture.startTime ? new Date(lecture.startTime).toLocaleDateString('de-DE') : '';
            const presentationTime = lecture.startTime ? new Date(lecture.startTime).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) : '';
            
            // Hauptreferent bestimmen
            let mainSpeaker = '';
            
            if (lecture.mainSpeakerUser) {
                // Fall 1: mainSpeakerUser ist direkt verfügbar
                const title = lecture.mainSpeakerUser.title || '';
                mainSpeaker = `${title} ${lecture.mainSpeakerUser.firstName} ${lecture.mainSpeakerUser.lastName}`.trim();
            } else if (lecture.lectureMainSpeakerId && lecture.speakers && lecture.speakers.length > 0) {
                // Fall 2: Hauptreferent ist Teil der Speakers-Liste und über lectureMainSpeakerId identifizierbar
                const mainSpeakerObj = lecture.speakers.find(s => s.id === lecture.lectureMainSpeakerId);
                if (mainSpeakerObj && mainSpeakerObj.employee && mainSpeakerObj.employee.user) {
                    const title = mainSpeakerObj.employee.user.title || '';
                    mainSpeaker = `${title} ${mainSpeakerObj.employee.user.firstName} ${mainSpeakerObj.employee.user.lastName}`.trim();
                }
            }
            
            // Alle Referenten mit Titeln auflisten
            let allSpeakers = '';
            if (lecture.speakers && lecture.speakers.length > 0) {
                allSpeakers = lecture.speakers
                    .filter(speaker => speaker.employee && speaker.employee.user)
                    .map(speaker => {
                        const title = speaker.employee.user.title || '';
                        return `${title} ${speaker.employee.user.firstName} ${speaker.employee.user.lastName}`.trim();
                    })
                    .join(', ');
            } else if (lecture.speakersUsers && lecture.speakersUsers.items && lecture.speakersUsers.items.length > 0) {
                // Fallback auf speakersUsers, falls verfügbar
                allSpeakers = lecture.speakersUsers.items
                    .filter(item => item.user)
                    .map(item => {
                        const title = item.user.title || '';
                        return `${title} ${item.user.firstName} ${item.user.lastName}`.trim();
                    })
                    .join(', ');
            }
            
            excelData.push([
                new Date(lecture.createdAt).toLocaleDateString('de-DE'),
                t(lecture.type),
                lecture.company?.name || '',
                lecture.title?.find(t => t.language === 'de')?.text || '',
                lecture.title?.find(t => t.language === 'en')?.text || '',
                { 
                    v: `Anzeigen`,
                    l: { 
                        Target: `https://app.cleanroom-processes.de/admin/lecture/${lecture.company?.id}/${lecture.id}`,
                        Tooltip: "Click to open lecture" 
                    }
                },
                t(lecture.status === "checked" ? "Freigegeben" : lecture.status),
                errors,
                lecture.adminComment || '',
                presentationDate,
                presentationTime,
                lecture.room || '',
                // mainSpeaker,
                allSpeakers,
                lecture.presentationLanguage,
                lecture.conditions?.find(condition => condition.name === "podcast")?.accepted ? "Ja" : "Nein",
                lecture.conditions?.find(condition => condition.name === "ecv")?.accepted ? "Ja" : "Nein",
            ]);
        });
    
        // Arbeitsmappe erstellen
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(excelData);
    
        // Spaltenbreiten automatisch anpassen
        const colWidths = [
            { wch: 12 },  // Created At
            { wch: 15 },  // Type
            { wch: 30 },  // Company
            { wch: 40 },  // Title DE
            { wch: 10 },  // Link
            { wch: 15 },  // Status
            { wch: 40 },  // Validation Errors
            { wch: 30 },  // Admin Comment
            { wch: 15 },  // Presentation Day
            { wch: 15 },  // Presentation Time
            { wch: 15 },  // Presentation Room
            { wch: 30 },  // Main Speaker
            { wch: 60 },  // All Speakers
            { wch: 15 },  // ECV Comment
            { wch: 10 },  // Podcast
            { wch: 10 },  // ECV
        ];
        ws['!cols'] = colWidths;
        if(!ws['!links']) ws['!links'] = [];
        shownLectures.forEach((_, idx) => {
            const cellRef = XLSX.utils.encode_cell({r: idx + 1, c: 4}); // 4 ist der Index der Link-Spalte
            ws['!links'].push([cellRef, ws[cellRef].l]);
        });
        // Worksheet zur Arbeitsmappe hinzufügen
        XLSX.utils.book_append_sheet(wb, ws, 'Lectures');
    
        // Event Name für Dateinamen holen
        const eventName = getLocalizedText(events.find(e => e.id === selectedEvent)?.name) || 'Lectures';
    
        // Excel-Datei herunterladen
        XLSX.writeFile(wb, `${eventName}-Lectures.xlsx`);
    };


    // Document Upload Modal
    const [documentUploadModal, setDocumentUploadModal] = useState(false);
    const [selectedLectureForDocuments, setSelectedLectureForDocuments] = useState(null);
    const toggleDocumentUploadModal = () => setDocumentUploadModal(!documentUploadModal);
    const [presentationUploadModal, setPresentationUploadModal] = useState(false);
    const togglePresentationUploadModal = () => setPresentationUploadModal(!presentationUploadModal);
    
    const handleOpenDocumentUploadModal = (lectureId) => {
        setSelectedLectureForDocuments(lectureId);
        toggleDocumentUploadModal();
    };

    const handleOpenPresentationUploadModal = (lectureId) => {
        setSelectedLectureForDocuments(lectureId);
        togglePresentationUploadModal();
    };

    // File upload states
    const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState({
        De: false,
        En: false,
        Presentation: false
    });
    
    const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState({
        De: null,
        En: null,
        Presentation: null
    });

    const handleSetFile = async (file, fileType) => {
            if (!file || !selectedLectureForDocuments) return;
            
            try {
                // fileType can be 'De', 'En', or 'Presentation'
                setIsUploadingLectureFiles({...isUploadingLectureFiles, [fileType]: true});
                
                // Upload file to S3
                const result = await uploadData({
                    key: file.name,
                    data: file,
                    options: {
                        accessLevel: 'protected'
                    }
                }).result;
                
                console.log('Upload succeeded: ', result);
                
                // Get the file URL
                const fileURL = await getUrl({ 
                    key: result.key, 
                    options: { level: 'protected' } 
                });
                
                // Update the lecture with the file info
                const identityId = (await fetchAuthSession()).identityId;
                
                // Determine which field to update based on fileType
                let updateField = {};
                if (fileType === 'De' || fileType === 'En') {
                    updateField[`pdf${fileType}`] = {
                        fileName: result.key,
                        name: result.key,
                        s3Path: `protected/${identityId}/${result.key}`,
                        updatedAt: new Date().toISOString()
                    };
                } else if (fileType === 'Presentation') {
                    updateField.presentation = {
                        fileName: result.key,
                        name: result.key,
                        s3Path: `protected/${identityId}/${result.key}`,
                        updatedAt: new Date().toISOString()
                    };
                }
                
                const updatedLecture = await client.graphql({
                    variables: {
                        input: {
                            id: selectedLectureForDocuments,
                            ...updateField
                        }
                    },
                    query: /* GraphQL */ `
                    mutation UpdateLecture($input: UpdateLectureInput!) {
                        updateLecture(input: $input) {
                            id
                            pdfDe {
                                fileName
                                name
                                s3Path
                                updatedAt
                            }
                            pdfEn {
                                fileName
                                name
                                s3Path
                                updatedAt
                            }
                            presentation {
                                fileName
                                name
                                s3Path
                                updatedAt
                            }
                        }
                    }
                    `
                });
                
                // Update the lecture in the local state
                setLectures(lectures.map(lecture => 
                    lecture.id === selectedLectureForDocuments 
                        ? {
                            ...lecture, 
                            ...(fileType === 'De' || fileType === 'En' 
                                ? { [`pdf${fileType}`]: updatedLecture.data.updateLecture[`pdf${fileType}`] }
                                : { presentation: updatedLecture.data.updateLecture.presentation })
                          } 
                        : lecture
                ));
                setShownLectures(shownLectures.map(lecture =>
                    lecture.id === selectedLectureForDocuments

                        ? {
                            ...lecture,
                            ...(fileType === 'De' || fileType === 'En'
                                ? { [`pdf${fileType}`]: updatedLecture.data.updateLecture[`pdf${fileType}`] }
                                : { presentation: updatedLecture.data.updateLecture.presentation })
                        }
                        : lecture
                ));
                
                setIsUploadingLectureFiles({...isUploadingLectureFiles, [fileType]: false});
                setUploadingLecturesErrors({...uploadingLecturesErrors, [fileType]: null});
                
            } catch (error) {
                console.log("Error uploading file:", error);
                setIsUploadingLectureFiles({...isUploadingLectureFiles, [fileType]: false});
                setUploadingLecturesErrors({
                    ...uploadingLecturesErrors, 
                    [fileType]: error.errors?.[0].message || error.message
                });
            }
        };

    const downloadFile = async (fileName, path) => {
        try {
            const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
            const match = path.match(regex);
            const identity = match[1];
            
            const getUrlResult = await getUrl({
                key: fileName,
                options: {
                    accessLevel: 'protected',
                    targetIdentityId: identity,
                },
            });
            
            console.log('Signed URL: ', getUrlResult.url);
            window.open(getUrlResult.url, '_blank');
        } catch (error) {
            console.log("Error downloading file:", error);
        }
    };

    const removeFile = async (fileName, path, fileType, lectureId) => {
        try {
            await remove({ key: fileName, options: { accessLevel: 'protected' } });
            
            // Update field based on fileType
            let updateField = {};
            if (fileType === 'De' || fileType === 'En') {
                updateField[`pdf${fileType}`] = null;
            } else if (fileType === 'Presentation') {
                updateField.presentation = null;
            }
            
            // Update the lecture in the database
            const updatedLecture = await client.graphql({
                variables: {
                    input: {
                        id: lectureId,
                        ...updateField
                    }
                },
                query: /* GraphQL */ `
                mutation UpdateLecture($input: UpdateLectureInput!) {
                    updateLecture(input: $input) {
                        id
                        pdfDe {
                            fileName
                            name
                            s3Path
                        }
                        pdfEn {
                            fileName
                            name
                            s3Path
                        }
                        presentation {
                            fileName
                            name
                            s3Path
                        }
                    }
                }
                `
            });
            
            // Update the lecture in the local state
            setLectures(lectures.map(lecture => 
                lecture.id === lectureId 
                    ? {
                        ...lecture,
                        ...(fileType === 'De' ? { pdfDe: null } : {}),
                        ...(fileType === 'En' ? { pdfEn: null } : {}),
                        ...(fileType === 'Presentation' ? { presentation: null } : {})
                        } 
                    : lecture
            ));

            setShownLectures(shownLectures.map(lecture =>
                lecture.id === lectureId
                    ? {
                        ...lecture,
                        ...(fileType === 'De' ? { pdfDe: null } : {}),
                        ...(fileType === 'En' ? { pdfEn: null } : {}),
                        ...(fileType === 'Presentation' ? { presentation: null } : {})
                    }
                    : lecture
            ));
            
        } catch (error) {
            console.log("Error removing file:", error);
        }
    };

    return (
        <><MDBCard className="w-auto">
            <MDBCardBody className="flex-column">
                <MDBCardTitle>
                    {t("Lectures")}
                    <MDBBtn 
                            className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'
                            onClick={() => {
                              setEditingLecture(null);
                              setIsLectureFormOpen(true);
                            }}
                          >
                            <MDBIcon fas icon="plus" className="me-2" /> 
                            {t("Create Lecture")}
                          </MDBBtn>
                          <MDBBtn
                    color="tertiary"
                    rippleColor="light" 
                    className="mb-3 ms-2 float-end"
                    onClick={handleDownloadExcel}
                    disabled={isLoading || shownLectures.length === 0}
                >
                    <MDBIcon fas icon="file-excel" className="me-2" />
                    {t("Download Excel")}
                </MDBBtn>
                </MDBCardTitle>
                <MDBTabs className='mb-3'>
                    {
                        events.length > 0 &&
                        events.map((event, index) => (
                            <MDBTabsItem>
                            <MDBTabsLink key={index} onClick={() => handleEventTabClick(event.id)} active={selectedEvent === event.id}>
                                {event.name.find(n => n.language === i18n.language)?.text || event.name[0].text}
                            </MDBTabsLink>
                            </MDBTabsItem>
                        ))
                    }   
                </MDBTabs>
                
                <MDBTabsContent>
                    <MDBTabsPane open={basicActive === 'tab1'}>
                    <MDBRow>
                        <MDBCol md="6">
                        <MDBInput type="text" onChange={handleInputSearch} value={searchTerm} label={t("Search")} />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("Start Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            value={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtStart: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("End Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            value={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtEnd: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                    </MDBRow>
                    
                    
                    <MDBRow className="mt-2 mt-xl-3">
                        <MDBCol md="3">
                            <MDBSelect label={t("Type")}
                                onChange={type => type && setFilters({ ...filters, type: type.value})}
                                value={filters.type}
                                data={
                                    [
                                        { text: t("All"), value: null },
                                        { text: t("Marketing"), value: "marketing" },
                                        { text: t("Technical"), value: "technical" },
                                        { text: t("Educational"), value: "educational" },
                                        { text: t("Recruiting"), value: "recruiting" },
                                        { text: t("Promotion Marketing"), value: "promotion_marketing" },
                                        { text: t("Promotion Technical"), value: "promotion_technical" }
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Status")}
                                onChange={selected => selected && setFilters({ ...filters, status: selected.value})}
                                value={filters.status}
                                data={
                                    [   
                                        { text: t("All"), value: null },
                                        { text: t("Open"), value: "created" },
                                        { text: t("Approved"), value: "checked" },
                                        { text: t("Not Approved"), value: "not_approved" },
                                        { text: t("In Planning"), value: "in_planning" },
                                        { text: t("Committed"), value: "committed" },
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Errors")}
                                onChange={selected => filterbyHasErrors(selected.map(s => s.value))}
                                multiple
                                data={
                                    [
                                        { text: "Titel", value: "Titel" },
                                        { text: "Referenten", value: "Referenten" },
                                        { text: "Unterpunkte", value: "Unterpunkte" },
                                        { text: "Beschreibung", value: "Beschreibung" },
                                        { text: "Bedingungen", value: "Bedingungen" },
                                        { text: "Bild", value: "Bild" },
                                        { text: "Themenbereich", value: "Themenbereich" }
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="1">
                            <MDBCheckbox label="ECV" onChange={e => setSelectedConditions(e.target.checked ? [...selectedConditions, "ECV"] : selectedConditions.filter(c => c !== "ECV"))} />
                        </MDBCol>
                        <MDBCol md="1">
                            <MDBCheckbox label="Podcast" onChange={e => setSelectedConditions(e.target.checked ? [...selectedConditions, "Podcast"] : selectedConditions.filter(c => c !== "Podcast"))} />
                        </MDBCol>
                        <MDBCol md="1">
                        <MDBIcon className="float-end" fas icon="sync-alt" onClick={() =>  getLectures(selectedEvent)} />
                        </MDBCol>

                        
                    </MDBRow>
                    
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th width="125">{t("Datum")} 
                                    <br />
                                    <span className="text-muted">
                                    {t("Erstellt")} <MDBIcon fas icon="long-arrow-alt-up" size='sm' onClick={() => setFilters({...filters, createdAtSort: "desc", updatedAtSort: null})} /> <MDBIcon fas icon="long-arrow-alt-down" size='sm' onClick={() => setFilters({...filters, createdAtSort: "asc", updatedAtSort: null})} />
                                    </span>
                                    <br />
                                    <span className="text-muted">
                                    {t("Geändert")} <MDBIcon fas icon="long-arrow-alt-up" size='sm' onClick={() => setFilters({...filters, updatedAtSort: "desc", createdAtSort: null})} /> <MDBIcon fas icon="long-arrow-alt-down" size='sm' onClick={() => setFilters({...filters, updatedAtSort: "asc", createdAtSort: null})} />
                                    </span>
                                    </th>
                                    <th>{t("Company")}</th>
                                    <th width="100">{t("Type")}</th>
                                    <th>{t("Title")}</th>
                                    <th>{t("Speakers")}</th>
                                    <th>{t("Errors")}</th>
                                    <th>{t("Status")}</th>
                                    <th>{t("Presentation")}</th>
                                    <th>{t("Video")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                { isLoading ? <tr><td colSpan="8" className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>
                                : shownLectures.length > 0 ?
                                    shownLectures.map((lecture, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="px-1">
                                                {new Date(lecture.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                                {new Date(lecture.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) !== new Date(lecture.updatedAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) && <>
                                                    <br />
                                                <span className="text-success" style={{fontSize: "smaller"}}>{new Date(lecture.updatedAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</span>
                                                </>}
                                                </td>
                                                <td width="300" className="px-1"><>
                                                    {
                                                        lecture.company ? (lecture.company?.image?.main?.fileName ?
                                                        <div className='d-flex align-items-center'>
                                                            {lecture.company.image?.main?.fileName ?
                                                                <img
                                                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                                    alt={lecture.company.image.alt}
                                                                />
                                                            :
                                                                <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                                        
                                                            }
                                                            <div className='ms-3'>
                                                            <p className='fw-bold mb-1'>{lecture.company.name}</p>
                                                            <p className='text-muted mb-0'>{lecture.companyBooth}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <MDBIcon
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                        )
                                                        :
                                                        lecture.mainSpeakerUser && 
                                                        (lecture.mainSpeakerUser?.image?.fileName ?
                                                        <div className='d-flex align-items-center'>
                                                            {lecture.mainSpeakerUser.image?.fileName ?
                                                                <img
                                                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+lecture.mainSpeakerUser.image.fileName}
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                                    alt={lecture.mainSpeakerUser.image.alt}
                                                                />
                                                            :
                                                                <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                                                        
                                                            }
                                                            <div className='ms-3'>
                                                            <p className='fw-bold mb-1'>{lecture.mainSpeakerUser.firstName} {lecture.mainSpeakerUser.lastName}</p>
                                                            <p className='text-muted mb-0'>{lecture.mainSpeakerUser.email}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <MDBIcon
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                        )
                                                    }
                                                </>
                                                </td>
                                                <td className="px-1">{t(lecture.type)}</td>
                                                <td className="px-1">{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                                                <td className="px-1">{lecture.speakers?.map((speaker, index) => <>
                                                    {speaker?.employee?.image?.fileName || speaker?.employee?.user?.image?.fileName ?
                                                        <img
                                                            key={index}
                                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image?.fileName || speaker.employee.user.image.fileName}`}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                objectFit: "cover",
                                                            }}
                                                            alt={speaker.employee?.image?.alt || speaker.employee.user?.image?.alt}
                                                        />
                                                        :
                                                        <MDBIcon
                                                        key={index}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                </>)}</td>
                                                <td width="0">
                                                    {/* {
                                                        validationErrors.find(error => error.id === lecture.id) ?
                                                            [...new Set(validationErrors.find(error => error.id === lecture.id).errors.map(error => error.message))].join(", ")
                                                            :
                                                            <p className="text-success">{t("No errors")}</p>
                                                    }
                                                {lecture.adminComment && lecture.adminComment.length > 2 &&
                                                <>
                                                <br />
                                                <span className="text-warning fw-bold">{t("Admin Comment")}:</span>
                                                <br />
                                                {lecture.adminComment}
                                                </>
                                                   
                                                } */}
                                                </td>
                                                <td>
                                                {lecture.status === "created" ? null : t(lecture.status === "checked" ? "✅" : lecture.status)}
                                                </td>
                                                <td className="px-1">
                                                    {lecture.room && <p className="mb-0">{t("Room") + " " + lecture.room}</p>}
                                                    {lecture.startTime && <p className="mb-0">{new Date(lecture.startTime).toLocaleDateString('de-DE')}</p>}
                                                    {lecture.startTime && <p className="mb-0">{new Date(lecture.startTime).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr</p>}
                                                    <div className="mt-2">
                                                        {/* <span>PDF:</span> */}
                                                        <div className="cursor-pointer" onClick={() => handleOpenDocumentUploadModal(lecture.id)}>
                                                            PDF: 
                                                            DE
                                                            {lecture.pdfDe?.name ? 
                                                                <MDBIcon fas icon="check" className="text-success me-1" title={t("German document available")} />
                                                                : 
                                                                <MDBIcon fas icon="times" className="text-danger me-1" title={t("No German document")} />
                                                            }
                                                            EN
                                                            {lecture.pdfEn?.name ? 
                                                                <MDBIcon fas icon="check" className="text-success me-1" title={t("English document available")} />
                                                                : 
                                                                <MDBIcon fas icon="times" className="text-danger me-1" title={t("No English document")} />
                                                            }
                                                           
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="cursor-pointer" onClick={() => handleOpenPresentationUploadModal(lecture.id)}>
                                                            PPTX: 
                                                            {lecture.presentation?.fileName ? 
                                                                <>
                                                                <MDBIcon fas icon="check" className="text-success me-1" title={t("Presentation available")} />
                                                                <br />
                                                                {lecture.presentation.updatedAt && <span className="text-muted">{isoToGermanDate(lecture.presentation.updatedAt)}</span>}
                                                                </>
                                                                : 
                                                                <MDBIcon fas icon="times" className="text-danger me-1" title={t("No presentation")} />
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <MDBIcon fas icon="edit" style={{ color: "link", cursor: "pointer" }} onClick={() => hanleOpenVideoModal(lecture.id)} />
                                                     <br></br>
                                                    {
                                                        lecture.videoS3Key?.s3Path &&
                                                        <MDBIcon fas style={{ color: "green" }} icon="video" />
                                                    }
                                               
                                               </td>
                                                <td>
                                                    <MDBBtnGroup shadow="0">
                                                        {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                                                        <div className="justify-content-between">
                                                        <Link to={`/admin/lecture/${lecture.company?.id}/${lecture.id}`}>
                                                            <MDBIcon color='link' fas icon="edit" />
                                                        </Link>
                                                        <MDBIcon
                                                            fas
                                                            icon="trash"
                                                            style={{ color: "red", cursor: "pointer" }} className="ms-3" onClick={() => { setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal() }}
                                                        />
                                                       
                                                        </div>
                                                        
                                                    </MDBBtnGroup>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7">
                                            {t("No lectures found")}
                                        </td>
                                    </tr>
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBTabsPane>
                </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>

            <MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
                <MDBModalDialog centered size="sm">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t("Remove Lecture")}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <p>{t("Are you sure you want to remove this lecture?")}</p>
                            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
                            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

<MDBModal open={videoModal} onClose={() => setVideoModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload Video")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => {
                    setLectureVideosUploadStatus({});
                    setSelectedLectureVideo(null);
                    inputFieldRef.current.value = "";
                    setSelectedVideoUrl(null);
                    toggleVideoModal();
                }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <h6>
                    {selectedLectureVideo && (lectures.find(l => l.id === selectedLectureVideo)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureVideo).title[0].text)}
                </h6>
                {
                    selectedVideoUrl &&
                    <ReactPlayer
                        url={selectedVideoUrl}
                        controls
                        width="100%"
                        height="auto"
                    />

                }
                <MDBRow>
                    <MDBCol size="11">
                        <MDBInput
                        type="file" 
                        onChange={e => uploadVideo(e.target.files[0], selectedLectureVideo)}
                        ref={inputFieldRef}
                        />
                    </MDBCol>
                    <MDBCol size="1">
                        <MDBIcon icon="trash" color="danger" className="mt-2" style={{cursor: "pointer"}} onClick={() => removeVideo(selectedLectureVideo)} />
                    </MDBCol>

                </MDBRow>
               
               
                {lectureVideosUploadStatus[selectedLectureVideo] && <>
                    <MDBProgress height="20" className="mt-4">
                        <MDBProgressBar striped bgColor='success' width={lectureVideosUploadStatus[selectedLectureVideo].percent} valuemin={0} valuemax={100}>
                            
                        </MDBProgressBar>
                    </MDBProgress>
                    <p>
                        {t("Uploaded")}: {lectureVideosUploadStatus[selectedLectureVideo].percent}% ({lectureVideosUploadStatus[selectedLectureVideo].speed})
                    </p>
                </>}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal
      open={isLectureFormOpen}
      tabIndex="-1"
      backdrop={true}
      size="lg"
    >
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              {editingLecture ? t("Edit Lecture") : t("Create Lecture")}
            </MDBModalTitle>
            <MDBBtn 
              className='btn-close' 
              color='none' 
              onClick={() => {
                setIsLectureFormOpen(false);
                setEditingLecture(null);
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <LectureForm
              lectureId={editingLecture?.id}
              eventId={selectedEvent}
              onSave={(savedLecture) => {
                // Add the new lecture to both lectures and shownLectures arrays
                const newLecture = {
                  ...savedLecture,
                  startTime: null,
                  endTime: null,
                  room: null,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                };
                console.log("newLecture", newLecture);
                if (editingLecture) {
                  // Update existing lecture
                  setLectures(prev => prev.map(l => l.id === savedLecture.id ? newLecture : l));
                  setShownLectures(prev => prev.map(l => l.id === savedLecture.id ? newLecture : l));
                } else {
                  // Add new lecture
                  setLectures(prev => [newLecture, ...prev]);
                  setShownLectures(prev => [newLecture, ...prev]);
                }

                setIsLectureFormOpen(false);
                setEditingLecture(null);
              }}
              onCancel={() => {
                setIsLectureFormOpen(false);
                setEditingLecture(null);
              }}
            />
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>

    <MDBModal open={documentUploadModal} onClose={toggleDocumentUploadModal}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload Lecture Documents")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleDocumentUploadModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                {selectedLectureForDocuments && (
                    <>
                        <h5>
                            {lectures.find(l => l.id === selectedLectureForDocuments)?.title?.find(t => t.language === i18n.language)?.text || 
                            lectures.find(l => l.id === selectedLectureForDocuments)?.title?.[0]?.text}
                        </h5>
                        
                        <p className="text-muted mb-4">
                            {t("The documents will be made available to the participants of the event. Deadline for submission: ")}
                            {isoToGermanDate(lectures.find(l => l.id === selectedLectureForDocuments)?.event?.lectureDocumentsEndDate)}
                        </p>
                        
                        {/* German Document Upload */}
                        <div className="mb-4">
                            <h6>{t("German Document")}</h6>
                            <div className="d-flex align-items-center mb-2">
                                <div className="me-2">
                                    <ReactCountryFlag
                                        countryCode="de"
                                        svg
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                                
                                {lectures.find(l => l.id === selectedLectureForDocuments)?.pdfDe?.fileName ? (
                                    <div className="d-flex flex-column">
                                        <MDBBtn 
                                            color="link" 
                                            onClick={() => downloadFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.s3Path
                                            )} 
                                            size="sm" 
                                            className="mb-1"
                                        >
                                            {lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.fileName}
                                        </MDBBtn>
                                        
                                        <MDBBtn 
                                            color="link" 
                                            style={{color: "red"}} 
                                            size="sm" 
                                            onClick={() => removeFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfDe.s3Path,
                                                'De',
                                                selectedLectureForDocuments
                                            )}
                                        >
                                            {t("Remove")}
                                        </MDBBtn>
                                    </div>
                                ) : (
                                    <MDBFile id='fileDocDe' onChange={e => handleSetFile(e.target.files[0], 'De')} />
                                )}
                                
                                {isUploadingLectureFiles.De && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' />}
                            </div>
                            
                            {uploadingLecturesErrors.De && (
                                <div className="text-danger small mb-2">{uploadingLecturesErrors.De}</div>
                            )}
                        </div>
                        
                        {/* English Document Upload */}
                        <div>
                            <h6>{t("English Document")}</h6>
                            <div className="d-flex align-items-center mb-2">
                                <div className="me-2">
                                    <ReactCountryFlag
                                        countryCode="gb"
                                        svg
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                                
                                {lectures.find(l => l.id === selectedLectureForDocuments)?.pdfEn?.fileName ? (
                                    <div className="d-flex flex-column">
                                        <MDBBtn 
                                            color="link" 
                                            onClick={() => downloadFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.s3Path
                                            )} 
                                            size="sm" 
                                            className="mb-1"
                                        >
                                            {lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.fileName}
                                        </MDBBtn>
                                        
                                        <MDBBtn 
                                            color="link" 
                                            style={{color: "red"}} 
                                            size="sm" 
                                            onClick={() => removeFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).pdfEn.s3Path,
                                                'En',
                                                selectedLectureForDocuments
                                            )}
                                        >
                                            {t("Remove")}
                                        </MDBBtn>
                                    </div>
                                ) : (
                                    <MDBFile id='fileDocEn' onChange={e => handleSetFile(e.target.files[0], 'En')} />
                                )}
                                
                                {isUploadingLectureFiles.En && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' />}
                            </div>
                            
                            {uploadingLecturesErrors.En && (
                                <div className="text-danger small mb-2">{uploadingLecturesErrors.En}</div>
                            )}
                        </div>
                    </>
                )}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={presentationUploadModal} onClose={togglePresentationUploadModal}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Upload Presentation")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={togglePresentationUploadModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                {selectedLectureForDocuments && (
                    <>
                        <h5>
                            {lectures.find(l => l.id === selectedLectureForDocuments)?.title?.find(t => t.language === i18n.language)?.text || 
                            lectures.find(l => l.id === selectedLectureForDocuments)?.title?.[0]?.text}
                        </h5>
                        
                        <p className="text-muted mb-4">
                            {t("Please upload your presentation for this lecture. Preferably a PowerPoint file, but other document formats are also accepted. Deadline for submission: ")}
                            {isoToGermanDate(lectures.find(l => l.id === selectedLectureForDocuments)?.event?.lecturePresentationEndDate)}
                        </p>
                        
                        <div className="mb-4">
                            <h6>{t("Presentation File")}</h6>
                            <div className="d-flex align-items-center mb-2">
                                {lectures.find(l => l.id === selectedLectureForDocuments)?.presentation?.fileName ? (
                                    <div className="d-flex flex-column">
                                        <MDBBtn 
                                            color="link" 
                                            onClick={() => downloadFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.s3Path
                                            )} 
                                            size="sm" 
                                            className="mb-1"
                                        >
                                            {lectures.find(l => l.id === selectedLectureForDocuments).presentation.fileName}
                                        </MDBBtn>
                                        
                                        <MDBBtn 
                                            color="link" 
                                            style={{color: "red"}} 
                                            size="sm" 
                                            onClick={() => removeFile(
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.fileName,
                                                lectures.find(l => l.id === selectedLectureForDocuments).presentation.s3Path,
                                                'Presentation',
                                                selectedLectureForDocuments
                                            )}
                                        >
                                            {t("Remove")}
                                        </MDBBtn>
                                    </div>
                                ) : (
                                    <MDBFile id='filePresentation' onChange={e => handleSetFile(e.target.files[0], 'Presentation')} />
                                )}
                                
                                {isUploadingLectureFiles.Presentation && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' />}
                            </div>
                            
                            {uploadingLecturesErrors.Presentation && (
                                <div className="text-danger small mb-2">{uploadingLecturesErrors.Presentation}</div>
                            )}
                        </div>
                    </>
                )}
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
        </>
    )
}
