import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  MDBSpinner,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { getLocalizedText } from "../../utils/localizedText";

const client = generateClient();

export default function AllDiscounts() {
  const { t, i18n } = useTranslation();
  const [discounts, setDiscounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [newDiscount, setNewDiscount] = useState({
    code: '',
    name: [
      { language: 'de', text: '' },
      { language: 'en', text: '' }
    ],
    discountType: 'fixedAmount',
    discountAmount: '',
    discountPercentage: '',
    maxUsage: '',
    startDate: '',
    endDate: '',
    status: 'active'
  });

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const fetchDiscounts = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
          query ListDiscountCodes {
            listDiscountCodes {
              items {
                id
                code
                name {
                  language
                  text
                }
                discountType
                discountAmount
                discountPercentage
                usageCount
                maxUsage
                startDate
                endDate
                status
                createdAt
                updatedAt
              }
            }
          }
        `
      });
      setDiscounts(response.data.listDiscountCodes.items);
    } catch (error) {
      console.error('Error fetching discounts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDiscount = async () => {
    try {
      setIsLoading(true);
      await client.graphql({
        query: /* GraphQL */ `
          mutation CreateDiscountCode($input: CreateDiscountCodeInput!) {
            createDiscountCode(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            ...newDiscount,
            startDate: new Date(newDiscount.startDate).toISOString(),
            endDate: new Date(newDiscount.endDate).toISOString(),
            discountAmount: newDiscount.discountType === 'fixedAmount' ? parseFloat(newDiscount.discountAmount) : null,
            discountPercentage: newDiscount.discountType === 'percentage' ? parseFloat(newDiscount.discountPercentage) : null,
            maxUsage: newDiscount.maxUsage ? parseInt(newDiscount.maxUsage) : null
          }
        }
      });
      
      setShowCreateModal(false);
      setNewDiscount({
        code: '',
        name: [
          { language: 'de', text: '' },
          { language: 'en', text: '' }
        ],
        discountType: 'fixedAmount',
        discountAmount: '',
        discountPercentage: '',
        maxUsage: '',
        startDate: '',
        endDate: '',
        status: 'active'
      });
      fetchDiscounts();
    } catch (error) {
      console.error('Error creating discount:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDiscount = async () => {
    try {
      setIsLoading(true);
      await client.graphql({
        query: /* GraphQL */ `
          mutation DeleteDiscountCode($input: DeleteDiscountCodeInput!) {
            deleteDiscountCode(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: { id: selectedDiscount.id }
        }
      });
      
      setShowDeleteModal(false);
      setSelectedDiscount(null);
      fetchDiscounts();
    } catch (error) {
      console.error('Error deleting discount:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <MDBCardTitle>{t("Discount Codes")}</MDBCardTitle>
          <MDBBtn onClick={() => setShowCreateModal(true)}>
            {t("Create Discount")}
          </MDBBtn>
        </div>

        {isLoading ? (
          <div className="text-center">
            <MDBSpinner />
          </div>
        ) : (
          <MDBTable>
            <MDBTableHead>
              <tr>
                <th>{t("Code")}</th>
                <th>{t("Name")}</th>
                <th>{t("Type")}</th>
                <th>{t("Value")}</th>
                <th>{t("Usage")}</th>
                <th>{t("Valid Period")}</th>
                <th>{t("Status")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {discounts.map((discount) => (
                <tr key={discount.id}>
                  <td>{discount.code}</td>
                  <td>{getLocalizedText(discount.name)}</td>
                  <td>{t(discount.discountType)}</td>
                  <td>
                    {discount.discountType === 'fixedAmount' 
                      ? `€${discount.discountAmount}`
                      : `${discount.discountPercentage}%`
                    }
                  </td>
                  <td>{discount.usageCount || 0}/{discount.maxUsage || "∞"}</td>
                  <td>
                    {new Date(discount.startDate).toLocaleDateString("de-DE")} - {new Date(discount.endDate).toLocaleDateString("de-DE")}
                  </td>
                  <td>
                    <MDBIcon
                      fas
                      icon="circle"
                      className={discount.status === 'active' ? 'text-success' : 'text-danger'}
                    />
                  </td>
                  <td>
                    <Link to={`/admin/discount/${discount.id}`}>
                      <MDBBtn color="primary" size="sm" className="me-2">
                        <MDBIcon fas icon="edit" />
                      </MDBBtn>
                    </Link>
                    <MDBBtn
                      color="danger"
                      size="sm"
                      onClick={() => {
                        setSelectedDiscount(discount);
                        setShowDeleteModal(true);
                      }}
                    >
                      <MDBIcon fas icon="trash" />
                    </MDBBtn>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        )}

        {/* Create Discount Modal */}
        <MDBModal open={showCreateModal} onClose={() => setShowCreateModal(false)} size="lg">
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{t("Create Discount Code")}</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={() => setShowCreateModal(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol size="12" className="mb-3">
                    <MDBInput
                      label={t("Code")}
                      value={newDiscount.code}
                      onChange={(e) => setNewDiscount({ ...newDiscount, code: e.target.value })}
                    />
                  </MDBCol>
                  <MDBCol size="12" className="mb-3">
                    <MDBInputGroup className="mb-3">
                      <div className="input-group-text">
                        <ReactCountryFlag countryCode="de" svg />
                      </div>
                      <MDBInput
                        label={t("Name (German)")}
                        value={newDiscount.name.find(n => n.language === 'de')?.text}
                        onChange={(e) => {
                          const updatedName = newDiscount.name.map(n =>
                            n.language === 'de' ? { ...n, text: e.target.value } : n
                          );
                          setNewDiscount({ ...newDiscount, name: updatedName });
                        }}
                      />
                    </MDBInputGroup>
                    <MDBInputGroup>
                      <div className="input-group-text">
                        <ReactCountryFlag countryCode="gb" svg />
                      </div>
                      <MDBInput
                        label={t("Name (English)")}
                        value={newDiscount.name.find(n => n.language === 'en')?.text}
                        onChange={(e) => {
                          const updatedName = newDiscount.name.map(n =>
                            n.language === 'en' ? { ...n, text: e.target.value } : n
                          );
                          setNewDiscount({ ...newDiscount, name: updatedName });
                        }}
                      />
                    </MDBInputGroup>
                  </MDBCol>
                  <MDBCol size="12" md="6" className="mb-3">
                    <select
                      className="form-select"
                      value={newDiscount.discountType}
                      onChange={(e) => setNewDiscount({ ...newDiscount, discountType: e.target.value })}
                    >
                      <option value="fixedAmount">{t("Fixed Amount")}</option>
                      <option value="percentage">{t("Percentage")}</option>
                    </select>
                  </MDBCol>
                  <MDBCol size="12" md="6" className="mb-3">
                    {newDiscount.discountType === 'fixedAmount' ? (
                      <MDBInput
                        type="number"
                        label={t("Discount Amount (€)")}
                        value={newDiscount.discountAmount}
                        onChange={(e) => setNewDiscount({ ...newDiscount, discountAmount: e.target.value })}
                      />
                    ) : (
                      <MDBInput
                        type="number"
                        label={t("Discount Percentage (%)")}
                        value={newDiscount.discountPercentage}
                        onChange={(e) => setNewDiscount({ ...newDiscount, discountPercentage: e.target.value })}
                      />
                    )}
                  </MDBCol>
                  <MDBCol size="12" md="4" className="mb-3">
                    <MDBInput
                      type="number"
                      label={t("Max Usage")}
                      value={newDiscount.maxUsage}
                      onChange={(e) => setNewDiscount({ ...newDiscount, maxUsage: e.target.value })}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4" className="mb-3">
                    <MDBInput
                      type="datetime-local"
                      label={t("Start Date")}
                      value={newDiscount.startDate}
                      onChange={(e) => setNewDiscount({ ...newDiscount, startDate: e.target.value })}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4" className="mb-3">
                    <MDBInput
                      type="datetime-local"
                      label={t("End Date")}
                      value={newDiscount.endDate}
                      onChange={(e) => setNewDiscount({ ...newDiscount, endDate: e.target.value })}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => setShowCreateModal(false)}>
                  {t("Cancel")}
                </MDBBtn>
                <MDBBtn onClick={handleCreateDiscount} disabled={isLoading}>
                  {isLoading ? <MDBSpinner size="sm" /> : t("Create")}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        {/* Delete Confirmation Modal */}
        <MDBModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{t("Delete Discount Code")}</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={() => setShowDeleteModal(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {t("Are you sure you want to delete the discount code")} "{selectedDiscount?.code}"?
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => setShowDeleteModal(false)}>
                  {t("Cancel")}
                </MDBBtn>
                <MDBBtn color="danger" onClick={handleDeleteDiscount} disabled={isLoading}>
                  {isLoading ? <MDBSpinner size="sm" /> : t("Delete")}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBCardBody>
    </MDBCard>
  );
}