import React, { useState, useEffect, useContext } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { 
  MDBBtn, 
  MDBCard, 
  MDBCardBody, 
  MDBCol, 
  MDBInput, 
  MDBRow, 
  MDBCheckbox,
  MDBTypography,
  MDBSpinner
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../App';

import { useTicket } from '../hooks/useTicket';
import { usePrintTicket } from '../hooks/usePrintTicket';
import TicketPDF from '../components/TicketPDF';
import getEventDays from '../../../../utils/getEventDays';
import { isoToGermanDate } from '../../../../utils/dateTools';

/**
 * Print tab component for ticket printing
 * @param {Object} props - Component props
 * @param {Object} props.selectedEvent - The selected event object
 * @param {Object} props.initialTicketData - Initial ticket data (from search)
 * @returns {JSX.Element} - Component
 */
const PrintTab = ({ selectedEvent, initialTicketData = {} }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  
  const [ticketId, setTicketId] = useState('');
  const [scanDevice, setScanDevice] = useState('');
  const [autoPrint, setAutoPrint] = useState(false);
  
  const [ticketData, setTicketData] = useState({
    firstName: '',
    lastName: '',
    title: '',
    company: '',
    eventDays: [],
    email: '',
    phone: '',
    visitorType: ''
  });
  
  const { 
    getTicketById, 
    createTicketScan,
    ticketIdError,
    isLoading
  } = useTicket(user, selectedEvent?.id);
  
  const {
    pdfTicketContent,
    generateTicketPdf,
    printTicket
  } = usePrintTicket();

  // Initialize with data from props if available
  useEffect(() => {
    if (initialTicketData && Object.keys(initialTicketData).length > 0) {
      setTicketData(initialTicketData);
    }
  }, [initialTicketData]);
  
  // Handle ticket fetching when user enters a ticket ID
  const handleGetTicket = async () => {
    const fetchedTicket = await getTicketById(ticketId);
    if (fetchedTicket) {
      setTicketData(fetchedTicket);
      createTicketScan(fetchedTicket.id, scanDevice);
      
      if (autoPrint) {
        await printTicket(fetchedTicket, TicketPDF);
      }
    }
    setTicketId('');
  };
  
  // Generate PDF when ticket data changes
  useEffect(() => {
    if (Object.keys(ticketData).length > 0 && ticketData.firstName) {
      generateTicketPdf(ticketData, TicketPDF);
    }
  }, [ticketData]);

  // Update a field in ticket data
  const updateTicketData = (field, value) => {
    setTicketData(prev => ({ ...prev, [field]: value }));
  };

  // Handle event day selection
  const handleDaySelection = (day, checked) => {
    setTicketData(prev => ({
      ...prev,
      eventDays: checked
        ? [...(prev.eventDays || []), day]
        : (prev.eventDays || []).filter(d => d !== day)
    }));
  };

  return (
    <MDBRow>
      <MDBCol md="6" xl="8">
        <MDBCard>
          <MDBCardBody className="px-xl-5">
            <MDBRow>
              <MDBCol size="10">
                <MDBInput 
                  label={t("Ticket ID")} 
                  value={ticketId} 
                  onChange={(e) => setTicketId(e.target.value)} 
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleGetTicket();
                    }
                  }}
                />
                {ticketIdError && <div className="text-danger">{ticketIdError}</div>}
              </MDBCol>
              <MDBCol size="2">
                <MDBCheckbox 
                  label={t("Auto print")} 
                  checked={autoPrint} 
                  onChange={(e) => setAutoPrint(e.target.checked)} 
                />
              </MDBCol>
            </MDBRow>
        
            <hr className="my-4" />
            <h6 className="mt-3">{t("Personal information")}</h6>
            <MDBRow>
              <MDBCol md="2">
                <MDBInput 
                  label={t("Title")} 
                  value={ticketData.title} 
                  onChange={(e) => updateTicketData('title', e.target.value)} 
                />
              </MDBCol>
              <MDBCol md="5">
                <MDBInput 
                  label={t("First name")} 
                  value={ticketData.firstName} 
                  onChange={(e) => updateTicketData('firstName', e.target.value)} 
                />
              </MDBCol>
              <MDBCol md="5">
                <MDBInput 
                  label={t("Last name")} 
                  value={ticketData.lastName} 
                  onChange={(e) => updateTicketData('lastName', e.target.value)} 
                />
              </MDBCol>
            </MDBRow>
            <MDBInput 
              className="mt-3" 
              label={t("Company")} 
              value={ticketData.company} 
              onChange={(e) => updateTicketData('company', e.target.value)} 
            />
            
            <h6 className="mt-3">{t("QR-Code Contact Details")}</h6>
            <MDBInput 
              label={t("Email")} 
              value={ticketData.email} 
              onChange={(e) => updateTicketData('email', e.target.value)} 
            />
            <MDBInput 
              className="mt-3" 
              label={t("Phone")} 
              value={ticketData.phone} 
              onChange={(e) => updateTicketData('phone', e.target.value)} 
            />

            <h6 className="mt-3">{t("Visiting days")}</h6>
            {selectedEvent && getEventDays(selectedEvent).map((day, index) => (
              <MDBCheckbox 
                key={index} 
                label={isoToGermanDate(day)} 
                checked={ticketData?.eventDays?.includes(day)} 
                onChange={(e) => handleDaySelection(day, e.target.checked)} 
              />
            ))}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      
      <MDBCol md="6" xl="4" className="text-center">
        <MDBBtn 
          className="mb-3" 
          onClick={() => printTicket(ticketData, TicketPDF)}
          disabled={isLoading || !ticketData.firstName}
        >
          {isLoading ? (
            <>
              <MDBSpinner size="sm" role="status" tag="span" className="me-2" />
              {t("Printing...")}
            </>
          ) : (
            t("Print")
          )}
        </MDBBtn>
    
        {pdfTicketContent && (
          <PDFViewer width="100%" height="600px">
            {pdfTicketContent}
          </PDFViewer>
        )}
        
        <MDBTypography 
          className={ticketData.visitorType === "staff" ? "fw-bold text-secondary display-6" : "fw-bold"}
        >
          {ticketData.visitorType === "staff" ? t("Exhibitor") : t("Visitor")}
        </MDBTypography>
      </MDBCol>
      
      <div className="fixed-bottom w-25 mx-auto">
        <MDBInput 
          label={t("Scan Device")} 
          value={scanDevice} 
          onChange={(e) => setScanDevice(e.target.value)} 
        />
      </div>
    </MDBRow>
  );
};

export default PrintTab;