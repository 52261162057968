import { useEffect, useState, createContext } from "react";
import { MDBBadge, MDBCard, MDBCardBody, MDBSpinner } from "mdb-react-ui-kit";
import { hotjar } from 'react-hotjar';
import { Amplify } from 'aws-amplify';
import config from './aws-exports'
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { Hub } from 'aws-amplify/utils';

import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';

import { useThemeDomain } from "./utils/useThemeDomain"; 
import { IndustryProvider } from "./utils/industryContextHook";

import Navigation from "./Navigation";
import NavigationAdmin from "./NavigationAdmin";
import Routes from "./Routes";

import AuthenticatorConfig from "./authenticatorConfig";

import { getFullUser } from "./graphql-custom/user";

// Import Signin Components
import SignIn from "./components-user/login/SignIn";
import SignUp from "./components-user/login/SignUp";
import Migrate from "./components-user/login/Migrate";
import ConfirmCustomSignUp from "./components-user/login/ConfirmCustomSignUp";
import ResetPassword from "./components-user/login/ResetPassword";
// import ForgotPassword from "./components-user/login/ForgotPassword";

import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";
const ZENDESK_KEY = "b36990bc-1506-4de9-aec8-308c5ca7bc20";
ZendeskAPI("webWidget", "setLocale", "de");


// Hotjar
hotjar.initialize(3789019, 6);

// I18n Config
I18n.putVocabularies(translations);
I18n.setLanguage('de');
I18n.putVocabularies(AuthenticatorConfig.translations);


Amplify.configure(config)

async function getIdToken() {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  console.log("idToken", idToken)
  return idToken;
}

// Dev State Coxtext
export const DevContext = createContext({
  isDevMode: false,
  isKioskMode: false,
  setIsDevMode: (value) => { },
  setIsKioskMode: (value) => { }
});

// Userdata
export const UserContext = createContext({
  user: {},
  setUserCompletedOnboarding: (user) => { },
  acceptEmploymentRequest: (companyEmploymentRequestsId) => { },
  addUserCondition: (condition) => { },
  reloadUserGroups: async () => {},
  reloadUser: async () => {}
});

// Get user data


function App() {
  useThemeDomain();

  const [devMode, setDevMode] = useState(false);
  const setIsDevMode = (value) => {
    setDevMode(value);
  }

  const [kioskMode, setKioskMode] = useState(false);
  const setIsKioskMode = (value) => {
    setKioskMode(value);
  }

  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [ authRoute, setAuthRoute ]= useState("signIn")
  // Addditional login flow states
  const [isStepConfirm, setIsStepConfirm] = useState(false);
  const [isStepConfirmResetPassword, setIsStepConfirmResetPassword] = useState(false);
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");

  const [isInitialized, setIsInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminAsUser, setAdminAsUser] = useState(localStorage.getItem("adminAsUser") === "true" ? true : false);
  const [user, setUser] = useState(null);
  const [DBUser, setDBUser] = useState(null);
  const [desktopFullscreen, setDesktopFullscreen] = useState(false);

  const setAdminAsUserHandler = (value) => {
    setAdminAsUser(value);
    localStorage.setItem("adminAsUser", value.toString());
  }

  

  const initUser = async () => {
  
    try {
      const setDBUserFunc = async () => {
        const idToken = await getIdToken();
        console.log("idToken", idToken)
        const userData = await getFullUser(idToken.payload?.["custom:userId"]);
        setDBUser(userData);
        console.log("loaded user data", userData)
        const groups = idToken.payload?.["cognito:groups"];
        if (groups && (groups.includes('Admin') || groups.includes('Moderator'))) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        hotjar.identify(idToken.payload?.sub, {email: idToken.payload?.email, userId: idToken.payload?.["custom:userId"]})
      }
      if(user) {

        setDBUserFunc()
      }
      
    } catch (error) {
      console.log("Error initializing user", error)
    }

  }

  const reloadUserGroups = async () => {
    try {
      const idToken = await getIdToken();
      const groups = idToken.payload?.["cognito:groups"];
      // Aktualisiere den Benutzer im Zustand nur mit den neuen Gruppeninformationen
      setUser(user => ({ ...user, groups }));
    } catch (error) {
      console.error("Error reloading user groups", error);
    }
  };

  const reloadUser = async () => {
    try {
      const newSession = await fetchAuthSession({ forceRefresh: true });
      const idToken = newSession.tokens.idToken;
      setUser(idToken.payload);
      const userData = await getFullUser(idToken.payload?.["custom:userId"]);
      setDBUser(userData);

    } catch (error) {
      console.error('Error reloading user session', error);
    }
  }

  useEffect(() => {

    initUser();
    
  }, [user])

  const checkCurrentUser = async () => {
    try {
      const idToken = await getIdToken();
      setUser(idToken.payload);
      setIsInitialized(true); // Benutzer ist eingeloggt und initialisiert
    } catch (error) {
      console.log("Kein Benutzer eingeloggt");
      setUser(null);
      setIsInitialized(false); // Kein Benutzer eingeloggt
    }
  };
  const onUserLogin = async () => {
        const idToken = await getIdToken();
        console.log("idToken", idToken)
        const userData = await getFullUser(idToken.payload?.["custom:userId"]);
        setDBUser(userData);
        console.log("loaded user data", userData)
        const groups = idToken.payload?.["cognito:groups"];
        if (groups && (groups.includes('Admin') || groups.includes('Moderator'))) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        hotjar.identify(idToken.payload?.sub, {email: idToken.payload?.email, userId: idToken.payload?.["custom:userId"]})
  }

  useEffect(() => {
    // Überprüft den aktuellen Anmeldestatus des Benutzers beim Start der App
    checkCurrentUser();

    // Listener für Authentifizierungsevents
    const listener = (data) => {
      console.log("Auth Event", data.payload.event);
      hotjar.event("Auth Event: " + data.payload.event);
      switch (data.payload.event) {
        case 'signedIn':
          console.log("New User signed in")
          onUserLogin();
          break;
        case 'signedOut':
          setUser(null);
          setIsInitialized(false);
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

  }, []);

  const setUserCompletedOnboarding = (hasCompletedOnboarding) => {
    setUser({ ...user, hasCompletedOnboarding });
  }

  const acceptEmploymentRequest = (companyEmploymentRequestsId) => {
    setDBUser({ ...DBUser, employeeProfiles: {items: DBUser.employeeProfiles.items.map(profile => profile.company.id === companyEmploymentRequestsId ? { ...profile, status: "active" } : profile) }});
  }

  const addUserCondition = (condition) => {
    setDBUser({ ...DBUser, conditions: [...(DBUser.conditions || []), condition] });
  }

  const zendeskSettings = {
    setLocale: "de",//I18n.language,
    color: {
      theme: "#1f3241"
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help"
      }
    },
    // contactForm: {
    //   fields: [
    //     { id: "email", prefill: { "*": user?.email } }
    //   ]
    // }
  };
  


 //style={desktopFullscreen ? {margin: "3.3rem 0 0 0"} : {margin: "3.3rem 0 0 240px"}}

  return (
  <DevContext.Provider value={{isDevMode: devMode, setIsDevMode, isKioskMode: kioskMode, setIsKioskMode}}>
  <IndustryProvider>
  {
  authStatus  === "configuring" ? (
    <MDBSpinner color="primary" />
  ) : authStatus  === "authenticated" ? (
    
      <UserContext.Provider value={{user: {...user, ...DBUser}, setUserCompletedOnboarding, acceptEmploymentRequest, addUserCondition, reloadUserGroups, reloadUser}}>
      <main className={kioskMode ? "p-0 m-3" : "py-2 px-1 p-md-5"} > 
            <Routes isInitialized isAdmin={isAdmin} setAdminAsUser={setAdminAsUserHandler} adminAsUser={adminAsUser} setDesktopFullscreen={setDesktopFullscreen} />
        </main>
        <Zendesk defer zendeskKey={ZENDESK_KEY} {...zendeskSettings} contactForm={{fields: [{ id: "email", prefill: { "*": user?.email } }]}} onLoaded={() => console.log('is loaded')} />
    </UserContext.Provider>
    
   
    
  ) :
  window.location.href.includes("signupstate=migrate") ?
  <ConfirmCustomSignUp user={user} setAuthRoute={setAuthRoute} />
  :
  authRoute === "signIn" ?
  <>
    <Zendesk defer zendeskKey={ZENDESK_KEY} {...zendeskSettings} contactForm={{fields: [{ id: "email", prefill: { "*": user?.email } }]}} onLoaded={() => console.log('is loaded')} />
    <SignIn user={user} setAuthRoute={setAuthRoute} setIsStepConfirm={setIsStepConfirm} setEmail={setEmail} setPasswordSignUp={setPassword} />
  </>
  
  : (authRoute === "signUp") ?
  <SignUp isStepConfirm={isStepConfirm} setIsStepConfirm={setIsStepConfirm} email={email} password={password} user={user} authRoute={authRoute} setAuthRoute={setAuthRoute} />
  : authRoute === "migrate" ?
  <Migrate user={user} setAuthRoute={setAuthRoute} />
  :  authRoute === "resetPassword" ?
  <ResetPassword isStepConfirmResetPassword={isStepConfirmResetPassword} setIsStepConfirmResetPassword={setIsStepConfirmResetPassword} user={user} setAuthRoute={setAuthRoute} />
  :
  <>
  <MDBCard>
    <MDBCardBody>
      <h1>404 - Seite nicht gefunden</h1>
      <p>Die angeforderte Seite konnte nicht gefunden werden.</p>
    </MDBCardBody>
    </MDBCard>
  </>
  }
  </IndustryProvider>
  </DevContext.Provider>
)}

export default App;


