import React, { useEffect, useState } from 'react';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { getLocalizedText } from "../../utils/localizedText";

// Styles angepasst für optimale Mobilanzeige
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 10,
  },
  eventImage: {
    width: '100%',
    objectFit: 'cover',
    marginBottom: 10,
  },
  qrCode: {
    width: 150,
    height: 150,
    alignSelf: 'center',
    marginVertical: 10,
  },
  eventName: {
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 5,
    },
  name: {
    fontSize: 14,
    textAlign: 'center',
    marginVertical: 5,
  },
  daysContainer: {
    marginVertical: 5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  days: {
    fontSize: 12,
    textAlign: 'center',
  },
});

const MobileTicketPdf = ({ event, qrCodeValue, ticketOwnerName, selectedDays }) => {
  const [qrImageUrl, setQrImageUrl] = useState('');

  // QR-Code generieren
  useEffect(() => {
    QRCode.toDataURL(qrCodeValue).then(setQrImageUrl);
  }, [qrCodeValue]);

  
  return (
    <Document>
      <Page size={{ width: 250, height: 400 }} style={styles.page}>
      {qrImageUrl && <Image src={qrImageUrl} style={styles.qrCode} />}
        <Text style={styles.eventName}>{getLocalizedText(event.name)}</Text>
        <Text style={styles.name}>{ticketOwnerName}</Text>
        <View style={styles.daysContainer}>
          {selectedDays.map((day, index) => (
            <Text key={index} style={styles.days}>
              {day}
            </Text>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default MobileTicketPdf;
