
import React, { useEffect, useState } from 'react';
import EmployeeSelector from "../../components-user/news/EmployeeSelector";
import { MDBInput, MDBCheckbox, MDBBtn, MDBRow, MDBCol, MDBTypography, MDBInputGroup, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBFile, MDBSpinner, MDBIcon, MDBTextArea, MDBSelect } from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML} from 'draft-js';
import {convertHTMlToRaw} from "../../utils/HTMLDraft";
import SelectTopics from "../../components-user/topics/SelectTopics";
import { generateClient } from 'aws-amplify/api';
import { useNavigate, useParams } from "react-router-dom";


const client = generateClient();

const CreateNewsPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { companyId, newsId } = useParams();

    const { t, i18n } = useTranslation();
    const [newsData, setNewsData] = useState({
        name: [],
        content: [],
        image: {},
        file: {},
        cMSNewsEmployeeId: "",
        cMSNewsTopicId: "",
        conditions: [],
    });

    const getNewsData = async () => {
      try {
        const newsData = await client.graphql({
          variables: { id: newsId },
          query: /* GraphQL */ `
          query GetCMSNews($id: ID!) {
            getCMSNews(id: $id) {
              id
              type
              company {
                id
                name
                status
                createdAt
                companyAdminId
                image {
                    main {
                        fileName
                        alt
                    }
                    }
                adminEmployees {
                    items {
                      status
                      image {
                        fileName
                        alt
                      }
                      user {
                        lastName
                        firstName
                        title
                        contact {
                          email
                          phone
                        }
                      }
                    }
                  }
              }
              employee {
                id
                contact {
                  email
                  phone
                  mobile
                  fax
                  address {
                    street
                    streetNumber
                    company
                    zip
                    city
                    countryCode
                  }
                }
                user {
                  id
                  email
                  firstName
                  middleName
                  lastName
                  salutation
                  title
                  status
                  createdAt

                } 
              }
              topic {
                id
                name {
                  language
                  text
                }
                color
                topicGroup {
                  id
                  color
                }
                topicGroupTopicsId
              }
              name {
                language
                text
              }
              image {
                fileName
                alt
              }
              previewImage {
                fileName
                alt
              }
              excerpt {
                language
                text
              }
              interests {
                name {
                  language
                  text
                }
                id
              }
              content {
                language
                text
              }
              file {
                url
                s3Path
                fileName
                type
                name
              }
              conditions {
                name
                accepted
              }
              status
              approved
              adminComment
              isHero
              createdAt
              updatedAt
              companyNewsId
              employeeNewsId
              cMSNewsEmployeeId
              cMSNewsTopicId
              owner
              __typename
            }
          }
        `,
        });
        console.log(newsData);
       
        return {
          ...newsData.data.getCMSNews,
          topics: newsData.data.getCMSNews.topics?.items?.map(topic => topic.topicId) || []};
      } catch (error) {
        console.warn("Error getting news data", error);
      }
    };

  const intialLoadNews = async () => {
    console.log("loading news");
    setIsLoading(true);
    const dbNews = await getNewsData();
    setNewsData(dbNews);
    if(dbNews.content) {
      const deContent = dbNews.content.find(c => c.language === "de")?.text;
      const enContent = dbNews.content.find(c => c.language === "en")?.text;
      setEditorState({
        de: deContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(deContent)
                        )
                      ) : EditorState.createEmpty(),
        en: enContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(enContent)
                        )
                      ) : EditorState.createEmpty(),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!companyId) {
      console.warn("No company id provided");
      return;
    } else if (!newsId) {
      console.log("No lecture id provided");
      return;
    } else {
      intialLoadNews();
    }
  }, []);

 

  const [validationErrors, setValidationErrors] = useState([]);

  
  const validateNews = () => {
    const errors = [];
    if (newsData.name.length !== 2) {
      errors.push(t("Please enter a title in both languages"));
    }
    // if(newsData.content.find(t => t.language === "de")?.text?.length < 1000 || newsData.content.find(t => t.language === "en")?.text?.length < 1000) {
    //   errors.push(t("The content must not be longer than 1000 characters"));
    // }
    if(!newsData.cMSNewsTopicId) {
      errors.push(t("Please select a topic"));
    }
    if(newsData.content.length !== 2) {
      errors.push(t("Please enter content in both languages"));
    }
    if(!newsData.cMSNewsEmployeeId) {
      errors.push(t("Please select an employee"));
    }
    if(!newsData.image?.fileName) {
      errors.push(t("Please upload an image"));
    }
    if(newsData.image?.fileName && !newsData.conditions?.find(c => c.name === "image")?.accepted) {
      errors.push(t("Please agree to the image conditions"));
    }
    if(newsData.file?.fileName && !newsData.conditions?.find(c => c.name === "file")?.accepted) {
      errors.push(t("Please agree to the file conditions"));
    }

    setValidationErrors(errors);
    return errors.length === 0;
  }

  const createNews = async () => {
    try {
      if(!validateNews()) {
        return;
      }
      setIsLoading(true);
      const response = await client.graphql({
        query: newsId ? /* GraphQL */ `
        mutation UpdateCMSNews($input: UpdateCMSNewsInput!) {
          updateCMSNews(input: $input) {
            id  
          }
        }
        ` : /* GraphQL */ `
        mutation CreateCMSNews(
          $input: CreateCMSNewsInput!
          $condition: ModelCMSNewsConditionInput
        ) {
          createCMSNews(input: $input, condition: $condition) {
              id
            }
          }
        `,
        variables: {
          input: {
            companyNewsId: companyId,
            name: newsData.name,
            content: newsData.content,
            image: newsData.image,
            file: newsData.file,
            cMSNewsEmployeeId: newsData.cMSNewsEmployeeId,
            cMSNewsTopicId: newsData.cMSNewsTopicId,
            conditions: newsData.conditions,
            ...newsId ? {id: newsId} : {},
            adminComment: newsData.adminComment,
            status: newsData.status,
            approved: newsData.approved
          },
        },
      });
      console.log("response", response);
      setTimeout(() => {
        intialLoadNews();
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

    // Topics
  // Select Topics 
  const [topics, setTopics] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState(newsData.topics || []);


  const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);

  const handleSetSelectedTopics = (topics) => {
    setSelectedTopics(topics);
    setNewsData(oldNewsData => ({...oldNewsData, cMSNewsTopicId: topics[0]}));
    // setLectureData(oldLecture => ({...oldLecture, topics}));
  }

  // Topics data

  const getTopics = async () => {
      try {
        const topicGroupsWithTopics = await client.graphql({
          query: /* GraphQL */ `
          query ListTopicGroups(
            $filter: ModelTopicGroupFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                color
                name {
                  language
                  text
                }
                topics {
                  items {
                    id
                    name {
                      language
                      text
                    }
                    topicGroupTopicsId
                  }
                }
              }
              nextToken
              __typename
            }
          }
        `
          });
          setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
          const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
          setTopics(topics);

      } catch (error) {
          console.log("error on fetching topics", error);
      }
  }

  useEffect(() => {
      if (topics.length === 0) {
          getTopics();
      }
  }, [topics]);

  const Topics = ({topics, topicGroups, selectedTopics}) => {

    // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
    if(topics?.length === 0 || topicGroups?.length === 0 || selectedTopics?.length === 0) {
        return null;
    }
    let sortedTopics = [];
    selectedTopics.forEach((selectedTopic) => {
        const topicData = topics.find((topic) => topic.id === selectedTopic);
        const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
        const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
        if (topicGroupIndex === -1) {
            sortedTopics.push({...topicGroup, topics: [topicData]});
        } else {
            sortedTopics[topicGroupIndex].topics.push(topicData);
        }
    });
    return (
        <MDBRow className="my-2">
            {sortedTopics.map((topicGroup) => {
                return (
                    <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
                        <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                        <MDBRow>
                            {topicGroup.topics.map((topic) => {
                                return (
                                    <MDBCol size="12" key={topic.id}>
                                        <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                    </MDBCol>
                                )
                            })}
                        </MDBRow>
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}

// Select Employee
const [employee, setEmployee] = useState();
const [selectEmployeeModal, setSelectEmployeeModal] = useState(false);
const toggleSelectEmployeeModal = () => setSelectEmployeeModal(!selectEmployeeModal);

const getEmployee = async () => {
    try {
      const employee = await client.graphql({
        query: /* GraphQL */ `
        query GetEmployee($id: ID!) {
            getEmployee(id: $id) {
              id
              user {
                id
                firstName
                lastName
                title
              }
              image {
                fileName
                alt
              }
            }
          }
        `,
        variables: { id: newsData.cMSNewsEmployeeId },
      });
      setEmployee(employee.data.getEmployee);
    } catch (error) {
      console.log("error on fetching employee", error);
    }
  };


useEffect(() => {
    if (newsData.cMSNewsEmployeeId) {
        getEmployee();
    }
}, [newsData.cMSNewsEmployeeId]);

// Editor
// Editor
const [editorState, setEditorState] = useState({
    de: EditorState.createEmpty(),
    en: EditorState.createEmpty(),
})
const onEditorStateChange = (editorState, language ) => {
  setEditorState(oldState => ({...oldState, [language]: editorState}));
  setNewsData(oldNewsData => ({...oldNewsData, content: [...oldNewsData.content.filter(c => c.language !== language), {language, text: convertHTMlToRaw(editorState)}] }));

} 

// Image and Script

const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState(false);
const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState(null);

const handleSetFile = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
  try {
    setIsUploadingLectureFiles(true);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onerror = error => {
    console.log('Error: ', error);
    setUploadingLecturesErrors(error);
  }
  reader.onload = async () => {
   try {
    const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
    const response = await client.graphql({
      query: /* GraphQL */ `
        mutation UploadLectureFile($base64FileBuffer: String!, $companyId: String!) {
          uploadLectureFile(base64FileBuffer: $base64FileBuffer, companyId: $companyId) {
            message
            fileName
          }
        }
      `,
      variables: {
        base64FileBuffer,
        companyId,
      },
    });
    console.log("response upload file", response);
    setNewsData(oldNewsData => ({...oldNewsData, file: {fileName: response.data.uploadLectureFile.fileName, type: "newsFile"}}));
    
   } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
   }
  };
  
  setIsUploadingLectureFiles(false);
  setUploadingLecturesErrors(null);
  } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
  }
  
}
  
}

const [isUploadingLectureImage, setIsUploadingLectureImage] = useState(false);
const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);

const handleSetImage = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
  if(file) {
    try {
      setIsUploadingLectureImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error reader: ', error);
      setUploadingLectureImageError(error);
    }
    reader.onload = async () => {
      try {
        console.log(reader.result)
        const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
        const response = await client.graphql({
          query: /* GraphQL */ `
          mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
            uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
          }
        `,
          variables: {
            imageBuffer: base64FileBuffer,
            imageType: "newsImage",
          },
        });
        console.log("response", response);
        const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
        setNewsData(oldNewsData => ({...oldNewsData, image: {fileName, alt: "Lecture Image"}}));
        
        } catch (error) {
          console.log("error", error);
          setIsUploadingLectureImage(false);
          setUploadingLectureImageError(error.errors[0].message);
        }
    }
    setIsUploadingLectureImage(false);
    setUploadingLectureImageError(null);
    } catch (error) {
      console.log("error", error);
      setIsUploadingLectureImage(false);
      setUploadingLectureImageError(error.errors[0].message);
    }
  }
}


return (
<>

    <MDBCard>
      <MDBCardBody>
        <MDBRow>
          <MDBCol size="12" lg="6" xl="4">
            <MDBTypography tag="h5">{t("Event")}</MDBTypography>
            <MDBTypography tag="h6">Lounges 2024</MDBTypography>
            <br></br>
            <MDBTypography tag="h5">{t("Company")}</MDBTypography>
            <>
              {newsData.company?.image?.main?.fileName ?
                  <div className='d-flex align-items-center'>
                      {newsData.company?.image?.main?.fileName ?
                          <img
                              src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+newsData.company.image.main.fileName}
                              className="rounded-circle border"
                              style={{ height: "50px", width: "50px", objectFit: "contain" }}
                              alt={newsData.company.image.alt}
                          />
                      :
                          <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                  
                      }
                      <div className='ms-3'>
                      <p className='fw-bold mb-1'>{newsData.company?.name}</p>
                      {/* <p className='text-muted mb-0'><a href={company.website} target="_blank" rel="noreferrer">{company.website}</a></p> */}
                      </div>
                  </div>
                  :
                  <div className='d-flex align-items-center'>
                    <MDBIcon
                        className="rounded-circle border"
                        style={{
                            height: "50px",
                            width: "50px",
                            textAlign: "center",
                            paddingTop: "12px",
                            fontSize: "20px",
                        }}
                        fas
                        icon="user-alt"
                    />
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{newsData.company?.name}</p>
                      <p className='text-muted mb-0'><a href={newsData.company?.website} target="_blank" rel="noreferrer">{newsData.company?.website}</a></p>
                    </div>
                  </div>
              }
            </>
            <MDBTypography tag="h5" className="mt-2 mt-xl-3">{t("Company Admins")}</MDBTypography>
            {newsData.company?.adminEmployees?.items?.length > 0 && newsData.company?.adminEmployees?.items.map((admin, index) => (
              <div className='d-flex align-items-center'>
              {admin.image?.fileName ?
                          <img
                              src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ admin.image.fileName}
                              className="rounded-circle border"
                              style={{ height: "50px", width: "50px", objectFit: "contain" }}
                              alt={admin.image.alt}
                          />
                          :
                          <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                          
              }
                <div className='ms-3'>
                <p className='fw-bold mb-1'>{admin.user.title ? admin.user.title + " " : ""}{admin.user.firstName} {admin.user.middleName} {admin.user.lastName}{admin.status === "pending" && <MDBChip className="bg-warning text-white bg-opacity-75 inline" style={{maxWidth: "fit-content"}}>{t("Pending")}</MDBChip>}</p>
                <p className='text-muted mb-0'>{admin.user.contact?.email}</p>
                <p className='text-muted mb-0'>{admin.user.contact?.phone}</p>
                </div>
              </div>
            ))}
            
          </MDBCol>
          <MDBCol size="12" lg="6" xl="4">
            <MDBTypography tag="h5">{t("Erstellung & Update")}</MDBTypography>
            <MDBTypography tag="h6">{t("Erstellt")}: {new Date(newsData?.createdAt).toLocaleString("de-de")}</MDBTypography>
            <MDBTypography tag="h6">{t("Zuletzt aktualisiert")}: {new Date(newsData?.updatedAt).toLocaleString("de-de")}</MDBTypography>
            <MDBTypography tag="h5">{t("Comment")}</MDBTypography>
            <MDBTextArea datapath="adminComment" value={newsData?.adminComment || ""} onChange={e => setNewsData(old => ({...old, adminComment: e.target.value}))} />
          </MDBCol>
          <MDBCol size="12" lg="6" xl="4">
          
         
            {/* Status */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Status")}</MDBTypography>
              <MDBSelect
                size="sm"
                data={[
                  { text: t("Created"), value: "created" },
                  { text: t("Approved"), value: "checked" },
                  { text: t("Not Approved"), value: "not_approved" }
                ]}
                datapath="status"
                value={newsData?.status}
                onChange={e => setNewsData(old => ({...old, status: e.value}))}
              />
            </div>
            {/* Approved */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Approved")}</MDBTypography>
              <div>

                  <MDBCheckbox
                    name='approved'
                    id='approved_yes'
                    label={t("Yes")}
                    inline
                    checked={newsData?.approved === true}
                    onChange={(e) => setNewsData(oldNewsData => ({...oldNewsData, approved: true}))}
                  />

                  <MDBCheckbox
                    name='approved'
                    id='approved_no'
                    label={t("No")}
                    inline
                    checked={!newsData?.approved}
                    onChange={(e) => setNewsData(oldNewsData => ({...oldNewsData, approved: false}))}
                  />
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        {
          isLoading ? (
            <MDBSpinner color="primary" className="float-end mt-3" />
          )
          :
          <div className="float-end mt-3">
          <MDBBtn color="primary" onClick={() => createNews()}>{t("Save")}</MDBBtn>
          <br></br>
          <MDBBtn color="link" onClick={() => navigate(-1)}>{t("Back")}</MDBBtn>
          </div>
          
          
        }
        <br></br>
        
      </MDBCardBody>
    </MDBCard>
<MDBRow className=" mt-5 h-auto d-flex justify-content-center">
<MDBCol size="12" xl="12" xxl="11">
<MDBCard>
<MDBCardBody className="px-1 px-md-5">

{/* Topics */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
            {t("Topic")}
            </MDBTypography>
           
        </div>
        <div className="w-100 text-center">
        <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
            {t("Select Topic")}
        </MDBBtn>
        <MDBRow className="w-100">
            <MDBCol>
            {topics.length > 0 && topicGroups.length > 0 && newsData.cMSNewsTopicId && (
                <Topics
                topics={topics}
                topicGroups={topicGroups}
                selectedTopics={[newsData.cMSNewsTopicId] || []}
                setSelectedTopics={handleSetSelectedTopics}
                />
            )}
            </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
</MDBRow>
{/* Topics END */}

{/* TITEL */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="mb-1 mb-xl-2" />
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
                {t("Title")}
            </MDBTypography>
        </div>
        <MDBInputGroup className="mb-1" textAfter={<span style={newsData.name?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((newsData?.name?.length > 0? newsData.name.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
            <div className="input-group-text">
            <ReactCountryFlag
                countryCode="de"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            /> 
            </div>
            <MDBInput
            label={t("Title German")}
            type="text"
            maxLength="60"
            value={newsData?.name?.find((t) => t.language === "de")?.text || ""}
            onChange={(e) => setNewsData({...newsData, title: [{language: "de", text: e.target.value}, ...newsData.name.filter((t) => t.language !== "de")]})}
            />
        </MDBInputGroup>
        <MDBInputGroup className="mb-1" textAfter={<span style={newsData.name?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((newsData?.name?.length > 0? newsData.name.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
            <div className="input-group-text">
            <ReactCountryFlag
                countryCode="gb"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            />
            </div>
            <input
            className="form-control"
            placeholder={t("Title English")}
            type="text"
            maxLength="60"
            value={newsData?.name?.find((t) => t.language === "en")?.text || ""}
            onChange={(e) => setNewsData({...newsData, title: [{language: "en", text: e.target.value}, ...newsData.name.filter((t) => t.language !== "en")]})}
            
            />
        </MDBInputGroup>
    </MDBCol>
</MDBRow>
{/* TITEL END */}

{/* LECTURE DESCRIPTION */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
        <MDBTypography tag="h5">
        {t("Content")}
        </MDBTypography>
    </div>
    <ReactCountryFlag
        countryCode="de"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    /> 
    <Editor
        editorState={editorState["de"]}
        editorClassName="md-textarea form-control"
        onEditorStateChange={e => onEditorStateChange(e, "de")}
      />
      <br></br>
      <ReactCountryFlag
        countryCode="gb"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    />
    <Editor
        editorState={editorState["en"]}
        editorClassName="md-textarea form-control"
        onEditorStateChange={e => onEditorStateChange(e, "en")}
      />
    </MDBCol>
</MDBRow>
{/* LECTURE DESCRIPTION END */}

{/* LECTURE IMAGE */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("Image")}
      </MDBTypography>

    </div>
    <div className="w-100 text-center mb-2 mb-lg-4">
    <img
      src={newsData?.image?.fileName ? 
      "https://iec-cp-public.s3.eu-central-1.amazonaws.com/newsImages/" + newsData?.image.fileName: "https://via.placeholder.com/600x400"}
      className="img-thumbnail"
      style={{ height: "200px", width: "400px", objectFit: "cover" }}
      alt='...' />
    </div>
    <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
    
    
   
    <div className="mt-3">
      <MDBCheckbox
        name="image-condition"
        id="image-condition"
        label={t("I agree that the image will be made public and we have the rights to use the image.")}
        checked={newsData?.conditions?.findIndex(c => c.name === "image") > -1 ? newsData?.conditions[newsData?.conditions?.findIndex(c => c.name === "image")].accepted : false}
        onChange={(e) => {setNewsData(oldNewsData => ({...oldNewsData, conditions: [{name: "image", accepted: e.target.checked}, ...oldNewsData.conditions?.filter(c => c.name !== "image")]}))}}
      />
    </div>
  </MDBCol>
 
</MDBRow>
{/* LECTURE IMAGE END */}

{/* LECTURE SCRIPT */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("File")}
      </MDBTypography>
    </div>
    {newsData?.file?.fileName && (
      <p className="text-muted">{t("File uploaded successfully")}</p>
    )
    }
    <MDBFile id='fileDE' onChange={e => handleSetFile(e.target.files[0])} />
      
    <div className="mt-3">
      <MDBCheckbox
        name="script-condition"
        id="script-condition"
        label={t("I confirm that I have the rights to use the file and that it can be made public.")}
        checked={newsData?.conditions?.findIndex(c => c.name === "file") > -1 ? newsData?.conditions[newsData?.conditions?.findIndex(c => c.name === "file")].accepted : false}
        onChange={(e) => {setNewsData(oldNewsData => ({...oldNewsData, conditions: [{name: "file", accepted: e.target.checked}, ...oldNewsData.conditions?.filter(c => c.name !== "file")]}))}}
      />
    </div>
    
  </MDBCol>
  
</MDBRow>
{/* LECTURE SCRIPT END */}

{/* Employee */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="my-4" />
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
            {t("Contact person")}
            </MDBTypography>
        </div>
        <div className="w-100 text-center">
        <MDBBtn onClick={() => toggleSelectEmployeeModal()} color="primary" outline>
            {t("Select employee")}
        </MDBBtn>
        {employee && (
          <div className="d-flex gap-4 align-items-center">
                  {employee.image?.fileName ? (
                    <img
                      src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                      className="rounded-circle border"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                      }}
                      alt={employee.image.alt}
                    />
                  ) : (
                    <MDBIcon
                      className="rounded-circle border"
                      style={{
                        height: "50px",
                        width: "50px",
                        textAlign: "center",
                        paddingTop: "12px",
                        fontSize: "20px",
                      }}
                      fas
                      icon="user-alt"
                    />
                  )}
                  <div>
                    <h6>{`${employee.user?.title ? employee.user?.title + " ": ""}${employee.user?.firstName} ${employee.user?.middleName || ""} ${employee.user?.lastName}`}</h6>
                    <span>{employee.department?.items?.[0]?.department.name[0].text}</span>
                  </div>
                </div>
        )}
      </div>
    </MDBCol>
</MDBRow>
{/* Employee END */}
<hr className="my-4" />
<MDBRow className="d-flex justify-content-evenly">
<MDBCol size="12" lg="10" xl="8" xxl="7">
{validationErrors.length > 0 && (
  <div className="alert alert-danger" role="alert">
    {validationErrors.map((error) => (
      <p key={error}>{error}</p>
    ))}
  </div>
)}


</MDBCol>
</MDBRow>

</MDBCardBody>
</MDBCard>
</MDBCol>
</MDBRow>

<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics}
                topicGroups={topicGroups}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={selectedTopics}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics}
                parentTopicGroups={topicGroups}
                maxSelect={1}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
<MDBModal open={selectEmployeeModal} onClose={() => setSelectEmployeeModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>{t("Select employee")}</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectEmployeeModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <EmployeeSelector
              companyId={companyId}
              selectedEmployees={newsData.cMSNewsEmployeeId ? [newsData.cMSNewsEmployeeId]: []}
              setSelectedEmployees={(employees) => setNewsData({...newsData, cMSNewsEmployeeId: employees[0]})}
              maxSelect={1}
              />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectEmployeeModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

</>
);
};

export default CreateNewsPage;
