import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBTypography, MDBCardImage, MDBBtnGroup, MDBIcon, MDBSpinner, MDBInput, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { isoToGermanDate } from "../../utils/dateTools";
import { generateClient } from 'aws-amplify/api';
import { useContext, useEffect, useState } from "react";
import { getEvent } from "../../graphql/queries";
import getEventDays from "../../utils/getEventDays";
import shortUUID from 'short-uuid';
import { getLocalizedText } from "../../utils/localizedText";
// Import PDF components
import { PDFDownloadLink } from '@react-pdf/renderer';
import MobileTicketPDF from './MobileTicketPDF';
import { logError } from "../../utils/logging";
import { MDBToast } from "mdb-react-ui-kit";
import { UserContext } from "../../App";

const client = generateClient();

export default function ShowTicket({ ticketId, handleDeleteTicket, inModal }) {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const [ticket, setTicket] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [eventDays, setEventDays] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [isEditDays, setIsEditDays] = useState(false);
    const [isEditContact, setIsEditContact] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastColor, setToastColor] = useState("success");
    const [contactData, setContactData] = useState({
        personalAddress: {
            title: "",
            firstName: "",
            lastName: "",
            company: ""
        },
        personalContact: {
            phone: "",
            email: ""
        }
    });

    // Fetch ticket data
    useEffect(() => {
        const fetchTicket = async () => {
            if (!ticketId) return;
            
            setIsLoading(true);
            try {
                const response = await client.graphql({
                    query: /* GraphQL */ `
                    query GetTicket($id: ID!) {
                      getTicket(id: $id) {
                        id
                        event {
                          id
                          ticketCancellationDeadline
                        }
                        type
                        eventDays
                        personalAddress {
                          firstName
                          lastName
                          company
                          title
                        }
                        personalContact {
                          phone
                          email
                        }
                        createdAt
                      }
                    }
                    `,
                    variables: { id: ticketId }
                });
                
                const ticketData = response.data.getTicket;
                if (ticketData) {
                    setTicket(ticketData);
                    setSelectedDays(ticketData.eventDays || []);
                    setContactData({
                        personalAddress: {
                            title: ticketData.personalAddress?.title || "",
                            firstName: ticketData.personalAddress?.firstName || "",
                            lastName: ticketData.personalAddress?.lastName || "",
                            company: ticketData.personalAddress?.company || ""
                        },
                        personalContact: {
                            phone: ticketData.personalContact?.phone || "",
                            email: ticketData.personalContact?.email || ""
                        }
                    });
                    
                    // Check if we should show download
                    if (new Date(ticketData.createdAt).getTime() + 10000 < new Date().getTime()) {
                        setShowDownload(true);
                    }
                } else {
                    console.error("Ticket not found");
                    setToastMessage(t("Ticket not found"));
                    setToastColor("warning");
                    setShowToast(true);
                }
            } catch (error) {
                console.error("Error fetching ticket:", error);
                logError({ 
                    message: "Error fetching ticket data", 
                    userId: ticketId, 
                    additionalData: { error } 
                });
                setToastMessage(t("Error loading ticket data"));
                setToastColor("danger");
                setShowToast(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTicket();
    }, [ticketId, t]);

    // Fetch event data once we have the ticket
    useEffect(() => {
        const fetchEvent = async () => {
            if (!ticket?.event?.id) return;
            
            try {
                const eventData = await client.graphql({
                    query: getEvent,
                    variables: { id: ticket.event.id }
                });
                
                const fetchedEvent = eventData.data.getEvent;
                if (fetchedEvent) {
                    setEvent(fetchedEvent);
                    setEventDays(getEventDays(fetchedEvent));
                } else {
                    console.error("Event not found");
                    setToastMessage(t("Event data not found"));
                    setToastColor("warning");
                    setShowToast(true);
                }
            } catch (error) {
                console.error("Error fetching event:", error);
                logError({ 
                    message: "Error fetching event data", 
                    userId: ticket?.id, 
                    additionalData: { error } 
                });
                setToastMessage(t("Error loading event data"));
                setToastColor("danger");
                setShowToast(true);
            }
        };

        if (ticket?.event?.id) {
            fetchEvent();
        }
    }, [ticket?.event?.id, t, ticket?.id]);

    // Check if download should be shown
    useEffect(() => {
        if (!ticket?.createdAt) return;

        const interval = setInterval(() => {
            if (new Date(ticket.createdAt).getTime() + 10000 < new Date().getTime()) {
                setShowDownload(true);
                clearInterval(interval);
            }
        }, 1000);
        
        return () => clearInterval(interval);
    }, [ticket?.createdAt]);

    const handleEditDays = () => {
        setIsEditDays(true);
    };

    const handleEditContact = () => {
        setIsEditContact(true);
    };

    const handleContactInputChange = (field, value, parent = 'personalAddress') => {
        setContactData(prev => ({
            ...prev,
            [parent]: {
                ...prev[parent],
                [field]: value
            }
        }));
    };

    const handleSaveContact = async () => {
        setIsSaving(true);
        try {
            const data = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateTicket(
                  $input: UpdateTicketInput!
                  $condition: ModelTicketConditionInput
                ) {
                  updateTicket(input: $input, condition: $condition) {
                    id
                    personalAddress {
                      title
                      firstName
                      lastName
                      company
                    }
                    personalContact {
                      phone
                      email
                    }
                  }
                }
              `,
                variables: { 
                    input: { 
                        id: ticket.id,
                        personalAddress: contactData.personalAddress,
                        personalContact: contactData.personalContact,
                        firstName: contactData.personalAddress.firstName,
                        lastName: contactData.personalAddress.lastName,
                        companyName: contactData.personalAddress.company
                    } 
                },
            });
            
            // Update local ticket data with the response
            setTicket(prev => ({
                ...prev,
                personalAddress: data.data.updateTicket.personalAddress,
                personalContact: data.data.updateTicket.personalContact
            }));
            
            setIsEditContact(false);
            setToastMessage(t("Contact information updated successfully"));
            setToastColor("success");
            setShowToast(true);
        } catch (error) {
            console.error(error);
            logError({ 
                message: error.message, 
                userId: ticket?.id, 
                additionalData: { error } 
            });
            setToastMessage(t("Error updating contact information"));
            setToastColor("danger");
            setShowToast(true);
        } finally {
            setIsSaving(false);
        }
    };

    const handleChangeDays = async () => {
        setIsSaving(true);
        try {
            const data = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateTicket(
                  $input: UpdateTicketInput!
                  $condition: ModelTicketConditionInput
                ) {
                  updateTicket(input: $input, condition: $condition) {
                    id
                    eventDays
                  }
                }
              `,
                variables: { 
                    input: { 
                        id: ticket.id, 
                        eventDays: selectedDays 
                    } 
                },
            });
            
            // Update local ticket data with the response
            setTicket(prev => ({
                ...prev,
                eventDays: data.data.updateTicket.eventDays
            }));
            
            setIsEditDays(false);
            setToastMessage(t("Days updated successfully. Please wait a few secords for the ticket to update."));
            setToastColor("success");
            setShowToast(true);
        } catch (error) {
            console.error(error);
            logError({ 
                message: error.message, 
                userId: ticket?.id, 
                additionalData: { error } 
            });
            setToastMessage(t("Error updating days"));
            setToastColor("danger");
            setShowToast(true);
        } finally {
            setIsSaving(false);
        }
    };

    const clickDeleteTicket = async () => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteTicket(
                  $input: DeleteTicketInput!
                  $condition: ModelTicketConditionInput
                ) {
                  deleteTicket(input: $input, condition: $condition) {
                    id
                  }
                }
                `,
                variables: { input: { id: ticket.id } },
            });
            
            if (handleDeleteTicket) {
                handleDeleteTicket(ticket.id);
            }
        } catch (error) {
            console.error(error);
            logError({ 
                message: error.message, 
                userId: ticket?.id, 
                additionalData: { error } 
            });
            setToastMessage(t("Error deleting ticket"));
            setToastColor("danger");
            setShowToast(true);
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
                <MDBSpinner role="status">
                    <span className="visually-hidden">{t("Loading")}</span>
                </MDBSpinner>
            </div>
        );
    }

    if (!ticket) {
        return (
            <MDBCard className={inModal ? "w-100 p-0 border-0 shadow-0" : ""}>
                <MDBCardBody>
                    <MDBTypography note noteColor="danger">
                        {t("Ticket not found")}
                    </MDBTypography>
                </MDBCardBody>
            </MDBCard>
        );
    }

    return (
        <>
            <MDBCard className={inModal ? "w-100 p-0 border-0 shadow-0" : ""}>
                <MDBCardBody>
                    {event?.image?.fileName && (
                        <MDBCardImage 
                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.image.fileName} 
                            position='top' 
                            alt={getLocalizedText(event.name)} 
                        />
                    )}
                    <MDBCardTitle className="mt-3">{t("Download Ticket")}</MDBCardTitle>
                    {showDownload && event && ticket?.personalAddress ? (
                        <MDBBtnGroup shadow='0' aria-label='Basic example' className="w-100">
                            {event?.image?.fileName && ticket?.id && ticket?.personalAddress?.firstName && ticket?.personalAddress?.lastName && (
                                <PDFDownloadLink
                                    document={
                                        <MobileTicketPDF
                                            eventImageUrl={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/${event?.image?.fileName}`}
                                            qrCodeValue={shortUUID().fromUUID(ticket.id)}
                                            ticketOwnerName={`${ticket.personalAddress.firstName} ${ticket.personalAddress.lastName}`}
                                            selectedDays={selectedDays.map(isoToGermanDate)}
                                            event={event}
                                            ticket={ticket}
                                        />
                                    }
                                    fileName={`Mobile_Ticket_${ticket.personalAddress.firstName}_${ticket.personalAddress.lastName}_&_${getLocalizedText(event.name)}.pdf`}
                                    className="btn ripple ripple-surface ripple-surface-dark btn btn-outline-secondary"
                                >
                                    {({ loading }) =>
                                        loading ? (
                                            <>
                                                <MDBIcon fas size='2x' icon="spinner" spin />
                                            </>
                                        ) : (
                                            <>
                                                <MDBIcon fas size='2x' icon="mobile" />
                                                <br />
                                                Mobile Ticket
                                            </>
                                        )
                                    }
                                </PDFDownloadLink>
                            )}

                            {ticket?.id && (
                                <a
                                    className="btn ripple ripple-surface ripple-surface-dark btn btn-outline-secondary"
                                    href={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/tickets/${ticket.id}.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                    download
                                >
                                    <MDBIcon fas size='2x' icon="file-pdf" />
                                    <br />
                                    A4 PDF Ticket
                                </a>
                            )}
                        </MDBBtnGroup>
                    ) : (
                        <div className="w-100 text-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <br />
                            <span>{t("Generating ticket")}</span>
                        </div>
                    )}

                    <MDBCardTitle className="mt-3">
                        {t("Selected Days")}
                        <MDBBtn
                            className='mx-2 my-0 py-0'
                            color='tertiary'
                            rippleColor='light'
                            disabled={
                                ticket?.event?.ticketCancellationDeadline && !((user?.["cognito:groups"]?.includes("Admin"))) && 
                                (() => {
                                    const deadline = new Date(ticket.event.ticketCancellationDeadline);
                                    deadline.setHours(23, 59, 59, 999); // Set to end of day
                                    return deadline < new Date();
                                })()
                            }
                            onClick={handleEditDays}
                        >
                            {t("Change")}
                        </MDBBtn>
                    </MDBCardTitle>
                    
                    {isEditDays ? (
                        <>
                            {eventDays && eventDays.map((day, index) => (
                                <MDBCheckbox
                                    key={index}
                                    label={isoToGermanDate(day)}
                                    id={"day" + index}
                                    checked={selectedDays?.includes(day)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            setSelectedDays(old => ([...old, day]).filter(Boolean));
                                        } else {
                                            setSelectedDays(old => old.filter(d => d !== day).filter(Boolean));
                                        }
                                    }}
                                />
                            ))}
                            <MDBBtn 
                                className="mt-2" 
                                color="primary" 
                                size="sm" 
                                onClick={handleChangeDays}
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <MDBSpinner size='sm' className='me-2' />
                                        {t("Saving...")}
                                    </>
                                ) : (
                                    t("Save")
                                )}
                            </MDBBtn>
                        </>
                    ) : (
                        <MDBCardText>
                            {selectedDays.map((day, index) => (
                                (index !== 0 ? ", " : "") + isoToGermanDate(day)
                            ))}
                        </MDBCardText>
                    )}

                    <MDBCardTitle className="mt-3">
                        {t("Badge Contact Data")}
                        <MDBBtn
                            className='mx-2 my-0 py-0'
                            color='tertiary'
                            rippleColor='light'
                            onClick={handleEditContact}
                        >
                            {t("Change")}
                        </MDBBtn>
                    </MDBCardTitle>
                    
                    {isEditContact ? (
                        <>
                            <MDBRow>
                                <MDBCol size="12" md="6">
                                    <MDBInput 
                                        label={t("Title")}
                                        value={contactData.personalAddress.title || ""}
                                        onChange={(e) => handleContactInputChange('title', e.target.value)}
                                        className="mb-3"
                                    />
                                </MDBCol>
                                <MDBCol size="12" md="6">
                                    <MDBInput 
                                        label={t("First Name")}
                                        value={contactData.personalAddress.firstName || ""}
                                        onChange={(e) => handleContactInputChange('firstName', e.target.value)}
                                        className="mb-3"
                                        required
                                    />
                                </MDBCol>
                                <MDBCol size="12" md="6">
                                    <MDBInput 
                                        label={t("Last Name")}
                                        value={contactData.personalAddress.lastName || ""}
                                        onChange={(e) => handleContactInputChange('lastName', e.target.value)}
                                        className="mb-3"
                                        required
                                    />
                                </MDBCol>
                                <MDBCol size="12" md="6">
                                    <MDBInput 
                                        label={t("Company")}
                                        value={contactData.personalAddress.company || ""}
                                        onChange={(e) => handleContactInputChange('company', e.target.value)}
                                        className="mb-3"
                                    />
                                </MDBCol>
                                <MDBCol size="12" md="6">
                                    <MDBInput 
                                        label={t("Phone")}
                                        value={contactData.personalContact.phone || ""}
                                        onChange={(e) => handleContactInputChange('phone', e.target.value, 'personalContact')}
                                        className="mb-3"
                                    />
                                </MDBCol>
                                <MDBCol size="12" md="6">
                                    <MDBInput 
                                        label={t("Email")}
                                        type="email"
                                        value={contactData.personalContact.email || ""}
                                        onChange={(e) => handleContactInputChange('email', e.target.value, 'personalContact')}
                                        className="mb-3"
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBBtn 
                                className="mt-2" 
                                color="primary" 
                                size="sm" 
                                onClick={handleSaveContact}
                                disabled={isSaving || !contactData.personalAddress.firstName || !contactData.personalAddress.lastName}
                            >
                                {isSaving ? (
                                    <>
                                        <MDBSpinner size='sm' className='me-2' />
                                        {t("Saving...")}
                                    </>
                                ) : (
                                    t("Save")
                                )}
                            </MDBBtn>
                        </>
                    ) : (
                        <MDBCardText>
                            {ticket.personalAddress?.title && <>{ticket.personalAddress.title} </>}
                            {ticket.personalAddress?.firstName} {ticket.personalAddress?.lastName}
                            {ticket.personalAddress?.company && <><br />{ticket.personalAddress.company}</>}
                            {ticket.personalContact?.phone && <><br />{t("Phone")}: {ticket.personalContact.phone}</>}
                            {ticket.personalContact?.email && <><br />{t("Email")}: {ticket.personalContact.email}</>}
                        </MDBCardText>
                    )}
                    
                    <MDBCardTitle>{t("Booking")}</MDBCardTitle>
                    <MDBCardText>
                        {t("Booking date")}: {isoToGermanDate(ticket.createdAt)}
                        <br />
                        {t("Invoice")}: {ticket.type === "default" 
                            ? t("No invoice available for free ticket.") 
                            : t("You will receive the invoice by email.")}
                    </MDBCardText>
                    <MDBCardTitle>{t("Cancelation")}</MDBCardTitle>
                    {ticket?.event?.ticketCancellationDeadline && !(user?.["cognito:groups"]?.includes("Admin")) ? (
                        (() => {
                            const deadline = new Date(ticket.event.ticketCancellationDeadline);
                            deadline.setHours(23, 59, 59, 999); // Set to end of day
                            return deadline >= new Date();
                        })() ? (
                            <>
                                <MDBTypography note noteColor="success" className="text-body">
                                    {t("You can cancel this ticket until")} {isoToGermanDate(ticket?.event?.ticketCancellationDeadline)}
                                </MDBTypography>
                                <MDBBtn 
                                    color="danger" 
                                    size="sm"
                                    onClick={() => {
                                        if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                                            clickDeleteTicket();
                                        }
                                    }}
                                >
                                    {t("Cancel ticket")}
                                </MDBBtn>
                            </>
                        ) : (
                            <MDBTypography note noteColor="danger">
                                {t("You cannot cancel this ticket anymore please contact the support.")}
                            </MDBTypography>
                        )
                    ) : (
                        <MDBBtn 
                            color="danger" 
                            size="sm"
                            onClick={() => {
                                if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                                    clickDeleteTicket();
                                }
                            }}
                        >
                            {t("Cancel ticket")}
                        </MDBBtn>
                    )}
                </MDBCardBody>
            </MDBCard>

            <MDBToast
                color={toastColor}
                open={showToast}
                offset={50}
                onClose={() => setShowToast(false)}
                autohide
                delay={3000}
                position='top-right'
                appendToBody
                bodyContent={toastMessage}
            />
        </>
    );
}